import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import Add from "@material-ui/icons/Add";
import withSizes from "react-sizes";
import { DialogContent, DialogActions, Dialog, Button } from "@material-ui/core";

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600
});

function SimpleDialog(props) {
	const { onClose, open } = props;
	const theme = useTheme();

	function handleClose() {
		onClose(true);
	}

	var url = `https://${props.u}/cart/add?id=${props.v}&quantity=${props.q}`;
	return (
		<Dialog onClose={handleClose} open={open}>
			<DialogContent>
				<h2>Add more products to your cart</h2>
				<p style={{ fontSize: "1.2em" }}>
					Click on the button below to get redirected to the store to add more products to
					your cart and then click on the EnterOffer button on the cart page to make an
					offer on the cart!
				</p>
			</DialogContent>
			<DialogActions style={{ marginBottom: "16px" }}>
				<Button
					onClick={() => {
						window.parent.location.href = url;
					}}
					style={{
						color: theme.palette.primary.main,
						backgroundColor: theme.palette.primary.light,
						fontWeight: "700",
						maxWidth: "max-content",
						margin: "0px auto"
					}}
				>
					Add More Products
				</Button>
			</DialogActions>
		</Dialog>
	);
}

function AddProductsDialog(props) {
	const [open, setOpen] = useState(false);
	var id = 0;
	for (let p in props.data) {
		id = p;
	}
	if (id === 0) {
		for (let v in props.v) {
			id = v;
		}
	}
	const [cartUrl, setCartUrl] = useState({
		url: props.url,
		variant: props.v[Number(id)],
		quantity: Number(props.q[Number(id)])
	});
	useEffect(() => {
		setCartUrl({
			...cartUrl,
			variant: props.v[Number(id)],
			quantity: Number(props.q[Number(id)])
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.data, props.v, props.q]);

	function handleClickOpen() {
		setOpen(true);
	}

	const handleClose = value => {
		setOpen(false);
	};
	return (
		<div style={{ display: "flex", justifyContent: "center" }}>
			<Button
				id="add-products"
				variant="text"
				style={{ color: "#929292", cursor: "pointer" }}
				onClick={handleClickOpen}
			>
				<Add />
				<p style={{ textTransform: "none" }}>Add more products</p>
			</Button>

			<SimpleDialog
				u={cartUrl.url}
				v={cartUrl.variant}
				q={cartUrl.quantity}
				open={open}
				onClose={handleClose}
			/>
		</div>
	);
}

export default withSizes(mapSizesToProps)(AddProductsDialog);
