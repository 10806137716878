import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import QueryString from "query-string";
import withSizes from "react-sizes";
import Lottie from "react-lottie";
import * as nonOfferableAnimation from "../assets/nonOfferableAnimation.json";
import { FacebookPixel } from "./";
import { analytics } from "../firebase";

const useStyles = makeStyles({
	root: {
		maxWidth: 500,
		margin: "0em auto",
		marginTop: "4.5em"
	},
	link: {
		"&:hover": {
			textDecoration: "underline"
		},
		textDecoration: "none",
		color: "#35b2ff"
	}
});

function NonOfferableError(props) {
	const classes = useStyles();
	const theme = useTheme();
	var stores = {
		ichuJewellery: {
			name: "Ichu Jewellery's",
			link: "https://ichu.com.au/collections/sale",
			message: "Sale / Make an Offer Collection "
		},
		bausele: {
			name: "Bausele's",
			link: "https://www.bausele.com/collections/pilot-automatic",
			message: "PILOT AUTOMATIC - Limited Edition Collection "
		},
		fishingTackleDirect: {
			name: "Fishing Tackle Direct's",
			link: "https://www.fishing-tackle-direct.com.au/collections/make-an-offer",
			message: "Make an Offer Collection "
		},
		vaaniCollection: {
			name: "Vaani Collection's",
			link: "https://vaanicollection.com/collections/clearance",
			message: "Clearance Collection "
		},
		melox: {
			name: "MELOX's",
			link: "https://meloxthelabel.com/collections/sale",
			message: "SALE / MAKE AN OFFER Collection"
		},
		productsRUs: {
			name: "Products R Us'",
			link: "https://oaklanding.myshopify.com/collections/tech-accessories",
			message: "Tech & Accessories Collection "
		},
		nikkiLissoni: {
			name: "Nikki Lissoni's",
			link: "https://nikkilissoni.com.au/collections/make-an-offer",
			message: "Make an Offer Collection "
		},
		threadsNTrends: {
			name: "Threads N Trends'",
			link: "https://threadsntrends.com.au/collections/make-an-offer",
			message: "Make an Offer Collection "
		},
		ozStar: {
			name: "OzStar's",
			link: "https://ozstar.com.au/",
			message: "Full Collection"
		},
		azuraRunway: {
			name: "Azura Runway's",
			link: "https://azurarunway.com/",
			message: "Store"
		},
		iceJewellery: {
			name: "Ice Jewellery's",
			link: "https://enteroffer.com/icejewellery",
			message: "24 Hour Offers"
		},
		shimmerBazaar: {
			name: "Shimmer Bazaar's",
			link: "https://shimmerbazaar.com/collections/beauty",
			message: "Beauty Collection"
		}
	};

	let p = QueryString.parse(window.location.search);
	if (
		window.location.hostname !== "localhost" &&
		window.location.hostname !== "127.0.0.1" &&
		p.s !== "productsRUs"
	) {
		FacebookPixel.trackCustom("NonOfferable", { storeName: p.s });
		analytics.logEvent("non_offerable", { storeName: p.s, products: p.p });
	}
	return (
		<div
			id="no-offerable-products"
			className={classes.root}
			style={{
				textAlign: "center",
				display: "block",
				marginLeft: props.isMobile ? "5%" : "auto",
				marginRight: props.isMobile ? "5%" : "auto",
				marginTop: props.isMobile ? "20%" : "10%"
			}}
		>
			<Typography
				variant="h6"
				gutterBottom
				style={{
					fontWeight: "700",
					textAlign: "center"
				}}
			>
				{p.spp === "true" || p.spp === "false"
					? "Unfortunately this offer has expired."
					: "Unfortunately you cannot make an offer on any items in your cart."}
			</Typography>
			<Lottie
				height={200}
				width={200}
				options={{
					loop: true,
					autoplay: true,
					animationData: nonOfferableAnimation.default,
					rendererSettings: {
						preserveAspectRatio: "xMidYMid slice"
					}
				}}
			/>
			{p.spp === "true" || p.spp === "false" ? (
				<Typography
					variant="body1"
					gutterBottom
					style={{
						fontWeight: "600",
						textAlign: "center",
						color: theme.palette.primary.main
					}}
				>
					<a className={classes.link} href={"https://enteroffer.com/"}>
						{"Click here to see our new offer of the day"}
					</a>
				</Typography>
			) : (
				<Typography
					variant="body1"
					gutterBottom
					style={{
						fontWeight: "600",
						textAlign: "center",
						color: theme.palette.primary.main
					}}
				>
					To shop by products you can make offers on, visit {stores[p.s].name} <br />
					<br />
					<a className={classes.link} href={stores[p.s].link}>
						{" "}
						{stores[p.s].message}
					</a>
				</Typography>
			)}
		</div>
	);
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600
});

export default withSizes(mapSizesToProps)(NonOfferableError);
