import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { auth } from "./firebase";

class AuthorizedRoute extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pending: true,
			loggedIn: undefined
		};
	}

	UNSAFE_componentWillMount() {
		auth.onAuthStateChanged(user => {
			this.setState({
				pending: false,
				loggedIn: !!user
			});
		});
	}

	render() {
		const {
			component: Component,
			font: font,
			darkModeCallback: darkModeCallback,
			...rest
		} = this.props;
		return (
			<Route
				{...rest}
				render={renderProps => {
					if (this.state.pending) return null;
					return this.state.loggedIn ? (
						<Component
							font={font}
							darkModeCallback={darkModeCallback}
							{...renderProps}
						/>
					) : (
						<Redirect
							to={{
								pathname: "/a",
								state: { from: renderProps.location },
								search: this.props.location.search
							}}
						/>
					);
				}}
			/>
		);
	}
}

export default AuthorizedRoute;
