import React, { useEffect, useState } from "react";
import "./App.css";
import "typeface-nunito";
import { Offer, Settings, Help, PageNotFound, Signup, Anonymous, NewSignIn, Submit } from "./pages";
import AuthorizedRoute from "./authorizedRoute";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import TagManager from "react-gtm-module";
import { hotjar } from "react-hotjar";
import QueryString from "query-string";
import { authFunctions } from "./firebase";
import { cf } from "./firebase/";

const parsed = QueryString.parse(window.location.search);

if (
	window.location.hostname !== "localhost" &&
	window.location.hostname !== "127.0.0.1" &&
	parsed.s !== "productsRUs"
) {
	hotjar.initialize("1477546");

	const tagManagerArgs = {
		gtmId: "GTM-KF4JN42"
	};

	TagManager.initialize(tagManagerArgs);
}

const font = "Nunito Sans, sans-serif";
var themeColor = "#35B2FF";
if (parsed.demo) {
	themeColor = "#" + String(parsed.color);
}
if (parsed.buyNow) themeColor = "#35B2FF";
const themeColorLight = themeColor + "44"; // alpha value at 25%

const lightTheme = createMuiTheme({
	"@global": {
		body: {
			fontFamily: font
		}
	},
	typography: {
		fontFamily: font
	},
	palette: {
		type: "light",
		background: {
			transition: "background-color 0.3s ease"
		},
		primary: {
			light: themeColorLight,
			main: themeColor
		},
		secondary: {
			light: "#f6f6f8",
			main: "#ffffff",
			dark: "#000000"
		},
		fontFamily: font
	}
});

const darkTheme = createMuiTheme({
	"@global": {
		body: {
			fontFamily: font
		}
	},
	typography: {
		fontFamily: font
	},
	palette: {
		type: "dark",
		background: {
			default: "#212121",
			paper: "#303030",
			transition: "background-color 0.3s ease"
		},
		primary: {
			light: themeColorLight,
			main: themeColor
		},
		secondary: {
			light: "#212121",
			main: "#303030",
			dark: "#ffffff"
		},
		fontFamily: font
	}
});

// const OfferWrapped = () => <PageContainer page={Offer}/>
// const SettingsWrapped = () => <PageContainer page={Settings}/>
// const HelpWrapped = () => <PageContainer page={Help}/>

function App() {
	const [myTheme, setTheme] = useState(
		localStorage.getItem("darkMode") === "true" ? darkTheme : lightTheme
	);
	const [color, setColor] = useState("#35B2FF");

	useEffect(() => {
		if (parsed.s !== undefined) {
			cf.getThemeColor(parsed.s).then(color => {
				var newPrimary = {
					light: color + "44",
					main: color
				};
				setTheme({
					...myTheme,
					palette: { ...myTheme.palette, primary: newPrimary }
				});
				setColor(color);
			});
		}
	}, []);

	const darkModeCallback = e => {
		if (e) {
			var newPrimary = {
				light: color + "44",
				main: color
			};
			setTheme({
				...darkTheme,
				palette: { ...darkTheme.palette, primary: newPrimary }
			});
		} else {
			var newPrimary = {
				light: color + "44",
				main: color
			};
			setTheme({
				...lightTheme,
				palette: { ...lightTheme.palette, primary: newPrimary }
			});
		}
	};

	return (
		<MuiThemeProvider theme={parsed.demo ? lightTheme : createMuiTheme(myTheme)}>
			<Router>
				<div
					style={{
						fontFamily: { font },
						display: "flex",
						flexDirection: "column",
						minHeight: "100vh",
						maxWidth: "100%",
						overflowX: "hidden",
						backgroundColor:
							myTheme.palette.type === "dark"
								? darkTheme.palette.background.default
								: lightTheme.palette.background.default
					}}
				>
					<Switch>
						<AuthorizedRoute
							exact
							path="/"
							darkModeCallback={darkModeCallback}
							font={font}
							component={Offer}
						/>
						<AuthorizedRoute
							exact
							path="/settings"
							darkModeCallback={darkModeCallback}
							font={font}
							component={Settings}
						/>
						<AuthorizedRoute
							exact
							path="/help"
							darkModeCallback={darkModeCallback}
							font={font}
							component={Help}
						/>
						<AuthorizedRoute
							exact
							path="/submit"
							darkModeCallback={darkModeCallback}
							font={font}
							component={Submit}
						/>
						<AuthorizedRoute
							exact
							path="/signup"
							darkModeCallback={darkModeCallback}
							font={font}
							component={Signup}
						/>
						<Route exact path="/a" component={Anonymous} />
						<Route exact path="/signin" component={NewSignIn} />
						<Route
							path="/marketplace"
							component={() => {
								window.location.href = "https://market.enteroffer.com";
								return null;
							}}
						/>
						<Route component={PageNotFound} />
					</Switch>
				</div>
			</Router>
		</MuiThemeProvider>
	);
}

export default App;
