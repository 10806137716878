import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/analytics";
import "firebase/performance";

var liveTesting = false;
let config = {
	apiKey: "AIzaSyB5rSaDR8wATQh7XcppVYpv5A3cILnwjNo",
	authDomain: "oaklas-user.firebaseapp.com",
	databaseURL: "https://oaklas-user.firebaseio.com",
	projectId: "oaklas-user",
	storageBucket: "oaklas-user.appspot.com",
	messagingSenderId: "282663992239",
	appId: "1:282663992239:web:9e52b412786acf47",
	measurementId: "G-36G1CXRSQ0",
};
if (liveTesting) {
	config = {
		apiKey: "AIzaSyAhXdBRz-UN5nM9W0Rq1zVhy272ldn3ZAE",
		authDomain: "oaklas-user-dev.firebaseapp.com",
		databaseURL: "https://oaklas-user-dev.firebaseio.com",
		projectId: "oaklas-user-dev",
		storageBucket: "oaklas-user-dev.appspot.com",
		messagingSenderId: "1043319675732",
		appId: "1:1043319675732:web:ecaa6863ac04d3d9",
	};
}

const fb = app.initializeApp(config);

const db = fb.database();
const auth = fb.auth();
const functions = fb.functions();
var analytics = null;
var perf = null;
if (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1") {
	analytics = fb.analytics();
	perf = fb.performance();
}
// functions.useFunctionsEmulator("http://localhost:5000");

export { app, auth, db, functions, analytics, perf };
