import React, { Component } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";

class PageContainer extends Component {
	static propTypes = {
		page: PropTypes.func.isRequired
	};

	render() {
		const { page: Page } = this.props;

		return (
			<Grid container spacing={0} direction="column">
				<Grid item>
					<div
						style={{
							fontFamily: "Roboto",
							display: "flex",
							flexDirection: "column",
							minHeight: "100vh"
						}}
					>
						<div style={{ flex: 1 }}>
							<Grid container justify="center">
								<Grid item xs={12}>
									<Page />
								</Grid>
							</Grid>
						</div>
					</div>
				</Grid>
			</Grid>
		);
	}
}

export default PageContainer;
