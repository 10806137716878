import React, { Component } from "react";
import { app, cf, analytics } from "../firebase";
import { Redirect } from "react-router-dom";
import QueryString from "query-string";
import withSizes from "react-sizes";

class Anonymous extends Component {
	state = {
		isSignedIn: false,
		openLearnMore: false,
		checked: false,
		stepVal: false,
		offerable: true
	};

	UNSAFE_componentWillMount() {
		if (!this.state.isSignedIn) {
			const parsed = QueryString.parse(window.location.search);
			app.auth()
				.signInAnonymously()
				.then(async authdata => {
					if (
						window.location.hostname !== "localhost" &&
						window.location.hostname !== "127.0.0.1" &&
						parsed.s !== "productsRUs"
					) {
						analytics.setUserId(authdata.uid);
						analytics.setUserProperties({
							anonymous: true,
							submitted_offer: false
						});
						analytics.logEvent("anonymous_signup");
					}
					if (authdata.additionalUserInfo.isNewUser) {
						var newUserObj = {
							email: authdata.user.email,
							isNewUser: true,
							anonymous: true
						};
						if (this.state.storeName !== undefined && this.state.storeName !== null) {
							newUserObj.whichStore = this.state.storeName;
						}
						await app
							.database()
							.ref(`/users/${authdata.user.uid}/meta`)
							.set(newUserObj);
						this.setState({ isSignedIn: true });
					}
					return;
				})
				.then(() => {
					this.setState({ isSignedIn: true });
				});
			this.setState({ storeName: parsed.s });
			cf.isOfferable(parsed.p, parsed.s)
				.then(res => {
					if (res === false) this.setState({ offerable: false });
					else this.setState({ offerable: true });
				})
				.catch(e => {
					console.error(e);
					this.setState({ offerable: true });
				});
		}
	}

	// Listen to the Firebase Auth state and set the local state.
	componentDidMount() {
		this.unregisterAuthObserver = app.auth().onAuthStateChanged(user => {
			this.setState({ isSignedIn: !!user });
		});
	}

	componentWillUnmount() {
		this.unregisterAuthObserver();
	}

	render() {
		if (this.state.isSignedIn) {
			return (
				<Redirect
					to={{
						pathname:
							!this.props.hasOwnProperty("location") &&
							this.props.location !== "settings"
								? this.props.location.state.from.pathname
								: "/",
						search: window.location.search
					}}
				/>
			);
		} else {
			return <p>{app.auth.user}</p>;
		}
	}
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600
});

export default withSizes(mapSizesToProps)(Anonymous);
