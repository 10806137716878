import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import withSizes from "react-sizes";

const styles = theme => ({
	root: {
		flexGrow: 1
	},
	paper: {
		padding: theme.spacing(2),
		maxWidth: 500
	},
	button: {
		padding: "0.2em"
	},
	image: {
		height: "130px"
	},
	img: {
		margin: "auto",
		display: "block",
		maxWidth: "100%",
		height: "100%",
		objectFit: "contain"
	},
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	textField: {
		position: "relative",
		width: "100%"
	},
	textFieldDropdown: {
		backgroundColor:
			theme.palette.type === "dark"
				? theme.palette.secondary.main
				: theme.palette.secondary.light,
		borderRadius: "4px"
	},
	textFieldDisabledInput: {
		backgroundColor:
			theme.palette.type === "dark"
				? theme.palette.secondary.main
				: theme.palette.secondary.light,
		color: "#000",
		borderRadius: "4px",
		textAlign: "center",
		padding: "1em",
		fontWeight: "700",
		cursor: "pointer",
		fontSize: "0.8em",
		display: "inline-block",
		position: "absolute",
		right: 0,
		bottom: 0,
		float: "right"
	},
	input: {
		padding: "0.3em",
		fontWeight: "700"
	},
	inputLoc: {
		position: "absolute",
		bottom: 0,
		width: "100%",
		height: "2.5em"
	},
	inputDrop: {
		marginLeft: " -8px",
		padding: "0.2em 1.8em 0.2em 1em",
		fontWeight: "700",
		fontSize: "0.8em"
	},
	notchedOutline: {
		borderWidth: "1px",
		borderColor: "#f6f6f8 !important"
	},
	adornment: {
		fontSize: "1.8em"
	}
});

const quantities = [
	{ value: 1, label: "1" },
	{ value: 2, label: "2" },
	{ value: 3, label: "3" },
	{ value: 4, label: "4" },
	{ value: 5, label: "5" },
	{ value: 6, label: "6" },
	{ value: 7, label: "7" },
	{ value: 8, label: "8" },
	{ value: 9, label: "9" },
	{ value: 10, label: "10" }
];

class Products extends React.Component {
	state = {
		amounts: [],
		quantities: [],
		open: false,
		openDelete: false,
		openDeleteKey: "",
		variants: [],
		variantObjs: [],
		data: [],
		deleted: [],
		deletedKeys: [],
		weights: [],
		itemDeleted: false
	};

	componentDidMount() {
		if (this.props.data) {
			let amountsArray = this.state.amounts;
			let variantsObjArray = this.state.variantObjs;
			let quantitiesArray = this.state.quantities;
			let deletedArray = this.state.deletedKeys;
			let weightsArray = this.state.weights;
			// PRODUCT OBJECT
			this.props.data.products.forEach(product => {
				// VARIANT OBJECT
				product.variants.forEach(pVars => {
					if (pVars.id === Number(this.props.variants[product.id])) {
						if (!product.offerable) {
							amountsArray[product.id] = pVars.price;
						} else {
							if (
								this.props.prices[product.id] === undefined ||
								this.props.prices[product.id] === null ||
								this.props.prices[product.id] === ""
							) {
								amountsArray[product.id] = "";
							} else {
								amountsArray[product.id] = Number(this.props.prices[product.id]);
							}
						}
						weightsArray[product.id] = pVars.weight;
						variantsObjArray[product.id] = pVars;
						quantitiesArray[product.id] = this.props.quantities[product.id];
					}
				});
			});
			this.props.data.products.sort((a, b) => (a.offerable < b.offerable ? 1 : -1));
			if (this.props.mpOffer) amountsArray = this.props.prices;
			this.setState({
				data: this.props.data,
				amounts: amountsArray,
				variantObjs: variantsObjArray,
				quantities: quantitiesArray,
				deletedKeys: deletedArray
			});
			this.props.parentCallbackValues(amountsArray, weightsArray);
			this.props.parentCallbackDeleted(deletedArray);
		}
	}

	itemDeletion() {
		let amountsArray = this.state.amounts;
		let variantsArray = this.state.variants;
		let weightsArray = this.state.weights;
		let variantObjsArray = this.state.variantObjs;
		this.state.deleted.forEach(item => {
			delete amountsArray[item.id];
			delete weightsArray[item.id];
			delete variantsArray[item.id];
			delete variantObjsArray[item.id];
		});
		this.setState({
			amounts: amountsArray,
			variants: variantsArray,
			weights: weightsArray,
			variantObjs: variantObjsArray
		});
		this.props.parentCallbackDeleted(this.state.deletedKeys);
		this.props.parentCallbackValues(amountsArray, weightsArray);
	}

	handleChangeVariants = pid => event => {
		let tempVariants = this.state.variants;
		let tempVariantObjs = this.state.variantObjs;
		let tempAmounts = this.state.amounts;
		this.state.data.products.forEach(product => {
			if (product.id === pid) {
				product.variants.forEach(variant => {
					if (variant.title === event.target.value) {
						tempVariants[pid] = Number(variant.id);
						tempVariantObjs[pid] = variant;
						if (!product.offerable) {
							tempAmounts[pid] = Number(variant.price);
						} else {
							if (
								variant.price !== null ||
								variant.price !== undefined ||
								variant.price !== ""
							) {
								tempAmounts[pid] = Number(this.state.amounts[pid]);
							} else {
								tempAmounts[pid] = "";
							}
						}
					}
				});
			}
		});

		this.setState({
			variants: tempVariants,
			variantObjs: tempVariantObjs,
			amounts: tempAmounts
		});
		this.props.parentCallbackVariants(tempVariantObjs, tempAmounts);
		// this.props.updateShippingOptions()
	};

	handleChangeQuantity = pid => event => {
		let newArray = this.state.quantities;
		newArray[pid] = event.target.value;
		this.setState({ quantities: newArray });
		this.props.parentCallbackQuantities(newArray);
		// this.props.updateShippingOptions()
	};

	changeAmounts = pid => event => {
		if (event.key === "Enter" || event.key === "Return") {
			this.props.parentCallbackNext(0);
			event.preventDefault();
		}
		let newArray = this.state.amounts;
		newArray[pid] = event.target.value;
		this.setState({ amounts: newArray });
		this.props.parentCallbackValues(newArray, this.state.weights);
	};

	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleClickOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	handleOpenDelete = key => {
		this.setState({ openDelete: true, openDeleteKey: key });
	};

	handleCloseDelete = () => {
		this.setState({ openDelete: false, itemDeleted: true });
		// setTimeout(() => {
		var sliced = this.state.data.products.splice(this.state.openDeleteKey, 1);
		this.state.deleted.push(sliced[0]);
		this.state.deletedKeys.push(this.state.openDeleteKey);
		this.setState({
			data: this.state.data,
			deletedKeys: this.state.deletedKeys,
			deleted: this.state.deleted,
			itemDeleted: false,
			openDeleteKey: ""
		});
		this.itemDeletion();
		// }, 5000);
	};

	handleCloseDeleteCancel = () => {
		this.setState({ openDelete: false });
	};

	render() {
		const { classes } = this.props;
		const { data, variantObjs } = this.state;

		return (
			<div>
				<Dialog
					open={this.state.open}
					onClose={this.handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogContent>
						<h2>Product Not Offerable</h2>
						<span style={{ fontSize: "1.2em", color: "#929292" }}>
							Sorry, you can't make an offer on this product. However, you can still
							submit it in this order with products that you want to make an offer on.
						</span>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={this.state.openDelete} onClose={this.handleCloseDeleteCancel}>
					<DialogContent>
						<h2>Item Deletion</h2>
						<span style={{ fontWeight: "700", fontSize: "1.2em" }}>
							Are you sure you want to delete this product?
						</span>{" "}
						<br />
						<span style={{ fontSize: "1em" }}>
							You can undo this by refreshing the page later.
						</span>
						<br />
						<br />
					</DialogContent>
					<DialogActions>
						<Button
							onClick={this.handleCloseDeleteCancel}
							style={{
								color: " #929292",
								fontWeight: "700"
							}}
						>
							Cancel
						</Button>
						<Button
							onClick={this.handleCloseDelete}
							style={{
								color: " #f00",
								backgroundColor: "#ff000030",
								fontWeight: "700"
							}}
						>
							Delete
						</Button>
					</DialogActions>
				</Dialog>
				{data &&
					_.map(data.products, (product, key) => (
						<Grid container key={key} spacing={2} style={{ marginBottom: "2em" }}>
							<Grid item xs={4}>
								<Grid container spacing={0}>
									<Grid item xs={12} className={classes.image}>
										<ReactCSSTransitionGroup
											transitionName="loadingItem"
											transitionAppear={true}
											transitionAppearTimeout={500}
											transitionEnterTimeout={500}
											transitionLeaveTimeout={300}
										>
											<img
												className={classes.img}
												alt={product.title}
												src={variantObjs[product.id].image}
											/>
										</ReactCSSTransitionGroup>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={8} container>
								<Grid item xs={12} container>
									<Grid
										item
										xs={12}
										style={{
											paddingBottom: "0em",
											paddingRight: "0em"
										}}
									>
										<div
											style={{
												display: "inline",
												float: "right",
												zoom: "0.8"
											}}
										>
											{data.products.length > 1 ? (
												<IconButton
													className={classes.button}
													aria-label="Delete"
													onClick={() => {
														this.handleOpenDelete(key);
													}}
												>
													<DeleteIcon />
												</IconButton>
											) : null}
										</div>
										<a
											href={this.props.mpOffer ? this.props.prevUrl : null}
											style={{ textDecoration: "none", color: "#000" }}
										>
											<Typography
												style={{
													fontSize: this.props.isMobile
														? "0.9em"
														: "1.1em",
													fontWeight: "700",
													overflow: "hidden",
													textOverflow: "ellipsis",
													WebkitLineClamp: "2",
													WebKitBoxOrient: "vertical",
													cursor: this.props.mpOffer ? "pointer" : null
												}}
											>
												{product.title}
											</Typography>
										</a>
										{this.props.mpOffer ? null : (
											<Typography
												style={{
													fontSize: "0.9em",
													fontWeight: "700",
													color: "#929292",
													margin: "-3px auto 5px"
												}}
											>
												{`${data.currency} ${data.currency_symbol}${Number(
													variantObjs[product.id].price
												).toFixed(2)}`}
											</Typography>
										)}
										{this.props.mpOffer ? null : (
											<form
												className={classes.container}
												noValidate
												autoComplete="off"
											>
												<Grid item xs={12} container>
													<Grid
														item
														xs={this.props.isMobile ? 5 : 4}
														container
													>
														<TextField
															id={`outlined-select-${product.id}`}
															select
															className={classes.textFieldDropdown}
															value={
																this.state.quantities[product.id]
																	? this.state.quantities[
																			product.id
																	  ]
																	: 1
															}
															onChange={this.handleChangeQuantity(
																product.id
															)}
															style={{
																marginRight: this.props.isMobile
																	? "4px"
																	: null
															}}
															InputProps={{
																classes: {
																	input: classes.inputDrop,
																	notchedOutline:
																		classes.notchedOutline
																},
																startAdornment: (
																	<InputAdornment position="start">
																		<span
																			style={{
																				fontSize: "0.8em",
																				color: "#929292",
																				fontWeight: "700"
																			}}
																		>
																			QTY
																		</span>
																	</InputAdornment>
																)
															}}
															variant="outlined"
														>
															{quantities.map(option => (
																<MenuItem
																	key={option.value}
																	value={option.value}
																>
																	{option.label}
																</MenuItem>
															))}
														</TextField>
													</Grid>
													<Grid
														item
														xs={this.props.isMobile ? 7 : 8}
														container
													>
														{product.variants[0].title ===
														"-" ? null : (
															<TextField
																id={`outlined-variants-${product.id}`}
																select
																className={
																	classes.textFieldDropdown
																}
																value={
																	variantObjs[product.id].title
																}
																onChange={this.handleChangeVariants(
																	product.id
																)}
																style={{
																	width: "100%"
																}}
																InputProps={{
																	classes: {
																		input: classes.inputDrop,
																		notchedOutline:
																			classes.notchedOutline
																	},
																	startAdornment: (
																		<InputAdornment position="start">
																			<span
																				style={{
																					fontSize:
																						"0.8em",
																					color:
																						"#929292",
																					fontWeight:
																						"700"
																				}}
																			>
																				Option
																			</span>
																		</InputAdornment>
																	)
																}}
																variant="outlined"
															>
																{product.variants.map(option => (
																	<MenuItem
																		key={option.id}
																		value={option.title}
																		disabled={!option.inventory}
																	>
																		{option.title}
																	</MenuItem>
																))}
															</TextField>
														)}
													</Grid>
												</Grid>
											</form>
										)}
									</Grid>

									<Grid item xs={12} container>
										<form
											style={{
												width: "100%",
												height: "3em"
											}}
											className={classes.container}
											noValidate
											autoComplete="off"
										>
											{product.offerable ? (
												this.props.cartAbandoned ? null : (
													<TextField
														id={`outlined-adornment-amount-${product.id}`}
														className={classes.textField}
														data-hj-whitelist
														variant="outlined"
														placeholder={
															!variantObjs[product.id].inventory
																? "Sold Out"
																: "Enter Offer..."
														}
														type="number"
														pattern="[0-9]*"
														disabled={
															!variantObjs[product.id].inventory ||
															this.props.mpOffer
														}
														inputMode="numeric"
														value={
															this.props.mpOffer
																? Number(
																		variantObjs[product.id]
																			.price
																  ).toFixed(2)
																: this.state.amounts[product.id]
														}
														onChange={this.changeAmounts(product.id)}
														onKeyPress={this.changeAmounts(product.id)}
														max={variantObjs[product.id].price}
														InputProps={{
															max: `${variantObjs[product.id].price}`,
															classes: {
																input: classes.input
															},
															className: classes.inputLoc,
															type: "number",
															pattern: "[0-9]*",
															inputMode: "numeric",
															step: "1",
															startAdornment: (
																<InputAdornment position="start">
																	{data.currency_symbol}
																</InputAdornment>
															),
															endAdornment:
																Number(
																	this.state.quantities[
																		product.id
																	]
																) > 1 ? (
																	<InputAdornment position="end">
																		{"each"}
																	</InputAdornment>
																) : null
														}}
														inputProps={{
															"data-hj-whitelist": true
														}}
													/>
												)
											) : this.props.cartAbandoned ? null : (
												<div
													style={{
														width: "100%",
														position: "relative"
													}}
												>
													<Typography
														style={{
															fontWeight: "700",
															display: "inline-block",
															bottom: 0,
															position: "absolute"
														}}
													>
														<span
															style={{
																color: "#929292",
																display: "inline-block"
															}}
														>{`${data.currency} ${data.currency_symbol}`}</span>
														<span
															style={{
																fontSize: this.props.isMobile
																	? "1em"
																	: "1.2em"
															}}
														>{`${Number(
															variantObjs[product.id].price
														).toFixed(2)}`}</span>
													</Typography>
													<span
														id={`outlined-adornment-amount-${product.id}`}
														onClick={this.handleClickOpen}
														className={classes.textFieldDisabledInput}
														style={{
															padding: this.props.isMobile
																? "0.7em"
																: "1em"
														}}
													>
														Not Offerable
													</span>
												</div>
											)}
										</form>
									</Grid>
								</Grid>
							</Grid>
							<Divider />
						</Grid>
					))}
			</div>
		);
	}
}
Products.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600
});

export default withSizes(mapSizesToProps)(withStyles(styles)(Products));
