import React, {useState, useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import RightArrow from '@material-ui/icons/KeyboardArrowRight';
import BackArrow from '@material-ui/icons/KeyboardBackspace';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash';
import {db, analytics} from '../firebase'
import {ErrorSnackbar} from './index'
import withSizes from 'react-sizes'
import {countries, currencies, currency_symbols} from './countries'
import {FacebookPixel} from './'
import QueryString from 'query-string'
import Autocomplete from 'react-google-autocomplete';
import Fade from 'react-reveal/Fade';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1, 0),
        display:'inline-block',
        width: "100%"
    },
    formControl2: {
        margin: theme.spacing(1, 0),
        position: 'inherit',
        float: 'right',
        maxWidth: "40%"
    },
    group: {
        margin: theme.spacing(1, 0)
    },
    button: {
        display: 'inline-block'
    },
    infoDiv: {
        // backgroundColor: '#f6f6f8',
        padding: theme.spacing(1, 2)
    },
    contentDiv: {
        padding: theme.spacing(0, 2)
    },
    textField: {
      marginRight: theme.spacing(1),
    },
    autocompleteTextfield:{
        width: '100%',
        fontFamily :'inherit',
        border: '1px solid #929292',
        borderRadius: '4px',
        padding: '10.5px',
        fontSize: '1.1em',
        lineHeight:'1.1875em',
        '&:hover': {
            border: '1px solid #000 !important'
        },
        '&:active': {
            border: '0 !important'
        },
        '&:focus': {
            border: '2px solid #35B2FF !important',
            outline: '0 !important'
        }
    }
}));

function ShippingSelector(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = useState("");
    const [anonymous, setAnonymous] = useState(true);
    const [data, setData] = useState(props.data)
    const [email, setEmail] = useState(props.email)

    function handleChange(event) {
        setValue(event.target.value);
    }

    const [open, setOpen] = useState(false);
    const [openBA, setOpenBA] = useState(false);

    var addressString,
        BAaddressString
    if (_.isEmpty(data.shippingAddress) || data.shippingAddress === "noAddress") {
        addressString = "Enter your shipping details..."
    } else {
        addressString = `${data.shippingAddress.line1},`
        if (data.shippingAddress.line2 !== "")
            addressString += ` ${data.shippingAddress.line2},`
        addressString += ` ${data.shippingAddress.city}, ${data.shippingAddress.address_zip}`
    }
    if (_.isEmpty(data.billingAddress) || data.billingAddress === undefined || data.billingAddress === null || data.billingAddress.line1 === undefined) {
        BAaddressString = "Enter your billing details..."
    } else {
        BAaddressString = `${data.billingAddress.line1},`
        if (data.billingAddress.line2 !== "" && data.billingAddress.line2 !== undefined){
            BAaddressString += ` ${data.billingAddress.line2},`
        }
        BAaddressString += ` ${data.billingAddress.city}, ${data.billingAddress.address_zip}`
    }
    const [selectedSA, setSelectedSA] = useState(addressString);
    const [selectedBA, setSelectedBA] = useState(BAaddressString);
    const [selectedShipping, setSelectedShipping] = useState("")

    function handleClickOpen() {
        setOpen(true);
    }

    useEffect(() => {
        // debugger;
        if (props.selectedShipping !== null && props.selectedShipping !== undefined  && props.data.hasOwnProperty("shipping")) {
            var found = false
            for (var i of props.shipping.weight_rates) {
                if (Number(i.id) === Number(props.selectedShipping.id) && i.valid){
                    found = true
                }
            }
            for (var j of props.shipping.price_rates) {
                if (Number(j.id) === Number(props.selectedShipping.id) && j.valid){
                    found = true
                }
            }
            if (found) setSelectedShipping(props.selectedShipping.id)
            else {
                props.parentCallbackShipping("")
                setSelectedShipping("")
            }
        }
    }, [props.shipping, props.selectedShipping])



    useEffect(() => {
        db.getAnon()
            .then(snapshot => {
                if (
                    snapshot === false ||
                    snapshot === undefined ||
                    snapshot === null
                ) {
                    setAnonymous( false );
                } else {
                    setAnonymous( true );
                }
            })
            .catch(e => {
                console.log(e);
            });
    }, []);

    function handleClickOpenBA() {
        setOpenBA(true);
    }
    const handleClose = value => {
        // if (value !== "Enter your shipping details...") props.updateShippingData()
        setOpen(false);
        setSelectedSA(value);
        // add call back to offer.js to handle change of address
    };

    const handleCloseBA = value => {
        setOpenBA(false);
        setSelectedBA(value);
        // add call back to offer.js to handle change of payment
    };

    const handleShippingDataUpdate = ()=>{
        props.updateShippingData()
    }


    return (<div className={classes.root}>
        <SADialog email={props.email.email} data={data} ismobile={props.ismobile} selectedSA={selectedSA} open={open} onClose={handleClose} updateshipping={handleShippingDataUpdate}/>
        <Paper elevation={0} className={classes.infoDiv}>
            <div onClick={handleClickOpen} style={{
                    cursor: 'pointer'
            }}>
                <Typography variant="body1" color="textSecondary" style={{
                        fontWeight: '700',
                        marginTop: '1em'
                }}>SHIPPING ADDRESS</Typography>
                <Typography variant="body1" style={{
                        fontWeight: '700',
                        marginBottom: '1em',
                        color:  addressString === "Enter your shipping details..." && props.error === 4 ? '#f00' : null
                }}>{selectedSA}</Typography>
                <IconButton className={classes.button} style={{
                        float: 'right',
                        margin: '-2.5em 0em'
                }} aria-label="Edit" onClick={handleClickOpen}>
                    <RightArrow/>
                </IconButton>
            </div>
            {!_.isEmpty(data.shippingAddress) && data.shippingAddress !== "noAddress" ?
            <div>

            <Divider/>
                <div onClick={handleClickOpenBA} style={{
                        cursor: 'pointer'
                }}>
                    <Typography variant="body1" color="textSecondary" style={{
                            fontWeight: '700',
                            marginTop: '1em'
                    }}>BILLING ADDRESS</Typography>
                    <Typography variant="body1" style={{
                            fontWeight: '700',
                            marginBottom: '1em',
                            color: props.error === 5 ? '#f00' : null
                    }}>{selectedBA}</Typography>
                    <IconButton className={classes.button} style={{
                            float: 'right',
                            margin: '-2.5em 0em'
                    }} aria-label="Edit" onClick={handleClickOpenBA}>
                        <RightArrow/>
                    </IconButton>
                </div>
            <BADialog data={data} ismobile={props.ismobile} selectedBA={selectedBA} open={openBA} onClose={handleCloseBA}/>

            <Divider/>
            </div>
            :
            null }
        </Paper>
        <Paper elevation={0} className={classes.contentDiv}>
        {!data.hasOwnProperty("shipping") ? null :
            <Typography variant="body1" color='textSecondary'  style={{fontWeight: '700', marginTop: '1em', color: props.error === 6 && !selectedShipping  ? '#f00' : null }}>
                SHIPPING OPTION
            </Typography>
        }
            {!_.isEmpty(props.shipping) && data.hasOwnProperty("shipping")?
                <React.Fragment>
                    {props.shipping.price_rates.length === 0 && props.shipping.weight_rates.length === 0 ?
                        <Typography variant="body1" style={{fontWeight: '400', marginBottom: '1em'}}>
                            Unfortunately, this store does not ship to your location. Change your shipping address to proceed.
                        </Typography>
                    :
                    <div>
                        <FormControl component="fieldset" className={classes.formControl} >
                            <RadioGroup aria-label="Payment" name="Payment1" className={classes.group} value={value} onChange={handleChange}>
                                {
                                    data && _.map(props.shipping, rates => (_.map(rates, index => (
                                        index.valid === true
                                            ? <FormControlLabel style={{width: "100%"}} value={index.name} key={index.id} onClick={() => props.parentCallbackShipping(index)} control={<Radio  color = "primary" style = {{color: theme.palette.primary.main}}/>} label={<p style={{display:"inline-flex", position:"relative", width:"100%"}}><span style={{width: "70%"}}>{index.name}</span><span style={{position: "absolute", right: 0}}>{Number(index.price) === 0
                                                ? 'FREE'
                                                : `${data.currency} ${data.currency_symbol}${Number(index.price).toFixed(2)}`}</span></p>}></FormControlLabel>
                                            : null))))
                                }
                            </RadioGroup>
                        </FormControl>
                        {/* <FormControl component="fieldset" className={classes.formControl2}>
                            <RadioGroup aria-label="Payment" name="Payment1" className={classes.group} value={value} onChange={handleChange}>
                                {
                                    data && _.map(props.shipping, rates => (_.map(rates, index => (
                                        index.valid === true
                                            ? <FormControlLabel
                                                key={index.id}
                                                value={index.name} control={<Radio color = "primary" style = {{color: theme.palette.secondary.main}}/>} label={Number(index.price) === 0
                                                    ? 'FREE'
                                                    : `${data.currency} ${data.currency_symbol}${Number(index.price).toFixed(2)}`}
                                                onClick={() => props.parentCallbackShipping(index)}
                                              labelPlacement="start"/>
                                            : null))))
                                }
                            </RadioGroup>
                        </FormControl> */}
                    </div>
                    }
                </React.Fragment>
            :
            !data.hasOwnProperty("shipping") ? null
            // <Typography variant="body1" style={{fontWeight: '400', marginBottom: '1em'}}>
            //     Once you input your shipping address, shipping options will appear
            // </Typography>
            :
            <Typography variant="body1" style={{fontWeight: '400', marginBottom: '1em'}}>
                This item doesn't ship to your location, please enter a different location and try again
            </Typography>
    }
        </Paper>
    </div>);
}


const mapSizesToProps = ({width}) => ({
    ismobile: width < 600
})
export default withSizes(mapSizesToProps)(ShippingSelector)

const shippingdetails = [];

function SADialog(props) {
    const {
        onClose,
        selectedSA,
        email,
        ...other
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [values, setValues] = useState({
        firstName: props.data.firstName,
        lastName: props.data.lastName,
        phoneNo: props.data.shippingAddress.phoneNo,
        email: email,
        address: props.data.shippingAddress,
        line1: props.data.shippingAddress.line1,
        line2: props.data.shippingAddress.line2,
        city: props.data.shippingAddress.city,
        state: props.data.shippingAddress.state,
        postcode: props.data.shippingAddress.address_zip,
        country: props.data.shippingAddress.country,
        company: props.data.shippingAddress.company,
        countryIndex: 0,
        countryCode: '',
        stateIndex: '',
        stateCode: '',
        errorOpen : false,
        errorType : 0,
        message: '',
        autoEntered: false,
        autoAddress: '',

    });
    const parse = QueryString.parse(window.location.search)
    function handleClose() {
        onClose(selectedSA);
    }
    function handleCloseAdd() {
        
        if(values.autoAddress === '' || values.autoAddress === undefined || values.firstName === '' || values.firstName === undefined || values.lastName === '' || values.lastName === undefined || values.line1 === '' || values.line1 === undefined || values.city === ''|| values.city === undefined || values.stateIndex === '' || values.stateIndex === undefined || values.postcode === '' || values.postcode === undefined || values.countryIndex === '' || values.countryIndex === undefined){
            var message = ''
            if (values.firstName === '' || values.firstName === undefined) message = 'First Name'
            else if (values.lastName === '' || values.lastName === undefined) message = 'Last Name'
            else if (parse.s=== "iceJewellery" && (values.phoneNo === '' || values.phoneNo === undefined)) message = 'Phone Number'
            else if (values.autoAddress === '' || values.autoAddress === undefined) message = 'Shipping Address'
            else if (values.line1 === '' || values.line1 === undefined) message = 'Line 1'
            else if (values.city === '' || values.city === undefined ) message = 'City'
            else if (values.stateIndex === '' || values.stateIndex === undefined) message = 'State'
            else if (values.postcode === '' || values.postcode === undefined) message = 'Postcode'
            else message = 'Country'
            setValues({...values, errorOpen: true, errorType: 8, message : message})
        } else {
            var newAddress = `${values.line1},`
            if (values.line2 !== "" && values.line2 !== undefined)
                newAddress += ` ${values.line2},`
            newAddress += ` ${values.city}, ${values.postcode}`
            setValues({
                ...values,
                address: newAddress,
                errorType: 0
            })

            shippingdetails.push(values.address)
            var country = countries.countries[values.countryIndex].name
            var countryCode = countries.countries[values.countryIndex].code

            var state = countries.countries[values.countryIndex].states[values.stateIndex].name
            var stateCode = countries.countries[values.countryIndex].states[values.stateIndex].code
            for (var i in currencies.countries) {
                if (currencies.countries[i].country === country) {
                    var currency = currencies.countries[i].currency_code
                    break
                }
            }
            for (var j in currency_symbols.countries) {
                if (currency_symbols.countries[j].code === currency) {
                    var currency_symbol = currency_symbols.countries[j].symbol_native
                    break
                }
            }
            let phoneNo = '-'
            if (values.phoneNo !== undefined && values.phoneNo !== null){
                phoneNo = values.phoneNo
            }
            var addressObj = {
                company: values.company !== undefined ? values.company : "",
                line1: values.line1,
                line2: values.line2 !== undefined ? values.line2 : "",
                city: values.city,
                address_zip: values.postcode,
                state: state,
                country: country,
                country_code: countryCode,
                state_code: stateCode,
                phoneNo : phoneNo
            }
            var currencyObj = {
                currency: currency,
                currency_symbol: currency_symbol
            }
            db.updateAddress(addressObj, "shippingAddress")
            db.updateAddress(addressObj, "billingAddress")
            db.updateCurrency(currencyObj)
            // db.upadateEmail(userEmail)
            db.upadateFirstName(values.firstName)
            db.upadateLastName(values.lastName)
            props.updateshipping()
            onClose(newAddress)
            if (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1" && parse.s !== "productsRUs") {
                FacebookPixel.trackCustom('AddShippingAddress', {storeName: parse.s});
                analytics.logEvent('add_shipping_info', {
                    storeName: parse.s
                });
            }
        }
    }

    const handleChange = name => event => {
        setValues({
            ...values,
            [name]: event.target.value
        });
    };

    useEffect(() => {
        for (var i in countries.countries) {
            if (props.data.shippingAddress.country === countries.countries[i].name) {
                for (var j in countries.countries[i].states) {
                    if (countries.countries[i].states[j].name === props.data.shippingAddress.state) {
                        setValues({
                            ...values,
                            countryIndex: i,
                            stateIndex: j
                        })
                        break
                    }
                }
                break
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const callbackCloseError = (param) => {
        setValues({...values, errorOpen: false})
    }

    const handleSelectedAddress = (address) => {
        if(!_.isEmpty(address.address_components)){
            var len = address.address_components.length
            let line1 = `${address.address_components[0].long_name} ${address.address_components[1].long_name}`
            let city = address.address_components[2].long_name
            let state1 = address.address_components[3].long_name
            let stateCode1 = address.address_components[3].short_name
            let state2 = address.address_components[4].long_name
            let stateCode2 = address.address_components[4].short_name
            let state3 = address.address_components[len-3].long_name
            let stateCode3 = address.address_components[len-3].short_name
            let country = address.address_components[len-2].long_name
            let countryCode = address.address_components[len-2].short_name
            let postcode = address.address_components[len-1].long_name
            let indexi = values.countryIndex
            let indexj = values.stateIndex
            let stateCode= ''
            let state = ''
            for (var i in countries.countries) {
                if (country === countries.countries[i].name || countryCode === countries.countries[i].code) {
                    for (var j in countries.countries[i].states) {
                        if (countries.countries[i].states[j].code === stateCode1 || countries.countries[i].states[j].name === state1) {
                            indexi = i
                            indexj = j
                            state = state1
                            stateCode = stateCode1
                            break
                        }
                        else if (countries.countries[i].states[j].code === stateCode2 || countries.countries[i].states[j].name === state2) {
                            indexi = i
                            indexj = j
                            state = state2
                            stateCode = stateCode2
                            break
                        } else if (countries.countries[i].states[j].code === stateCode3 || countries.countries[i].states[j].name === state3) {
                            indexi = i
                            indexj = j
                            state = state3
                            stateCode = stateCode3
                            break
                        }
                    }
                    break
                }
            }
            setValues({
                ...values,
                line1: line1,
                city: city,
                state: state,
                stateCode: stateCode,
                postcode: postcode,
                country: country,
                countryCode: countryCode,
                countryIndex: indexi,
                stateIndex: indexj,
                autoEntered: true,
                test: address
            });

        } else {
            setValues({
                ...values,
                autoEntered: true,
            });
        }
    }

    return (
        <Dialog fullScreen={props.ismobile} onClose={handleClose} {...other}>
        <ErrorSnackbar errorType={values.errorType} open={values.errorOpen} parentCallbackCloseError={callbackCloseError} message={values.message} />
        <DialogTitle>
            <p style={{margin: '0em',color: theme.palette.primary.main}}><BackArrow onClick={handleClose} style={{cursor: 'pointer'}}/></p>
            <span style={{color: theme.palette.primary.main, fontWeight: 900}}>SHIPPING</span><span style={{fontWeight: 900}}> ADDRESS</span>
        </DialogTitle>
        <DialogContent>
            <TextField
                margin="dense"
                disabled
                variant="outlined"
                id="email"
                style={{width: '100%'}}
                label="Email*"
                value={props.email}
            />
            <TextField
                margin="dense"
                variant="outlined"
                id="firstName"
                style={{width: '49.5%', marginRight: '1%'}}
                error ={values.errorType === 8 && values.firstName === undefined ? true : false }
                label="First Name*"
                value={values.firstName}
                onChange={handleChange('firstName')}
            />
            <TextField
                margin="dense"
                variant="outlined"
                id="lastName"
                style={{width: '49.5%'}}
                error ={values.errorType === 8 && values.lastName === undefined ? true : false }
                label="Last Name*"
                value={values.lastName}
                onChange={handleChange('lastName')}
            />
            {parse.s === "iceJewellery" ? 
            <TextField
                margin="dense"
                variant="outlined"
                id="phoneNo"
                type="tel"
                style={{width: '49.5%'}}
                error ={values.errorType === 8 && values.phoneNo === undefined ? true : false }
                label="Phone Number*"
                value={values.phoneNo}
                onChange={handleChange('phoneNo')}
            /> : null }
            <br/><br/>
            <Autocomplete
                className= {classes.autocompleteTextfield}
                onPlaceSelected={(address) => {
                    handleSelectedAddress(address);
                }}
                onChange={handleChange('autoAddress')}
                types={['address']}
                placeholder="Enter Shipping Address"
            />
            <p onClick={()=>{handleSelectedAddress(values.autoAddress)}} style={{
                    color: theme.palette.primary.main,
                    fontSize: '0.8em',
                    fontWeight: '700',
                    cursor: 'pointer',
                    float:'right',
                    margin: '0.5em 0em'
            }}>
                Address not listed?
            </p>
            <br/><br/>
            {values.autoEntered || values.errorType === 8 ?
            <Fade>
            <div>
            <TextField
                margin="dense"
                variant="outlined"
                id="company"
                label="Company"
                fullWidth
                value={values.company}
                onChange={handleChange('company')}
            />
            <TextField
                margin="dense"
                variant="outlined"
                id="line1"
                error ={values.errorType === 8 && values.line1 === undefined ? true : false }
                label="Line 1*"
                fullWidth
                value={values.line1}
                onChange={handleChange('line1')}
            />
            <TextField
                margin="dense"
                variant="outlined"
                id="line2"
                label="Line 2"
                fullWidth
                value={values.line2}
                onChange={handleChange('line2')}
            />
            <TextField
                margin="dense"
                variant="outlined"
                id="city"
                error ={values.errorType === 8 && values.city === undefined ? true : false }
                label="City*"
                fullWidth
                value={values.city}
                onChange={handleChange('city')}
            />
            <TextField
                select={true}
                margin="dense"
                variant="outlined"
                id="state"
                style={{width: '49.5%', marginRight: '1%'}}
                error ={values.errorType === 8 && values.state === undefined ? true : false }
                label="State*"
                value={values.stateIndex}
                onChange={handleChange('stateIndex')}>
                {
                    countries.countries[values.countryIndex].states.map((option, index) => (<MenuItem key={option.code} value={index}>
                        {option.name}
                    </MenuItem>))
                }
            </TextField>
            <TextField
                margin="dense"
                variant="outlined"
                style={{width: '49.5%'}}
                id="postcode"
                error ={values.errorType === 8 && values.postcode === undefined ? true : false }
                label="Postcode*"
                value={values.postcode}
                onChange={handleChange('postcode')}/>
            <TextField
                select="select"
                variant="outlined"
                margin="dense"
                id="country"
                error ={values.errorType === 8 && values.countryIndex === undefined ? true : false }
                label="Country*"
                fullWidth
                value={values.countryIndex}
                onChange={handleChange('countryIndex')}>
                {
                    countries.countries.map((option, index) => (
                        option.hasOwnProperty("states")
                            ? <MenuItem key={index} value={index}>
                                {option.name}
                            </MenuItem>
                            : null))
                }
            </TextField>
            </div>
            </Fade>
            :
            null }
        </DialogContent>
        <DialogActions  style={{padding:'24px 24px'}}>
            <Button onClick={handleCloseAdd} style={{
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary.light,
                    fontWeight: '700'
            }}>
                Add
            </Button>
        </DialogActions>
    </Dialog>);
}

const paymentdetails = [];

function BADialog(props) {
    const theme = useTheme();
    const {
        onClose,
        selectedBA,
        ...other
    } = props;

    const [values, setValues] = useState({
        address: props.data.billingAddress,
        line1: props.data.billingAddress.line1,
        line2: props.data.billingAddress.line2,
        city: props.data.billingAddress.city,
        state: props.data.billingAddress.state,
        postcode: props.data.billingAddress.address_zip,
        country: props.data.billingAddress.country,
        company: props.data.billingAddress.company,
        countryIndex: 0,
        countryCode: '',
        stateIndex: '',
        stateCode: '',
        errorOpen : false,
        errorType : 0,
        message: ''
    });
    function handleClose() {
        onClose(selectedBA);
    }
    function handleCloseAdd() {
        if(values.line1 === '' || values.line1 === undefined || values.city === ''|| values.city === undefined || values.stateIndex === '' || values.stateIndex === undefined || values.postcode === '' || values.postcode === undefined || values.countryIndex === '' || values.countryIndex === undefined){
            var message = ''
            if(values.line1 === '' || values.line1 === undefined) message = 'Line 1'
            else if (values.city === '' || values.city === undefined ) message = 'City'
            else if (values.stateIndex === '' || values.stateIndex === undefined) message = 'State'
            else if (values.postcode === '' || values.postcode === undefined) message = 'Postcode'
            else message = 'Country'
            setValues({...values, errorOpen: true, errorType: 8, message : message})
        } else {
            var newAddress = `${values.line1},`
            if (values.line2 !== "" && values.line2 !== undefined){
                newAddress += ` ${values.line2},`
            }
            newAddress += ` ${values.city}, ${values.postcode}`
            setValues({
                ...values,
                address: newAddress
            })

            onClose(newAddress);
            paymentdetails.push(values.address)
            var country = countries.countries[values.countryIndex].name
            var countryCode = countries.countries[values.countryIndex].code
            var state = countries.countries[values.countryIndex].states[values.stateIndex].name
            var stateCode = countries.countries[values.countryIndex].states[values.stateIndex].code
            var addressObj = {
                company: values.company !== undefined ? values.company : "",
                line1: values.line1,
                line2: values.line2 !== undefined ? values.line2 : "",
                city: values.city,
                address_zip: values.postcode,
                state: state,
                country: country,
                country_code: countryCode,
                state_code: stateCode
            }
            db.updateAddress(addressObj, "billingAddress")
            let p = QueryString.parse(window.location.search)
            if (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1" && p.s !== "productsRUs") {
                FacebookPixel.trackCustom("AddBillingAddress", {storeName: p.s})
                analytics.logEvent('add_billing_info', {
                    storeName: p.s
                });
            }
        }
    }

    const handleChange = name => event => {
        setValues({
            ...values,
            [name]: event.target.value
        });
    };

    function handleCopyAddress() {
        db.getAddress("shippingAddress").then(snapshot => {
          for (var i in countries.countries) {
            if (snapshot.country === countries.countries[i].name) {
                for (var j in countries.countries[i].states) {
                    if (snapshot.state === countries.countries[i].states[j].name) {
                        setValues({
                            ...values,
                            countryIndex: i,
                            stateIndex: j,
                            line1: snapshot.line1,
                            line2: snapshot.line2,
                            city: snapshot.city,
                            state: snapshot.state,
                            postcode: snapshot.address_zip,
                            country: snapshot.country,
                            company: snapshot.company,
                            countryCode: snapshot.country_code,
                            stateCode: snapshot.state_code
                        })
                        break
                    }
                }
                break
              }
          }
        })
    }

    useEffect(() => {
        if (!props.open) handleCloseAdd()
    },[values.line1])

    useEffect(() => {
        for (var i in countries.countries) {
            if (props.data.billingAddress.country === countries.countries[i].name) {
                for (var j in countries.countries[i].states) {
                    if (props.data.billingAddress.state === countries.countries[i].states[j].name) {
                        setValues({
                            ...values,
                            countryIndex: i,
                            stateIndex: j
                        })
                        break
                    }
                }
                break
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const callbackCloseError = (param) => {
        setValues({...values, errorOpen: false})
    }

    return (
        <div>
            {/* <Button onClick={handleCopyAddress} style={{
                    color: theme.palette.primary.main,
                    fontWeight: '700',
                    padding: '8px',
                    marginBottom:'0.5em',
                    marginLeft: '-8px',
                }}>
                Same as Shipping Address
            </Button> */}
    <Dialog fullScreen={props.ismobile} onClose={handleClose} {...other}>
        <ErrorSnackbar errorType={values.errorType} open={values.errorOpen} parentCallbackCloseError={callbackCloseError} message={values.message}/>
        <DialogTitle>
            <p style={{margin: '0em',color: theme.palette.primary.main}}><BackArrow onClick={handleClose} style={{cursor: 'pointer'}}/></p>
            <span style={{color: theme.palette.primary.main, fontWeight: 900}}>BILLING</span><span style={{fontWeight: 900}}> ADDRESS</span>
        </DialogTitle>
        <DialogContent>
            <Button onClick={handleCopyAddress} style={{
                    color: theme.palette.primary.main,
                    fontWeight: '700',
                    padding: '4px'
                }}>
                Same as Shipping Address
            </Button>
            <TextField margin="dense" variant="outlined" id="company" label="Company" fullWidth value={values.company} onChange={handleChange('company')}/>
            <TextField error ={values.errorType === 8 && values.line1 === undefined ? true : false } margin="dense" variant="outlined" id="line1" label="Line 1*" fullWidth value={values.line1} onChange={handleChange('line1')} />
            <TextField margin="dense" variant="outlined" id="line2" label="Line 2" fullWidth value={values.line2} onChange={handleChange('line2')}/>
            <TextField error ={values.errorType === 8 && values.city === undefined ? true : false } margin="dense" variant="outlined" id="city" label="City*" fullWidth value={values.city} onChange={handleChange('city')}/>
            <TextField error ={values.errorType === 8 && values.state === undefined ? true : false } select="select" margin="dense" variant="outlined" id="state" label="State*" style={{width: '49.5%', marginRight: '1%'}} value={values.stateIndex} onChange={handleChange('stateIndex')}>
                {
                    countries.countries[values.countryIndex].states.map((option, index) => (<MenuItem key={option.code} value={index}>
                        {option.name}
                    </MenuItem>))
                }
            </TextField>
            <TextField error ={values.errorType === 8 && values.postcode === undefined ? true : false } margin="dense" variant="outlined" id="postcode" label="Postcode*"  style={{width: '49.5%'}} value={values.postcode} onChange={handleChange('postcode')}/>
            <TextField error ={values.errorType === 8 && values.countryIndex === undefined ? true : false } select="select" margin="dense" variant="outlined" id="country" label="Country*" fullWidth value={values.countryIndex} onChange={handleChange('countryIndex')}>
                {
                    countries.countries.map((option, index) => (
                        option.hasOwnProperty("states")
                        ? <MenuItem key={index} value={index}>
                            {option.name}
                        </MenuItem>
                        : null))
                }
            </TextField>
        </DialogContent>
        <DialogActions  style={{padding:'8px 24px'}}>
            <Button onClick={handleCloseAdd} style={{
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary.light,
                    fontWeight: '700'
                }}>
                Add
            </Button>
        </DialogActions>
    </Dialog>
        </div>);
}
