import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Divider from "@material-ui/core/Divider";
import Lottie from "react-lottie";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import * as errorAnimation from "../assets/errorAnimation.json";
import _ from "lodash";
import withSizes from "react-sizes";
import CouponIcon from "@material-ui/icons/Style";
import { db } from "../firebase";

const styles = theme => ({
	submitOfferButton: {
		color:
			theme.palette.primary.main === "#35B2FF" || theme.palette.primary.main === "#35b2ff"
				? "#FFF"
				: theme.palette.primary.contrastText,
		background: theme.palette.primary.main,
		border: "none",
		width: "100%",
		fontSize: "1.3em",
		fontWeight: "600",
		letterSpacing: "1px",
		display: "inline-block",
		margin: "0em auto 0.5em auto",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark
		}
	},
	button: {
		margin: theme.spacing(1),
		background: "#f6f6f8",
		color: theme.palette.primary.main,
		fontWeight: 600,
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#D3D3D3"
		}
	},
	circularProgress: {
		color: "#ffffff",
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12
	}
});

function SubmitOffer(props) {
	const { classes, alldata } = props;
	const theme = useTheme();

	var offerTotal = Number(alldata.cartTotal);
	if (
		alldata.selectedShipping !== undefined &&
		alldata.selectedShipping !== "" &&
		alldata.selectedShipping !== null
	) {
		offerTotal += Number(alldata.selectedShipping.price);
	}
	if (
		alldata.couponValue !== undefined &&
		alldata.couponValue !== "" &&
		alldata.couponValue !== null
	) {
		offerTotal += Number(alldata.couponValue);
	}
	const [values, setValues] = useState({
		anonymous: true
	});

	const [checked, setChecked] = useState(true);
	const handleChange = event => {
		setChecked(event.target.checked);
		props.subscribed(event.target.checked);
	};

	var discount = Number(alldata.couponValue);
	discount = -1 * discount;

	useEffect(() => {
		let anon = false;
		db.getMeta().then(snapshot => {
			if (!_.isEmpty(snapshot)) {
				if (snapshot.anonymous === true) {
					anon = true;
				}
			}
			setValues({ ...values, anonymous: anon });
		});
	}, []);

	return (
		<div>
			<Dialog
				open={alldata.offerErrorOpen}
				onClose={props.handleOfferErrorClose}
				style={{ minWidth: 500 }}
			>
				<DialogTitle
					style={{
						textAlign: "center"
					}}
				>
					{"Offer Submission Error"}
				</DialogTitle>
				<DialogContent>
					<Lottie
						options={{
							loop: true,
							autoplay: true,
							animationData: errorAnimation.default,
							rendererSettings: {
								preserveAspectRatio: "xMidYMid slice"
							}
						}}
					/>
				</DialogContent>
				<DialogContentText
					variant="subtitle1"
					align="center"
					style={{ textAlign: "center" }}
				>
					{`Your offer could not be submitted.`}
				</DialogContentText>
				<DialogContentText
					variant="subtitle1"
					align="center"
					style={{ textAlign: "center" }}
				>
					{`Please try again.`}
				</DialogContentText>
				<DialogActions style={{ textAlign: "center" }}>
					<Button
						onClick={props.handleOfferErrorClose}
						className={classes.button}
						style={{ display: "inline-block" }}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
			<Divider />
			<div
				style={{
					padding: "0.5em 1.25em 0em",
					lineHeight: "1em",
					backgroundColor: theme.palette.secondary.main,
					fontWeight: "600",
					color: "#7f7f7f"
				}}
			>
				<p>
					<span
						style={{
							display: "inline",
							float: "left"
						}}
					>
						Cart Total
					</span>
					<span
						style={{
							display: "inline",
							marginLeft: "0.5em",
							float: "right"
						}}
					>
						{alldata.data.currency} {alldata.data.currency_symbol}
						{alldata.cartAbandoned
							? Number(alldata.listTotal).toFixed(2)
							: Number(alldata.cartTotal).toFixed(2)}
					</span>
				</p>
				<br />
				{Number(alldata.couponValue) !== 0 ? (
					<div>
						<p>
							<span
								style={{
									display: "inline",
									float: "left",
									marginLeft: "0.5em"
								}}
							>
								Discount <CouponIcon style={{ verticalAlign: "middle" }} />
								{alldata.couponString}
							</span>
							<span
								style={{
									display: "inline",
									marginLeft: "0.5em",
									float: "right"
								}}
							>
								{alldata.data.currency} {alldata.data.currency_symbol}
								{Number(alldata.couponValue).toFixed(2)}
							</span>
						</p>
						<br />
					</div>
				) : null}
				<p>
					<span
						style={{
							display: "inline",
							float: "left",
							maxWidth: "70%"
						}}
					>
						{alldata.selectedShipping !== undefined &&
						alldata.selectedShipping !== null &&
						alldata.selectedShipping !== "" &&
						alldata.selectedShipping.valid === true
							? alldata.selectedShipping.name
							: "Shipping"}
					</span>
					<span
						style={{
							display: "inline",
							marginLeft: "0.5em",
							float: "right"
						}}
					>
						{alldata.selectedShipping !== undefined &&
						alldata.selectedShipping !== null &&
						alldata.selectedShipping !== "" &&
						alldata.selectedShipping.valid === true
							? alldata.data.currency +
							  " " +
							  alldata.data.currency_symbol +
							  Number(alldata.selectedShipping.price).toFixed(2)
							: "Not Selected"}
					</span>
				</p>
				<br />
				<p>
					<span
						style={{
							display: "inline",
							float: "left"
						}}
					>
						Offer Total
					</span>
					<span
						style={{
							display: "inline",
							marginLeft: "0.5em",
							color: Number(offerTotal) > 0 ? theme.palette.primary.main : "#f00",
							float: "right",
							cursor: Number(offerTotal) > 0 ? null : "pointer"
						}}
						onClick={
							Number(offerTotal) > 0 ? null : () => props.parentCallbackSendBack(0)
						}
					>
						{alldata.data.currency} {alldata.data.currency_symbol}
						{Number(offerTotal).toFixed(2)}
					</span>
				</p>
				<br />
				{props.mpOffer ? null : (
					<p
						style={{
							textAlign: "center",
							fontSize: props.isMobile ? "0.8em" : null
						}}
					>
						Offers submitted are open for transaction for {alldata.data.response} hours.
					</p>
				)}
				{props.mpOffer ? <br /> : null}
				<Button
					variant="contained"
					disabled={props.submitting || props.demo}
					className={classes.submitOfferButton}
					onClick={props.submitOffer}
				>
					{!props.submitting ? (
						props.mpOffer ? (
							"Purchase"
						) : (
							"Submit Offer"
						)
					) : (
						<CircularProgress size={24} className={classes.circularProgress} />
					)}
				</Button>
				<div style={{ textAlign: "center" }}>
					<Checkbox checked={checked} onChange={handleChange} color="primary" />

					<p
						style={{
							display: "inline",
							fontSize: "0.8em"
						}}
					>
						Subscribe to daily offers and promotions
					</p>
				</div>
			</div>
			<div
				style={{
					padding: "0em 1.250em",
					backgroundColor: theme.palette.secondary.main,
					fontWeight: "800",
					textAlign: "center",
					color: "#7f7f7f"
				}}
			>
				<p
					style={{
						textAlign: "center",
						fontSize: "0.8em",
						fontWeight: "600"
					}}
				>
					{"By clicking 'Submit Offer' you agree to the "}
					<a
						style={{
							color: theme.palette.primary.main,
							textDecoration: "none"
						}}
						href="https://enteroffer.com/terms"
						target="_blank"
						rel="noopener noreferrer"
					>
						Terms & Conditions
					</a>
					{" and "}
					<a
						style={{
							color: theme.palette.primary.main,
							textDecoration: "none"
						}}
						href="https://enteroffer.com/privacy"
						target="_blank"
						rel="noopener noreferrer"
					>
						Privacy Policy
					</a>
					{"."}
				</p>
				<p
					style={{
						textAlign: "center",
						fontSize: "0.8em",
						fontWeight: "600"
					}}
				>
					{
						"Excluding the offer price, there are no additional fees associated with making an offer."
					}
				</p>
			</div>
			<div
				style={{
					width: "max-content",
					padding: "1em",
					fontSize: "0.8em",
					fontWeight: "600",
					textAlign: "center",
					border: "1px solid #7f7f7f",
					borderRadius: "1em",
					margin: "1em auto"
				}}
			>
				Secure payment <br /> by{" "}
				<a
					style={{
						textDecoration: "none",
						color: theme.palette.primary.main
					}}
					href="https://stripe.com/au"
					target="_blank"
					rel="noopener noreferrer"
				>
					Stripe
				</a>
			</div>
		</div>
	);
}

SubmitOffer.propTypes = {
	classes: PropTypes.object.isRequired
};
const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600
});

export default withStyles(styles)(withSizes(mapSizesToProps)(SubmitOffer));
