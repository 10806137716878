import React, { Component } from "react";
import {
	CardNumberElement,
	CardExpiryElement,
	CardCVCElement,
	injectStripe
} from "react-stripe-elements";
import { cf, analytics } from "../firebase";
import { FacebookPixel } from "./";
import QueryString from "query-string";
import withSizes from "react-sizes";

const createOptions = (fontSize, padding) => {
	return {
		style: {
			base: {
				fontSize,
				color: "#747474",
				width: "50% !important",
				letterSpacing: "0.025em",
				border: "1px solid #383838",
				fontFamily: "inherit",
				"::placeholder": {
					color: "#aab7c4",
					fontFamily: "inherit"
				},
				padding
			},
			invalid: {
				color: "#9e2146"
			}
		}
	};
};

class CheckoutForm extends Component {
	state = {
		error: ""
	};

	componentDidUpdate(prevProps) {
		if (this.props.add !== prevProps.add) {
			if (this.props.stripe) {
				this.props.stripe
					.createToken({ type: "card" })
					.then(payload => {
						if (payload.error) {
							this.props.errorMessage(payload.error.message);
							this.props.resetSave();
							return "initialError";
						} else return cf.updatePayment(payload);
					})
					.then(res => {
						if (res !== "initialError") {
							if (res.data.hasOwnProperty("error")) {
								this.props.errorMessage(res.data.error);
								this.props.resetSave();
							} else {
								this.props.returnStatus(res.data);
								this.props.resetSave();
								let p = QueryString.parse(window.location.search);
								if (
									window.location.hostname !== "localhost" &&
									window.location.hostname !== "127.0.0.1" &&
									p.s !== "productsRUs"
								) {
									FacebookPixel.track("AddPaymentInfo", {
										storeName: p.s
									});
									analytics.logEvent("add_payment_info", {
										storeName: p.s
									});
								}
							}
						}
					})
					.catch(e => {
						this.props.errorMessage(e.message);
						console.error(e);
						this.props.resetSave();
					});
			} else {
				console.log("Stripe.js hasn't loaded yet.");
			}
		}
	}

	handleSubmit = ev => {
		ev.preventDefault();
		if (this.props.stripe) {
			this.props.stripe
				.createToken({ type: "card" })
				.then(payload => {
					console.log("[token]", payload);
					return cf.updatePayment(payload.token.id);
				})
				.then(res => {
					console.log(res);
				})
				.catch(e => {
					console.error("error", e);
					this.props.resetSave();
				});
		} else {
			console.log("Stripe.js hasn't loaded yet.");
		}
	};

	render() {
		return (
			<form onSubmit={this.handleSubmit.bind(this)}>
				<div className="split-form" style={{ textAlign: "left" }}>
					<div>
						<label style={{ color: "#929292" }}>
							Card number
							<div
								style={{
									border: "1px solid #929292",
									padding: "0.5em",
									marginBottom: "1em",
									borderRadius: "4px"
								}}
							>
								<div
									style={{
										width: "65%",
										display: "inline-block"
									}}
								>
									{" "}
									<CardNumberElement
										{...createOptions()}
										onChange={this.handleChange}
									/>
								</div>
								<div
									style={{
										width: "35%",
										display: "inline-block"
									}}
								>
									<img
										src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/creditCards.png"
										alt="cards"
										style={{
											maxWidth: this.props.isMobile ? "115px" : "140px",
											margin: this.props.isMobile ? null : "0em 0.5em",
											verticalAlign: "bottom"
										}}
									/>
								</div>
							</div>
						</label>
					</div>
					<div
						style={{
							width: "49%",
							display: "inline-block",
							marginRight: "2%"
						}}
					>
						<label style={{ color: "#929292" }}>
							Expiration date
							<div
								style={{
									border: "1px solid #929292",
									padding: "0.5em",
									marginBottom: "1em",
									borderRadius: "4px"
								}}
							>
								<CardExpiryElement
									{...createOptions()}
									onChange={this.handleChange}
								/>
							</div>
						</label>
					</div>
					<div
						style={{
							width: "49%",
							display: "inline-block"
						}}
					>
						<label style={{ color: "#929292" }}>
							CVC
							<div
								style={{
									border: "1px solid #929292",
									padding: "0.5em",
									marginBottom: "1em",
									borderRadius: "4px"
								}}
							>
								<CardCVCElement {...createOptions()} onChange={this.handleChange} />
							</div>
						</label>
					</div>
				</div>
			</form>
		);
	}
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600
});

export default withSizes(mapSizesToProps)(injectStripe(CheckoutForm));
