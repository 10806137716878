import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AppBar, Toolbar, Button } from "@material-ui/core";
import { TemporaryDrawer } from "./index";
import { Link, withRouter } from "react-router-dom";
import { db } from "../firebase";
import QueryString from "query-string";

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		fontFamily: "inherit"
	},
	menuButton: {
		marginRight: "16px"
	},
	title: {
		flexGrow: 1
	},
	button: {
		color: theme.palette.primary.main,
		fontWeight: 600,
		zoom: 0.8,
		fontSize: "1.2em",
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#D3D3D3"
		}
	}
}));

const nameChange = string => {
	if (string === "undefined undefined") return "";
	var names = string.split(" ");
	var initials = names[0]
		.substring(0, 1)
		.toUpperCase()
		.concat("");
	if (names.length > 1)
		initials += names[names.length - 1]
			.substring(0, 1)
			.toUpperCase()
			.concat("");
	return initials;
};

function ButtonAppBar(props) {
	const classes = useStyles();
	const theme = useTheme();
	const [values, setValues] = useState({
		isAnonymous: false
	});

	useEffect(() => {
		db.getAnon()
			.then(snapshot => {
				if (snapshot === false || snapshot === undefined || snapshot === null) {
					setValues({ ...values, isAnonymous: false });
				} else {
					setValues({ ...values, isAnonymous: true });
				}
			})
			.catch(e => {
				console.log(e);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const refreshPage = () => {
		if (props.location.pathname === "/") {
			window.location.reload();
		}
	};
	const darkModeCallback = e => {
		props.darkModeCallback(e);
	};

	const parsed = QueryString.parse(window.location.search);

	return (
		<div className={classes.root}>
			<AppBar
				position="fixed"
				style={{
					backgroundColor: theme.palette.secondary.main,
					boxShadow: "none",
					border: "1px solid",
					borderColor: theme.palette.secondary.light,
					fontSize: props.size ? "0.875em" : null
				}}
			>
				<Toolbar style={{ minHeight: "50px !important" }}>
					<div className={classes.title}>
						<TemporaryDrawer
							anonymous={values.isAnonymous}
							darkModeCallback={darkModeCallback}
							mp={parsed.s === "marketplace"}
						/>
						<div onClick={refreshPage} style={{ display: "inline-flex" }}>
							<Link
								to={{
									pathname: parsed.s === "marketplace" ? "/marketplace" : "/",
									search: props.location.search
								}}
								style={{
									textDecoration: "none",
									color: theme.palette.primary.main
								}}
							>
								<img
									src={
										theme.palette.type === "dark"
											? "https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlueAlt.png"
											: "https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png"
									}
									alt="Logo"
									style={{
										width: "9em",
										verticalAlign: "middle"
									}}
								/>
							</Link>
						</div>
					</div>
					{values.isAnonymous ? (
						<Link
							to={{
								pathname:
									props.location.pathname === "/signin" ||
									props.location.pathname === "/signup"
										? "/"
										: "/signin",
								search: props.location.search
							}}
							style={{
								textDecoration: "none",
								color: theme.palette.primary.main
							}}
						>
							{props.location.pathname === "/signin" ||
							props.location.pathname === "/signup" ? (
								props.location.search.indexOf("r=") === -1 ? (
									<Button
										className={classes.button}
										style={{ backgroundColor: "#f6f6f8" }}
									>
										Continue as Guest
									</Button>
								) : null
							) : (
								<Button className={classes.button}>Sign In</Button>
							)}
						</Link>
					) : (
						<Link
							to={{
								pathname: values.isAnonymous ? "/signup" : "/settings",
								search: props.location.search
							}}
							style={{
								textDecoration: "none",
								color: theme.palette.primary.main
							}}
						>
							<div
								id="username"
								style={{
									margin: "0.625em",
									padding: "0.5em",
									backgroundColor: !props.preload
										? theme.palette.primary.main
										: "#fff",
									borderRadius: "50%",
									fontWeight: "700",
									color:
										theme.palette.primary.main === "#35B2FF" ||
										theme.palette.primary.main === "#35b2ff"
											? "#FFF"
											: theme.palette.primary.contrastText
								}}
							>
								{nameChange(props.username)}
							</div>
						</Link>
					)}
				</Toolbar>
			</AppBar>
		</div>
	);
}

export default withRouter(ButtonAppBar);
