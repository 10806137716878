import React, { useState, useEffect } from "react";
import { db, cf, auth, analytics } from "../firebase";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import BackArrow from "@material-ui/icons/KeyboardBackspace";
import Error from "@material-ui/icons/Error";
import Dialog from "@material-ui/core/Dialog";
import QueryString from "query-string";
import jwt from "jsonwebtoken";
import withSizes from "react-sizes";
import _ from "lodash";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";
import * as errorAnimation from "../assets/errorAnimation.json";
import * as offerSubmitted from "../assets/offerSubmitted.json";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import SwipeableViews from "react-swipeable-views";
import validator from "validator";
import Countdown from "react-countdown";
import Fade from "react-reveal/Fade";
import moment from "moment";

import {
	PaymentSelector,
	Products,
	ShippingSelector,
	SubmitOffer,
	BottomNavigation,
	Header,
	ErrorSnackbar,
	NonOfferableError,
	FacebookPixel
} from "../components";
import { Submit } from "./index";
import AddProductsDialog from "../components/simpleDialog";

if (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1") {
	FacebookPixel.pageView();
}

const useStyles = makeStyles(theme => ({
	root: {
		maxWidth: 500,
		margin: "0em auto",
		marginTop: "4.5em"
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
		flexBasis: "40%",
		flexShrink: 0
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary
	},
	textField: {
		width: "100%"
	},
	expansionPanel: {
		margin: "0px !important"
	},
	paper: {
		margin: "auto",
		border: "1px solid",
		borderColor: theme.palette.secondary.light,
		borderRadius: "0px"
	},
	indicator: {
		backgroundColor: theme.palette.primary.main
	},
	button: {
		color:
			theme.palette.primary.main === "#35B2FF" || theme.palette.primary.main === "#35b2ff"
				? "#FFF"
				: theme.palette.primary.contrastText,
		background: theme.palette.primary.main,
		fontWeight: 600,
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#D3D3D3"
		}
	},
	link: {
		color: theme.palette.primary.main,
		textDecoration: "underline",
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.primary.dark
		}
	},
	input: {
		padding: "0.6em"
	}
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{children}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`
	};
}

const demoData = {
	data: {
		firstName: "EnterOffer",
		lastName: "User",
		email: "hello@enteroffer.com",
		products: [
			{
				variants: [
					{
						inventory: true,
						id: 18573338869817,
						url:
							"https://oaklanding.myshopify.com/products/rose-t-shirt?variant=18573338869817",
						weight: 0,
						inventoryId: 20764421062713,
						title: "Variant 1",
						price: "50.00",
						image:
							"https://cdn.shopify.com/s/files/1/0091/4541/7785/products/beautiful-beauty-belt-1408183_1024x1024@2x.jpg?v=1547157757"
					},
					{
						inventory: true,
						id: 18573338902585,
						url:
							"https://oaklanding.myshopify.com/products/rose-t-shirt?variant=18573338902585",
						weight: 0,
						inventoryId: 20764421095481,
						title: "Variant 2",
						price: "50.00",
						image:
							"https://cdn.shopify.com/s/files/1/0091/4541/7785/products/beautiful-beauty-belt-1408183_1024x1024@2x.jpg?v=1547157757"
					}
				],
				title: "Rose t-shirt",
				id: 1983944097849,
				offerable: true
			}
		],
		billingAddress: {
			address_zip: "2010",
			city: "Surry Hills",
			company: "",
			country: "Australia",
			country_code: "AU",
			line1: "7 Poplar Street",
			line2: "",
			state: "New South Wales",
			state_code: "NSW"
		},
		shippingAddress: {
			address_zip: "2010",
			city: "Surry Hills",
			company: "",
			country: "Australia",
			country_code: "AU",
			line1: "7 Poplar Street",
			line2: "",
			state: "New South Wales",
			state_code: "NSW"
		},
		currency_symbol: "$",
		currency: "",
		storeUrl: "https://oaklanding.myshopify.com",
		shipping: {
			price_rates: [
				{
					id: 111348613177,
					name: "Free Shipping",
					price: "0.00",
					shipping_zone_id: 47630778425,
					min_order_subtotal: "1.0",
					max_order_subtotal: null
				},
				{
					id: 111348711481,
					name: "Standard Shipping",
					price: "2.00",
					shipping_zone_id: 47630778425,
					min_order_subtotal: "0.0",
					max_order_subtotal: null
				}
			],
			weight_rates: []
		}
	},
	iat: 1573034433
};

const mpDemoData = {
	data: {
		firstName: "EnterOffer",
		lastName: "User",
		email: "hello@enteroffer.com",
		products: [
			{
				variants: [
					{
						inventory: true,
						id: 19443212845113,
						url:
							"https://oaklanding.myshopify.com/products/iphone-10-cases?variant=19443212845113",
						weight: 0,
						inventoryId: 23115522867257,
						title: "-",
						price: 200,
						sku: "",
						image:
							"https://cdn.shopify.com/s/files/1/0091/4541/7785/products/Untitled_design_13.png?v=1560248414"
					}
				],
				title: "Vintage Camera",
				id: 101984011075641,
				offerable: true,
				instock: true
			}
		],
		billingAddress: {},
		shippingAddress: {},
		currency_symbol: "$",
		currency: "",
		storeUrl: "https://oaklanding.myshopify.com",
		shipping: {
			price_rates: [
				{
					id: 111348613177,
					name: "Free Shipping (Included)",
					price: "0.00",
					shipping_zone_id: 47630778425,
					min_order_subtotal: "1.0",
					max_order_subtotal: null,
					valid: true
				}
			],
			weight_rates: []
		}
	},
	iat: 1573034433
};

function OfferPage(props) {
	const classes = useStyles();
	const theme = useTheme();

	const [email, setEmail] = useState({
		email: "",
		emailEntered: false
	});
	const [anonymous, setAnonymous] = useState(true);
	const [submitting, setSubmitting] = useState(false);
	// const [show, setShow] = useState({
	//     show: false,
	//     showZindex: 0
	// })
	const [deleted, setDeleted] = useState([]);

	const [demo, setDemo] = useState(false);
	const [expiry, setExpiry] = useState(0);

	const [values, setValues] = useState({
		prices: [],
		quantities: [],
		cartTotal: 0,
		data: {},
		storeName: "",
		loading: true,
		variants: [],
		inventoryIds: [],
		shipping: {},
		shippingAddressString: "",
		shippingType: "",
		tabType: 0,
		tabName: "Cart",
		errorType: 0,
		errorOpen: false,
		offerSuccessOpen: false,
		error: false,
		stripeError: "",
		deliveryLoading: false,
		tab1Colour: "#929292",
		tab2Colour: "#929292",
		tab3Colour: "#929292",
		couponValue: 0,
		couponToken: "",
		couponString: "Enter Code",
		userError: "",
		weightTotal: 0,
		offerErrorOpen: false,
		shippingUpdateRequired: false,
		couponLoading: false,
		shippingThreshold: 0,
		couponCode: "",
		offer: {},
		email: "",
		emailEntered: false,
		cartAbandoned: false,
		spp: false,
		listTotal: 0,
		addCard: false,
		cardAdded: false,
		cardChanged: false,
		errorSubmitted: false,
		subscribed: true,
		mpOffer: false
	});

	const [prevUrl, setPrevUrl] = useState("");

	useEffect(() => {
		db.updateOfferUrl();
		db.isOnline();
		db.getSPP().then(snapshot => {
			setExpiry(snapshot.expiry);
		});
		// const parsed = QueryString.parse("?s=productsRUs&p=1764513382457&v=17402820231225&p=1884619997241&v=18307311697977&p=1982014521401&v=18573198590009&url=https://oaklanding.myshopify.com/cart&q=1&q=1&q=1&carta=true")
		let demoString = window.location.search;
		var parsed = QueryString.parse(window.location.search);
		if (parsed.s === "marketplace" || parsed.buyNow) {
			parsed = QueryString.parse(demoString + "&p=1984011075641");
		}
		if (parsed.p === undefined && parsed.s === undefined && parsed.demo) {
			demoString += "&s=productsRUs&url=oaklanding.myshopify.com&p=1983944097849";
		}
		if (parsed.demo) {
			setDemo(true);
			parsed = QueryString.parse(demoString);
		}
		if ((parsed.p === undefined || parsed.s === undefined) && parsed.demo !== "true") {
			setValues({ ...values, error: true });
		} else if (parsed.demo === "true") {
			let cartAbandoned = false;
			let email = "";
			var res = demoData;
			var variants = [],
				quantities = [],
				inventoryIds = [];
			var count = 0;
			var initTotal = 0;
			var initPrices = [];
			var listTotal = 0;
			if ({}.hasOwnProperty.call(parsed, "e") && res.data.email === "") {
				if (validator.isEmail(parsed.e)) {
					db.updateEmail(parsed.e);
				}
			}
			for (var p of res.data.products) {
				if (parsed.demo === "true") {
					if (parsed.n) res.data.products[0].title = String(parsed.n);
					if (parsed.i) res.data.products[0].variants[0].image = String(parsed.i);
					if (parsed.c) res.data.products[0].variants[0].price = String(parsed.c);
					if (parsed.o1) res.data.products[0].variants[0].title = String(parsed.o1);
					if (parsed.i) res.data.products[0].variants[1].image = String(parsed.i);
					if (parsed.c) res.data.products[0].variants[1].price = String(parsed.c);
					if (parsed.o2) res.data.products[0].variants[1].title = String(parsed.o2);
				}
				if (parsed.q === undefined) {
					quantities[p.id] = 1;
				} else {
					quantities[p.id] = parsed.q[count];
				}
				if (parsed.v === undefined) {
					var def = 0;
					for (let v in p.variants) {
						if (p.variants[v].inventory === true) {
							def = v;
							break;
						}
					}
					variants[p.id] = Number(p.variants[def].id);
					inventoryIds[p.id] = Number(p.variants[def].inventoryId);
					if (!p.offerable || cartAbandoned) {
						initTotal += Number(p.variants[def].price) * quantities[p.id];
						initPrices[p.id] = Number(p.variants[def].price);
					}
					listTotal += Number(p.variants[def].price) * quantities[p.id];
				} else {
					variants[p.id] = Number(parsed.v[count]);
					for (let v in p.variants) {
						if (Number(p.variants[v].id) === Number(parsed.v[count])) {
							inventoryIds[p.id] = Number(p.variants[v].inventoryId);
						}
						if (p.variants[v].id === Number(parsed.v[count]) && !p.offerable) {
							initTotal += Number(p.variants[v].price) * quantities[p.id];
							initPrices[p.id] = Number(p.variants[v].price);
						} else if (p.offerable) {
							initPrices[p.id] = "";
						}
						if (p.variants[v].id === Number(parsed.v[count])) {
							listTotal += Number(p.variants[v].price) * quantities[p.id];
						}
					}
				}
				count++;
			}
			for (let p of res.data.products) {
				if (parsed.v !== undefined) {
					for (let v in p.variants) {
						for (let parsei in parsed.v) {
							if (Number(p.variants[v].id) === Number(parsed.v[parsei])) {
								inventoryIds[p.id] = Number(p.variants[v].inventoryId);
							}
						}
					}
				}
			}
			var addressString = "";
			if (values.shippingAddress !== "noAddress") {
				addressString = `${res.data.shippingAddress.line1},`;
				if (res.data.shippingAddress.line2 !== "")
					addressString += ` ${res.data.shippingAddress.line2},`;
				addressString += ` ${res.data.shippingAddress.city}, ${res.data.shippingAddress.address_zip}`;
			}
			var shippingThreshold = 99999999;
			if (res.data.hasOwnProperty("shipping")) {
				for (let r in res.data.shipping.price_rates) {
					if (
						Number(res.data.shipping.price_rates[r].min_order_subtotal) <
							shippingThreshold &&
						Number(res.data.shipping.price_rates[r].price) === 0
					) {
						shippingThreshold = Number(
							res.data.shipping.price_rates[r].min_order_subtotal
						);
					}
				}
			} else {
				shippingThreshold = 0;
			}
			if (cartAbandoned) initTotal = "";
			let buyNow = false;
			let buyPrice = [];
			if (parsed.buyNow) {
				res.data.products[0].variants[0].price = Number(parsed.buyNow);
				buyNow = true;
				buyPrice[parsed.p] = Number(parsed.buyNow);
			}
			setValues({
				...values,
				data: res.data,
				storeName: parsed.s,
				loading: false,
				variants: variants,
				quantities: quantities,
				shippingAddressString: addressString,
				shipping: res.data.shipping,
				cartTotal: buyNow ? Number(parsed.buyNow) : initTotal,
				listTotal: buyNow ? Number(parsed.buyNow) : listTotal,
				prices: buyNow ? buyPrice : initPrices,
				shippingThreshold: shippingThreshold,
				inventoryIds: inventoryIds,
				cartAbandoned: cartAbandoned,
				email: email,
				emailEntered: cartAbandoned,
				mpOffer: buyNow
			});
		} else {
			cf.getOfferDetails(parsed)
				.then(token => {
					let cartAbandoned = false;
					let spp = false;
					let email = "";
					if (parsed.carta === "true" && {}.hasOwnProperty.call(parsed, "e")) {
						if (validator.isEmail(parsed.e)) {
							cartAbandoned = true;
						}
					}

					if (parsed.spp === "true") spp = true;
					else if (parsed.spp === "false") spp = false;
					else spp = null;
					if (token.data !== "campaignError" && parsed.demo !== "true") {
						var res = jwt.decode(token.data);
						var variants = [],
							quantities = [],
							inventoryIds = [];
						var count = 0;
						var initTotal = 0;
						var initPrices = [];
						var listTotal = 0;

						if (parsed.buyNow) {
							res.data.products = mpDemoData.data.products;
							if (parsed.n) res.data.products[0].title = String(parsed.n);
							if (parsed.i) res.data.products[0].variants[0].image = String(parsed.i);
							if (parsed.c)
								res.data.products[0].variants[0].price = String(parsed.buyNow);
							if (parsed.id)
								setPrevUrl(
									`https://market.enteroffer.com/${parsed.id.toLowerCase()}`
								);
							email = parsed.e;
						}
						if ({}.hasOwnProperty.call(parsed, "e") && res.data.email === "") {
							if (validator.isEmail(parsed.e)) {
								db.updateEmail(parsed.e);
							}
						}
						for (var p of res.data.products) {
							if (parsed.q === undefined) {
								quantities[p.id] = 1;
							} else {
								quantities[p.id] = parsed.q[count];
							}
							if (parsed.v === undefined) {
								var def = 0;
								for (let v in p.variants) {
									if (p.variants[v].inventory === true) {
										def = v;
										break;
									}
								}
								variants[p.id] = Number(p.variants[def].id);
								inventoryIds[p.id] = Number(p.variants[def].inventoryId);
								if (!p.offerable || cartAbandoned) {
									initTotal += Number(p.variants[def].price) * quantities[p.id];
									initPrices[p.id] = Number(p.variants[def].price);
								}
								listTotal += Number(p.variants[def].price) * quantities[p.id];
							} else {
								// eslint-disable-next-line no-loop-func
								let obj = res.data.products[count].variants.find(
									// eslint-disable-next-line no-loop-func
									o => Number(o.id) === Number(parsed.v[count])
								);
								if (obj !== null && obj !== undefined) {
									variants[p.id] = Number(parsed.v[count]);
								} else {
									variants[p.id] = Number(
										res.data.products[count].variants[0].id
									);
								}
								for (let v in p.variants) {
									if (Number(p.variants[v].id) === Number(parsed.v[count])) {
										inventoryIds[p.id] = Number(p.variants[v].inventoryId);
									}
									if (
										p.variants[v].id === Number(parsed.v[count]) &&
										!p.offerable
									) {
										initTotal += Number(p.variants[v].price) * quantities[p.id];
										initPrices[p.id] = Number(p.variants[v].price);
									} else if (p.offerable) {
										initPrices[p.id] = "";
									}
									if (p.variants[v].id === Number(parsed.v[count])) {
										listTotal += Number(p.variants[v].price) * quantities[p.id];
									}
								}
							}
							count++;
						}

						for (let p of res.data.products) {
							var instock = false;
							p.instock = false;
							if (parsed.v !== undefined) {
								for (let v in p.variants) {
									instock = p.variants[v].inventory;
									for (let parsei in parsed.v) {
										if (Number(p.variants[v].id) === Number(parsed.v[parsei])) {
											inventoryIds[p.id] = Number(p.variants[v].inventoryId);
										}
									}
								}
							} else {
								for (let v in p.variants) {
									instock = p.variants[v].inventory;
								}
							}
							if (Boolean(instock)) {
								p.instock = instock;
							}
						}
						var addressString = "";
						if (values.shippingAddress !== "noAddress") {
							addressString = `${res.data.shippingAddress.line1},`;
							if (res.data.shippingAddress.line2 !== "")
								addressString += ` ${res.data.shippingAddress.line2},`;
							addressString += ` ${res.data.shippingAddress.city}, ${res.data.shippingAddress.address_zip}`;
						}
						var shippingThreshold = 99999999;
						if (res.data.hasOwnProperty("shipping")) {
							for (let r in res.data.shipping.price_rates) {
								if (
									Number(res.data.shipping.price_rates[r].min_order_subtotal) <
										shippingThreshold &&
									Number(res.data.shipping.price_rates[r].price) === 0
								) {
									shippingThreshold = Number(
										res.data.shipping.price_rates[r].min_order_subtotal
									);
								}
							}
						} else {
							shippingThreshold = 0;
						}
						if (cartAbandoned) initTotal = "";
						let buyNow = false;
						let buyPrice = [];
						if (parsed.buyNow) {
							res.data.products[0].variants[0].price = Number(parsed.buyNow);
							res.data.shipping = mpDemoData.data.shipping;
							buyNow = true;
							buyPrice[parsed.p] = Number(parsed.buyNow);
							setEmail({ ...email, email: email, emailEntered: true });
						}
						setValues({
							...values,
							data: res.data,
							storeName: parsed.s,
							loading: false,
							variants: variants,
							quantities: quantities,
							shippingAddressString: addressString,
							shipping: buyNow ? mpDemoData.data.shipping : res.data.shipping,
							selectedShipping: buyNow
								? mpDemoData.data.shipping.price_rates[0]
								: undefined,
							token: token.data,
							cartTotal: buyNow ? Number(parsed.buyNow) : initTotal,
							listTotal: buyNow ? Number(parsed.buyNow) : listTotal,
							prices: buyNow ? buyPrice : initPrices,
							shippingThreshold: shippingThreshold,
							inventoryIds: inventoryIds,
							cartAbandoned: cartAbandoned,
							spp: spp,
							email: email,
							emailEntered: buyNow ? true : cartAbandoned,
							mpOffer: buyNow,
							tabType: buyNow ? 1 : 0,
							tabName: buyNow ? "Delivery" : "Cart"
						});
					} else {
						console.error("Offer Info Error");
						setValues({ ...values, error: true });
					}
					return;
				})
				.catch(e => {
					setValues({ ...values, error: true });
					console.error(e);
				});
		}
		if (parsed.buyNow === undefined || parsed.buyNow === null) {
			db.getMetaItem("email").then(snapshot => {
				if (snapshot !== null && snapshot !== "" && snapshot !== undefined) {
					setEmail({ ...email, email: snapshot, emailEntered: true });
				}
			});
		}
		db.getMetaItem("anonymous").then(snapshot => {
			if (snapshot !== null && snapshot !== "" && snapshot !== undefined) {
				setAnonymous(snapshot);
			} else {
				setAnonymous(false);
			}
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	auth.onAuthStateChanged(async user => {
		if (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1") {
			user && analytics.setUserId(user.uid);
		}
	});

	function handleChangeIndex(index) {
		setValues({
			...values,
			tabType: index,
			tabName: index === 0 ? "Cart" : index === 1 ? "Shipping" : "Payment"
		});
	}

	const handleOfferErrorClose = () => {
		setValues({ ...values, offerErrorOpen: false });
	};

	const errorMessage = message => {
		setValues({
			...values,
			errorType: 13,
			errorOpen: true,
			stripeError: message,
			addCard: false
		});
	};

	const popError = type => {
		setValues({ ...values, errorType: type, errorOpen: true });
	};

	const handleChange = name => event => {
		setValues({ ...values, [name]: event.target.value });
	};

	const handleSendBack = () => {
		setValues({
			...values,
			tabType: 0,
			tabName: "Cart"
		});
	};
	const handleChangeTab = (event, type) => {
		// window.location.hash = type
		if (type === 0) {
			setValues({
				...values,
				tabType: type,
				tabName: "Cart"
			});
		} else if (type === 1) {
			let flag = true;
			let emptyFlag = true;
			for (let i in values.prices) {
				emptyFlag = false;
				if (values.cartAbandoned === true) {
					if (values.cartTotal >= values.listTotal) {
						flag = false;
					}
					if (
						values.cartTotal === "" ||
						values.cartTotal === undefined ||
						values.cartTotal === null
					) {
						flag = false;
					} else if (values.cartTotal < 1) {
						flag = false;
					}
					break;
				}
				if (
					values.prices[i] === "" ||
					values.prices[i] === undefined ||
					values.prices[i] === null
				) {
					flag = false;
				} else if (values.prices[i] < 1) {
					flag = false;
				}

				for (let j in values.data.products) {
					if (values.data.products[j].offerable === true) {
						let temp = values.data.products[j];
						for (let k in temp.variants) {
							for (let n in values.variants) {
								if (temp.variants[k].id === values.variants[n]) {
									if (
										Number(i) === temp.id &&
										Number(values.prices[i]) >= Number(temp.variants[k].price)
									) {
										flag = false;
									}
								}
							}
						}
					}
				}
			}
			if (emptyFlag) {
				flag = false;
			}
			setValues({
				...values,
				tab1Colour: flag ? "#929292" : "#f00",
				tabType: type,
				tabName: "Delivery"
			});
		} else if (type === 2) {
			let flag1 = true;
			let flag2 = true;
			let emptyFlag = true;

			for (let i in values.prices) {
				emptyFlag = false;
				if (values.cartAbandoned === true) {
					if (values.cartTotal >= values.listTotal) {
						flag1 = false;
					}
					if (
						values.cartTotal === "" ||
						values.cartTotal === undefined ||
						values.cartTotal === null
					) {
						flag1 = false;
					} else if (values.cartTotal < 1) {
						flag1 = false;
					}
					break;
				}
				if (
					values.prices[i] === "" ||
					values.prices[i] === undefined ||
					values.prices[i] === null
				) {
					flag1 = false;
				} else if (values.prices[i] < 1) {
					flag1 = false;
				}

				for (let j in values.data.products) {
					if (values.data.products[j].offerable === true) {
						let temp = values.data.products[j];
						for (let k in temp.variants) {
							for (let n in values.variants) {
								if (temp.variants[k].id === values.variants[n]) {
									if (
										Number(i) === temp.id &&
										Number(values.prices[i]) >= Number(temp.variants[k].price)
									) {
										flag1 = false;
									}
								}
							}
						}
					}
				}
			}
			if (emptyFlag) {
				flag1 = false;
			}
			db.getMetaItem("firstName").then(snapshot => {
				if (_.isEmpty(snapshot)) {
					flag2 = false;
				}
			});

			db.getMetaItem("lastName").then(snapshot => {
				if (_.isEmpty(snapshot)) {
					flag2 = false;
				}
			});

			db.getMetaItem("email").then(snapshot => {
				if (_.isEmpty(snapshot)) {
					flag2 = false;
				}
			});

			db.getAddress("shippingAddress").then(snapshot => {
				if (_.isEmpty(snapshot)) {
					flag2 = false;
				}
			});

			db.getAddress("billingAddress").then(snapshot => {
				if (_.isEmpty(snapshot)) {
					flag2 = false;
				}
			});

			if (_.isEmpty(values.selectedShipping)) {
				flag2 = false;
			}
			setValues({
				...values,
				tab1Colour: flag1 ? "#929292" : "#f00",
				tab2Colour: flag2 ? "#929292" : "#f00",
				tabType: type,
				tabName: "Payment"
			});
		}
	};

	const handleRemoveCoupon = () => {
		setValues({
			...values,
			couponToken: "",
			couponValue: 0,
			couponString: "",
			selectedShipping: undefined
		});
		updateShippingOptions(values.cartTotal, values.weightTotal);
	};

	const callbackValues = (prices, weights) => {
		var totalPrice = 0,
			totalWeight = 0,
			newListTotal = 0;
		for (var key in prices) {
			if (values.quantities[key]) {
				totalPrice += Number(prices[key]) * Number(values.quantities[key]);
			} else {
				totalPrice += Number(prices[key]);
			}
		}
		for (var w in weights) {
			if (values.quantities[w]) {
				totalWeight += Number(weights[w]) * Number(values.quantities[w]);
			} else {
				totalWeight += Number(weights[w]);
			}
		}
		for (var v in values.variants) {
			for (let p in values.data.products) {
				if (values.data.products[p].id === Number(v)) {
					for (let vv in values.data.products[p].variants) {
						if (
							values.data.products[p].variants[vv].id === Number(values.variants[v])
						) {
							newListTotal += Number(values.data.products[p].variants[vv].price);
						}
					}
				}
			}
		}

		var shipping = updateShippingOptions(totalPrice, totalWeight);
		setValues({
			...values,
			cartTotal: Number(totalPrice),
			prices: prices,
			shipping: shipping,
			weightTotal: totalWeight,
			listTotal: newListTotal
		});
	};
	const callbackQuantities = quantities => {
		var total = 0;
		for (var key in values.prices) {
			if (quantities[key]) {
				total += Number(values.prices[key]) * Number(quantities[key]);
			} else {
				total += Number(values.prices[key]);
			}
		}
		var shipping = updateShippingOptions(total);
		setValues({
			...values,
			cartTotal: Number(total),
			quantities: quantities,
			shipping: shipping
		});
	};
	const updateShippingOptions = (totalPrice, totalWeight) => {
		if (
			values.data.shippingAddress === "noAddress" ||
			!values.data.hasOwnProperty("shipping")
		) {
			setValues({ ...values, shipping: {} });
			return;
		}
		var priceRates = values.data.shipping.price_rates;
		var weightRates = values.data.shipping.weight_rates;
		var shipping = values.shipping;
		for (var i in priceRates) {
			var max = Number(priceRates[i].max_order_subtotal);
			if (priceRates[i].max_order_subtotal === null) {
				max = 999999;
			}
			var min = Number(priceRates[i].min_order_subtotal);
			if (totalPrice >= min && totalPrice <= max) {
				shipping.price_rates[i].valid = true;
			} else {
				shipping.price_rates[i].valid = false;
			}
		}
		for (var w in weightRates) {
			max = Number(weightRates[w].weight_high);
			if (weightRates[w].weight_high === null) {
				max = 999999;
			}
			min = Number(weightRates[w].weight_low);
			if (totalWeight >= min && totalWeight <= max) {
				shipping.weight_rates[w].valid = true;
			} else {
				shipping.weight_rates[w].valid = false;
			}
		}
		// debugger;
		return shipping;
	};
	const callbackVariants = (variants, prices) => {
		var newTotal = 0;
		let vs = [];
		for (var p of values.data.products) {
			for (let v in p.variants) {
				if (p.variants[v].id === variants[p.id].id) {
					vs[p.id] = p.variants[v].id;
					newTotal += Number(prices[p.id]) * values.quantities[p.id];
				}
			}
		}
		setValues({
			...values,
			cartTotal: Number(newTotal),
			prices: prices,
			variants: vs
		});
	};
	const callbackShipping = shipping => {
		setValues({
			...values,
			selectedShipping: shipping
		});
	};

	const callbackDeleted = deletedArr => {
		setDeleted(deletedArr);
	};

	const callbackCloseError = param => {
		setValues({ ...values, errorOpen: false });
	};

	const handleApplyCoupon = (
		success,
		couponToken,
		couponValue,
		couponString,
		shippingUpdateRequired
	) => {
		if (success) {
			if (shippingUpdateRequired) {
				setValues({
					...values,
					couponToken: couponToken,
					couponValue: couponValue,
					couponString: couponString,
					selectedShipping: undefined
				});
				updateShippingOptions(
					Math.max(couponValue + values.cartTotal, 0),
					values.weightTotal
				);
			} else {
				setValues({
					...values,
					couponToken: couponToken,
					couponValue: couponValue,
					couponString: couponString
				});
			}
		}
	};

	const cardAdded = flag => {
		if (flag) {
			setValues({ ...values, cardAdded: true, cardChanged: false });
		}
	};
	const cardChanged = flag => {
		if (flag) {
			setValues({ ...values, cardChanged: true });
		}
	};

	// const sleep = (milliseconds) => {
	//     return new Promise(resolve => setTimeout(resolve, milliseconds))
	//   }

	const updateShippingData = () => {
		setValues({ ...values, deliveryLoading: true });

		var parsed = QueryString.parse(window.location.search);
		var f = parsed.buyNow;
		if (f) {
			parsed = QueryString.parse(
				"?s=productsRUs&p=1984011075641&url=oaklanding.myshopify.com#Cart"
			);
		}
		cf.getOfferDetails(parsed).then(res => {
			if (res.data !== "campaignError") {
				var decoded = jwt.decode(res.data);
				decoded = decoded.data;
				if (f) {
					decoded.products = mpDemoData.data.products;
					if (parsed.n) decoded.products[0].title = String(parsed.n);
					if (parsed.i) decoded.products[0].variants[0].image = String(parsed.i);
					if (parsed.c) decoded.products[0].variants[0].price = String(parsed.buyNow);
					if (parsed.id)
						setPrevUrl(`https://market.enteroffer.com/${parsed.id.toLowerCase()}`);
					decoded.shipping = mpDemoData.data.shipping;

					setValues({
						...values,
						data: decoded,
						shipping: mpDemoData.data.shipping,
						selectedShipping: mpDemoData.data.shipping.price_rates[0],
						deliveryLoading: false
					});
				} else {
					decoded.products.sort((a, b) => (a.offerable < b.offerable ? 1 : -1));
					deleted.forEach(index => {
						decoded.products.splice(index, 1);
					});
					var shipping = {};
					if (decoded.hasOwnProperty("shipping")) {
						var priceRates = decoded.shipping.price_rates;
						var weightRates = decoded.shipping.weight_rates;
						shipping = decoded.shipping;
						for (var i in priceRates) {
							var max = Number(priceRates[i].max_order_subtotal);
							if (priceRates[i].max_order_subtotal === null) max = 999999;
							var min = Number(priceRates[i].min_order_subtotal);
							if (values.cartTotal >= min && values.cartTotal <= max) {
								shipping.price_rates[i].valid = true;
							} else {
								shipping.price_rates[i].valid = false;
							}
						}
						for (var w in weightRates) {
							max = Number(weightRates[w].weight_high);
							if (weightRates[w].weight_high === null) max = 999999;
							min = Number(weightRates[w].weight_low);
							if (values.weightTotal >= min && values.weightTotal <= max) {
								shipping.weight_rates[w].valid = true;
							} else {
								shipping.weight_rates[w].valid = false;
							}
						}
						decoded.shipping = shipping;
					}

					var shippingThreshold = 99999999;
					if (decoded.hasOwnProperty("shipping")) {
						for (let r in decoded.shipping.price_rates) {
							if (
								Number(decoded.shipping.price_rates[r].min_order_subtotal) <
									shippingThreshold &&
								Number(decoded.shipping.price_rates[r].price) === 0
							) {
								shippingThreshold = Number(
									decoded.shipping.price_rates[r].min_order_subtotal
								);
							}
						}
					} else {
						shippingThreshold = 0;
					}
					setValues({
						...values,
						data: decoded,
						email: parsed.e,
						emailEntered: true,
						shipping: decoded.shipping,
						selectedShipping: "",
						token: res.data,
						shippingThreshold: shippingThreshold,
						deliveryLoading: false
					});
					setEmail({ ...email, email: parsed.e, emailEntered: true });
				}
			} else {
				setValues({ ...values, error: true, deliveryLoading: false });
			}
			return;
		});
	};

	const callbackEmailEntered = (flag, newEmail) => {
		if (flag) {
			setEmail({ ...email, email: newEmail, emailEntered: true });
			db.updateEmail(newEmail);
		} else {
			setValues({
				...values,
				errorType: 13,
				errorOpen: true,
				stripeError:
					"The email you entered is invalid! Please enter a valid email and try again."
			});
		}
	};

	const cartATotal = total => {
		setValues({ ...values, cartTotal: total });
	};

	// All Errors get checked here
	const callbackNext = type => {
		var f = true;
		var emptyFlag = true;
		const parsed = QueryString.parse(window.location.search);
		if (!values.mpOffer) {
			for (let i in values.prices) {
				emptyFlag = false;
				if (values.cartAbandoned === true) {
					if (values.cartTotal >= values.listTotal) {
						setValues({ ...values, errorType: 3, errorOpen: true });
						f = false;
					}
					if (
						values.cartTotal === "" ||
						values.cartTotal === undefined ||
						values.cartTotal === null
					) {
						setValues({
							...values,
							errorType: 21,
							errorOpen: true,
							stripeError: "Please ensure you have entered an offer"
						});
						f = false;
					} else if (values.cartTotal < 1) {
						setValues({ ...values, errorType: 2, errorOpen: true });
						f = false;
					}
					break;
				} else {
					if (
						values.prices[i] === "" ||
						values.prices[i] === undefined ||
						values.prices[i] === null
					) {
						setValues({ ...values, errorType: 1, errorOpen: true });
						f = false;
					} else if (values.prices[i] < 1) {
						setValues({ ...values, errorType: 2, errorOpen: true });
						f = false;
					}
					for (let j in values.data.products) {
						if (values.data.products[j].offerable === true) {
							let temp = values.data.products[j];
							for (let k in temp.variants) {
								for (let n in values.variants) {
									if (temp.variants[k].id === values.variants[n]) {
										if (
											Number(i) === temp.id &&
											Number(values.prices[i]) >=
												Number(temp.variants[k].price)
										) {
											setValues({
												...values,
												errorType: 3,
												errorOpen: true
											});
											f = false;
										}
									}
								}
							}
						}
					}
				}
			}

			if (emptyFlag) {
				setValues({ ...values, errorType: 1, errorOpen: true });
				f = false;
			}
		}

		if (values.tabType === 1 || values.tabType === 2) {
			db.getMetaItem("firstName").then(snapshot => {
				if (_.isEmpty(snapshot)) {
					setValues({ ...values, errorType: 4, errorOpen: true });
					f = false;
				}
			});

			db.getMetaItem("lastName").then(snapshot => {
				if (_.isEmpty(snapshot)) {
					setValues({ ...values, errorType: 4, errorOpen: true });
					f = false;
				}
			});

			db.getMetaItem("email").then(snapshot => {
				if (_.isEmpty(snapshot) && values.email === "") {
					setValues({ ...values, errorType: 4, errorOpen: true });
					f = false;
				}
			});
			if (parsed.s === "iceJewellery") {
				db.getAddress("shippingAddress").then(snapshot => {
					if (_.isEmpty(snapshot.phoneNo)) {
						setValues({ ...values, errorType: 4, errorOpen: true });
						f = false;
					}
				});
			}

			db.getAddress("shippingAddress").then(snapshot => {
				if (_.isEmpty(snapshot)) {
					setValues({ ...values, errorType: 4, errorOpen: true });
					f = false;
				}
			});

			db.getAddress("billingAddress").then(snapshot => {
				if (_.isEmpty(snapshot) && f) {
					setValues({ ...values, errorType: 5, errorOpen: true });
					f = false;
				}
			});

			if (_.isEmpty(values.selectedShipping) && f) {
				setValues({ ...values, errorType: 6, errorOpen: true });
				f = false;
			}
		}

		if (values.tabType === 2) {
			db.getPayment().then(snapshot => {
				if (_.isEmpty(snapshot)) {
					setValues({ ...values, errorType: 7, errorOpen: true });
					f = false;
				}
			});
		}

		if (type === 0 && f) {
			setValues({
				...values,
				tabType: 1,
				tabName: "Delivery",
				tab1Colour: "#929292"
			});
		} else if (type === 1 && f) {
			setValues({
				...values,
				tabType: 2,
				tabName: "Payment",
				tab2Colour: "#929292"
			});
		}
	};

	const handleAddCard = () => {
		setValues({ ...values, addCard: true });
	};

	const addCardsubmitOffer = () => {
		handleAddCard();
	};
	const handleSubscribe = flag => {
		setValues({ ...values, subscribed: flag });
	};

	useEffect(() => {
		if (values.cardAdded) {
			submitOffer();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values.cardAdded]);

	const deleteAnonData = async flag => {
		if (flag) {
			await db.updateMetaItem("payment", null);
			await db.updateMetaItem("billingAddress", null);
			await db.updateMetaItem("shippingAddress", null);
		}
	};
	const submitOffer = async () => {
		var flag = true;
		var errorType = 0;
		var emptyFlag = true;
		let payment = "";
		if (values.subscribed) db.newsletterSignup(email);

		for (let i in values.prices) {
			emptyFlag = false;
			if (values.cartAbandoned === true) {
				if (values.cartTotal >= values.listTotal) {
					setValues({ ...values, errorType: 3, errorOpen: true });
					errorType = 3;
					flag = false;
				}
				if (
					values.cartTotal === "" ||
					values.cartTotal === undefined ||
					values.cartTotal === null
				) {
					setValues({ ...values, errorType: 1, errorOpen: true });
					errorType = 1;
					flag = false;
				} else if (values.cartTotal < 1) {
					setValues({ ...values, errorType: 2, errorOpen: true });
					errorType = 2;
					flag = false;
				}
				break;
			} else {
				if (
					values.prices[i] === "" ||
					values.prices[i] === undefined ||
					values.prices[i] === null
				) {
					setValues({ ...values, errorType: 1, errorOpen: true });
					errorType = 1;
					flag = false;
				} else if (values.prices[i] < 1) {
					setValues({ ...values, errorType: 2, errorOpen: true });
					flag = false;
					errorType = 2;
				}

				for (let j in values.data.products) {
					if (values.data.products[j].offerable === true) {
						let temp = values.data.products[j];
						for (let k in temp.variants) {
							for (let n in values.variants) {
								if (temp.variants[k].id === values.variants[n]) {
									if (
										Number(i) === temp.id &&
										Number(values.prices[i]) >= Number(temp.variants[k].price)
									) {
										setValues({
											...values,
											errorType: 3,
											errorOpen: true
										});
										flag = false;
										errorType = 3;
									}
								}
							}
						}
					}
				}
			}
		}

		if (emptyFlag) {
			setValues({ ...values, errorType: 1, errorOpen: true });
			errorType = 1;
			flag = false;
		}
		if (flag) {
			await db.getMetaItem("firstName").then(snapshot => {
				if (_.isEmpty(snapshot)) {
					setValues({ ...values, errorType: 4, errorOpen: true });
					flag = false;
					errorType = 4;
				}
			});

			await db.getMetaItem("lastName").then(snapshot => {
				if (_.isEmpty(snapshot)) {
					setValues({ ...values, errorType: 4, errorOpen: true });
					flag = false;
					errorType = 4;
				}
			});

			await db.getMetaItem("email").then(snapshot => {
				if (_.isEmpty(snapshot) && values.email === "") {
					setValues({ ...values, errorType: 4, errorOpen: true });
					flag = false;
					errorType = 4;
				}
			});

			await db.getAddress("shippingAddress").then(snapshot => {
				if (_.isEmpty(snapshot)) {
					setValues({ ...values, errorType: 4, errorOpen: true });
					flag = false;
					errorType = 4;
				}
			});

			await db.getAddress("billingAddress").then(snapshot => {
				if (_.isEmpty(snapshot) && flag) {
					setValues({ ...values, errorType: 5, errorOpen: true });
					flag = false;
					errorType = 5;
				}
			});

			if (_.isEmpty(values.selectedShipping) && flag) {
				setValues({ ...values, errorType: 6, errorOpen: true });
				flag = false;
				errorType = 6;
			}
		}

		if (values.tabType === 2 && flag) {
			payment = await db.getPayment();
			if (
				payment === null ||
				payment === undefined ||
				payment === "" ||
				payment.token === null ||
				payment.token === undefined ||
				payment.token === ""
			) {
				flag = false;
				errorType = 7;
			}
			if (
				flag &&
				(values.selectedShipping === "" ||
					values.selectedShipping === undefined ||
					values.selectedShipping === null ||
					values.selectedShipping.valid === false)
			) {
				flag = false;
				errorType = 6;
			}
		}
		if (flag) {
			setSubmitting(true);
			var meta = await db.getMeta();
			let paymentInfo = {
				currency: values.data.currency,
				currency_symbol: values.data.currency_symbol,
				token: payment.token
			};
			let shippingAddress = meta.shippingAddress;
			let billingAddress = meta.billingAddress;
			let names = {
				firstName: meta.firstName,
				lastName: meta.lastName
			};
			let email = meta.email;
			shippingAddress.email = email;
			shippingAddress.firstName = names.firstName;
			shippingAddress.lastName = names.lastName;
			billingAddress.email = email;
			billingAddress.firstName = names.firstName;
			billingAddress.lastName = names.lastName;

			var products = [];
			for (var i in values.data.products) {
				if (
					values.mpOffer &&
					isNaN(Number(values.prices[values.data.products[i].id - 100000000000000]))
				) {
					continue;
				}
				if (!values.mpOffer && isNaN(Number(values.prices[values.data.products[i].id]))) {
					continue;
				}
				var vImg = "";
				var vType = "";
				var vPrice = 0;
				for (let j in values.data.products[i].variants) {
					if (
						values.data.products[i].variants[j].id ===
						Number(values.variants[values.data.products[i].id])
					) {
						vImg = values.data.products[i].variants[j].image;
						vType = values.data.products[i].variants[j].title;
						vPrice = values.data.products[i].variants[j].price;
					}
				}
				var prod = {
					offerable: values.data.products[i].offerable,
					title: values.data.products[i].title,
					variantID: Number(values.variants[values.data.products[i].id]),
					image: vImg,
					variantType: vType,
					price: vPrice,
					bidPrice: values.mpOffer
						? Number(values.prices[values.data.products[i].id - 100000000000000])
						: Number(values.prices[values.data.products[i].id]),
					productID: values.data.products[i].id,
					quantity:
						values.quantities[values.data.products[i].id] === undefined ||
						values.quantities[values.data.products[i].id] === null
							? 1
							: values.quantities[values.data.products[i].id],
					inventoryId: Number(values.inventoryIds[values.data.products[i].id])
				};
				products.push(prod);
			}

			var finalTotal =
				Number(values.cartTotal) +
				Number(values.selectedShipping.price) +
				Number(values.couponValue);
			let parsed = QueryString.parse(window.location.search);

			let loc = `${shippingAddress.line1},`;
			if (shippingAddress.line2 !== "") loc += ` ${shippingAddress.line2},`;
			loc += ` ${shippingAddress.city}, ${shippingAddress.address_zip}`;

			var offer = {
				token: values.token,
				anonymous: meta.anonymous,
				shipping: values.selectedShipping.id,
				storeName: values.storeName === "marketplace" ? "productsRUs" : values.storeName,
				offer: products,
				total: finalTotal,
				paymentInfo: paymentInfo,
				shippingAddress: shippingAddress,
				billingAddress: billingAddress,
				cartAbandoned: values.cartAbandoned,
				cartTotal: values.cartTotal,
				marketplaceOffer: values.mpOffer,
				location: loc,
				images: "-",
				description: "-",
				condition: parsed.c ? parsed.c : "-",
				sellId: parsed.sellId ? parsed.sellId : "-",
				productName: values.mpOffer ? products[0].title : "-",
				untilDate: parsed.till ? parsed.till : "-"
			};
			if (values.spp === true || values.spp === false) {
				offer.spp = values.spp;
			}
			if (values.couponToken !== "") {
				offer.couponToken = values.couponToken;
			}
			return await cf
				.submitOffer(offer)
				.then(res => {
					deleteAnonData(meta.anonymous);
					setTimeout(() => {
						setValues({
							...values,
							offerSuccessOpen: true,
							offer: offer
						});
						setSubmitting(false);
					}, 2000);
					if (
						window.location.hostname !== "localhost" &&
						window.location.hostname !== "127.0.0.1" &&
						values.storeName !== "productsRUs"
					) {
						FacebookPixel.trackCustom("OfferSubmitted", {
							storeName: values.storeName,
							cartTotal: values.cartTotal
						});
						analytics.logEvent("offer_submitted", {
							storeName: values.storeName,
							cartTotal: values.cartTotal
						});
						analytics.setUserProperties({ offer_submitted: true });
					}
				})
				.catch(e => {
					console.error(e);
					setValues({ ...values, offerErrorOpen: true });
					setSubmitting(false);
					throw e;
				});
		} else {
			setValues({ ...values, errorType: errorType, errorOpen: true });
			return null;
		}
	};

	// Ryan to push the error message to email.
	function handleSendError() {
		setValues({ ...values, errorSubmitted: true });
		cf.errorEmail(values.errorUser, values.errorUserEmail)
			.then(res => {
				//handle
			})
			.catch(res => {
				//handle
			});
	}

	const [openHelper, setOpenHelper] = useState(false);
	const handleClickOpenHelper = () => {
		setOpenHelper(true);
	};
	const handleCloseHelper = () => {
		setOpenHelper(false);
	};

	const darkModeCallback = e => {
		props.darkModeCallback(e);
	};
	const renderer = ({ days, hours, minutes, seconds }) => {
		return (
			<span>
				{days}d {hours}h {minutes}m {seconds}s
			</span>
		);
	};

	if (values.error === true) {
		if (
			window.location.hostname !== "localhost" &&
			window.location.hostname !== "127.0.0.1" &&
			values.storeName !== "productsRUs"
		) {
			FacebookPixel.trackCustom("OfferError", {
				storeName: values.storeName
			});
			analytics.logEvent("offer_error", { storeName: values.storeName });
		}
		return (
			<div>
				<Header username={``} darkModeCallback={darkModeCallback} />
				{values.errorSubmitted ? (
					<div
						id="offer-error"
						className={classes.root}
						style={{
							textAlign: "center",
							display: "block",
							marginLeft: props.isMobile ? "10%" : "auto",
							marginRight: props.isMobile ? "10%" : "auto",
							marginTop: props.isMobile ? "20%" : "10%"
						}}
					>
						<Lottie
							height={props.isMobile ? 150 : 200}
							width="80%"
							options={{
								loop: false,
								autoplay: true,
								animationData: offerSubmitted.default,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice"
								}
							}}
						/>
						<Typography
							variant="h6"
							gutterBottom
							style={{
								fontWeight: "700",
								textAlign: "center"
							}}
						>
							Thank you for submitting your feedback. <br />
							Someone from EnterOffer will be in contact with you shortly!
						</Typography>
					</div>
				) : (
					<div
						id="offer-error"
						className={classes.root}
						style={{
							textAlign: "center",
							display: "block",
							marginLeft: props.isMobile ? "10%" : "auto",
							marginRight: props.isMobile ? "10%" : "auto",
							marginTop: props.isMobile ? "75px" : "100px"
						}}
					>
						<Lottie
							height={150}
							width={150}
							options={{
								loop: true,
								autoplay: true,
								animationData: errorAnimation.default,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice"
								}
							}}
						/>
						<br />
						<br />
						<Typography
							variant="h3"
							gutterBottom
							style={{
								// fontWeight: '700',
								textAlign: "center",
								color: theme.palette.secondary.dark
							}}
						>
							Uh oh.
						</Typography>
						<br />
						<Typography
							variant={props.isMobile ? "body2" : "body1"}
							gutterBottom
							style={{
								fontWeight: "500",
								textAlign: "center",
								color: theme.palette.secondary.dark
							}}
						>
							The page you requested could not be found.
						</Typography>
						<Typography
							variant={props.isMobile ? "body2" : "body1"}
							gutterBottom
							style={{
								fontWeight: "500",
								textAlign: "center",
								color: theme.palette.secondary.dark
							}}
						>
							Try{" "}
							<span
								onClick={() => {
									window.location.reload();
								}}
								className={classes.link}
							>
								refreshing the page
							</span>{" "}
							or send us a message below and we'll try to fix it.
						</Typography>
						<TextField
							id="outlined-error-email"
							placeholder="Email address"
							className={classes.textField}
							value={values.errorUserEmail}
							onChange={handleChange("errorUserEmail")}
							InputProps={{
								classes: { input: classes.input }
							}}
							margin="normal"
							variant="outlined"
						/>
						<TextField
							id="outlined-error"
							multiline
							placeholder="Message"
							rows={props.isMobile ? "3" : "4"}
							className={classes.textField}
							value={values.errorUser}
							onChange={handleChange("errorUser")}
							margin="normal"
							variant="outlined"
						/>
						<br />
						<br />
						<Button onClick={handleSendError} className={classes.button}>
							Send
						</Button>
					</div>
				)}
			</div>
		);
	} else if (values.loading === false) {
		var offerableFlag = true;
		values.data.products.forEach(product => {
			if (product.offerable) {
				offerableFlag = false;
			}
		});
		if (values.cartAbandoned) offerableFlag = false;
		if (offerableFlag && !values.offerSuccessOpen && !values.cartAbandoned) {
			return (
				<div>
					<Header username={``} darkModeCallback={darkModeCallback} />

					<NonOfferableError />
					<div className={classes.root} style={{ textAlign: "center" }}>
						<Typography
							variant={props.isMobile ? "body2" : "body1"}
							gutterBottom
							style={{
								fontWeight: "500",
								textAlign: "center",
								color: theme.palette.secondary.dark
							}}
						>
							Try{" "}
							<span
								onClick={() => {
									window.location.reload();
								}}
								className={classes.link}
							>
								refreshing the page
							</span>{" "}
							if you think we have made an error please let us know what you did to
							get here so we can fix it for you.
						</Typography>
						<TextField
							id="outlined-error-email"
							placeholder="Email address"
							className={classes.textField}
							value={values.errorUserEmail}
							onChange={handleChange("errorUserEmail")}
							InputProps={{
								classes: { input: classes.input }
							}}
							margin="normal"
							variant="outlined"
						/>
						<TextField
							id="outlined-error"
							multiline
							placeholder="Message"
							rows={props.isMobile ? "3" : "4"}
							className={classes.textField}
							value={values.errorUser}
							onChange={handleChange("errorUser")}
							margin="normal"
							variant="outlined"
						/>
						<br />
						<br />
						<Button onClick={handleSendError} className={classes.button}>
							Send
						</Button>
					</div>
				</div>
			);
		} else if (!offerableFlag && !values.offerSuccessOpen) {
			var retailerLogo = `https://s3-ap-southeast-2.amazonaws.com/oaklas/logos/${values.storeName}Logo.png`;
			let parsed = QueryString.parse(window.location.search);
			if (demo) {
				if (parsed.logo) retailerLogo = parsed.logo;
			}
			return (
				(window.location.hash = values.tabName),
				(
					<div style={{ fontFamily: props.font }}>
						<Header
							username={`${values.data.firstName} ${values.data.lastName}`}
							darkModeCallback={darkModeCallback}
						/>
						<ErrorSnackbar
							errorType={values.errorType}
							stripeError={values.stripeError}
							open={values.errorOpen}
							parentCallbackCloseError={callbackCloseError}
						/>
						{/* {email.emailEntered ? null :
				<Fade opposite when={show.show}>
					<div style={{backgroundColor: 'rgba(0, 0, 0, 0.5)', position:'absolute', zIndex: show.showZindex, height: '100vh', width:'100%'}}>
						<div style={{marginTop: props.isMobile? (props.isSmallMobile? '50%':'65%'):'60vh'}}>
						<h2 style={{color: '#fff', textAlign:'center', padding:'0em 0.5em'}}>Enter email to continue to delivery and payment!</h2>
						<Lottie height={180} width={130} options={{
							loop: true,
							autoplay: true,
							animationData: arrowDown.default,
							rendererSettings: {
								preserveAspectRatio: 'xMidYMid slice'
							}
						}}/>
						</div>
					</div>
				</Fade>
				} */}
						<div className={classes.root} style={{ marginTop: "4.8em" }}>
							{parsed.spp === "true" || parsed.spp === "false" ? (
								<Paper
									elevation={0}
									className={classes.paper}
									style={{
										position: "static",
										width: props.isMobile ? "-webkit-fill-available" : 500,
										zIndex: "1",
										marginTop: props.isMobile ? "-12px" : "-2px",
										textAlign: "center",
										fontSize: "30px",
										fontWeight: 700,
										letterSpacing: 2,
										padding: "8px"
									}}
								>
									<p
										style={{
											margin: 0,
											letterSpacing: 0,
											color: "#35b2ff",
											fontSize: "16px",
											fontWeight: 600
										}}
									>
										Offer expires in
									</p>
									<Countdown
										date={moment.unix(expiry).format()}
										renderer={renderer}
										style={{ fontSize: "30px" }}
									/>
								</Paper>
							) : null}
							<Paper
								elevation={0}
								className={classes.paper}
								style={{
									position: "static",
									width: props.isMobile ? "-webkit-fill-available" : 500,
									zIndex: "1",
									marginTop: props.isMobile ? "-12px" : "-2px"
								}}
							>
								<Tabs
									value={values.tabType}
									onChange={handleChangeTab}
									classes={{
										indicator: classes.indicator
									}}
									variant="fullWidth"
									style={{
										fontSize: "2.5em"
									}}
								>
									<Tab
										label={
											values.tab1Colour === "#0f0" ? (
												<span>
													Cart
													<Error
														style={{
															fontSize: "1em",
															verticalAlign: "super",
															color: "#f00"
														}}
													/>
												</span>
											) : (
												<span>Cart</span>
											)
										}
										disabled={submitting}
										style={{
											fontSize: "0.4em",
											backgroundColor: "#fff !important",
											color:
												values.tabType === 0
													? theme.palette.secondary.dark
													: values.tab1Colour,
											fontWeight: "700"
										}}
										{...a11yProps(0)}
									/>
									<Tab
										label={
											values.tab2Colour === "#0f0" ? (
												<span>
													Delivery
													<Error
														style={{
															fontSize: "1em",
															verticalAlign: "super",
															color: "#f00"
														}}
													/>
												</span>
											) : (
												<span>Delivery</span>
											)
										}
										disabled={submitting || !email.emailEntered}
										style={{
											backgroundColor: "#fff !important",
											fontSize: "0.4em",
											color:
												values.tabType === 1
													? theme.palette.secondary.dark
													: values.tab2Colour,
											fontWeight: "700"
										}}
										{...a11yProps(1)}
									/>
									<Tab
										label="Payment"
										disabled={submitting || !email.emailEntered}
										style={{
											backgroundColor: "#fff !important",
											fontSize: "0.4em",
											color:
												values.tabType === 2
													? theme.palette.secondary.dark
													: "#929292",
											fontWeight: "700"
										}}
										{...a11yProps(2)}
									/>
								</Tabs>
							</Paper>
							{parsed.buyNow ? null : (
								<Paper
									elevation={0}
									className={classes.paper}
									style={{
										padding: "1em 1em",
										textAlign: "center"
									}}
								>
									<ReactCSSTransitionGroup
										transitionName="loadingItem"
										transitionAppear={true}
										transitionAppearTimeout={500}
										transitionEnterTimeout={500}
										transitionLeaveTimeout={300}
									>
										<img
											alt={`${values.storeName}`}
											src={retailerLogo}
											style={{
												maxWidth: "60%",
												maxHeight: " 60px",
												display: "inline-block",
												objectFit: "cover",
												backgroundColor: "#fff"
											}}
										/>
									</ReactCSSTransitionGroup>
								</Paper>
							)}
							<SwipeableViews
								disabled={!email.emailEntered}
								axis={theme.direction === "rtl" ? "x-reverse" : "x"}
								index={values.tabType}
								onChangeIndex={handleChangeIndex}
							>
								<TabPanel value={values.tabType} index={0} dir={theme.direction}>
									<div>
										<Paper
											elevation={0}
											className={classes.paper}
											style={{
												padding: "1em",
												marginBottom:
													values.data.products.length === 1
														? "1em"
														: "12em"
											}}
										>
											{values.shippingThreshold > 1 &&
											values.data.hasOwnProperty("shipping") ? (
												values.shippingThreshold !== 99999999 ? (
													<Typography
														color="textSecondary"
														style={{
															fontWeight: "700",
															fontSize: "0.8em",
															textAlign: "center",
															marginBottom: "1em"
														}}
													>
														Free shipping for offers totaling above{" "}
														{values.data.currency}{" "}
														{values.data.currency_symbol}
														{values.shippingThreshold}!
													</Typography>
												) : (
													<Typography
														color="textSecondary"
														style={{
															fontWeight: "700",
															fontSize: "0.8em",
															textAlign: "center",
															marginBottom: "1em"
														}}
													>
														Free shipping!
													</Typography>
												)
											) : values.shippingThreshold < 1 &&
											  values.data.hasOwnProperty("shipping") ? (
												<Typography
													color="textSecondary"
													style={{
														fontWeight: "700",
														fontSize: "0.8em",
														textAlign: "center",
														marginBottom: "1em"
													}}
												>
													Free shipping!
												</Typography>
											) : null}
											{values.deliveryLoading === false ? (
												<Products
													data={values.data}
													parentCallbackValues={callbackValues}
													parentCallbackQuantities={callbackQuantities}
													parentCallbackVariants={callbackVariants}
													parentCallbackDeleted={callbackDeleted}
													variants={values.variants}
													updateShippingOptions={updateShippingOptions}
													prices={values.prices}
													quantities={values.quantities}
													parentCallbackNext={callbackNext}
													cartAbandoned={values.cartAbandoned}
													mpOffer={values.mpOffer}
													prevUrl={prevUrl}
												/>
											) : null}
										</Paper>
										{values.data.products.length === 1 &&
										parsed.s !== "iceJewellery" &&
										!values.mpOffer ? (
											<AddProductsDialog
												data={values.prices}
												url={parsed.url}
												q={values.quantities}
												v={values.variants}
											/>
										) : null}
									</div>
								</TabPanel>
								<TabPanel value={values.tabType} index={1} dir={theme.direction}>
									<Paper
										elevation={0}
										className={classes.paper}
										style={{
											marginBottom: "7em"
										}}
									>
										{values.deliveryLoading === false ? (
											<ShippingSelector
												data={values.data}
												email={email}
												shipping={values.data.shipping}
												parentCallbackShipping={callbackShipping}
												selectedShipping={values.selectedShipping}
												updateShippingData={updateShippingData}
												error={values.errorType}
												mpOffer={values.mpOffer}
											/>
										) : (
											<Lottie
												height={200}
												width={200}
												options={{
													loop: true,
													autoplay: true,
													animationData: loader.default,
													rendererSettings: {
														preserveAspectRatio: "xMidYMid slice"
													}
												}}
											/>
										)}
									</Paper>
									{_.isEmpty(values.data.shipping) ||
									!values.data.hasOwnProperty("shipping") ? (
										<div
											style={{
												display:
													values.deliveryLoading !== false
														? "none"
														: "block",
												textAlign: "center",
												marginTop: "-2em"
											}}
										>
											<p
												style={{
													color: "#929292",
													fontWeight: 700,
													display: "inline-block",
													cursor: "pointer"
												}}
												onClick={handleClickOpenHelper}
											>
												Why do I need to enter shipping details?
											</p>
											<Dialog
												open={openHelper}
												onClose={handleCloseHelper}
												fullScreen={props.isMobile ? true : false}
											>
												<div
													style={{
														padding: props.isMobile
															? "3em 2em"
															: "1em 2em",
														maxWidth: 500
													}}
												>
													<Fade>
														<p
															style={{
																margin: "0em",
																color: theme.palette.primary.main
															}}
														>
															<BackArrow
																onClick={handleCloseHelper}
																style={{
																	cursor: "pointer"
																}}
															/>
														</p>
														<p
															style={{
																fontWeight: 700,
																fontSize: props.isMobile
																	? "1.3em"
																	: "1.2em",
																marginTop: "0em"
															}}
														>
															WHY DO I NEED TO ENTER SHIPPING DETAILS?
														</p>
														<p>
															Entering your shipping details ensures
															that when your offer is accepted, there
															is nothing further for you to do.
														</p>
													</Fade>
												</div>
											</Dialog>
										</div>
									) : null}
								</TabPanel>
								<TabPanel value={values.tabType} index={2} dir={theme.direction}>
									<Paper
										elevation={0}
										className={classes.paper}
										style={{
											backgroundColor: theme.palette.secondary.main
										}}
									>
										<PaymentSelector
											popError={popError}
											alldata={values}
											errorMessage={errorMessage}
											parentCallbackCoupon={handleApplyCoupon}
											parentCallbackCard={cardAdded}
											parentCallbackChangeCard={cardChanged}
											anonymous={anonymous}
											handleRemoveCoupon={handleRemoveCoupon}
											mpOffer={values.mpOffer}
										/>
										<SubmitOffer
											alldata={values}
											submitOffer={
												values.data.payment === undefined ||
												anonymous ||
												values.cardChanged
													? addCardsubmitOffer
													: submitOffer
											}
											handleOfferErrorClose={handleOfferErrorClose}
											submitting={submitting}
											parentCallbackSendBack={handleSendBack}
											demo={demo}
											email={email}
											subscribed={handleSubscribe}
											mpOffer={values.mpOffer}
										/>
									</Paper>
								</TabPanel>
							</SwipeableViews>
						</div>
						<BottomNavigation
							alldata={values}
							parentCallbackNext={callbackNext}
							parentCallbackEmailEntered={callbackEmailEntered}
							parentCallbackTotal={cartATotal}
						/>
					</div>
				)
			);
		} else {
			return (
				<Submit
					font={props.font}
					offer={values.offer}
					storeName={values.storeName}
					cartTotal={values.cartTotal}
					shipping={values.selectedShipping}
					response={values.data.response}
					mpOffer={values.mpOffer}
				/>
			);
		}
	} else {
		return (
			<div>
				<Header
					username={``}
					preload={true}
					size={true}
					darkModeCallback={darkModeCallback}
				/>
				<div style={{ display: "inline-block" }}>
					<Lottie
						height={200}
						width={200}
						options={{
							loop: true,
							autoplay: true,
							animationData: loader.default,
							rendererSettings: {
								preserveAspectRatio: "xMidYMid slice"
							}
						}}
						style={{
							position: "absolute",
							top: "50%",
							transform: "translateY(-50%) translateX(-50%)",
							left: "50%"
						}}
					/>
				</div>
			</div>
		);
	}
}

const mapSizesToProps = ({ width, height }) => ({
	isMobile: width < 600,
	isSmallMobile: height < 580
});

export default withSizes(mapSizesToProps)(OfferPage);
