import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
	root: {
		margin: 50,
		flexGrow: 1,
		fontFamily: theme.palette.fontFamily
	},
	main: {
		color: theme.palette.primary.main,
		fontWeight: 900
	},
	secondary: {
		fontWeight: 900,
		color: theme.palette.secondary.dark
	}
});
class PageNotFound extends Component {
	render() {
		const { classes } = this.props;
		return (
			(window.location.hash = "404"),
			(
				<div className={classes.root}>
					<h1>
						<span className={classes.main}>404</span>
						<span className={classes.secondary}> Page Not Found!</span>
					</h1>
				</div>
			)
		);
	}
}

export default withStyles(styles)(PageNotFound);
