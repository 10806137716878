export const countries = {
    "countries": [{
        "code": "AU",
        "name": "Australia",
        "states": [{
            "code": "ACT",
            "name": "Australian Capital Territory"
        }, {
            "code": "NSW",
            "name": "New South Wales"
        }, {
            "code": "NT",
            "name": "Northern Territory"
        }, {
            "code": "QLD",
            "name": "Queensland"
        }, {
            "code": "SA",
            "name": "South Australia"
        }, {
            "code": "TAS",
            "name": "Tasmania"
        }, {
            "code": "VIC",
            "name": "Victoria"
        }, {
            "code": "WA",
            "name": "Western Australia"
        }]
    }, {
        "code": "US",
        "name": "United States",
        "states": [{
            "code": "AK",
            "name": "Alaska"
        }, {
            "code": "AL",
            "name": "Alabama"
        }, {
            "code": "AR",
            "name": "Arkansas"
        }, {
            "code": "AS",
            "name": "American Samoa"
        }, {
            "code": "AZ",
            "name": "Arizona"
        }, {
            "code": "CA",
            "name": "California"
        }, {
            "code": "CO",
            "name": "Colorado"
        }, {
            "code": "CT",
            "name": "Connecticut"
        }, {
            "code": "DC",
            "name": "District Of Columbia"
        }, {
            "code": "DE",
            "name": "Delaware"
        }, {
            "code": "FL",
            "name": "Florida"
        }, {
            "code": "FM",
            "name": "Federated State Of Micronesia"
        }, {
            "code": "GA",
            "name": "Georgia"
        }, {
            "code": "GU",
            "name": "Guam"
        }, {
            "code": "HI",
            "name": "Hawaii"
        }, {
            "code": "IA",
            "name": "Iowa"
        }, {
            "code": "ID",
            "name": "Idaho"
        }, {
            "code": "IL",
            "name": "Illinois"
        }, {
            "code": "IN",
            "name": "Indiana"
        }, {
            "code": "KS",
            "name": "Kansas"
        }, {
            "code": "KY",
            "name": "Kentucky"
        }, {
            "code": "LA",
            "name": "Louisiana"
        }, {
            "code": "MA",
            "name": "Massachusetts"
        }, {
            "code": "MD",
            "name": "Maryland"
        }, {
            "code": "ME",
            "name": "Maine"
        }, {
            "code": "MH",
            "name": "Marshall Islands"
        }, {
            "code": "MI",
            "name": "Michigan"
        }, {
            "code": "MN",
            "name": "Minnesota"
        }, {
            "code": "MO",
            "name": "Missouri"
        }, {
            "code": "MP",
            "name": "Northern Mariana Islands"
        }, {
            "code": "MS",
            "name": "Mississippi"
        }, {
            "code": "MT",
            "name": "Montana"
        }, {
            "code": "NC",
            "name": "North Carolina"
        }, {
            "code": "ND",
            "name": "North Dakota"
        }, {
            "code": "NE",
            "name": "Nebraska"
        }, {
            "code": "NH",
            "name": "New Hampshire"
        }, {
            "code": "NJ",
            "name": "New Jersey"
        }, {
            "code": "NM",
            "name": "New Mexico"
        }, {
            "code": "NV",
            "name": "Nevada"
        }, {
            "code": "NY",
            "name": "New York"
        }, {
            "code": "OH",
            "name": "Ohio"
        }, {
            "code": "OK",
            "name": "Oklahoma"
        }, {
            "code": "OR",
            "name": "Oregon"
        }, {
            "code": "PA",
            "name": "Pennsylvania"
        }, {
            "code": "PR",
            "name": "Puerto Rico"
        }, {
            "code": "PW",
            "name": "Palau"
        }, {
            "code": "RI",
            "name": "Rhode Island"
        }, {
            "code": "SC",
            "name": "South Carolina"
        }, {
            "code": "SD",
            "name": "South Dakota"
        }, {
            "code": "TN",
            "name": "Tennessee"
        }, {
            "code": "TX",
            "name": "Texas"
        }, {
            "code": "UT",
            "name": "Utah"
        }, {
            "code": "VA",
            "name": "Virginia"
        }, {
            "code": "VI",
            "name": "Virgin Islands"
        }, {
            "code": "VT",
            "name": "Vermont"
        }, {
            "code": "WA",
            "name": "Washington"
        }, {
            "code": "WI",
            "name": "Wisconsin"
        }, {
            "code": "WV",
            "name": "West Virginia"
        }, {
            "code": "WY",
            "name": "Wyoming"
        }]
    }, {
        "code": "CA",
        "name": "Canada",
        "states": [{
            "code": "AB",
            "name": "Alberta"
        }, {
            "code": "BC",
            "name": "British Columbia"
        }, {
            "code": "MB",
            "name": "Manitoba"
        }, {
            "code": "NB",
            "name": "New Brunswick"
        }, {
            "code": "NL",
            "name": "Newfoundland & Labrador"
        }, {
            "code": "NS",
            "name": "Nova Scotia"
        }, {
            "code": "NT",
            "name": "Northwest Territories"
        }, {
            "code": "NU",
            "name": "Nunavut"
        }, {
            "code": "ON",
            "name": "Ontario"
        }, {
            "code": "PE",
            "name": "Prince Edward Island"
        }, {
            "code": "QC",
            "name": "Quebec"
        }, {
            "code": "SK",
            "name": "Saskatchewan"
        }, {
            "code": "YT",
            "name": "Yukon Territory"
        }]
    }, {
        "code": "AF",
        "name": "Afghanistan",
        "states": [{
            "code": "01",
            "name": "Badakhshan"
        }, {
            "code": "02",
            "name": "Badghis"
        }, {
            "code": "03",
            "name": "Baghlan"
        }, {
            "code": "05",
            "name": "Bamian"
        }, {
            "code": "06",
            "name": "Farah"
        }, {
            "code": "07",
            "name": "Faryab"
        }, {
            "code": "08",
            "name": "Ghazni"
        }, {
            "code": "09",
            "name": "Ghowr"
        }, {
            "code": "10",
            "name": "Helmand"
        }, {
            "code": "11",
            "name": "Herat"
        }, {
            "code": "13",
            "name": "Kabol"
        }, {
            "code": "14",
            "name": "Kapisa"
        }, {
            "code": "15",
            "name": "Konar"
        }, {
            "code": "16",
            "name": "Laghman"
        }, {
            "code": "17",
            "name": "Lowgar"
        }, {
            "code": "18",
            "name": "Nangarhar"
        }, {
            "code": "19",
            "name": "Nimruz"
        }, {
            "code": "20",
            "name": "Oruzgan"
        }, {
            "code": "21",
            "name": "Paktia"
        }, {
            "code": "22",
            "name": "Parvan"
        }, {
            "code": "23",
            "name": "Kandahar"
        }, {
            "code": "24",
            "name": "Kondoz"
        }, {
            "code": "26",
            "name": "Takhar"
        }, {
            "code": "27",
            "name": "Vardak"
        }, {
            "code": "28",
            "name": "Zabol"
        }, {
            "code": "29",
            "name": "Paktika"
        }, {
            "code": "30",
            "name": "Balkh"
        }, {
            "code": "31",
            "name": "Jowzjan"
        }, {
            "code": "32",
            "name": "Samangan"
        }, {
            "code": "33",
            "name": "Sar-e Pol"
        }, {
            "code": "34",
            "name": "Konar"
        }, {
            "code": "35",
            "name": "Laghman"
        }, {
            "code": "36",
            "name": "Paktia"
        }, {
            "code": "37",
            "name": "Khowst"
        }, {
            "code": "38",
            "name": "Nurestan"
        }]
    }, {
        "code": "AX",
        "name": "Aland Islands"
    }, {
        "code": "AL",
        "name": "Albania",
        "states": [{
            "code": "40",
            "name": "Berat"
        }, {
            "code": "41",
            "name": "Diber"
        }, {
            "code": "42",
            "name": "Durres"
        }, {
            "code": "43",
            "name": "Elbasan"
        }, {
            "code": "44",
            "name": "Fier"
        }, {
            "code": "45",
            "name": "Gjirokaster"
        }, {
            "code": "46",
            "name": "Korce"
        }, {
            "code": "47",
            "name": "Kukes"
        }, {
            "code": "48",
            "name": "Lezhe"
        }, {
            "code": "49",
            "name": "Shkoder"
        }, {
            "code": "50",
            "name": "Tirane"
        }, {
            "code": "51",
            "name": "Vlore"
        }]
    }, {
        "code": "DZ",
        "name": "Algeria",
        "states": [{
            "code": "01",
            "name": "Alger"
        }, {
            "code": "03",
            "name": "Batna"
        }, {
            "code": "04",
            "name": "Constantine"
        }, {
            "code": "06",
            "name": "Medea"
        }, {
            "code": "07",
            "name": "Mostaganem"
        }, {
            "code": "09",
            "name": "Oran"
        }, {
            "code": "10",
            "name": "Saida"
        }, {
            "code": "12",
            "name": "Setif"
        }, {
            "code": "13",
            "name": "Tiaret"
        }, {
            "code": "14",
            "name": "Tizi Ouzou"
        }, {
            "code": "15",
            "name": "Tlemcen"
        }, {
            "code": "18",
            "name": "Bejaia"
        }, {
            "code": "19",
            "name": "Biskra"
        }, {
            "code": "20",
            "name": "Blida"
        }, {
            "code": "21",
            "name": "Bouira"
        }, {
            "code": "22",
            "name": "Djelfa"
        }, {
            "code": "23",
            "name": "Guelma"
        }, {
            "code": "24",
            "name": "Jijel"
        }, {
            "code": "25",
            "name": "Laghouat"
        }, {
            "code": "26",
            "name": "Mascara"
        }, {
            "code": "27",
            "name": "M'sila"
        }, {
            "code": "29",
            "name": "Oum el Bouaghi"
        }, {
            "code": "30",
            "name": "Sidi Bel Abbes"
        }, {
            "code": "31",
            "name": "Skikda"
        }, {
            "code": "33",
            "name": "Tebessa"
        }, {
            "code": "34",
            "name": "Adrar"
        }, {
            "code": "35",
            "name": "Ain Defla"
        }, {
            "code": "36",
            "name": "Ain Temouchent"
        }, {
            "code": "37",
            "name": "Annaba"
        }, {
            "code": "38",
            "name": "Bechar"
        }, {
            "code": "39",
            "name": "Bordj Bou Arreridj"
        }, {
            "code": "40",
            "name": "Boumerdes"
        }, {
            "code": "41",
            "name": "Chlef"
        }, {
            "code": "42",
            "name": "El Bayadh"
        }, {
            "code": "43",
            "name": "El Oued"
        }, {
            "code": "44",
            "name": "El Tarf"
        }, {
            "code": "45",
            "name": "Ghardaia"
        }, {
            "code": "46",
            "name": "Illizi"
        }, {
            "code": "47",
            "name": "Khenchela"
        }, {
            "code": "48",
            "name": "Mila"
        }, {
            "code": "49",
            "name": "Naama"
        }, {
            "code": "50",
            "name": "Ouargla"
        }, {
            "code": "51",
            "name": "Relizane"
        }, {
            "code": "52",
            "name": "Souk Ahras"
        }, {
            "code": "53",
            "name": "Tamanghasset"
        }, {
            "code": "54",
            "name": "Tindouf"
        }, {
            "code": "55",
            "name": "Tipaza"
        }, {
            "code": "56",
            "name": "Tissemsilt"
        }]
    }, {
        "code": "AS",
        "name": "American Samoa"
    }, {
        "code": "AD",
        "name": "Andorra",
        "states": [{
            "code": "02",
            "name": "Canillo"
        }, {
            "code": "03",
            "name": "Encamp"
        }, {
            "code": "04",
            "name": "La Massana"
        }, {
            "code": "05",
            "name": "Ordino"
        }, {
            "code": "06",
            "name": "Sant Julia de Loria"
        }, {
            "code": "07",
            "name": "Andorra la Vella"
        }, {
            "code": "08",
            "name": "Escaldes-Engordany"
        }]
    }, {
        "code": "AO",
        "name": "Angola",
        "states": [{
            "code": "01",
            "name": "Benguela"
        }, {
            "code": "02",
            "name": "Bie"
        }, {
            "code": "03",
            "name": "Cabinda"
        }, {
            "code": "04",
            "name": "Cuando Cubango"
        }, {
            "code": "05",
            "name": "Cuanza Norte"
        }, {
            "code": "06",
            "name": "Cuanza Sul"
        }, {
            "code": "07",
            "name": "Cunene"
        }, {
            "code": "08",
            "name": "Huambo"
        }, {
            "code": "09",
            "name": "Huila"
        }, {
            "code": "12",
            "name": "Malanje"
        }, {
            "code": "14",
            "name": "Moxico"
        }, {
            "code": "15",
            "name": "Uige"
        }, {
            "code": "16",
            "name": "Zaire"
        }, {
            "code": "17",
            "name": "Lunda Norte"
        }, {
            "code": "18",
            "name": "Lunda Sul"
        }, {
            "code": "19",
            "name": "Bengo"
        }, {
            "code": "20",
            "name": "Luanda"
        }]
    }, {
        "code": "AI",
        "name": "Anguilla"
    }, {
        "code": "AQ",
        "name": "Antarctica"
    }, {
        "code": "AG",
        "name": "Antigua & Barbuda",
        "states": [{
            "code": "01",
            "name": "Barbuda"
        }, {
            "code": "03",
            "name": "Saint George"
        }, {
            "code": "04",
            "name": "Saint John"
        }, {
            "code": "05",
            "name": "Saint Mary"
        }, {
            "code": "06",
            "name": "Saint Paul"
        }, {
            "code": "07",
            "name": "Saint Peter"
        }, {
            "code": "08",
            "name": "Saint Philip"
        }]
    }, {
        "code": "AR",
        "name": "Argentina",
        "states": [{
            "code": "01",
            "name": "Buenos Aires"
        }, {
            "code": "02",
            "name": "Catamarca"
        }, {
            "code": "03",
            "name": "Chaco"
        }, {
            "code": "04",
            "name": "Chubut"
        }, {
            "code": "05",
            "name": "Cordoba"
        }, {
            "code": "06",
            "name": "Corrientes"
        }, {
            "code": "07",
            "name": "Distrito Federal"
        }, {
            "code": "08",
            "name": "Entre Rios"
        }, {
            "code": "09",
            "name": "Formosa"
        }, {
            "code": "10",
            "name": "Jujuy"
        }, {
            "code": "11",
            "name": "La Pampa"
        }, {
            "code": "12",
            "name": "La Rioja"
        }, {
            "code": "13",
            "name": "Mendoza"
        }, {
            "code": "14",
            "name": "Misiones"
        }, {
            "code": "15",
            "name": "Neuquen"
        }, {
            "code": "16",
            "name": "Rio Negro"
        }, {
            "code": "17",
            "name": "Salta"
        }, {
            "code": "18",
            "name": "San Juan"
        }, {
            "code": "19",
            "name": "San Luis"
        }, {
            "code": "20",
            "name": "Santa Cruz"
        }, {
            "code": "21",
            "name": "Santa Fe"
        }, {
            "code": "22",
            "name": "Santiago del Estero"
        }, {
            "code": "23",
            "name": "Tierra del Fuego"
        }, {
            "code": "24",
            "name": "Tucuman"
        }]
    }, {
        "code": "AM",
        "name": "Armenia",
        "states": [{
            "code": "01",
            "name": "Aragatsotn"
        }, {
            "code": "02",
            "name": "Ararat"
        }, {
            "code": "03",
            "name": "Armavir"
        }, {
            "code": "04",
            "name": "Geghark'unik'"
        }, {
            "code": "05",
            "name": "Kotayk'"
        }, {
            "code": "06",
            "name": "Lorri"
        }, {
            "code": "07",
            "name": "Shirak"
        }, {
            "code": "08",
            "name": "Syunik'"
        }, {
            "code": "09",
            "name": "Tavush"
        }, {
            "code": "10",
            "name": "Vayots' Dzor"
        }, {
            "code": "11",
            "name": "Yerevan"
        }]
    }, {
        "code": "AW",
        "name": "Aruba"
    }, {
        "code": "AT",
        "name": "Austria",
        "states": [{
            "code": "01",
            "name": "Burgenland"
        }, {
            "code": "02",
            "name": "Karnten"
        }, {
            "code": "03",
            "name": "Niederosterreich"
        }, {
            "code": "04",
            "name": "Oberosterreich"
        }, {
            "code": "05",
            "name": "Salzburg"
        }, {
            "code": "06",
            "name": "Steiermark"
        }, {
            "code": "07",
            "name": "Tirol"
        }, {
            "code": "08",
            "name": "Vorarlberg"
        }, {
            "code": "09",
            "name": "Wien"
        }]
    }, {
        "code": "AZ",
        "name": "Azerbaijan",
        "states": [{
            "code": "01",
            "name": "Abseron"
        }, {
            "code": "02",
            "name": "Agcabadi"
        }, {
            "code": "03",
            "name": "Agdam"
        }, {
            "code": "04",
            "name": "Agdas"
        }, {
            "code": "05",
            "name": "Agstafa"
        }, {
            "code": "06",
            "name": "Agsu"
        }, {
            "code": "07",
            "name": "Ali Bayramli"
        }, {
            "code": "08",
            "name": "Astara"
        }, {
            "code": "09",
            "name": "Baki"
        }, {
            "code": "10",
            "name": "Balakan"
        }, {
            "code": "11",
            "name": "Barda"
        }, {
            "code": "12",
            "name": "Beylaqan"
        }, {
            "code": "13",
            "name": "Bilasuvar"
        }, {
            "code": "14",
            "name": "Cabrayil"
        }, {
            "code": "15",
            "name": "Calilabad"
        }, {
            "code": "16",
            "name": "Daskasan"
        }, {
            "code": "17",
            "name": "Davaci"
        }, {
            "code": "18",
            "name": "Fuzuli"
        }, {
            "code": "19",
            "name": "Gadabay"
        }, {
            "code": "20",
            "name": "Ganca"
        }, {
            "code": "21",
            "name": "Goranboy"
        }, {
            "code": "22",
            "name": "Goycay"
        }, {
            "code": "23",
            "name": "Haciqabul"
        }, {
            "code": "24",
            "name": "Imisli"
        }, {
            "code": "25",
            "name": "Ismayilli"
        }, {
            "code": "26",
            "name": "Kalbacar"
        }, {
            "code": "27",
            "name": "Kurdamir"
        }, {
            "code": "28",
            "name": "Lacin"
        }, {
            "code": "29",
            "name": "Lankaran"
        }, {
            "code": "30",
            "name": "Lankaran"
        }, {
            "code": "31",
            "name": "Lerik"
        }, {
            "code": "32",
            "name": "Masalli"
        }, {
            "code": "33",
            "name": "Mingacevir"
        }, {
            "code": "34",
            "name": "Naftalan"
        }, {
            "code": "35",
            "name": "Naxcivan"
        }, {
            "code": "36",
            "name": "Neftcala"
        }, {
            "code": "37",
            "name": "Oguz"
        }, {
            "code": "38",
            "name": "Qabala"
        }, {
            "code": "39",
            "name": "Qax"
        }, {
            "code": "40",
            "name": "Qazax"
        }, {
            "code": "41",
            "name": "Qobustan"
        }, {
            "code": "42",
            "name": "Quba"
        }, {
            "code": "43",
            "name": "Qubadli"
        }, {
            "code": "44",
            "name": "Qusar"
        }, {
            "code": "45",
            "name": "Saatli"
        }, {
            "code": "46",
            "name": "Sabirabad"
        }, {
            "code": "47",
            "name": "Saki"
        }, {
            "code": "48",
            "name": "Saki"
        }, {
            "code": "49",
            "name": "Salyan"
        }, {
            "code": "50",
            "name": "Samaxi"
        }, {
            "code": "51",
            "name": "Samkir"
        }, {
            "code": "52",
            "name": "Samux"
        }, {
            "code": "53",
            "name": "Siyazan"
        }, {
            "code": "54",
            "name": "Sumqayit"
        }, {
            "code": "55",
            "name": "Susa"
        }, {
            "code": "56",
            "name": "Susa"
        }, {
            "code": "57",
            "name": "Tartar"
        }, {
            "code": "58",
            "name": "Tovuz"
        }, {
            "code": "59",
            "name": "Ucar"
        }, {
            "code": "60",
            "name": "Xacmaz"
        }, {
            "code": "61",
            "name": "Xankandi"
        }, {
            "code": "62",
            "name": "Xanlar"
        }, {
            "code": "63",
            "name": "Xizi"
        }, {
            "code": "64",
            "name": "Xocali"
        }, {
            "code": "65",
            "name": "Xocavand"
        }, {
            "code": "66",
            "name": "Yardimli"
        }, {
            "code": "67",
            "name": "Yevlax"
        }, {
            "code": "68",
            "name": "Yevlax"
        }, {
            "code": "69",
            "name": "Zangilan"
        }, {
            "code": "70",
            "name": "Zaqatala"
        }, {
            "code": "71",
            "name": "Zardab"
        }]
    }, {
        "code": "BS",
        "name": "Bahamas",
        "states": [{
            "code": "05",
            "name": "Bimini"
        }, {
            "code": "06",
            "name": "Cat Island"
        }, {
            "code": "10",
            "name": "Exuma"
        }, {
            "code": "13",
            "name": "Inagua"
        }, {
            "code": "15",
            "name": "Long Island"
        }, {
            "code": "16",
            "name": "Mayaguana"
        }, {
            "code": "18",
            "name": "Ragged Island"
        }, {
            "code": "22",
            "name": "Harbour Island"
        }, {
            "code": "23",
            "name": "New Providence"
        }, {
            "code": "24",
            "name": "Acklins and Crooked Islands"
        }, {
            "code": "25",
            "name": "Freeport"
        }, {
            "code": "26",
            "name": "Fresh Creek"
        }, {
            "code": "27",
            "name": "Governor's Harbour"
        }, {
            "code": "28",
            "name": "Green Turtle Cay"
        }, {
            "code": "29",
            "name": "High Rock"
        }, {
            "code": "30",
            "name": "Kemps Bay"
        }, {
            "code": "31",
            "name": "Marsh Harbour"
        }, {
            "code": "32",
            "name": "Nichollstown and Berry Islands"
        }, {
            "code": "33",
            "name": "Rock Sound"
        }, {
            "code": "34",
            "name": "Sandy Point"
        }, {
            "code": "35",
            "name": "San Salvador and Rum Cay"
        }]
    }, {
        "code": "BH",
        "name": "Bahrain",
        "states": [{
            "code": "01",
            "name": "Al Hadd"
        }, {
            "code": "02",
            "name": "Al Manamah"
        }, {
            "code": "03",
            "name": "Al Muharraq"
        }, {
            "code": "05",
            "name": "Jidd Hafs"
        }, {
            "code": "06",
            "name": "Sitrah"
        }, {
            "code": "08",
            "name": "Al Mintaqah al Gharbiyah"
        }, {
            "code": "09",
            "name": "Mintaqat Juzur Hawar"
        }, {
            "code": "10",
            "name": "Al Mintaqah ash Shamaliyah"
        }, {
            "code": "11",
            "name": "Al Mintaqah al Wusta"
        }, {
            "code": "12",
            "name": "Madinat"
        }, {
            "code": "13",
            "name": "Ar Rifa"
        }, {
            "code": "14",
            "name": "Madinat Hamad"
        }]
    }, {
        "code": "BD",
        "name": "Bangladesh",
        "states": [{
            "code": "01",
            "name": "Barisal"
        }, {
            "code": "04",
            "name": "Bandarban"
        }, {
            "code": "05",
            "name": "Comilla"
        }, {
            "code": "12",
            "name": "Mymensingh"
        }, {
            "code": "13",
            "name": "Noakhali"
        }, {
            "code": "15",
            "name": "Patuakhali"
        }, {
            "code": "22",
            "name": "Bagerhat"
        }, {
            "code": "23",
            "name": "Bhola"
        }, {
            "code": "24",
            "name": "Bogra"
        }, {
            "code": "25",
            "name": "Barguna"
        }, {
            "code": "26",
            "name": "Brahmanbaria"
        }, {
            "code": "27",
            "name": "Chandpur"
        }, {
            "code": "28",
            "name": "Chapai Nawabganj"
        }, {
            "code": "29",
            "name": "Chattagram"
        }, {
            "code": "30",
            "name": "Chuadanga"
        }, {
            "code": "31",
            "name": "Cox's Bazar"
        }, {
            "code": "32",
            "name": "Dhaka"
        }, {
            "code": "33",
            "name": "Dinajpur"
        }, {
            "code": "34",
            "name": "Faridpur"
        }, {
            "code": "35",
            "name": "Feni"
        }, {
            "code": "36",
            "name": "Gaibandha"
        }, {
            "code": "37",
            "name": "Gazipur"
        }, {
            "code": "38",
            "name": "Gopalganj"
        }, {
            "code": "39",
            "name": "Habiganj"
        }, {
            "code": "40",
            "name": "Jaipurhat"
        }, {
            "code": "41",
            "name": "Jamalpur"
        }, {
            "code": "42",
            "name": "Jessore"
        }, {
            "code": "43",
            "name": "Jhalakati"
        }, {
            "code": "44",
            "name": "Jhenaidah"
        }, {
            "code": "45",
            "name": "Khagrachari"
        }, {
            "code": "46",
            "name": "Khulna"
        }, {
            "code": "47",
            "name": "Kishorganj"
        }, {
            "code": "48",
            "name": "Kurigram"
        }, {
            "code": "49",
            "name": "Kushtia"
        }, {
            "code": "50",
            "name": "Laksmipur"
        }, {
            "code": "51",
            "name": "Lalmonirhat"
        }, {
            "code": "52",
            "name": "Madaripur"
        }, {
            "code": "53",
            "name": "Magura"
        }, {
            "code": "54",
            "name": "Manikganj"
        }, {
            "code": "55",
            "name": "Meherpur"
        }, {
            "code": "56",
            "name": "Moulavibazar"
        }, {
            "code": "57",
            "name": "Munshiganj"
        }, {
            "code": "58",
            "name": "Naogaon"
        }, {
            "code": "59",
            "name": "Narail"
        }, {
            "code": "60",
            "name": "Narayanganj"
        }, {
            "code": "61",
            "name": "Narsingdi"
        }, {
            "code": "62",
            "name": "Nator"
        }, {
            "code": "63",
            "name": "Netrakona"
        }, {
            "code": "64",
            "name": "Nilphamari"
        }, {
            "code": "65",
            "name": "Pabna"
        }, {
            "code": "66",
            "name": "Panchagar"
        }, {
            "code": "67",
            "name": "Parbattya Chattagram"
        }, {
            "code": "68",
            "name": "Pirojpur"
        }, {
            "code": "69",
            "name": "Rajbari"
        }, {
            "code": "70",
            "name": "Rajshahi"
        }, {
            "code": "71",
            "name": "Rangpur"
        }, {
            "code": "72",
            "name": "Satkhira"
        }, {
            "code": "73",
            "name": "Shariyatpur"
        }, {
            "code": "74",
            "name": "Sherpur"
        }, {
            "code": "75",
            "name": "Sirajganj"
        }, {
            "code": "76",
            "name": "Sunamganj"
        }, {
            "code": "77",
            "name": "Sylhet"
        }, {
            "code": "78",
            "name": "Tangail"
        }, {
            "code": "79",
            "name": "Thakurgaon"
        }]
    }, {
        "code": "BB",
        "name": "Barbados",
        "states": [{
            "code": "01",
            "name": "Christ Church"
        }, {
            "code": "02",
            "name": "Saint Andrew"
        }, {
            "code": "03",
            "name": "Saint George"
        }, {
            "code": "04",
            "name": "Saint James"
        }, {
            "code": "05",
            "name": "Saint John"
        }, {
            "code": "06",
            "name": "Saint Joseph"
        }, {
            "code": "07",
            "name": "Saint Lucy"
        }, {
            "code": "08",
            "name": "Saint Michael"
        }, {
            "code": "09",
            "name": "Saint Peter"
        }, {
            "code": "10",
            "name": "Saint Philip"
        }, {
            "code": "11",
            "name": "Saint Thomas"
        }]
    }, {
        "code": "BY",
        "name": "Belarus",
        "states": [{
            "code": "01",
            "name": "Brestskaya Voblasts'"
        }, {
            "code": "02",
            "name": "Homyel'skaya Voblasts'"
        }, {
            "code": "03",
            "name": "Hrodzyenskaya Voblasts'"
        }, {
            "code": "04",
            "name": "Minsk"
        }, {
            "code": "05",
            "name": "Minskaya Voblasts'"
        }, {
            "code": "06",
            "name": "Mahilyowskaya Voblasts'"
        }, {
            "code": "07",
            "name": "Vitsyebskaya Voblasts'"
        }]
    }, {
        "code": "BE",
        "name": "Belgium",
        "states": [{
            "code": "01",
            "name": "Antwerpen"
        }, {
            "code": "02",
            "name": "Brabant"
        }, {
            "code": "03",
            "name": "Hainaut"
        }, {
            "code": "04",
            "name": "Liege"
        }, {
            "code": "05",
            "name": "Limburg"
        }, {
            "code": "06",
            "name": "Luxembourg"
        }, {
            "code": "07",
            "name": "Namur"
        }, {
            "code": "08",
            "name": "Oost-Vlaanderen"
        }, {
            "code": "09",
            "name": "West-Vlaanderen"
        }, {
            "code": "10",
            "name": "Brabant Wallon"
        }, {
            "code": "11",
            "name": "Brussels Hoofdstedelijk Gewest"
        }, {
            "code": "12",
            "name": "Vlaams-Brabant"
        }]
    }, {
        "code": "BZ",
        "name": "Belize",
        "states": [{
            "code": "01",
            "name": "Belize"
        }, {
            "code": "02",
            "name": "Cayo"
        }, {
            "code": "03",
            "name": "Corozal"
        }, {
            "code": "04",
            "name": "Orange Walk"
        }, {
            "code": "05",
            "name": "Stann Creek"
        }, {
            "code": "06",
            "name": "Toledo"
        }]
    }, {
        "code": "BJ",
        "name": "Benin",
        "states": [{
            "code": "01",
            "name": "Atakora"
        }, {
            "code": "02",
            "name": "Atlantique"
        }, {
            "code": "03",
            "name": "Borgou"
        }, {
            "code": "04",
            "name": "Mono"
        }, {
            "code": "05",
            "name": "Oueme"
        }, {
            "code": "06",
            "name": "Zou"
        }]
    }, {
        "code": "BM",
        "name": "Bermuda",
        "states": [{
            "code": "01",
            "name": "Devonshire"
        }, {
            "code": "02",
            "name": "Hamilton"
        }, {
            "code": "03",
            "name": "Hamilton"
        }, {
            "code": "04",
            "name": "Paget"
        }, {
            "code": "05",
            "name": "Pembroke"
        }, {
            "code": "06",
            "name": "Saint George"
        }, {
            "code": "07",
            "name": "Saint George's"
        }, {
            "code": "08",
            "name": "Sandys"
        }, {
            "code": "09",
            "name": "Smiths"
        }, {
            "code": "10",
            "name": "Southampton"
        }, {
            "code": "11",
            "name": "Warwick"
        }]
    }, {
        "code": "BT",
        "name": "Bhutan",
        "states": [{
            "code": "05",
            "name": "Bumthang"
        }, {
            "code": "06",
            "name": "Chhukha"
        }, {
            "code": "07",
            "name": "Chirang"
        }, {
            "code": "08",
            "name": "Daga"
        }, {
            "code": "09",
            "name": "Geylegphug"
        }, {
            "code": "10",
            "name": "Ha"
        }, {
            "code": "11",
            "name": "Lhuntshi"
        }, {
            "code": "12",
            "name": "Mongar"
        }, {
            "code": "13",
            "name": "Paro"
        }, {
            "code": "14",
            "name": "Pemagatsel"
        }, {
            "code": "15",
            "name": "Punakha"
        }, {
            "code": "16",
            "name": "Samchi"
        }, {
            "code": "17",
            "name": "Samdrup"
        }, {
            "code": "18",
            "name": "Shemgang"
        }, {
            "code": "19",
            "name": "Tashigang"
        }, {
            "code": "20",
            "name": "Thimphu"
        }, {
            "code": "21",
            "name": "Tongsa"
        }, {
            "code": "22",
            "name": "Wangdi Phodrang"
        }]
    }, {
        "code": "BO",
        "name": "Bolivia",
        "states": [{
            "code": "01",
            "name": "Chuquisaca"
        }, {
            "code": "02",
            "name": "Cochabamba"
        }, {
            "code": "03",
            "name": "El Beni"
        }, {
            "code": "04",
            "name": "La Paz"
        }, {
            "code": "05",
            "name": "Oruro"
        }, {
            "code": "06",
            "name": "Pando"
        }, {
            "code": "07",
            "name": "Potosi"
        }, {
            "code": "08",
            "name": "Santa Cruz"
        }, {
            "code": "09",
            "name": "Tarija"
        }]
    }, {
        "code": "BQ",
        "name": "Bonaire, Sint Eustatius and Saba"
    }, {
        "code": "BA",
        "name": "Bosnia and Herzegovina",
        "states": [{
            "code": "01",
            "name": "Federation of Bosnia and Herzego"
        }, {
            "code": "02",
            "name": "Republika Srpska"
        }]
    }, {
        "code": "BW",
        "name": "Botswana",
        "states": [{
            "code": "01",
            "name": "Central"
        }, {
            "code": "02",
            "name": "Chobe"
        }, {
            "code": "03",
            "name": "Ghanzi"
        }, {
            "code": "04",
            "name": "Kgalagadi"
        }, {
            "code": "05",
            "name": "Kgatleng"
        }, {
            "code": "06",
            "name": "Kweneng"
        }, {
            "code": "07",
            "name": "Ngamiland"
        }, {
            "code": "08",
            "name": "North-East"
        }, {
            "code": "09",
            "name": "South-East"
        }, {
            "code": "10",
            "name": "Southern"
        }]
    }, {
        "code": "BV",
        "name": "Bouvet Island"
    }, {
        "code": "BR",
        "name": "Brazil",
        "states": [{
            "code": "01",
            "name": "Acre"
        }, {
            "code": "02",
            "name": "Alagoas"
        }, {
            "code": "03",
            "name": "Amapa"
        }, {
            "code": "04",
            "name": "Amazonas"
        }, {
            "code": "05",
            "name": "Bahia"
        }, {
            "code": "06",
            "name": "Ceara"
        }, {
            "code": "07",
            "name": "Distrito Federal"
        }, {
            "code": "08",
            "name": "Espirito Santo"
        }, {
            "code": "11",
            "name": "Mato Grosso do Sul"
        }, {
            "code": "13",
            "name": "Maranhao"
        }, {
            "code": "14",
            "name": "Mato Grosso"
        }, {
            "code": "15",
            "name": "Minas Gerais"
        }, {
            "code": "16",
            "name": "Para"
        }, {
            "code": "17",
            "name": "Paraiba"
        }, {
            "code": "18",
            "name": "Parana"
        }, {
            "code": "20",
            "name": "Piaui"
        }, {
            "code": "21",
            "name": "Rio de Janeiro"
        }, {
            "code": "22",
            "name": "Rio Grande do Norte"
        }, {
            "code": "23",
            "name": "Rio Grande do Sul"
        }, {
            "code": "24",
            "name": "Rondonia"
        }, {
            "code": "25",
            "name": "Roraima"
        }, {
            "code": "26",
            "name": "Santa Catarina"
        }, {
            "code": "27",
            "name": "Sao Paulo"
        }, {
            "code": "28",
            "name": "Sergipe"
        }, {
            "code": "29",
            "name": "Goias"
        }, {
            "code": "30",
            "name": "Pernambuco"
        }, {
            "code": "31",
            "name": "Tocantins"
        }]
    }, {
        "code": "IO",
        "name": "British Indian Ocean Territory"
    }, {
        "code": "VG",
        "name": "British Virgin Islands"
    }, {
        "code": "BN",
        "name": "Brunei Darussalam",
        "states": [{
            "code": "07",
            "name": "Alibori"
        }, {
            "code": "08",
            "name": "Belait"
        }, {
            "code": "09",
            "name": "Brunei and Muara"
        }, {
            "code": "10",
            "name": "Temburong"
        }, {
            "code": "11",
            "name": "Collines"
        }, {
            "code": "12",
            "name": "Kouffo"
        }, {
            "code": "13",
            "name": "Donga"
        }, {
            "code": "14",
            "name": "Littoral"
        }, {
            "code": "15",
            "name": "Tutong"
        }, {
            "code": "16",
            "name": "Oueme"
        }, {
            "code": "17",
            "name": "Plateau"
        }, {
            "code": "18",
            "name": "Zou"
        }]
    }, {
        "code": "BG",
        "name": "Bulgaria",
        "states": [{
            "code": "33",
            "name": "Mikhaylovgrad"
        }, {
            "code": "38",
            "name": "Blagoevgrad"
        }, {
            "code": "39",
            "name": "Burgas"
        }, {
            "code": "40",
            "name": "Dobrich"
        }, {
            "code": "41",
            "name": "Gabrovo"
        }, {
            "code": "42",
            "name": "Grad Sofiya"
        }, {
            "code": "43",
            "name": "Khaskovo"
        }, {
            "code": "44",
            "name": "Kurdzhali"
        }, {
            "code": "45",
            "name": "Kyustendil"
        }, {
            "code": "46",
            "name": "Lovech"
        }, {
            "code": "47",
            "name": "Montana"
        }, {
            "code": "48",
            "name": "Pazardzhik"
        }, {
            "code": "49",
            "name": "Pernik"
        }, {
            "code": "50",
            "name": "Pleven"
        }, {
            "code": "51",
            "name": "Plovdiv"
        }, {
            "code": "52",
            "name": "Razgrad"
        }, {
            "code": "53",
            "name": "Ruse"
        }, {
            "code": "54",
            "name": "Shumen"
        }, {
            "code": "55",
            "name": "Silistra"
        }, {
            "code": "56",
            "name": "Sliven"
        }, {
            "code": "57",
            "name": "Smolyan"
        }, {
            "code": "58",
            "name": "Sofiya"
        }, {
            "code": "59",
            "name": "Stara Zagora"
        }, {
            "code": "60",
            "name": "Turgovishte"
        }, {
            "code": "61",
            "name": "Varna"
        }, {
            "code": "62",
            "name": "Veliko Turnovo"
        }, {
            "code": "63",
            "name": "Vidin"
        }, {
            "code": "64",
            "name": "Vratsa"
        }, {
            "code": "65",
            "name": "Yambol"
        }]
    }, {
        "code": "BF",
        "name": "Burkina Faso",
        "states": [{
            "code": "15",
            "name": "Bam"
        }, {
            "code": "19",
            "name": "Boulkiemde"
        }, {
            "code": "20",
            "name": "Ganzourgou"
        }, {
            "code": "21",
            "name": "Gnagna"
        }, {
            "code": "28",
            "name": "Kouritenga"
        }, {
            "code": "33",
            "name": "Oudalan"
        }, {
            "code": "34",
            "name": "Passore"
        }, {
            "code": "36",
            "name": "Sanguie"
        }, {
            "code": "40",
            "name": "Soum"
        }, {
            "code": "42",
            "name": "Tapoa"
        }, {
            "code": "44",
            "name": "Zoundweogo"
        }, {
            "code": "45",
            "name": "Bale"
        }, {
            "code": "46",
            "name": "Banwa"
        }, {
            "code": "47",
            "name": "Bazega"
        }, {
            "code": "48",
            "name": "Bougouriba"
        }, {
            "code": "49",
            "name": "Boulgou"
        }, {
            "code": "50",
            "name": "Gourma"
        }, {
            "code": "51",
            "name": "Houet"
        }, {
            "code": "52",
            "name": "Ioba"
        }, {
            "code": "53",
            "name": "Kadiogo"
        }, {
            "code": "54",
            "name": "Kenedougou"
        }, {
            "code": "55",
            "name": "Komoe"
        }, {
            "code": "56",
            "name": "Komondjari"
        }, {
            "code": "57",
            "name": "Kompienga"
        }, {
            "code": "58",
            "name": "Kossi"
        }, {
            "code": "59",
            "name": "Koulpelogo"
        }, {
            "code": "60",
            "name": "Kourweogo"
        }, {
            "code": "61",
            "name": "Leraba"
        }, {
            "code": "62",
            "name": "Loroum"
        }, {
            "code": "63",
            "name": "Mouhoun"
        }, {
            "code": "64",
            "name": "Namentenga"
        }, {
            "code": "65",
            "name": "Naouri"
        }, {
            "code": "66",
            "name": "Nayala"
        }, {
            "code": "67",
            "name": "Noumbiel"
        }, {
            "code": "68",
            "name": "Oubritenga"
        }, {
            "code": "69",
            "name": "Poni"
        }, {
            "code": "70",
            "name": "Sanmatenga"
        }, {
            "code": "71",
            "name": "Seno"
        }, {
            "code": "72",
            "name": "Sissili"
        }, {
            "code": "73",
            "name": "Sourou"
        }, {
            "code": "74",
            "name": "Tuy"
        }, {
            "code": "75",
            "name": "Yagha"
        }, {
            "code": "76",
            "name": "Yatenga"
        }, {
            "code": "77",
            "name": "Ziro"
        }, {
            "code": "78",
            "name": "Zondoma"
        }]
    }, {
        "code": "BI",
        "name": "Burundi",
        "states": [{
            "code": "02",
            "name": "Bujumbura"
        }, {
            "code": "09",
            "name": "Bubanza"
        }, {
            "code": "10",
            "name": "Bururi"
        }, {
            "code": "11",
            "name": "Cankuzo"
        }, {
            "code": "12",
            "name": "Cibitoke"
        }, {
            "code": "13",
            "name": "Gitega"
        }, {
            "code": "14",
            "name": "Karuzi"
        }, {
            "code": "15",
            "name": "Kayanza"
        }, {
            "code": "16",
            "name": "Kirundo"
        }, {
            "code": "17",
            "name": "Makamba"
        }, {
            "code": "18",
            "name": "Muyinga"
        }, {
            "code": "19",
            "name": "Ngozi"
        }, {
            "code": "20",
            "name": "Rutana"
        }, {
            "code": "21",
            "name": "Ruyigi"
        }, {
            "code": "22",
            "name": "Muramvya"
        }, {
            "code": "23",
            "name": "Mwaro"
        }]
    }, {
        "code": "KH",
        "name": "Cambodia",
        "states": [{
            "code": "02",
            "name": "Kampong Cham"
        }, {
            "code": "03",
            "name": "Kampong Chhnang"
        }, {
            "code": "04",
            "name": "Kampong Spoe"
        }, {
            "code": "05",
            "name": "Kampong Thum"
        }, {
            "code": "06",
            "name": "Kampot"
        }, {
            "code": "07",
            "name": "Kandal"
        }, {
            "code": "08",
            "name": "Kaoh Kong"
        }, {
            "code": "09",
            "name": "Kracheh"
        }, {
            "code": "10",
            "name": "Mondol Kiri"
        }, {
            "code": "11",
            "name": "Phnum Penh"
        }, {
            "code": "12",
            "name": "Pouthisat"
        }, {
            "code": "13",
            "name": "Preah Vihear"
        }, {
            "code": "14",
            "name": "Prey Veng"
        }, {
            "code": "15",
            "name": "Rotanokiri"
        }, {
            "code": "16",
            "name": "Siemreab-Otdar Meanchey"
        }, {
            "code": "17",
            "name": "Stoeng Treng"
        }, {
            "code": "18",
            "name": "Svay Rieng"
        }, {
            "code": "19",
            "name": "Takev"
        }, {
            "code": "29",
            "name": "Batdambang"
        }, {
            "code": "30",
            "name": "Pailin"
        }]
    }, {
        "code": "CM",
        "name": "Cameroon",
        "states": [{
            "code": "04",
            "name": "Est"
        }, {
            "code": "05",
            "name": "Littoral"
        }, {
            "code": "07",
            "name": "Nord-Ouest"
        }, {
            "code": "08",
            "name": "Ouest"
        }, {
            "code": "09",
            "name": "Sud-Ouest"
        }, {
            "code": "10",
            "name": "Adamaoua"
        }, {
            "code": "11",
            "name": "Centre"
        }, {
            "code": "12",
            "name": "Extreme-Nord"
        }, {
            "code": "13",
            "name": "Nord"
        }, {
            "code": "14",
            "name": "Sud"
        }]
    }, {
        "code": "CV",
        "name": "Cape Verde",
        "states": [{
            "code": "01",
            "name": "Boa Vista"
        }, {
            "code": "02",
            "name": "Brava"
        }, {
            "code": "04",
            "name": "Maio"
        }, {
            "code": "05",
            "name": "Paul"
        }, {
            "code": "07",
            "name": "Ribeira Grande"
        }, {
            "code": "08",
            "name": "Sal"
        }, {
            "code": "10",
            "name": "Sao Nicolau"
        }, {
            "code": "11",
            "name": "Sao Vicente"
        }, {
            "code": "13",
            "name": "Mosteiros"
        }, {
            "code": "14",
            "name": "Praia"
        }, {
            "code": "15",
            "name": "Santa Catarina"
        }, {
            "code": "16",
            "name": "Santa Cruz"
        }, {
            "code": "17",
            "name": "Sao Domingos"
        }, {
            "code": "18",
            "name": "Sao Filipe"
        }, {
            "code": "19",
            "name": "Sao Miguel"
        }, {
            "code": "20",
            "name": "Tarrafal"
        }]
    }, {
        "code": "KY",
        "name": "Cayman Islands",
        "states": [{
            "code": "01",
            "name": "Creek"
        }, {
            "code": "02",
            "name": "Eastern"
        }, {
            "code": "03",
            "name": "Midland"
        }, {
            "code": "04",
            "name": "South Town"
        }, {
            "code": "05",
            "name": "Spot Bay"
        }, {
            "code": "06",
            "name": "Stake Bay"
        }, {
            "code": "07",
            "name": "West End"
        }, {
            "code": "08",
            "name": "Western"
        }]
    }, {
        "code": "CF",
        "name": "Central African Republic",
        "states": [{
            "code": "01",
            "name": "Bamingui-Bangoran"
        }, {
            "code": "02",
            "name": "Basse-Kotto"
        }, {
            "code": "03",
            "name": "Haute-Kotto"
        }, {
            "code": "04",
            "name": "Mambere-Kadei"
        }, {
            "code": "05",
            "name": "Haut-Mbomou"
        }, {
            "code": "06",
            "name": "Kemo"
        }, {
            "code": "07",
            "name": "Lobaye"
        }, {
            "code": "08",
            "name": "Mbomou"
        }, {
            "code": "09",
            "name": "Nana-Mambere"
        }, {
            "code": "11",
            "name": "Ouaka"
        }, {
            "code": "12",
            "name": "Ouham"
        }, {
            "code": "13",
            "name": "Ouham-Pende"
        }, {
            "code": "14",
            "name": "Vakaga"
        }, {
            "code": "15",
            "name": "Nana-Grebizi"
        }, {
            "code": "16",
            "name": "Sangha-Mbaere"
        }, {
            "code": "17",
            "name": "Ombella-Mpoko"
        }, {
            "code": "18",
            "name": "Bangui"
        }]
    }, {
        "code": "TD",
        "name": "Chad",
        "states": [{
            "code": "01",
            "name": "Batha"
        }, {
            "code": "02",
            "name": "Biltine"
        }, {
            "code": "03",
            "name": "Borkou-Ennedi-Tibesti"
        }, {
            "code": "04",
            "name": "Chari-Baguirmi"
        }, {
            "code": "05",
            "name": "Guera"
        }, {
            "code": "06",
            "name": "Kanem"
        }, {
            "code": "07",
            "name": "Lac"
        }, {
            "code": "08",
            "name": "Logone Occidental"
        }, {
            "code": "09",
            "name": "Logone Oriental"
        }, {
            "code": "10",
            "name": "Mayo-Kebbi"
        }, {
            "code": "11",
            "name": "Moyen-Chari"
        }, {
            "code": "12",
            "name": "Ouaddai"
        }, {
            "code": "13",
            "name": "Salamat"
        }, {
            "code": "14",
            "name": "Tandjile"
        }]
    }, {
        "code": "CL",
        "name": "Chile",
        "states": [{
            "code": "01",
            "name": "Valparaiso"
        }, {
            "code": "02",
            "name": "Aisen del General Carlos Ibanez "
        }, {
            "code": "03",
            "name": "Antofagasta"
        }, {
            "code": "04",
            "name": "Araucania"
        }, {
            "code": "05",
            "name": "Atacama"
        }, {
            "code": "06",
            "name": "Bio-Bio"
        }, {
            "code": "07",
            "name": "Coquimbo"
        }, {
            "code": "08",
            "name": "Libertador General Bernardo O'H"
        }, {
            "code": "09",
            "name": "Los Lagos"
        }, {
            "code": "10",
            "name": "Magallanes y de la Antartica Chi"
        }, {
            "code": "11",
            "name": "Maule"
        }, {
            "code": "12",
            "name": "Region Metropolitana"
        }, {
            "code": "13",
            "name": "Tarapaca"
        }]
    }, {
        "code": "CN",
        "name": "China",
        "states": [{
            "code": "01",
            "name": "Anhui"
        }, {
            "code": "02",
            "name": "Zhejiang"
        }, {
            "code": "03",
            "name": "Jiangxi"
        }, {
            "code": "04",
            "name": "Jiangsu"
        }, {
            "code": "05",
            "name": "Jilin"
        }, {
            "code": "06",
            "name": "Qinghai"
        }, {
            "code": "07",
            "name": "Fujian"
        }, {
            "code": "08",
            "name": "Heilongjiang"
        }, {
            "code": "09",
            "name": "Henan"
        }, {
            "code": "10",
            "name": "Hebei"
        }, {
            "code": "11",
            "name": "Hunan"
        }, {
            "code": "12",
            "name": "Hubei"
        }, {
            "code": "13",
            "name": "Xinjiang"
        }, {
            "code": "14",
            "name": "Xizang"
        }, {
            "code": "15",
            "name": "Gansu"
        }, {
            "code": "16",
            "name": "Guangxi"
        }, {
            "code": "18",
            "name": "Guizhou"
        }, {
            "code": "19",
            "name": "Liaoning"
        }, {
            "code": "20",
            "name": "Nei Mongol"
        }, {
            "code": "21",
            "name": "Ningxia"
        }, {
            "code": "22",
            "name": "Beijing"
        }, {
            "code": "23",
            "name": "Shanghai"
        }, {
            "code": "24",
            "name": "Shanxi"
        }, {
            "code": "25",
            "name": "Shandong"
        }, {
            "code": "26",
            "name": "Shaanxi"
        }, {
            "code": "28",
            "name": "Tianjin"
        }, {
            "code": "29",
            "name": "Yunnan"
        }, {
            "code": "30",
            "name": "Guangdong"
        }, {
            "code": "31",
            "name": "Hainan"
        }, {
            "code": "32",
            "name": "Sichuan"
        }, {
            "code": "33",
            "name": "Chongqing"
        }]
    }, {
        "code": "CX",
        "name": "Christmas Island"
    }, {
        "code": "CC",
        "name": "Cocos (Keeling) Islands"
    }, {
        "code": "CO",
        "name": "Colombia",
        "states": [{
            "code": "01",
            "name": "Amazonas"
        }, {
            "code": "02",
            "name": "Antioquia"
        }, {
            "code": "03",
            "name": "Arauca"
        }, {
            "code": "04",
            "name": "Atlantico"
        }, {
            "code": "08",
            "name": "Caqueta"
        }, {
            "code": "09",
            "name": "Cauca"
        }, {
            "code": "10",
            "name": "Cesar"
        }, {
            "code": "11",
            "name": "Choco"
        }, {
            "code": "12",
            "name": "Cordoba"
        }, {
            "code": "14",
            "name": "Guaviare"
        }, {
            "code": "15",
            "name": "Guainia"
        }, {
            "code": "16",
            "name": "Huila"
        }, {
            "code": "17",
            "name": "La Guajira"
        }, {
            "code": "19",
            "name": "Meta"
        }, {
            "code": "20",
            "name": "Narino"
        }, {
            "code": "21",
            "name": "Norte de Santander"
        }, {
            "code": "22",
            "name": "Putumayo"
        }, {
            "code": "23",
            "name": "Quindio"
        }, {
            "code": "24",
            "name": "Risaralda"
        }, {
            "code": "25",
            "name": "San Andres y Providencia"
        }, {
            "code": "26",
            "name": "Santander"
        }, {
            "code": "27",
            "name": "Sucre"
        }, {
            "code": "28",
            "name": "Tolima"
        }, {
            "code": "29",
            "name": "Valle del Cauca"
        }, {
            "code": "30",
            "name": "Vaupes"
        }, {
            "code": "31",
            "name": "Vichada"
        }, {
            "code": "32",
            "name": "Casanare"
        }, {
            "code": "33",
            "name": "Cundinamarca"
        }, {
            "code": "34",
            "name": "Distrito Especial"
        }, {
            "code": "35",
            "name": "Bolivar"
        }, {
            "code": "36",
            "name": "Boyaca"
        }, {
            "code": "37",
            "name": "Caldas"
        }, {
            "code": "38",
            "name": "Magdalena"
        }]
    }, {
        "code": "KM",
        "name": "Comoros",
        "states": [{
            "code": "01",
            "name": "Anjouan"
        }, {
            "code": "02",
            "name": "Grande Comore"
        }, {
            "code": "03",
            "name": "Moheli"
        }]
    }, {
        "code": "CG",
        "name": "Congo",
        "states": [{
            "code": "01",
            "name": "Bouenza"
        }, {
            "code": "03",
            "name": "Cuvette"
        }, {
            "code": "04",
            "name": "Kouilou"
        }, {
            "code": "05",
            "name": "Lekoumou"
        }, {
            "code": "06",
            "name": "Likouala"
        }, {
            "code": "07",
            "name": "Niari"
        }, {
            "code": "08",
            "name": "Plateaux"
        }, {
            "code": "10",
            "name": "Sangha"
        }, {
            "code": "11",
            "name": "Pool"
        }, {
            "code": "12",
            "name": "Brazzaville"
        }]
    }, {
        "code": "CD",
        "name": "Congo, the Democratic Republic of the"
    }, {
        "code": "CK",
        "name": "Cook Islands"
    }, {
        "code": "CR",
        "name": "Costa Rica",
        "states": [{
            "code": "01",
            "name": "Alajuela"
        }, {
            "code": "02",
            "name": "Cartago"
        }, {
            "code": "03",
            "name": "Guanacaste"
        }, {
            "code": "04",
            "name": "Heredia"
        }, {
            "code": "06",
            "name": "Limon"
        }, {
            "code": "07",
            "name": "Puntarenas"
        }, {
            "code": "08",
            "name": "San Jose"
        }]
    }, {
        "code": "CI",
        "name": "Cote D'ivoire (Ivory Coast)",
        "states": [{
            "code": "01",
            "name": "Abengourou"
        }, {
            "code": "03",
            "name": "Dabakala"
        }, {
            "code": "05",
            "name": "Adzope"
        }, {
            "code": "06",
            "name": "Agboville"
        }, {
            "code": "07",
            "name": "Biankouma"
        }, {
            "code": "11",
            "name": "Bouna"
        }, {
            "code": "12",
            "name": "Boundiali"
        }, {
            "code": "14",
            "name": "Danane"
        }, {
            "code": "16",
            "name": "Divo"
        }, {
            "code": "17",
            "name": "Ferkessedougou"
        }, {
            "code": "18",
            "name": "Gagnoa"
        }, {
            "code": "20",
            "name": "Katiola"
        }, {
            "code": "21",
            "name": "Korhogo"
        }, {
            "code": "23",
            "name": "Odienne"
        }, {
            "code": "25",
            "name": "Seguela"
        }, {
            "code": "26",
            "name": "Touba"
        }, {
            "code": "27",
            "name": "Bongouanou"
        }, {
            "code": "28",
            "name": "Issia"
        }, {
            "code": "29",
            "name": "Lakota"
        }, {
            "code": "30",
            "name": "Mankono"
        }, {
            "code": "31",
            "name": "Oume"
        }, {
            "code": "32",
            "name": "Soubre"
        }, {
            "code": "33",
            "name": "Tingrela"
        }, {
            "code": "34",
            "name": "Zuenoula"
        }, {
            "code": "36",
            "name": "Bangolo"
        }, {
            "code": "37",
            "name": "Beoumi"
        }, {
            "code": "38",
            "name": "Bondoukou"
        }, {
            "code": "39",
            "name": "Bouafle"
        }, {
            "code": "40",
            "name": "Bouake"
        }, {
            "code": "41",
            "name": "Daloa"
        }, {
            "code": "42",
            "name": "Daoukro"
        }, {
            "code": "44",
            "name": "Duekoue"
        }, {
            "code": "45",
            "name": "Grand-Lahou"
        }, {
            "code": "47",
            "name": "Man"
        }, {
            "code": "48",
            "name": "Mbahiakro"
        }, {
            "code": "49",
            "name": "Sakassou"
        }, {
            "code": "50",
            "name": "San Pedro"
        }, {
            "code": "51",
            "name": "Sassandra"
        }, {
            "code": "52",
            "name": "Sinfra"
        }, {
            "code": "53",
            "name": "Tabou"
        }, {
            "code": "54",
            "name": "Tanda"
        }, {
            "code": "55",
            "name": "Tiassale"
        }, {
            "code": "56",
            "name": "Toumodi"
        }, {
            "code": "57",
            "name": "Vavoua"
        }, {
            "code": "61",
            "name": "Abidjan"
        }, {
            "code": "62",
            "name": "Aboisso"
        }, {
            "code": "63",
            "name": "Adiake"
        }, {
            "code": "64",
            "name": "Alepe"
        }, {
            "code": "65",
            "name": "Bocanda"
        }, {
            "code": "66",
            "name": "Dabou"
        }, {
            "code": "67",
            "name": "Dimbokro"
        }, {
            "code": "68",
            "name": "Grand-Bassam"
        }, {
            "code": "69",
            "name": "Guiglo"
        }, {
            "code": "70",
            "name": "Jacqueville"
        }, {
            "code": "71",
            "name": "Tiebissou"
        }, {
            "code": "72",
            "name": "Toulepleu"
        }, {
            "code": "73",
            "name": "Yamoussoukro"
        }]
    }, {
        "code": "HR",
        "name": "Croatia",
        "states": [{
            "code": "01",
            "name": "Bjelovarsko-Bilogorska"
        }, {
            "code": "02",
            "name": "Brodsko-Posavska"
        }, {
            "code": "03",
            "name": "Dubrovacko-Neretvanska"
        }, {
            "code": "04",
            "name": "Istarska"
        }, {
            "code": "05",
            "name": "Karlovacka"
        }, {
            "code": "06",
            "name": "Koprivnicko-Krizevacka"
        }, {
            "code": "07",
            "name": "Krapinsko-Zagorska"
        }, {
            "code": "08",
            "name": "Licko-Senjska"
        }, {
            "code": "09",
            "name": "Medimurska"
        }, {
            "code": "10",
            "name": "Osjecko-Baranjska"
        }, {
            "code": "11",
            "name": "Pozesko-Slavonska"
        }, {
            "code": "12",
            "name": "Primorsko-Goranska"
        }, {
            "code": "13",
            "name": "Sibensko-Kninska"
        }, {
            "code": "14",
            "name": "Sisacko-Moslavacka"
        }, {
            "code": "15",
            "name": "Splitsko-Dalmatinska"
        }, {
            "code": "16",
            "name": "Varazdinska"
        }, {
            "code": "17",
            "name": "Viroviticko-Podravska"
        }, {
            "code": "18",
            "name": "Vukovarsko-Srijemska"
        }, {
            "code": "19",
            "name": "Zadarska"
        }, {
            "code": "20",
            "name": "Zagrebacka"
        }, {
            "code": "21",
            "name": "Grad Zagreb"
        }]
    }, {
        "code": "CU",
        "name": "Cuba",
        "states": [{
            "code": "01",
            "name": "Pinar del Rio"
        }, {
            "code": "02",
            "name": "Ciudad de la Habana"
        }, {
            "code": "03",
            "name": "Matanzas"
        }, {
            "code": "04",
            "name": "Isla de la Juventud"
        }, {
            "code": "05",
            "name": "Camaguey"
        }, {
            "code": "07",
            "name": "Ciego de Avila"
        }, {
            "code": "08",
            "name": "Cienfuegos"
        }, {
            "code": "09",
            "name": "Granma"
        }, {
            "code": "10",
            "name": "Guantanamo"
        }, {
            "code": "11",
            "name": "La Habana"
        }, {
            "code": "12",
            "name": "Holguin"
        }, {
            "code": "13",
            "name": "Las Tunas"
        }, {
            "code": "14",
            "name": "Sancti Spiritus"
        }, {
            "code": "15",
            "name": "Santiago de Cuba"
        }, {
            "code": "16",
            "name": "Villa Clara"
        }]
    }, {
        "code": "CW",
        "name": "Curacao"
    }, {
        "code": "CY",
        "name": "Cyprus",
        "states": [{
            "code": "01",
            "name": "Famagusta"
        }, {
            "code": "02",
            "name": "Kyrenia"
        }, {
            "code": "03",
            "name": "Larnaca"
        }, {
            "code": "04",
            "name": "Nicosia"
        }, {
            "code": "05",
            "name": "Limassol"
        }, {
            "code": "06",
            "name": "Paphos"
        }]
    }, {
        "code": "CZ",
        "name": "Czech Republic",
        "states": [{
            "code": "03",
            "name": "Blansko"
        }, {
            "code": "04",
            "name": "Breclav"
        }, {
            "code": "20",
            "name": "Hradec Kralove"
        }, {
            "code": "21",
            "name": "Jablonec nad Nisou"
        }, {
            "code": "23",
            "name": "Jiein"
        }, {
            "code": "24",
            "name": "Jihlava"
        }, {
            "code": "30",
            "name": "Kolin"
        }, {
            "code": "33",
            "name": "Liberec"
        }, {
            "code": "36",
            "name": "Melnik"
        }, {
            "code": "37",
            "name": "Mlada Boleslav"
        }, {
            "code": "39",
            "name": "Nachod"
        }, {
            "code": "41",
            "name": "Nymburk"
        }, {
            "code": "45",
            "name": "Pardubice"
        }, {
            "code": "52",
            "name": "Hlavni Mesto Praha"
        }, {
            "code": "61",
            "name": "Semily"
        }, {
            "code": "70",
            "name": "Trutnov"
        }, {
            "code": "78",
            "name": "Jihomoravsky Kraj"
        }, {
            "code": "79",
            "name": "Jihocesky Kraj"
        }, {
            "code": "80",
            "name": "Vysocina"
        }, {
            "code": "81",
            "name": "Karlovarsky Kraj"
        }, {
            "code": "82",
            "name": "Kralovehradecky Kraj"
        }, {
            "code": "83",
            "name": "Liberecky Kraj"
        }, {
            "code": "84",
            "name": "Olomoucky Kraj"
        }, {
            "code": "85",
            "name": "Moravskoslezsky Kraj"
        }, {
            "code": "86",
            "name": "Pardubicky Kraj"
        }, {
            "code": "87",
            "name": "Plzensky Kraj"
        }, {
            "code": "88",
            "name": "Stredocesky Kraj"
        }, {
            "code": "89",
            "name": "Ustecky Kraj"
        }, {
            "code": "90",
            "name": "Zlinsky Kraj"
        }]
    }, {
        "code": "DK",
        "name": "Denmark",
        "states": [{
            "code": "01",
            "name": "Arhus"
        }, {
            "code": "02",
            "name": "Bornholm"
        }, {
            "code": "03",
            "name": "Frederiksborg"
        }, {
            "code": "04",
            "name": "Fyn"
        }, {
            "code": "05",
            "name": "Kobenhavn"
        }, {
            "code": "06",
            "name": "Staden Kobenhavn"
        }, {
            "code": "07",
            "name": "Nordjylland"
        }, {
            "code": "08",
            "name": "Ribe"
        }, {
            "code": "09",
            "name": "Ringkobing"
        }, {
            "code": "10",
            "name": "Roskilde"
        }, {
            "code": "11",
            "name": "Sonderjylland"
        }, {
            "code": "12",
            "name": "Storstrom"
        }, {
            "code": "13",
            "name": "Vejle"
        }, {
            "code": "14",
            "name": "Vestsjalland"
        }, {
            "code": "15",
            "name": "Viborg"
        }]
    }, {
        "code": "DJ",
        "name": "Djibouti",
        "states": [{
            "code": "02",
            "name": "Dikhil"
        }, {
            "code": "03",
            "name": "Djibouti"
        }, {
            "code": "04",
            "name": "Obock"
        }, {
            "code": "05",
            "name": "Tadjoura"
        }]
    }, {
        "code": "DM",
        "name": "Dominica",
        "states": [{
            "code": "02",
            "name": "Saint Andrew"
        }, {
            "code": "03",
            "name": "Saint David"
        }, {
            "code": "04",
            "name": "Saint George"
        }, {
            "code": "05",
            "name": "Saint John"
        }, {
            "code": "06",
            "name": "Saint Joseph"
        }, {
            "code": "07",
            "name": "Saint Luke"
        }, {
            "code": "08",
            "name": "Saint Mark"
        }, {
            "code": "09",
            "name": "Saint Patrick"
        }, {
            "code": "10",
            "name": "Saint Paul"
        }, {
            "code": "11",
            "name": "Saint Peter"
        }]
    }, {
        "code": "DO",
        "name": "Dominican Republic",
        "states": [{
            "code": "01",
            "name": "Azua"
        }, {
            "code": "02",
            "name": "Baoruco"
        }, {
            "code": "03",
            "name": "Barahona"
        }, {
            "code": "04",
            "name": "Dajabon"
        }, {
            "code": "05",
            "name": "Distrito Nacional"
        }, {
            "code": "06",
            "name": "Duarte"
        }, {
            "code": "08",
            "name": "Espaillat"
        }, {
            "code": "09",
            "name": "Independencia"
        }, {
            "code": "10",
            "name": "La Altagracia"
        }, {
            "code": "11",
            "name": "Elias Pina"
        }, {
            "code": "12",
            "name": "La Romana"
        }, {
            "code": "14",
            "name": "Maria Trinidad Sanchez"
        }, {
            "code": "15",
            "name": "Monte Cristi"
        }, {
            "code": "16",
            "name": "Pedernales"
        }, {
            "code": "17",
            "name": "Peravia"
        }, {
            "code": "18",
            "name": "Puerto Plata"
        }, {
            "code": "19",
            "name": "Salcedo"
        }, {
            "code": "20",
            "name": "Samana"
        }, {
            "code": "21",
            "name": "Sanchez Ramirez"
        }, {
            "code": "23",
            "name": "San Juan"
        }, {
            "code": "24",
            "name": "San Pedro De Macoris"
        }, {
            "code": "25",
            "name": "Santiago"
        }, {
            "code": "26",
            "name": "Santiago Rodriguez"
        }, {
            "code": "27",
            "name": "Valverde"
        }, {
            "code": "28",
            "name": "El Seibo"
        }, {
            "code": "29",
            "name": "Hato Mayor"
        }, {
            "code": "30",
            "name": "La Vega"
        }, {
            "code": "31",
            "name": "Monsenor Nouel"
        }, {
            "code": "32",
            "name": "Monte Plata"
        }, {
            "code": "33",
            "name": "San Cristobal"
        }]
    }, {
        "code": "EC",
        "name": "Ecuador",
        "states": [{
            "code": "01",
            "name": "Galapagos"
        }, {
            "code": "02",
            "name": "Azuay"
        }, {
            "code": "03",
            "name": "Bolivar"
        }, {
            "code": "04",
            "name": "Canar"
        }, {
            "code": "05",
            "name": "Carchi"
        }, {
            "code": "06",
            "name": "Chimborazo"
        }, {
            "code": "07",
            "name": "Cotopaxi"
        }, {
            "code": "08",
            "name": "El Oro"
        }, {
            "code": "09",
            "name": "Esmeraldas"
        }, {
            "code": "10",
            "name": "Guayas"
        }, {
            "code": "11",
            "name": "Imbabura"
        }, {
            "code": "12",
            "name": "Loja"
        }, {
            "code": "13",
            "name": "Los Rios"
        }, {
            "code": "14",
            "name": "Manabi"
        }, {
            "code": "15",
            "name": "Morona-Santiago"
        }, {
            "code": "17",
            "name": "Pastaza"
        }, {
            "code": "18",
            "name": "Pichincha"
        }, {
            "code": "19",
            "name": "Tungurahua"
        }, {
            "code": "20",
            "name": "Zamora-Chinchipe"
        }, {
            "code": "22",
            "name": "Sucumbios"
        }, {
            "code": "23",
            "name": "Napo"
        }, {
            "code": "24",
            "name": "Orellana"
        }]
    }, {
        "code": "EG",
        "name": "Egypt",
        "states": [{
            "code": "01",
            "name": "Ad Daqahliyah"
        }, {
            "code": "02",
            "name": "Al Bahr al Ahmar"
        }, {
            "code": "03",
            "name": "Al Buhayrah"
        }, {
            "code": "04",
            "name": "Al Fayyum"
        }, {
            "code": "05",
            "name": "Al Gharbiyah"
        }, {
            "code": "06",
            "name": "Al Iskandariyah"
        }, {
            "code": "07",
            "name": "Al Isma'iliyah"
        }, {
            "code": "08",
            "name": "Al Jizah"
        }, {
            "code": "09",
            "name": "Al Minufiyah"
        }, {
            "code": "10",
            "name": "Al Minya"
        }, {
            "code": "11",
            "name": "Al Qahirah"
        }, {
            "code": "12",
            "name": "Al Qalyubiyah"
        }, {
            "code": "13",
            "name": "Al Wadi al Jadid"
        }, {
            "code": "14",
            "name": "Ash Sharqiyah"
        }, {
            "code": "15",
            "name": "As Suways"
        }, {
            "code": "16",
            "name": "Aswan"
        }, {
            "code": "17",
            "name": "Asyut"
        }, {
            "code": "18",
            "name": "Bani Suwayf"
        }, {
            "code": "19",
            "name": "Bur Sa'id"
        }, {
            "code": "20",
            "name": "Dumyat"
        }, {
            "code": "21",
            "name": "Kafr ash Shaykh"
        }, {
            "code": "22",
            "name": "Matruh"
        }, {
            "code": "23",
            "name": "Qina"
        }, {
            "code": "24",
            "name": "Suhaj"
        }, {
            "code": "26",
            "name": "Janub Sina'"
        }, {
            "code": "27",
            "name": "Shamal Sina'"
        }]
    }, {
        "code": "SV",
        "name": "El Salvador",
        "states": [{
            "code": "01",
            "name": "Ahuachapan"
        }, {
            "code": "02",
            "name": "Cabanas"
        }, {
            "code": "03",
            "name": "Chalatenango"
        }, {
            "code": "04",
            "name": "Cuscatlan"
        }, {
            "code": "05",
            "name": "La Libertad"
        }, {
            "code": "06",
            "name": "La Paz"
        }, {
            "code": "07",
            "name": "La Union"
        }, {
            "code": "08",
            "name": "Morazan"
        }, {
            "code": "09",
            "name": "San Miguel"
        }, {
            "code": "10",
            "name": "San Salvador"
        }, {
            "code": "11",
            "name": "Santa Ana"
        }, {
            "code": "12",
            "name": "San Vicente"
        }, {
            "code": "13",
            "name": "Sonsonate"
        }, {
            "code": "14",
            "name": "Usulutan"
        }]
    }, {
        "code": "GQ",
        "name": "Equatorial Guinea",
        "states": [{
            "code": "03",
            "name": "Annobon"
        }, {
            "code": "04",
            "name": "Bioko Norte"
        }, {
            "code": "05",
            "name": "Bioko Sur"
        }, {
            "code": "06",
            "name": "Centro Sur"
        }, {
            "code": "07",
            "name": "Kie-Ntem"
        }, {
            "code": "08",
            "name": "Litoral"
        }, {
            "code": "09",
            "name": "Wele-Nzas"
        }]
    }, {
        "code": "ER",
        "name": "Eritrea"
    }, {
        "code": "EE",
        "name": "Estonia",
        "states": [{
            "code": "01",
            "name": "Harjumaa"
        }, {
            "code": "02",
            "name": "Hiiumaa"
        }, {
            "code": "03",
            "name": "Ida-Virumaa"
        }, {
            "code": "04",
            "name": "Jarvamaa"
        }, {
            "code": "05",
            "name": "Jogevamaa"
        }, {
            "code": "06",
            "name": "Kohtla-Jarve"
        }, {
            "code": "07",
            "name": "Laanemaa"
        }, {
            "code": "08",
            "name": "Laane-Virumaa"
        }, {
            "code": "09",
            "name": "Narva"
        }, {
            "code": "10",
            "name": "Parnu"
        }, {
            "code": "11",
            "name": "Parnumaa"
        }, {
            "code": "12",
            "name": "Polvamaa"
        }, {
            "code": "13",
            "name": "Raplamaa"
        }, {
            "code": "14",
            "name": "Saaremaa"
        }, {
            "code": "15",
            "name": "Sillamae"
        }, {
            "code": "16",
            "name": "Tallinn"
        }, {
            "code": "17",
            "name": "Tartu"
        }, {
            "code": "18",
            "name": "Tartumaa"
        }, {
            "code": "19",
            "name": "Valgamaa"
        }, {
            "code": "20",
            "name": "Viljandimaa"
        }, {
            "code": "21",
            "name": "Vorumaa"
        }]
    }, {
        "code": "ET",
        "name": "Ethiopia",
        "states": [{
            "code": "02",
            "name": "Amhara"
        }, {
            "code": "07",
            "name": "Somali"
        }, {
            "code": "08",
            "name": "Gambella"
        }, {
            "code": "10",
            "name": "Addis Abeba"
        }, {
            "code": "11",
            "name": "Southern"
        }, {
            "code": "12",
            "name": "Tigray"
        }, {
            "code": "13",
            "name": "Benishangul"
        }, {
            "code": "14",
            "name": "Afar"
        }, {
            "code": "44",
            "name": "Adis Abeba"
        }, {
            "code": "45",
            "name": "Afar"
        }, {
            "code": "46",
            "name": "Amara"
        }, {
            "code": "47",
            "name": "Binshangul Gumuz"
        }, {
            "code": "48",
            "name": "Dire Dawa"
        }, {
            "code": "49",
            "name": "Gambela Hizboch"
        }, {
            "code": "50",
            "name": "Hareri Hizb"
        }, {
            "code": "51",
            "name": "Oromiya"
        }, {
            "code": "52",
            "name": "Sumale"
        }, {
            "code": "53",
            "name": "Tigray"
        }, {
            "code": "54",
            "name": "YeDebub Biheroch Bihereseboch na"
        }]
    }, {
        "code": "FK",
        "name": "Falkland Islands (Malvinas)"
    }, {
        "code": "FO",
        "name": "Faroe Islands"
    }, {
        "code": "FJ",
        "name": "Fiji",
        "states": [{
            "code": "01",
            "name": "Central"
        }, {
            "code": "02",
            "name": "Eastern"
        }, {
            "code": "03",
            "name": "Northern"
        }, {
            "code": "04",
            "name": "Rotuma"
        }, {
            "code": "05",
            "name": "Western"
        }]
    }, {
        "code": "FI",
        "name": "Finland",
        "states": [{
            "code": "01",
            "name": "Åland"
        }, {
            "code": "06",
            "name": "Lapland"
        }, {
            "code": "08",
            "name": "Oulu"
        }, {
            "code": "13",
            "name": "Southern Finland"
        }, {
            "code": "14",
            "name": "Eastern Finland"
        }, {
            "code": "15",
            "name": "Western Finland"
        }]
    }, {
        "code": "FR",
        "name": "France",
        "states": [{
            "code": "97",
            "name": "Aquitaine"
        }, {
            "code": "98",
            "name": "Auvergne"
        }, {
            "code": "99",
            "name": "Basse-Normandie"
        }, {
            "code": "A1",
            "name": "Bourgogne"
        }, {
            "code": "A2",
            "name": "Bretagne"
        }, {
            "code": "A3",
            "name": "Centre"
        }, {
            "code": "A4",
            "name": "Champagne-Ardenne"
        }, {
            "code": "A5",
            "name": "Corse"
        }, {
            "code": "A6",
            "name": "Franche-Comte"
        }, {
            "code": "A7",
            "name": "Haute-Normandie"
        }, {
            "code": "A8",
            "name": "Ile-de-France"
        }, {
            "code": "A9",
            "name": "Languedoc-Roussillon"
        }, {
            "code": "B1",
            "name": "Limousin"
        }, {
            "code": "B2",
            "name": "Lorraine"
        }, {
            "code": "B3",
            "name": "Midi-Pyrenees"
        }, {
            "code": "B4",
            "name": "Nord-Pas-de-Calais"
        }, {
            "code": "B5",
            "name": "Pays de la Loire"
        }, {
            "code": "B6",
            "name": "Picardie"
        }, {
            "code": "B7",
            "name": "Poitou-Charentes"
        }, {
            "code": "B8",
            "name": "Provence-Alpes-Cote d'Azur"
        }, {
            "code": "B9",
            "name": "Rhone-Alpes"
        }, {
            "code": "C1",
            "name": "Alsace"
        }]
    }, {
        "code": "GF",
        "name": "French Guiana"
    }, {
        "code": "PF",
        "name": "French Polynesia"
    }, {
        "code": "TF",
        "name": "French Southern Territories"
    }, {
        "code": "GA",
        "name": "Gabon",
        "states": [{
            "code": "01",
            "name": "Estuaire"
        }, {
            "code": "02",
            "name": "Haut-Ogooue"
        }, {
            "code": "03",
            "name": "Moyen-Ogooue"
        }, {
            "code": "04",
            "name": "Ngounie"
        }, {
            "code": "05",
            "name": "Nyanga"
        }, {
            "code": "06",
            "name": "Ogooue-Ivindo"
        }, {
            "code": "07",
            "name": "Ogooue-Lolo"
        }, {
            "code": "08",
            "name": "Ogooue-Maritime"
        }, {
            "code": "09",
            "name": "Woleu-Ntem"
        }]
    }, {
        "code": "GM",
        "name": "Gambia",
        "states": [{
            "code": "01",
            "name": "Banjul"
        }, {
            "code": "02",
            "name": "Lower River"
        }, {
            "code": "03",
            "name": "MacCarthy Island"
        }, {
            "code": "04",
            "name": "Upper River"
        }, {
            "code": "05",
            "name": "Western"
        }, {
            "code": "07",
            "name": "North Bank"
        }]
    }, {
        "code": "GE",
        "name": "Georgia",
        "states": [{
            "code": "01",
            "name": "Abashis Raioni"
        }, {
            "code": "02",
            "name": "Abkhazia"
        }, {
            "code": "03",
            "name": "Adigenis Raioni"
        }, {
            "code": "04",
            "name": "Ajaria"
        }, {
            "code": "05",
            "name": "Akhalgoris Raioni"
        }, {
            "code": "06",
            "name": "Akhalk'alak'is Raioni"
        }, {
            "code": "07",
            "name": "Akhalts'ikhis Raioni"
        }, {
            "code": "08",
            "name": "Akhmetis Raioni"
        }, {
            "code": "09",
            "name": "Ambrolauris Raioni"
        }, {
            "code": "10",
            "name": "Aspindzis Raioni"
        }, {
            "code": "11",
            "name": "Baghdat'is Raioni"
        }, {
            "code": "12",
            "name": "Bolnisis Raioni"
        }, {
            "code": "13",
            "name": "Borjomis Raioni"
        }, {
            "code": "14",
            "name": "Chiat'ura"
        }, {
            "code": "15",
            "name": "Ch'khorotsqus Raioni"
        }, {
            "code": "16",
            "name": "Ch'okhatauris Raioni"
        }, {
            "code": "17",
            "name": "Dedop'listsqaros Raioni"
        }, {
            "code": "18",
            "name": "Dmanisis Raioni"
        }, {
            "code": "19",
            "name": "Dushet'is Raioni"
        }, {
            "code": "20",
            "name": "Gardabanis Raioni"
        }, {
            "code": "21",
            "name": "Gori"
        }, {
            "code": "22",
            "name": "Goris Raioni"
        }, {
            "code": "23",
            "name": "Gurjaanis Raioni"
        }, {
            "code": "24",
            "name": "Javis Raioni"
        }, {
            "code": "25",
            "name": "K'arelis Raioni"
        }, {
            "code": "26",
            "name": "Kaspis Raioni"
        }, {
            "code": "27",
            "name": "Kharagaulis Raioni"
        }, {
            "code": "28",
            "name": "Khashuris Raioni"
        }, {
            "code": "29",
            "name": "Khobis Raioni"
        }, {
            "code": "30",
            "name": "Khonis Raioni"
        }, {
            "code": "31",
            "name": "K'ut'aisi"
        }, {
            "code": "32",
            "name": "Lagodekhis Raioni"
        }, {
            "code": "33",
            "name": "Lanch'khut'is Raioni"
        }, {
            "code": "34",
            "name": "Lentekhis Raioni"
        }, {
            "code": "35",
            "name": "Marneulis Raioni"
        }, {
            "code": "36",
            "name": "Martvilis Raioni"
        }, {
            "code": "37",
            "name": "Mestiis Raioni"
        }, {
            "code": "38",
            "name": "Mts'khet'is Raioni"
        }, {
            "code": "39",
            "name": "Ninotsmindis Raioni"
        }, {
            "code": "40",
            "name": "Onis Raioni"
        }, {
            "code": "41",
            "name": "Ozurget'is Raioni"
        }, {
            "code": "42",
            "name": "P'ot'i"
        }, {
            "code": "43",
            "name": "Qazbegis Raioni"
        }, {
            "code": "44",
            "name": "Qvarlis Raioni"
        }, {
            "code": "45",
            "name": "Rust'avi"
        }, {
            "code": "46",
            "name": "Sach'kheris Raioni"
        }, {
            "code": "47",
            "name": "Sagarejos Raioni"
        }, {
            "code": "48",
            "name": "Samtrediis Raioni"
        }, {
            "code": "49",
            "name": "Senakis Raioni"
        }, {
            "code": "50",
            "name": "Sighnaghis Raioni"
        }, {
            "code": "51",
            "name": "T'bilisi"
        }, {
            "code": "52",
            "name": "T'elavis Raioni"
        }, {
            "code": "53",
            "name": "T'erjolis Raioni"
        }, {
            "code": "54",
            "name": "T'et'ritsqaros Raioni"
        }, {
            "code": "55",
            "name": "T'ianet'is Raioni"
        }, {
            "code": "56",
            "name": "Tqibuli"
        }, {
            "code": "57",
            "name": "Ts'ageris Raioni"
        }, {
            "code": "58",
            "name": "Tsalenjikhis Raioni"
        }, {
            "code": "59",
            "name": "Tsalkis Raioni"
        }, {
            "code": "60",
            "name": "Tsqaltubo"
        }, {
            "code": "61",
            "name": "Vanis Raioni"
        }, {
            "code": "62",
            "name": "Zestap'onis Raioni"
        }, {
            "code": "63",
            "name": "Zugdidi"
        }, {
            "code": "64",
            "name": "Zugdidis Raioni"
        }]
    }, {
        "code": "DE",
        "name": "Germany",
        "states": [{
            "code": "01",
            "name": "Baden-Württemberg"
        }, {
            "code": "02",
            "name": "Bayern"
        }, {
            "code": "03",
            "name": "Bremen"
        }, {
            "code": "04",
            "name": "Hamburg"
        }, {
            "code": "05",
            "name": "Hessen"
        }, {
            "code": "06",
            "name": "Niedersachsen"
        }, {
            "code": "07",
            "name": "Nordrhein-Westfalen"
        }, {
            "code": "08",
            "name": "Rheinland-Pfalz"
        }, {
            "code": "09",
            "name": "Saarland"
        }, {
            "code": "10",
            "name": "Schleswig-Holstein"
        }, {
            "code": "11",
            "name": "Brandenburg"
        }, {
            "code": "12",
            "name": "Mecklenburg-Vorpommern"
        }, {
            "code": "13",
            "name": "Sachsen"
        }, {
            "code": "14",
            "name": "Sachsen-Anhalt"
        }, {
            "code": "15",
            "name": "Thuringen"
        }, {
            "code": "16",
            "name": "Berlin"
        }]
    }, {
        "code": "GH",
        "name": "Ghana",
        "states": [{
            "code": "01",
            "name": "Greater Accra"
        }, {
            "code": "02",
            "name": "Ashanti"
        }, {
            "code": "03",
            "name": "Brong-Ahafo"
        }, {
            "code": "04",
            "name": "Central"
        }, {
            "code": "05",
            "name": "Eastern"
        }, {
            "code": "06",
            "name": "Northern"
        }, {
            "code": "08",
            "name": "Volta"
        }, {
            "code": "09",
            "name": "Western"
        }, {
            "code": "10",
            "name": "Upper East"
        }, {
            "code": "11",
            "name": "Upper West"
        }]
    }, {
        "code": "GI",
        "name": "Gibraltar"
    }, {
        "code": "GR",
        "name": "Greece",
        "states": [{
            "code": "01",
            "name": "Evros"
        }, {
            "code": "02",
            "name": "Rodhopi"
        }, {
            "code": "03",
            "name": "Xanthi"
        }, {
            "code": "04",
            "name": "Drama"
        }, {
            "code": "05",
            "name": "Serrai"
        }, {
            "code": "06",
            "name": "Kilkis"
        }, {
            "code": "07",
            "name": "Pella"
        }, {
            "code": "08",
            "name": "Florina"
        }, {
            "code": "09",
            "name": "Kastoria"
        }, {
            "code": "10",
            "name": "Grevena"
        }, {
            "code": "11",
            "name": "Kozani"
        }, {
            "code": "12",
            "name": "Imathia"
        }, {
            "code": "13",
            "name": "Thessaloniki"
        }, {
            "code": "14",
            "name": "Kavala"
        }, {
            "code": "15",
            "name": "Khalkidhiki"
        }, {
            "code": "16",
            "name": "Pieria"
        }, {
            "code": "17",
            "name": "Ioannina"
        }, {
            "code": "18",
            "name": "Thesprotia"
        }, {
            "code": "19",
            "name": "Preveza"
        }, {
            "code": "20",
            "name": "Arta"
        }, {
            "code": "21",
            "name": "Larisa"
        }, {
            "code": "22",
            "name": "Trikala"
        }, {
            "code": "23",
            "name": "Kardhitsa"
        }, {
            "code": "24",
            "name": "Magnisia"
        }, {
            "code": "25",
            "name": "Kerkira"
        }, {
            "code": "26",
            "name": "Levkas"
        }, {
            "code": "27",
            "name": "Kefallinia"
        }, {
            "code": "28",
            "name": "Zakinthos"
        }, {
            "code": "29",
            "name": "Fthiotis"
        }, {
            "code": "30",
            "name": "Evritania"
        }, {
            "code": "31",
            "name": "Aitolia kai Akarnania"
        }, {
            "code": "32",
            "name": "Fokis"
        }, {
            "code": "33",
            "name": "Voiotia"
        }, {
            "code": "34",
            "name": "Evvoia"
        }, {
            "code": "35",
            "name": "Attiki"
        }, {
            "code": "36",
            "name": "Argolis"
        }, {
            "code": "37",
            "name": "Korinthia"
        }, {
            "code": "38",
            "name": "Akhaia"
        }, {
            "code": "39",
            "name": "Ilia"
        }, {
            "code": "40",
            "name": "Messinia"
        }, {
            "code": "41",
            "name": "Arkadhia"
        }, {
            "code": "42",
            "name": "Lakonia"
        }, {
            "code": "43",
            "name": "Khania"
        }, {
            "code": "44",
            "name": "Rethimni"
        }, {
            "code": "45",
            "name": "Iraklion"
        }, {
            "code": "46",
            "name": "Lasithi"
        }, {
            "code": "47",
            "name": "Dhodhekanisos"
        }, {
            "code": "48",
            "name": "Samos"
        }, {
            "code": "49",
            "name": "Kikladhes"
        }, {
            "code": "50",
            "name": "Khios"
        }, {
            "code": "51",
            "name": "Lesvos"
        }]
    }, {
        "code": "GL",
        "name": "Greenland",
        "states": [{
            "code": "01",
            "name": "Nordgronland"
        }, {
            "code": "02",
            "name": "Ostgronland"
        }, {
            "code": "03",
            "name": "Vestgronland"
        }]
    }, {
        "code": "GD",
        "name": "Grenada",
        "states": [{
            "code": "01",
            "name": "Saint Andrew"
        }, {
            "code": "02",
            "name": "Saint David"
        }, {
            "code": "03",
            "name": "Saint George"
        }, {
            "code": "04",
            "name": "Saint John"
        }, {
            "code": "05",
            "name": "Saint Mark"
        }, {
            "code": "06",
            "name": "Saint Patrick"
        }]
    }, {
        "code": "GP",
        "name": "Guadeloupe"
    }, {
        "code": "GU",
        "name": "Guam"
    }, {
        "code": "GT",
        "name": "Guatemala",
        "states": [{
            "code": "01",
            "name": "Alta Verapaz"
        }, {
            "code": "02",
            "name": "Baja Verapaz"
        }, {
            "code": "03",
            "name": "Chimaltenango"
        }, {
            "code": "04",
            "name": "Chiquimula"
        }, {
            "code": "05",
            "name": "El Progreso"
        }, {
            "code": "06",
            "name": "Escuintla"
        }, {
            "code": "07",
            "name": "Guatemala"
        }, {
            "code": "08",
            "name": "Huehuetenango"
        }, {
            "code": "09",
            "name": "Izabal"
        }, {
            "code": "10",
            "name": "Jalapa"
        }, {
            "code": "11",
            "name": "Jutiapa"
        }, {
            "code": "12",
            "name": "Peten"
        }, {
            "code": "13",
            "name": "Quetzaltenango"
        }, {
            "code": "14",
            "name": "Quiche"
        }, {
            "code": "15",
            "name": "Retalhuleu"
        }, {
            "code": "16",
            "name": "Sacatepequez"
        }, {
            "code": "17",
            "name": "San Marcos"
        }, {
            "code": "18",
            "name": "Santa Rosa"
        }, {
            "code": "19",
            "name": "Solola"
        }, {
            "code": "20",
            "name": "Suchitepequez"
        }, {
            "code": "21",
            "name": "Totonicapan"
        }, {
            "code": "22",
            "name": "Zacapa"
        }]
    }, {
        "code": "GG",
        "name": "Guernsey"
    }, {
        "code": "GN",
        "name": "Guinea",
        "states": [{
            "code": "01",
            "name": "Beyla"
        }, {
            "code": "02",
            "name": "Boffa"
        }, {
            "code": "03",
            "name": "Boke"
        }, {
            "code": "04",
            "name": "Conakry"
        }, {
            "code": "05",
            "name": "Dabola"
        }, {
            "code": "06",
            "name": "Dalaba"
        }, {
            "code": "07",
            "name": "Dinguiraye"
        }, {
            "code": "09",
            "name": "Faranah"
        }, {
            "code": "10",
            "name": "Forecariah"
        }, {
            "code": "11",
            "name": "Fria"
        }, {
            "code": "12",
            "name": "Gaoual"
        }, {
            "code": "13",
            "name": "Gueckedou"
        }, {
            "code": "15",
            "name": "Kerouane"
        }, {
            "code": "16",
            "name": "Kindia"
        }, {
            "code": "17",
            "name": "Kissidougou"
        }, {
            "code": "18",
            "name": "Koundara"
        }, {
            "code": "19",
            "name": "Kouroussa"
        }, {
            "code": "21",
            "name": "Macenta"
        }, {
            "code": "22",
            "name": "Mali"
        }, {
            "code": "23",
            "name": "Mamou"
        }, {
            "code": "25",
            "name": "Pita"
        }, {
            "code": "27",
            "name": "Telimele"
        }, {
            "code": "28",
            "name": "Tougue"
        }, {
            "code": "29",
            "name": "Yomou"
        }, {
            "code": "30",
            "name": "Coyah"
        }, {
            "code": "31",
            "name": "Dubreka"
        }, {
            "code": "32",
            "name": "Kankan"
        }, {
            "code": "33",
            "name": "Koubia"
        }, {
            "code": "34",
            "name": "Labe"
        }, {
            "code": "35",
            "name": "Lelouma"
        }, {
            "code": "36",
            "name": "Lola"
        }, {
            "code": "37",
            "name": "Mandiana"
        }, {
            "code": "38",
            "name": "Nzerekore"
        }, {
            "code": "39",
            "name": "Siguiri"
        }]
    }, {
        "code": "GW",
        "name": "Guinea-Bissau",
        "states": [{
            "code": "01",
            "name": "Bafata"
        }, {
            "code": "02",
            "name": "Quinara"
        }, {
            "code": "04",
            "name": "Oio"
        }, {
            "code": "05",
            "name": "Bolama"
        }, {
            "code": "06",
            "name": "Cacheu"
        }, {
            "code": "07",
            "name": "Tombali"
        }, {
            "code": "10",
            "name": "Gabu"
        }, {
            "code": "11",
            "name": "Bissau"
        }, {
            "code": "12",
            "name": "Biombo"
        }]
    }, {
        "code": "GY",
        "name": "Guyana",
        "states": [{
            "code": "10",
            "name": "Barima-Waini"
        }, {
            "code": "11",
            "name": "Cuyuni-Mazaruni"
        }, {
            "code": "12",
            "name": "Demerara-Mahaica"
        }, {
            "code": "13",
            "name": "East Berbice-Corentyne"
        }, {
            "code": "14",
            "name": "Essequibo Islands-West Demerara"
        }, {
            "code": "15",
            "name": "Mahaica-Berbice"
        }, {
            "code": "16",
            "name": "Pomeroon-Supenaam"
        }, {
            "code": "17",
            "name": "Potaro-Siparuni"
        }, {
            "code": "18",
            "name": "Upper Demerara-Berbice"
        }, {
            "code": "19",
            "name": "Upper Takutu-Upper Essequibo"
        }]
    }, {
        "code": "HT",
        "name": "Haiti",
        "states": [{
            "code": "03",
            "name": "Nord-Ouest"
        }, {
            "code": "06",
            "name": "Artibonite"
        }, {
            "code": "07",
            "name": "Centre"
        }, {
            "code": "08",
            "name": "Grand' Anse"
        }, {
            "code": "09",
            "name": "Nord"
        }, {
            "code": "10",
            "name": "Nord-Est"
        }, {
            "code": "11",
            "name": "Ouest"
        }, {
            "code": "12",
            "name": "Sud"
        }, {
            "code": "13",
            "name": "Sud-Est"
        }]
    }, {
        "code": "HM",
        "name": "Heard & McDonald Islands"
    }, {
        "code": "HN",
        "name": "Honduras",
        "states": [{
            "code": "01",
            "name": "Atlantida"
        }, {
            "code": "02",
            "name": "Choluteca"
        }, {
            "code": "03",
            "name": "Colon"
        }, {
            "code": "04",
            "name": "Comayagua"
        }, {
            "code": "05",
            "name": "Copan"
        }, {
            "code": "06",
            "name": "Cortes"
        }, {
            "code": "07",
            "name": "El Paraiso"
        }, {
            "code": "08",
            "name": "Francisco Morazan"
        }, {
            "code": "09",
            "name": "Gracias a Dios"
        }, {
            "code": "10",
            "name": "Intibuca"
        }, {
            "code": "11",
            "name": "Islas de la Bahia"
        }, {
            "code": "12",
            "name": "La Paz"
        }, {
            "code": "13",
            "name": "Lempira"
        }, {
            "code": "14",
            "name": "Ocotepeque"
        }, {
            "code": "15",
            "name": "Olancho"
        }, {
            "code": "16",
            "name": "Santa Barbara"
        }, {
            "code": "17",
            "name": "Valle"
        }, {
            "code": "18",
            "name": "Yoro"
        }]
    }, {
        "code": "HK",
        "name": "Hong Kong"
    }, {
        "code": "HU",
        "name": "Hungary",
        "states": [{
            "code": "01",
            "name": "Bacs-Kiskun"
        }, {
            "code": "02",
            "name": "Baranya"
        }, {
            "code": "03",
            "name": "Bekes"
        }, {
            "code": "04",
            "name": "Borsod-Abauj-Zemplen"
        }, {
            "code": "05",
            "name": "Budapest"
        }, {
            "code": "06",
            "name": "Csongrad"
        }, {
            "code": "07",
            "name": "Debrecen"
        }, {
            "code": "08",
            "name": "Fejer"
        }, {
            "code": "09",
            "name": "Gyor-Moson-Sopron"
        }, {
            "code": "10",
            "name": "Hajdu-Bihar"
        }, {
            "code": "11",
            "name": "Heves"
        }, {
            "code": "12",
            "name": "Komarom-Esztergom"
        }, {
            "code": "13",
            "name": "Miskolc"
        }, {
            "code": "14",
            "name": "Nograd"
        }, {
            "code": "15",
            "name": "Pecs"
        }, {
            "code": "16",
            "name": "Pest"
        }, {
            "code": "17",
            "name": "Somogy"
        }, {
            "code": "18",
            "name": "Szabolcs-Szatmar-Bereg"
        }, {
            "code": "19",
            "name": "Szeged"
        }, {
            "code": "20",
            "name": "Jasz-Nagykun-Szolnok"
        }, {
            "code": "21",
            "name": "Tolna"
        }, {
            "code": "22",
            "name": "Vas"
        }, {
            "code": "23",
            "name": "Veszprem"
        }, {
            "code": "24",
            "name": "Zala"
        }, {
            "code": "25",
            "name": "Gyor"
        }, {
            "code": "26",
            "name": "Bekescsaba"
        }, {
            "code": "27",
            "name": "Dunaujvaros"
        }, {
            "code": "28",
            "name": "Eger"
        }, {
            "code": "29",
            "name": "Hodmezovasarhely"
        }, {
            "code": "30",
            "name": "Kaposvar"
        }, {
            "code": "31",
            "name": "Kecskemet"
        }, {
            "code": "32",
            "name": "Nagykanizsa"
        }, {
            "code": "33",
            "name": "Nyiregyhaza"
        }, {
            "code": "34",
            "name": "Sopron"
        }, {
            "code": "35",
            "name": "Szekesfehervar"
        }, {
            "code": "36",
            "name": "Szolnok"
        }, {
            "code": "37",
            "name": "Szombathely"
        }, {
            "code": "38",
            "name": "Tatabanya"
        }, {
            "code": "39",
            "name": "Veszprem"
        }, {
            "code": "40",
            "name": "Zalaegerszeg"
        }]
    }, {
        "code": "IS",
        "name": "Iceland",
        "states": [{
            "code": "01",
            "name": "Akranes"
        }, {
            "code": "02",
            "name": "Akureyri"
        }, {
            "code": "03",
            "name": "Arnessysla"
        }, {
            "code": "04",
            "name": "Austur-Bardastrandarsysla"
        }, {
            "code": "05",
            "name": "Austur-Hunavatnssysla"
        }, {
            "code": "06",
            "name": "Austur-Skaftafellssysla"
        }, {
            "code": "07",
            "name": "Borgarfjardarsysla"
        }, {
            "code": "08",
            "name": "Dalasysla"
        }, {
            "code": "09",
            "name": "Eyjafjardarsysla"
        }, {
            "code": "10",
            "name": "Gullbringusysla"
        }, {
            "code": "11",
            "name": "Hafnarfjordur"
        }, {
            "code": "12",
            "name": "Husavik"
        }, {
            "code": "13",
            "name": "Isafjordur"
        }, {
            "code": "14",
            "name": "Keflavik"
        }, {
            "code": "15",
            "name": "Kjosarsysla"
        }, {
            "code": "16",
            "name": "Kopavogur"
        }, {
            "code": "17",
            "name": "Myrasysla"
        }, {
            "code": "18",
            "name": "Neskaupstadur"
        }, {
            "code": "19",
            "name": "Nordur-Isafjardarsysla"
        }, {
            "code": "20",
            "name": "Nordur-Mulasysla"
        }, {
            "code": "21",
            "name": "Nordur-Tingeyjarsysla"
        }, {
            "code": "22",
            "name": "Olafsfjordur"
        }, {
            "code": "23",
            "name": "Rangarvallasysla"
        }, {
            "code": "24",
            "name": "Reykjavik"
        }, {
            "code": "25",
            "name": "Saudarkrokur"
        }, {
            "code": "26",
            "name": "Seydisfjordur"
        }, {
            "code": "27",
            "name": "Siglufjordur"
        }, {
            "code": "28",
            "name": "Skagafjardarsysla"
        }, {
            "code": "29",
            "name": "Snafellsnes- og Hnappadalssysla"
        }, {
            "code": "30",
            "name": "Strandasysla"
        }, {
            "code": "31",
            "name": "Sudur-Mulasysla"
        }, {
            "code": "32",
            "name": "Sudur-Tingeyjarsysla"
        }, {
            "code": "33",
            "name": "Vestmannaeyjar"
        }, {
            "code": "34",
            "name": "Vestur-Bardastrandarsysla"
        }, {
            "code": "35",
            "name": "Vestur-Hunavatnssysla"
        }, {
            "code": "36",
            "name": "Vestur-Isafjardarsysla"
        }, {
            "code": "37",
            "name": "Vestur-Skaftafellssysla"
        }]
    }, {
        "code": "IN",
        "name": "India",
        "states": [{
            "code": "01",
            "name": "Andaman and Nicobar Islands"
        }, {
            "code": "02",
            "name": "Andhra Pradesh"
        }, {
            "code": "03",
            "name": "Assam"
        }, {
            "code": "05",
            "name": "Chandigarh"
        }, {
            "code": "06",
            "name": "Dadra and Nagar Haveli"
        }, {
            "code": "07",
            "name": "Delhi"
        }, {
            "code": "09",
            "name": "Gujarat"
        }, {
            "code": "10",
            "name": "Haryana"
        }, {
            "code": "11",
            "name": "Himachal Pradesh"
        }, {
            "code": "12",
            "name": "Jammu and Kashmir"
        }, {
            "code": "13",
            "name": "Kerala"
        }, {
            "code": "14",
            "name": "Lakshadweep"
        }, {
            "code": "16",
            "name": "Maharashtra"
        }, {
            "code": "17",
            "name": "Manipur"
        }, {
            "code": "18",
            "name": "Meghalaya"
        }, {
            "code": "19",
            "name": "Karnataka"
        }, {
            "code": "20",
            "name": "Nagaland"
        }, {
            "code": "21",
            "name": "Orissa"
        }, {
            "code": "22",
            "name": "Pondicherry"
        }, {
            "code": "23",
            "name": "Punjab"
        }, {
            "code": "24",
            "name": "Rajasthan"
        }, {
            "code": "25",
            "name": "Tamil Nadu"
        }, {
            "code": "26",
            "name": "Tripura"
        }, {
            "code": "28",
            "name": "West Bengal"
        }, {
            "code": "29",
            "name": "Sikkim"
        }, {
            "code": "30",
            "name": "Arunachal Pradesh"
        }, {
            "code": "31",
            "name": "Mizoram"
        }, {
            "code": "32",
            "name": "Daman and Diu"
        }, {
            "code": "33",
            "name": "Goa"
        }, {
            "code": "34",
            "name": "Bihar"
        }, {
            "code": "35",
            "name": "Madhya Pradesh"
        }, {
            "code": "36",
            "name": "Uttar Pradesh"
        }, {
            "code": "37",
            "name": "Chhattisgarh"
        }, {
            "code": "38",
            "name": "Jharkhand"
        }, {
            "code": "39",
            "name": "Uttaranchal"
        }]
    }, {
        "code": "ID",
        "name": "Indonesia",
        "states": [{
            "code": "01",
            "name": "Aceh"
        }, {
            "code": "02",
            "name": "Bali"
        }, {
            "code": "03",
            "name": "Bengkulu"
        }, {
            "code": "04",
            "name": "Jakarta Raya"
        }, {
            "code": "05",
            "name": "Jambi"
        }, {
            "code": "07",
            "name": "Jawa Tengah"
        }, {
            "code": "08",
            "name": "Jawa Timur"
        }, {
            "code": "09",
            "name": "Papua"
        }, {
            "code": "10",
            "name": "Yogyakarta"
        }, {
            "code": "11",
            "name": "Kalimantan Barat"
        }, {
            "code": "12",
            "name": "Kalimantan Selatan"
        }, {
            "code": "13",
            "name": "Kalimantan Tengah"
        }, {
            "code": "14",
            "name": "Kalimantan Timur"
        }, {
            "code": "15",
            "name": "Lampung"
        }, {
            "code": "17",
            "name": "Nusa Tenggara Barat"
        }, {
            "code": "18",
            "name": "Nusa Tenggara Timur"
        }, {
            "code": "19",
            "name": "Riau"
        }, {
            "code": "20",
            "name": "Sulawesi Selatan"
        }, {
            "code": "21",
            "name": "Sulawesi Tengah"
        }, {
            "code": "22",
            "name": "Sulawesi Tenggara"
        }, {
            "code": "24",
            "name": "Sumatera Barat"
        }, {
            "code": "26",
            "name": "Sumatera Utara"
        }, {
            "code": "28",
            "name": "Maluku"
        }, {
            "code": "29",
            "name": "Maluku Utara"
        }, {
            "code": "30",
            "name": "Jawa Barat"
        }, {
            "code": "31",
            "name": "Sulawesi Utara"
        }, {
            "code": "32",
            "name": "Sumatera Selatan"
        }, {
            "code": "33",
            "name": "Banten"
        }, {
            "code": "34",
            "name": "Gorontalo"
        }, {
            "code": "35",
            "name": "Kepulauan Bangka Belitung"
        }]
    }, {
        "code": "IQ",
        "name": "Iraq",
        "states": [{
            "code": "01",
            "name": "Al Anbar"
        }, {
            "code": "02",
            "name": "Al Basrah"
        }, {
            "code": "03",
            "name": "Al Muthanna"
        }, {
            "code": "04",
            "name": "Al Qadisiyah"
        }, {
            "code": "05",
            "name": "As Sulaymaniyah"
        }, {
            "code": "06",
            "name": "Babil"
        }, {
            "code": "07",
            "name": "Baghdad"
        }, {
            "code": "08",
            "name": "Dahuk"
        }, {
            "code": "09",
            "name": "Dhi Qar"
        }, {
            "code": "10",
            "name": "Diyala"
        }, {
            "code": "11",
            "name": "Arbil"
        }, {
            "code": "12",
            "name": "Karbala'"
        }, {
            "code": "13",
            "name": "At Ta'mim"
        }, {
            "code": "14",
            "name": "Maysan"
        }, {
            "code": "15",
            "name": "Ninawa"
        }, {
            "code": "16",
            "name": "Wasit"
        }, {
            "code": "17",
            "name": "An Najaf"
        }, {
            "code": "18",
            "name": "Salah ad Din"
        }]
    }, {
        "code": "IE",
        "name": "Ireland",
        "states": [{
            "code": "01",
            "name": "Carlow"
        }, {
            "code": "02",
            "name": "Cavan"
        }, {
            "code": "03",
            "name": "Clare"
        }, {
            "code": "04",
            "name": "Cork"
        }, {
            "code": "06",
            "name": "Donegal"
        }, {
            "code": "07",
            "name": "Dublin"
        }, {
            "code": "10",
            "name": "Galway"
        }, {
            "code": "11",
            "name": "Kerry"
        }, {
            "code": "12",
            "name": "Kildare"
        }, {
            "code": "13",
            "name": "Kilkenny"
        }, {
            "code": "14",
            "name": "Leitrim"
        }, {
            "code": "15",
            "name": "Laois"
        }, {
            "code": "16",
            "name": "Limerick"
        }, {
            "code": "18",
            "name": "Longford"
        }, {
            "code": "19",
            "name": "Louth"
        }, {
            "code": "20",
            "name": "Mayo"
        }, {
            "code": "21",
            "name": "Meath"
        }, {
            "code": "22",
            "name": "Monaghan"
        }, {
            "code": "23",
            "name": "Offaly"
        }, {
            "code": "24",
            "name": "Roscommon"
        }, {
            "code": "25",
            "name": "Sligo"
        }, {
            "code": "26",
            "name": "Tipperary"
        }, {
            "code": "27",
            "name": "Waterford"
        }, {
            "code": "29",
            "name": "Westmeath"
        }, {
            "code": "30",
            "name": "Wexford"
        }, {
            "code": "31",
            "name": "Wicklow"
        }]
    }, {
        "code": "IR",
        "name": "Islamic Republic of Iran",
        "states": [{
            "code": "01",
            "name": "Azarbayjan-e Bakhtari"
        }, {
            "code": "02",
            "name": "Azarbayjan-e Khavari"
        }, {
            "code": "03",
            "name": "Chahar Mahall va Bakhtiari"
        }, {
            "code": "04",
            "name": "Sistan va Baluchestan"
        }, {
            "code": "05",
            "name": "Kohkiluyeh va Buyer Ahmadi"
        }, {
            "code": "07",
            "name": "Fars"
        }, {
            "code": "08",
            "name": "Gilan"
        }, {
            "code": "09",
            "name": "Hamadan"
        }, {
            "code": "10",
            "name": "Ilam"
        }, {
            "code": "11",
            "name": "Hormozgan"
        }, {
            "code": "13",
            "name": "Bakhtaran"
        }, {
            "code": "15",
            "name": "Khuzestan"
        }, {
            "code": "16",
            "name": "Kordestan"
        }, {
            "code": "22",
            "name": "Bushehr"
        }, {
            "code": "23",
            "name": "Lorestan"
        }, {
            "code": "25",
            "name": "Semnan"
        }, {
            "code": "26",
            "name": "Tehran"
        }, {
            "code": "28",
            "name": "Esfahan"
        }, {
            "code": "29",
            "name": "Kerman"
        }, {
            "code": "30",
            "name": "Khorasan"
        }, {
            "code": "31",
            "name": "Yazd"
        }, {
            "code": "32",
            "name": "Ardabil"
        }, {
            "code": "34",
            "name": "Markazi"
        }, {
            "code": "35",
            "name": "Mazandaran"
        }, {
            "code": "36",
            "name": "Zanjan"
        }, {
            "code": "37",
            "name": "Golestan"
        }, {
            "code": "38",
            "name": "Qazvin"
        }, {
            "code": "39",
            "name": "Qom"
        }]
    }, {
        "code": "IM",
        "name": "Isle of Man"
    }, {
        "code": "IL",
        "name": "Israel",
        "states": [{
            "code": "01",
            "name": "HaDarom"
        }, {
            "code": "02",
            "name": "HaMerkaz"
        }, {
            "code": "03",
            "name": "HaZafon"
        }, {
            "code": "04",
            "name": "Hefa"
        }, {
            "code": "05",
            "name": "Tel Aviv"
        }, {
            "code": "06",
            "name": "Yerushalayim"
        }]
    }, {
        "code": "IT",
        "name": "Italy",
        "states": [{
            "code": "01",
            "name": "Abruzzi"
        }, {
            "code": "02",
            "name": "Basilicata"
        }, {
            "code": "03",
            "name": "Calabria"
        }, {
            "code": "04",
            "name": "Campania"
        }, {
            "code": "05",
            "name": "Emilia-Romagna"
        }, {
            "code": "06",
            "name": "Friuli-Venezia Giulia"
        }, {
            "code": "07",
            "name": "Lazio"
        }, {
            "code": "08",
            "name": "Liguria"
        }, {
            "code": "09",
            "name": "Lombardia"
        }, {
            "code": "10",
            "name": "Marche"
        }, {
            "code": "11",
            "name": "Molise"
        }, {
            "code": "12",
            "name": "Piemonte"
        }, {
            "code": "13",
            "name": "Puglia"
        }, {
            "code": "14",
            "name": "Sardegna"
        }, {
            "code": "15",
            "name": "Sicilia"
        }, {
            "code": "16",
            "name": "Toscana"
        }, {
            "code": "17",
            "name": "Trentino-Alto Adige"
        }, {
            "code": "18",
            "name": "Umbria"
        }, {
            "code": "19",
            "name": "Valle d'Aosta"
        }, {
            "code": "20",
            "name": "Veneto"
        }]
    }, {
        "code": "JM",
        "name": "Jamaica",
        "states": [{
            "code": "01",
            "name": "Clarendon"
        }, {
            "code": "02",
            "name": "Hanover"
        }, {
            "code": "04",
            "name": "Manchester"
        }, {
            "code": "07",
            "name": "Portland"
        }, {
            "code": "08",
            "name": "Saint Andrew"
        }, {
            "code": "09",
            "name": "Saint Ann"
        }, {
            "code": "10",
            "name": "Saint Catherine"
        }, {
            "code": "11",
            "name": "Saint Elizabeth"
        }, {
            "code": "12",
            "name": "Saint James"
        }, {
            "code": "13",
            "name": "Saint Mary"
        }, {
            "code": "14",
            "name": "Saint Thomas"
        }, {
            "code": "15",
            "name": "Trelawny"
        }, {
            "code": "16",
            "name": "Westmoreland"
        }, {
            "code": "17",
            "name": "Kingston"
        }]
    }, {
        "code": "JP",
        "name": "Japan",
        "states": [{
            "code": "01",
            "name": "Aichi"
        }, {
            "code": "02",
            "name": "Akita"
        }, {
            "code": "03",
            "name": "Aomori"
        }, {
            "code": "04",
            "name": "Chiba"
        }, {
            "code": "05",
            "name": "Ehime"
        }, {
            "code": "06",
            "name": "Fukui"
        }, {
            "code": "07",
            "name": "Fukuoka"
        }, {
            "code": "08",
            "name": "Fukushima"
        }, {
            "code": "09",
            "name": "Gifu"
        }, {
            "code": "10",
            "name": "Gumma"
        }, {
            "code": "11",
            "name": "Hiroshima"
        }, {
            "code": "12",
            "name": "Hokkaido"
        }, {
            "code": "13",
            "name": "Hyogo"
        }, {
            "code": "14",
            "name": "Ibaraki"
        }, {
            "code": "15",
            "name": "Ishikawa"
        }, {
            "code": "16",
            "name": "Iwate"
        }, {
            "code": "17",
            "name": "Kagawa"
        }, {
            "code": "18",
            "name": "Kagoshima"
        }, {
            "code": "19",
            "name": "Kanagawa"
        }, {
            "code": "20",
            "name": "Kochi"
        }, {
            "code": "21",
            "name": "Kumamoto"
        }, {
            "code": "22",
            "name": "Kyoto"
        }, {
            "code": "23",
            "name": "Mie"
        }, {
            "code": "24",
            "name": "Miyagi"
        }, {
            "code": "25",
            "name": "Miyazaki"
        }, {
            "code": "26",
            "name": "Nagano"
        }, {
            "code": "27",
            "name": "Nagasaki"
        }, {
            "code": "28",
            "name": "Nara"
        }, {
            "code": "29",
            "name": "Niigata"
        }, {
            "code": "30",
            "name": "Oita"
        }, {
            "code": "31",
            "name": "Okayama"
        }, {
            "code": "32",
            "name": "Osaka"
        }, {
            "code": "33",
            "name": "Saga"
        }, {
            "code": "34",
            "name": "Saitama"
        }, {
            "code": "35",
            "name": "Shiga"
        }, {
            "code": "36",
            "name": "Shimane"
        }, {
            "code": "37",
            "name": "Shizuoka"
        }, {
            "code": "38",
            "name": "Tochigi"
        }, {
            "code": "39",
            "name": "Tokushima"
        }, {
            "code": "40",
            "name": "Tokyo"
        }, {
            "code": "41",
            "name": "Tottori"
        }, {
            "code": "42",
            "name": "Toyama"
        }, {
            "code": "43",
            "name": "Wakayama"
        }, {
            "code": "44",
            "name": "Yamagata"
        }, {
            "code": "45",
            "name": "Yamaguchi"
        }, {
            "code": "46",
            "name": "Yamanashi"
        }, {
            "code": "47",
            "name": "Okinawa"
        }]
    }, {
        "code": "JE",
        "name": "Jersey"
    }, {
        "code": "JO",
        "name": "Jordan",
        "states": [{
            "code": "02",
            "name": "Al Balqa'"
        }, {
            "code": "07",
            "name": "Ma"
        }, {
            "code": "09",
            "name": "Al Karak"
        }, {
            "code": "10",
            "name": "Al Mafraq"
        }, {
            "code": "12",
            "name": "At Tafilah"
        }, {
            "code": "13",
            "name": "Az Zarqa"
        }, {
            "code": "14",
            "name": "Irbid"
        }, {
            "code": "16",
            "name": "Amman"
        }]
    }, {
        "code": "KZ",
        "name": "Kazakhstan",
        "states": [{
            "code": "01",
            "name": "Almaty"
        }, {
            "code": "02",
            "name": "Almaty City"
        }, {
            "code": "03",
            "name": "Aqmola"
        }, {
            "code": "04",
            "name": "Aktobe"
        }, {
            "code": "05",
            "name": "Astana"
        }, {
            "code": "06",
            "name": "Atyrau"
        }, {
            "code": "07",
            "name": "West Kazakhstan"
        }, {
            "code": "08",
            "name": "Bayqonyr"
        }, {
            "code": "09",
            "name": "Mangghystau"
        }, {
            "code": "10",
            "name": "South Kazakhstan"
        }, {
            "code": "11",
            "name": "Pavlodar"
        }, {
            "code": "12",
            "name": "Qaraghandy"
        }, {
            "code": "13",
            "name": "Qostanay"
        }, {
            "code": "14",
            "name": "Qyzylorda"
        }, {
            "code": "15",
            "name": "East Kazakhstan"
        }, {
            "code": "16",
            "name": "North Kazakhstan"
        }, {
            "code": "17",
            "name": "Zhambyl"
        }]
    }, {
        "code": "KE",
        "name": "Kenya",
        "states": [{
            "code": "01",
            "name": "Central"
        }, {
            "code": "02",
            "name": "Coast"
        }, {
            "code": "03",
            "name": "Eastern"
        }, {
            "code": "05",
            "name": "Nairobi Area"
        }, {
            "code": "06",
            "name": "North-Eastern"
        }, {
            "code": "07",
            "name": "Nyanza"
        }, {
            "code": "08",
            "name": "Rift Valley"
        }, {
            "code": "09",
            "name": "Western"
        }]
    }, {
        "code": "KI",
        "name": "Kiribati",
        "states": [{
            "code": "01",
            "name": "Gilbert Islands"
        }, {
            "code": "02",
            "name": "Line Islands"
        }, {
            "code": "03",
            "name": "Phoenix Islands"
        }]
    }, {
        "code": "KP",
        "name": "Korea, Democratic People's Republic of"
    }, {
        "code": "KR",
        "name": "Korea, Republic of",
        "states": [{
            "code": "01",
            "name": "Cheju-do"
        }, {
            "code": "03",
            "name": "Cholla-bukto"
        }, {
            "code": "05",
            "name": "Ch'ungch'ong-bukto"
        }, {
            "code": "06",
            "name": "Kangwon-do"
        }, {
            "code": "10",
            "name": "Pusan-jikhalsi"
        }, {
            "code": "11",
            "name": "Seoul-t'ukpyolsi"
        }, {
            "code": "12",
            "name": "Inch'on-jikhalsi"
        }, {
            "code": "13",
            "name": "Kyonggi-do"
        }, {
            "code": "14",
            "name": "Kyongsang-bukto"
        }, {
            "code": "15",
            "name": "Taegu-jikhalsi"
        }, {
            "code": "16",
            "name": "Cholla-namdo"
        }, {
            "code": "17",
            "name": "Ch'ungch'ong-namdo"
        }, {
            "code": "18",
            "name": "Kwangju-jikhalsi"
        }, {
            "code": "19",
            "name": "Taejon-jikhalsi"
        }, {
            "code": "20",
            "name": "Kyongsang-namdo"
        }, {
            "code": "21",
            "name": "Ulsan-gwangyoksi"
        }]
    }, {
        "code": "XK",
        "name": "Kosovo"
    }, {
        "code": "KW",
        "name": "Kuwait",
        "states": [{
            "code": "01",
            "name": "Al Ahmadi"
        }, {
            "code": "02",
            "name": "Al Kuwayt"
        }, {
            "code": "03",
            "name": "Hawalli"
        }, {
            "code": "05",
            "name": "Al Jahra"
        }]
    }, {
        "code": "KG",
        "name": "Kyrgyzstan",
        "states": [{
            "code": "01",
            "name": "Bishkek"
        }, {
            "code": "02",
            "name": "Chuy"
        }, {
            "code": "03",
            "name": "Jalal-Abad"
        }, {
            "code": "04",
            "name": "Naryn"
        }, {
            "code": "06",
            "name": "Talas"
        }, {
            "code": "07",
            "name": "Ysyk-Kol"
        }, {
            "code": "08",
            "name": "Osh"
        }, {
            "code": "09",
            "name": "Batken"
        }]
    }, {
        "code": "LA",
        "name": "Laos",
        "states": [{
            "code": "01",
            "name": "Attapu"
        }, {
            "code": "02",
            "name": "Champasak"
        }, {
            "code": "03",
            "name": "Houaphan"
        }, {
            "code": "04",
            "name": "Khammouan"
        }, {
            "code": "05",
            "name": "Louang Namtha"
        }, {
            "code": "07",
            "name": "Oudomxai"
        }, {
            "code": "08",
            "name": "Phongsali"
        }, {
            "code": "09",
            "name": "Saravan"
        }, {
            "code": "10",
            "name": "Savannakhet"
        }, {
            "code": "11",
            "name": "Vientiane"
        }, {
            "code": "13",
            "name": "Xaignabouri"
        }, {
            "code": "14",
            "name": "Xiangkhoang"
        }, {
            "code": "17",
            "name": "Louangphrabang"
        }]
    }, {
        "code": "LV",
        "name": "Latvia",
        "states": [{
            "code": "01",
            "name": "Aizkraukles"
        }, {
            "code": "02",
            "name": "Aluksnes"
        }, {
            "code": "03",
            "name": "Balvu"
        }, {
            "code": "04",
            "name": "Bauskas"
        }, {
            "code": "05",
            "name": "Cesis"
        }, {
            "code": "06",
            "name": "Daugavpils"
        }, {
            "code": "07",
            "name": "Daugavpils"
        }, {
            "code": "08",
            "name": "Dobeles"
        }, {
            "code": "09",
            "name": "Gulbenes"
        }, {
            "code": "10",
            "name": "Jekabpils"
        }, {
            "code": "11",
            "name": "Jelgava"
        }, {
            "code": "12",
            "name": "Jelgavas"
        }, {
            "code": "13",
            "name": "Jurmala"
        }, {
            "code": "14",
            "name": "Kraslava"
        }, {
            "code": "15",
            "name": "Kuldigas"
        }, {
            "code": "16",
            "name": "Liepaja"
        }, {
            "code": "17",
            "name": "Liepajas"
        }, {
            "code": "18",
            "name": "Limbazu"
        }, {
            "code": "19",
            "name": "Ludzas"
        }, {
            "code": "20",
            "name": "Madonas"
        }, {
            "code": "21",
            "name": "Ogres"
        }, {
            "code": "22",
            "name": "Preilu"
        }, {
            "code": "23",
            "name": "Rezekne"
        }, {
            "code": "24",
            "name": "Rezeknes"
        }, {
            "code": "25",
            "name": "Riga"
        }, {
            "code": "26",
            "name": "Rigas"
        }, {
            "code": "27",
            "name": "Saldus"
        }, {
            "code": "28",
            "name": "Talsu"
        }, {
            "code": "29",
            "name": "Tukuma"
        }, {
            "code": "30",
            "name": "Valkas"
        }, {
            "code": "31",
            "name": "Valmieras"
        }, {
            "code": "32",
            "name": "Ventspils"
        }, {
            "code": "33",
            "name": "Ventspils"
        }]
    }, {
        "code": "LB",
        "name": "Lebanon",
        "states": [{
            "code": "01",
            "name": "Beqaa"
        }, {
            "code": "03",
            "name": "Liban-Nord"
        }, {
            "code": "04",
            "name": "Beyrouth"
        }, {
            "code": "05",
            "name": "Mont-Liban"
        }, {
            "code": "06",
            "name": "Liban-Sud"
        }, {
            "code": "07",
            "name": "Nabatiye"
        }]
    }, {
        "code": "LS",
        "name": "Lesotho",
        "states": [{
            "code": "10",
            "name": "Berea"
        }, {
            "code": "11",
            "name": "Butha-Buthe"
        }, {
            "code": "12",
            "name": "Leribe"
        }, {
            "code": "13",
            "name": "Mafeteng"
        }, {
            "code": "14",
            "name": "Maseru"
        }, {
            "code": "15",
            "name": "Mohales Hoek"
        }, {
            "code": "16",
            "name": "Mokhotlong"
        }, {
            "code": "17",
            "name": "Qachas Nek"
        }, {
            "code": "18",
            "name": "Quthing"
        }, {
            "code": "19",
            "name": "Thaba-Tseka"
        }]
    }, {
        "code": "LR",
        "name": "Liberia",
        "states": [{
            "code": "01",
            "name": "Bong"
        }, {
            "code": "02",
            "name": "Grand Jide"
        }, {
            "code": "04",
            "name": "Grand Cape Mount"
        }, {
            "code": "05",
            "name": "Lofa"
        }, {
            "code": "06",
            "name": "Maryland"
        }, {
            "code": "07",
            "name": "Monrovia"
        }, {
            "code": "09",
            "name": "Nimba"
        }, {
            "code": "10",
            "name": "Sino"
        }, {
            "code": "11",
            "name": "Grand Bassa"
        }, {
            "code": "14",
            "name": "Montserrado"
        }]
    }, {
        "code": "LY",
        "name": "Libya",
        "states": [{
            "code": "03",
            "name": "Al"
        }, {
            "code": "05",
            "name": "Al Jufrah"
        }, {
            "code": "08",
            "name": "Al Kufrah"
        }, {
            "code": "13",
            "name": "Ash Shati'"
        }, {
            "code": "30",
            "name": "Murzuq"
        }, {
            "code": "34",
            "name": "Sabha"
        }, {
            "code": "41",
            "name": "Tarhunah"
        }, {
            "code": "42",
            "name": "Tubruq"
        }, {
            "code": "45",
            "name": "Zlitan"
        }, {
            "code": "47",
            "name": "Ajdabiya"
        }, {
            "code": "48",
            "name": "Al Fatih"
        }, {
            "code": "49",
            "name": "Al Jabal al Akhdar"
        }, {
            "code": "50",
            "name": "Al Khums"
        }, {
            "code": "51",
            "name": "An Nuqat al Khams"
        }, {
            "code": "52",
            "name": "Awbari"
        }, {
            "code": "53",
            "name": "Az Zawiyah"
        }, {
            "code": "54",
            "name": "Banghazi"
        }, {
            "code": "55",
            "name": "Darnah"
        }, {
            "code": "56",
            "name": "Ghadamis"
        }, {
            "code": "57",
            "name": "Gharyan"
        }, {
            "code": "58",
            "name": "Misratah"
        }, {
            "code": "59",
            "name": "Sawfajjin"
        }, {
            "code": "60",
            "name": "Surt"
        }, {
            "code": "61",
            "name": "Tarabulus"
        }, {
            "code": "62",
            "name": "Yafran"
        }]
    }, {
        "code": "LI",
        "name": "Liechtenstein",
        "states": [{
            "code": "01",
            "name": "Balzers"
        }, {
            "code": "02",
            "name": "Eschen"
        }, {
            "code": "03",
            "name": "Gamprin"
        }, {
            "code": "04",
            "name": "Mauren"
        }, {
            "code": "05",
            "name": "Planken"
        }, {
            "code": "06",
            "name": "Ruggell"
        }, {
            "code": "07",
            "name": "Schaan"
        }, {
            "code": "08",
            "name": "Schellenberg"
        }, {
            "code": "09",
            "name": "Triesen"
        }, {
            "code": "10",
            "name": "Triesenberg"
        }, {
            "code": "11",
            "name": "Vaduz"
        }]
    }, {
        "code": "LT",
        "name": "Lithuania",
        "states": [{
            "code": "56",
            "name": "Alytaus Apskritis"
        }, {
            "code": "57",
            "name": "Kauno Apskritis"
        }, {
            "code": "58",
            "name": "Klaipedos Apskritis"
        }, {
            "code": "59",
            "name": "Marijampoles Apskritis"
        }, {
            "code": "60",
            "name": "Panevezio Apskritis"
        }, {
            "code": "61",
            "name": "Siauliu Apskritis"
        }, {
            "code": "62",
            "name": "Taurages Apskritis"
        }, {
            "code": "63",
            "name": "Telsiu Apskritis"
        }, {
            "code": "64",
            "name": "Utenos Apskritis"
        }, {
            "code": "65",
            "name": "Vilniaus Apskritis"
        }]
    }, {
        "code": "LU",
        "name": "Luxembourg",
        "states": [{
            "code": "01",
            "name": "Diekirch"
        }, {
            "code": "02",
            "name": "Grevenmacher"
        }, {
            "code": "03",
            "name": "Luxembourg"
        }]
    }, {
        "code": "MO",
        "name": "Macau",
        "states": [{
            "code": "01",
            "name": "Ilhas"
        }, {
            "code": "02",
            "name": "Macau"
        }]
    }, {
        "code": "MK",
        "name": "Macedonia, The Former Yugoslav Republic of"
    }, {
        "code": "MG",
        "name": "Madagascar",
        "states": [{
            "code": "01",
            "name": "Antsiranana"
        }, {
            "code": "02",
            "name": "Fianarantsoa"
        }, {
            "code": "03",
            "name": "Mahajanga"
        }, {
            "code": "04",
            "name": "Toamasina"
        }, {
            "code": "05",
            "name": "Antananarivo"
        }, {
            "code": "06",
            "name": "Toliara"
        }]
    }, {
        "code": "MW",
        "name": "Malawi",
        "states": [{
            "code": "02",
            "name": "Chikwawa"
        }, {
            "code": "03",
            "name": "Chiradzulu"
        }, {
            "code": "04",
            "name": "Chitipa"
        }, {
            "code": "05",
            "name": "Thyolo"
        }, {
            "code": "06",
            "name": "Dedza"
        }, {
            "code": "07",
            "name": "Dowa"
        }, {
            "code": "08",
            "name": "Karonga"
        }, {
            "code": "09",
            "name": "Kasungu"
        }, {
            "code": "11",
            "name": "Lilongwe"
        }, {
            "code": "12",
            "name": "Mangochi"
        }, {
            "code": "13",
            "name": "Mchinji"
        }, {
            "code": "15",
            "name": "Mzimba"
        }, {
            "code": "16",
            "name": "Ntcheu"
        }, {
            "code": "17",
            "name": "Nkhata Bay"
        }, {
            "code": "18",
            "name": "Nkhotakota"
        }, {
            "code": "19",
            "name": "Nsanje"
        }, {
            "code": "20",
            "name": "Ntchisi"
        }, {
            "code": "21",
            "name": "Rumphi"
        }, {
            "code": "22",
            "name": "Salima"
        }, {
            "code": "23",
            "name": "Zomba"
        }, {
            "code": "24",
            "name": "Blantyre"
        }, {
            "code": "25",
            "name": "Mwanza"
        }, {
            "code": "26",
            "name": "Balaka"
        }, {
            "code": "27",
            "name": "Likoma"
        }, {
            "code": "28",
            "name": "Machinga"
        }, {
            "code": "29",
            "name": "Mulanje"
        }, {
            "code": "30",
            "name": "Phalombe"
        }]
    }, {
        "code": "MY",
        "name": "Malaysia",
        "states": [{
            "code": "01",
            "name": "Johor"
        }, {
            "code": "02",
            "name": "Kedah"
        }, {
            "code": "03",
            "name": "Kelantan"
        }, {
            "code": "04",
            "name": "Melaka"
        }, {
            "code": "05",
            "name": "Negeri Sembilan"
        }, {
            "code": "06",
            "name": "Pahang"
        }, {
            "code": "07",
            "name": "Perak"
        }, {
            "code": "08",
            "name": "Perlis"
        }, {
            "code": "09",
            "name": "Pulau Pinang"
        }, {
            "code": "11",
            "name": "Sarawak"
        }, {
            "code": "12",
            "name": "Selangor"
        }, {
            "code": "13",
            "name": "Terengganu"
        }, {
            "code": "14",
            "name": "Wilayah Persekutuan"
        }, {
            "code": "15",
            "name": "Labuan"
        }, {
            "code": "16",
            "name": "Sabah"
        }]
    }, {
        "code": "MV",
        "name": "Maldives",
        "states": [{
            "code": "01",
            "name": "Seenu"
        }, {
            "code": "02",
            "name": "Aliff"
        }, {
            "code": "03",
            "name": "Laviyani"
        }, {
            "code": "04",
            "name": "Waavu"
        }, {
            "code": "05",
            "name": "Laamu"
        }, {
            "code": "07",
            "name": "Haa Aliff"
        }, {
            "code": "08",
            "name": "Thaa"
        }, {
            "code": "12",
            "name": "Meemu"
        }, {
            "code": "13",
            "name": "Raa"
        }, {
            "code": "14",
            "name": "Faafu"
        }, {
            "code": "17",
            "name": "Daalu"
        }, {
            "code": "20",
            "name": "Baa"
        }, {
            "code": "23",
            "name": "Haa Daalu"
        }, {
            "code": "24",
            "name": "Shaviyani"
        }, {
            "code": "25",
            "name": "Noonu"
        }, {
            "code": "26",
            "name": "Kaafu"
        }, {
            "code": "27",
            "name": "Gaafu Aliff"
        }, {
            "code": "28",
            "name": "Gaafu Daalu"
        }, {
            "code": "29",
            "name": "Naviyani"
        }]
    }, {
        "code": "ML",
        "name": "Mali",
        "states": [{
            "code": "01",
            "name": "Bamako"
        }, {
            "code": "03",
            "name": "Kayes"
        }, {
            "code": "04",
            "name": "Mopti"
        }, {
            "code": "05",
            "name": "Segou"
        }, {
            "code": "06",
            "name": "Sikasso"
        }, {
            "code": "07",
            "name": "Koulikoro"
        }, {
            "code": "08",
            "name": "Tombouctou"
        }, {
            "code": "09",
            "name": "Gao"
        }, {
            "code": "10",
            "name": "Kidal"
        }]
    }, {
        "code": "MT",
        "name": "Malta"
    }, {
        "code": "MH",
        "name": "Marshall Islands"
    }, {
        "code": "MQ",
        "name": "Martinique"
    }, {
        "code": "MR",
        "name": "Mauritania",
        "states": [{
            "code": "01",
            "name": "Hodh Ech Chargui"
        }, {
            "code": "02",
            "name": "Hodh El Gharbi"
        }, {
            "code": "03",
            "name": "Assaba"
        }, {
            "code": "04",
            "name": "Gorgol"
        }, {
            "code": "05",
            "name": "Brakna"
        }, {
            "code": "06",
            "name": "Trarza"
        }, {
            "code": "07",
            "name": "Adrar"
        }, {
            "code": "08",
            "name": "Dakhlet Nouadhibou"
        }, {
            "code": "09",
            "name": "Tagant"
        }, {
            "code": "10",
            "name": "Guidimaka"
        }, {
            "code": "11",
            "name": "Tiris Zemmour"
        }, {
            "code": "12",
            "name": "Inchiri"
        }]
    }, {
        "code": "MU",
        "name": "Mauritius",
        "states": [{
            "code": "12",
            "name": "Black River"
        }, {
            "code": "13",
            "name": "Flacq"
        }, {
            "code": "14",
            "name": "Grand Port"
        }, {
            "code": "15",
            "name": "Moka"
        }, {
            "code": "16",
            "name": "Pamplemousses"
        }, {
            "code": "17",
            "name": "Plaines Wilhems"
        }, {
            "code": "18",
            "name": "Port Louis"
        }, {
            "code": "19",
            "name": "Riviere du Rempart"
        }, {
            "code": "20",
            "name": "Savanne"
        }, {
            "code": "21",
            "name": "Agalega Islands"
        }, {
            "code": "22",
            "name": "Cargados Carajos"
        }, {
            "code": "23",
            "name": "Rodrigues"
        }]
    }, {
        "code": "YT",
        "name": "Mayotte"
    }, {
        "code": "MX",
        "name": "Mexico",
        "states": [{
            "code": "01",
            "name": "Aguascalientes"
        }, {
            "code": "02",
            "name": "Baja California"
        }, {
            "code": "03",
            "name": "Baja California Sur"
        }, {
            "code": "04",
            "name": "Campeche"
        }, {
            "code": "05",
            "name": "Chiapas"
        }, {
            "code": "06",
            "name": "Chihuahua"
        }, {
            "code": "07",
            "name": "Coahuila de Zaragoza"
        }, {
            "code": "08",
            "name": "Colima"
        }, {
            "code": "09",
            "name": "Distrito Federal"
        }, {
            "code": "10",
            "name": "Durango"
        }, {
            "code": "11",
            "name": "Guanajuato"
        }, {
            "code": "12",
            "name": "Guerrero"
        }, {
            "code": "13",
            "name": "Hidalgo"
        }, {
            "code": "14",
            "name": "Jalisco"
        }, {
            "code": "15",
            "name": "Mexico"
        }, {
            "code": "16",
            "name": "Michoacan de Ocampo"
        }, {
            "code": "17",
            "name": "Morelos"
        }, {
            "code": "18",
            "name": "Nayarit"
        }, {
            "code": "19",
            "name": "Nuevo Leon"
        }, {
            "code": "20",
            "name": "Oaxaca"
        }, {
            "code": "21",
            "name": "Puebla"
        }, {
            "code": "22",
            "name": "Queretaro de Arteaga"
        }, {
            "code": "23",
            "name": "Quintana Roo"
        }, {
            "code": "24",
            "name": "San Luis Potosi"
        }, {
            "code": "25",
            "name": "Sinaloa"
        }, {
            "code": "26",
            "name": "Sonora"
        }, {
            "code": "27",
            "name": "Tabasco"
        }, {
            "code": "28",
            "name": "Tamaulipas"
        }, {
            "code": "29",
            "name": "Tlaxcala"
        }, {
            "code": "30",
            "name": "Veracruz-Llave"
        }, {
            "code": "31",
            "name": "Yucatan"
        }, {
            "code": "32",
            "name": "Zacatecas"
        }]
    }, {
        "code": "FM",
        "name": "Micronesia",
        "states": [{
            "code": "01",
            "name": "Kosrae"
        }, {
            "code": "02",
            "name": "Pohnpei"
        }, {
            "code": "03",
            "name": "Chuuk"
        }, {
            "code": "04",
            "name": "Yap"
        }]
    }, {
        "code": "MD",
        "name": "Moldova, Republic of",
        "states": [{
            "code": "46",
            "name": "Balti"
        }, {
            "code": "47",
            "name": "Cahul"
        }, {
            "code": "48",
            "name": "Chisinau"
        }, {
            "code": "49",
            "name": "Stinga Nistrului"
        }, {
            "code": "50",
            "name": "Edinet"
        }, {
            "code": "51",
            "name": "Gagauzia"
        }, {
            "code": "52",
            "name": "Lapusna"
        }, {
            "code": "53",
            "name": "Orhei"
        }, {
            "code": "54",
            "name": "Soroca"
        }, {
            "code": "55",
            "name": "Tighina"
        }, {
            "code": "56",
            "name": "Ungheni"
        }]
    }, {
        "code": "MC",
        "name": "Monaco",
        "states": [{
            "code": "01",
            "name": "La Condamine"
        }, {
            "code": "02",
            "name": "Monaco"
        }, {
            "code": "03",
            "name": "Monte-Carlo"
        }]
    }, {
        "code": "MN",
        "name": "Mongolia",
        "states": [{
            "code": "01",
            "name": "Arhangay"
        }, {
            "code": "02",
            "name": "Bayanhongor"
        }, {
            "code": "03",
            "name": "Bayan-Olgiy"
        }, {
            "code": "05",
            "name": "Darhan"
        }, {
            "code": "06",
            "name": "Dornod"
        }, {
            "code": "07",
            "name": "Dornogovi"
        }, {
            "code": "08",
            "name": "Dundgovi"
        }, {
            "code": "09",
            "name": "Dzavhan"
        }, {
            "code": "10",
            "name": "Govi-Altay"
        }, {
            "code": "11",
            "name": "Hentiy"
        }, {
            "code": "12",
            "name": "Hovd"
        }, {
            "code": "13",
            "name": "Hovsgol"
        }, {
            "code": "14",
            "name": "Omnogovi"
        }, {
            "code": "15",
            "name": "Ovorhangay"
        }, {
            "code": "16",
            "name": "Selenge"
        }, {
            "code": "17",
            "name": "Suhbaatar"
        }, {
            "code": "18",
            "name": "Tov"
        }, {
            "code": "19",
            "name": "Uvs"
        }, {
            "code": "20",
            "name": "Ulaanbaatar"
        }, {
            "code": "21",
            "name": "Bulgan"
        }, {
            "code": "22",
            "name": "Erdenet"
        }, {
            "code": "23",
            "name": "Darhan Uul"
        }, {
            "code": "24",
            "name": "Govi-Sumber"
        }, {
            "code": "25",
            "name": "Orhon"
        }]
    }, {
        "code": "MS",
        "name": "Monserrat",
        "states": [{
            "code": "01",
            "name": "Saint Anthony"
        }, {
            "code": "02",
            "name": "Saint Georges"
        }, {
            "code": "03",
            "name": "Saint Peter"
        }]
    }, {
        "code": "ME",
        "name": "Montenegro"
    }, {
        "code": "MA",
        "name": "Morocco",
        "states": [{
            "code": "01",
            "name": "Agadir"
        }, {
            "code": "02",
            "name": "Al Hoceima"
        }, {
            "code": "03",
            "name": "Azilal"
        }, {
            "code": "04",
            "name": "Ben Slimane"
        }, {
            "code": "05",
            "name": "Beni Mellal"
        }, {
            "code": "06",
            "name": "Boulemane"
        }, {
            "code": "07",
            "name": "Casablanca"
        }, {
            "code": "08",
            "name": "Chaouen"
        }, {
            "code": "09",
            "name": "El Jadida"
        }, {
            "code": "10",
            "name": "El Kelaa des Srarhna"
        }, {
            "code": "11",
            "name": "Er Rachidia"
        }, {
            "code": "12",
            "name": "Essaouira"
        }, {
            "code": "13",
            "name": "Fes"
        }, {
            "code": "14",
            "name": "Figuig"
        }, {
            "code": "15",
            "name": "Kenitra"
        }, {
            "code": "16",
            "name": "Khemisset"
        }, {
            "code": "17",
            "name": "Khenifra"
        }, {
            "code": "18",
            "name": "Khouribga"
        }, {
            "code": "19",
            "name": "Marrakech"
        }, {
            "code": "20",
            "name": "Meknes"
        }, {
            "code": "21",
            "name": "Nador"
        }, {
            "code": "22",
            "name": "Ouarzazate"
        }, {
            "code": "23",
            "name": "Oujda"
        }, {
            "code": "24",
            "name": "Rabat-Sale"
        }, {
            "code": "25",
            "name": "Safi"
        }, {
            "code": "26",
            "name": "Settat"
        }, {
            "code": "27",
            "name": "Tanger"
        }, {
            "code": "29",
            "name": "Tata"
        }, {
            "code": "30",
            "name": "Taza"
        }, {
            "code": "32",
            "name": "Tiznit"
        }, {
            "code": "33",
            "name": "Guelmim"
        }, {
            "code": "34",
            "name": "Ifrane"
        }, {
            "code": "35",
            "name": "Laayoune"
        }, {
            "code": "36",
            "name": "Tan-Tan"
        }, {
            "code": "37",
            "name": "Taounate"
        }, {
            "code": "38",
            "name": "Sidi Kacem"
        }, {
            "code": "39",
            "name": "Taroudannt"
        }, {
            "code": "40",
            "name": "Tetouan"
        }, {
            "code": "41",
            "name": "Larache"
        }]
    }, {
        "code": "MZ",
        "name": "Mozambique",
        "states": [{
            "code": "01",
            "name": "Cabo Delgado"
        }, {
            "code": "02",
            "name": "Gaza"
        }, {
            "code": "03",
            "name": "Inhambane"
        }, {
            "code": "04",
            "name": "Maputo"
        }, {
            "code": "05",
            "name": "Sofala"
        }, {
            "code": "06",
            "name": "Nampula"
        }, {
            "code": "07",
            "name": "Niassa"
        }, {
            "code": "08",
            "name": "Tete"
        }, {
            "code": "09",
            "name": "Zambezia"
        }, {
            "code": "10",
            "name": "Manica"
        }]
    }, {
        "code": "MM",
        "name": "Myanmar",
        "states": [{
            "code": "01",
            "name": "Rakhine State"
        }, {
            "code": "02",
            "name": "Chin State"
        }, {
            "code": "03",
            "name": "Irrawaddy"
        }, {
            "code": "04",
            "name": "Kachin State"
        }, {
            "code": "05",
            "name": "Karan State"
        }, {
            "code": "06",
            "name": "Kayah State"
        }, {
            "code": "07",
            "name": "Magwe"
        }, {
            "code": "08",
            "name": "Mandalay"
        }, {
            "code": "09",
            "name": "Pegu"
        }, {
            "code": "10",
            "name": "Sagaing"
        }, {
            "code": "11",
            "name": "Shan State"
        }, {
            "code": "12",
            "name": "Tenasserim"
        }, {
            "code": "13",
            "name": "Mon State"
        }, {
            "code": "14",
            "name": "Rangoon"
        }, {
            "code": "17",
            "name": "Yangon"
        }]
    }, {
        "code": "NA",
        "name": "Namibia",
        "states": [{
            "code": "01",
            "name": "Bethanien"
        }, {
            "code": "02",
            "name": "Caprivi Oos"
        }, {
            "code": "03",
            "name": "Boesmanland"
        }, {
            "code": "04",
            "name": "Gobabis"
        }, {
            "code": "05",
            "name": "Grootfontein"
        }, {
            "code": "06",
            "name": "Kaokoland"
        }, {
            "code": "07",
            "name": "Karibib"
        }, {
            "code": "08",
            "name": "Keetmanshoop"
        }, {
            "code": "09",
            "name": "Luderitz"
        }, {
            "code": "10",
            "name": "Maltahohe"
        }, {
            "code": "11",
            "name": "Okahandja"
        }, {
            "code": "12",
            "name": "Omaruru"
        }, {
            "code": "13",
            "name": "Otjiwarongo"
        }, {
            "code": "14",
            "name": "Outjo"
        }, {
            "code": "15",
            "name": "Owambo"
        }, {
            "code": "16",
            "name": "Rehoboth"
        }, {
            "code": "17",
            "name": "Swakopmund"
        }, {
            "code": "18",
            "name": "Tsumeb"
        }, {
            "code": "20",
            "name": "Karasburg"
        }, {
            "code": "21",
            "name": "Windhoek"
        }, {
            "code": "22",
            "name": "Damaraland"
        }, {
            "code": "23",
            "name": "Hereroland Oos"
        }, {
            "code": "24",
            "name": "Hereroland Wes"
        }, {
            "code": "25",
            "name": "Kavango"
        }, {
            "code": "26",
            "name": "Mariental"
        }, {
            "code": "27",
            "name": "Namaland"
        }, {
            "code": "28",
            "name": "Caprivi"
        }, {
            "code": "29",
            "name": "Erongo"
        }, {
            "code": "30",
            "name": "Hardap"
        }, {
            "code": "31",
            "name": "Karas"
        }, {
            "code": "32",
            "name": "Kunene"
        }, {
            "code": "33",
            "name": "Ohangwena"
        }, {
            "code": "34",
            "name": "Okavango"
        }, {
            "code": "35",
            "name": "Omaheke"
        }, {
            "code": "36",
            "name": "Omusati"
        }, {
            "code": "37",
            "name": "Oshana"
        }, {
            "code": "38",
            "name": "Oshikoto"
        }, {
            "code": "39",
            "name": "Otjozondjupa"
        }]
    }, {
        "code": "NR",
        "name": "Nauru",
        "states": [{
            "code": "01",
            "name": "Aiwo"
        }, {
            "code": "02",
            "name": "Anabar"
        }, {
            "code": "03",
            "name": "Anetan"
        }, {
            "code": "04",
            "name": "Anibare"
        }, {
            "code": "05",
            "name": "Baiti"
        }, {
            "code": "06",
            "name": "Boe"
        }, {
            "code": "07",
            "name": "Buada"
        }, {
            "code": "08",
            "name": "Denigomodu"
        }, {
            "code": "09",
            "name": "Ewa"
        }, {
            "code": "10",
            "name": "Ijuw"
        }, {
            "code": "11",
            "name": "Meneng"
        }, {
            "code": "12",
            "name": "Nibok"
        }, {
            "code": "13",
            "name": "Uaboe"
        }, {
            "code": "14",
            "name": "Yaren"
        }]
    }, {
        "code": "NP",
        "name": "Nepal",
        "states": [{
            "code": "01",
            "name": "Bagmati"
        }, {
            "code": "02",
            "name": "Bheri"
        }, {
            "code": "03",
            "name": "Dhawalagiri"
        }, {
            "code": "04",
            "name": "Gandaki"
        }, {
            "code": "05",
            "name": "Janakpur"
        }, {
            "code": "06",
            "name": "Karnali"
        }, {
            "code": "07",
            "name": "Kosi"
        }, {
            "code": "08",
            "name": "Lumbini"
        }, {
            "code": "09",
            "name": "Mahakali"
        }, {
            "code": "10",
            "name": "Mechi"
        }, {
            "code": "11",
            "name": "Narayani"
        }, {
            "code": "12",
            "name": "Rapti"
        }, {
            "code": "13",
            "name": "Sagarmatha"
        }, {
            "code": "14",
            "name": "Seti"
        }]
    }, {
        "code": "NL",
        "name": "Netherlands",
        "states": [{
            "code": "01",
            "name": "Drenthe"
        }, {
            "code": "02",
            "name": "Friesland"
        }, {
            "code": "03",
            "name": "Gelderland"
        }, {
            "code": "04",
            "name": "Groningen"
        }, {
            "code": "05",
            "name": "Limburg"
        }, {
            "code": "06",
            "name": "Noord-Brabant"
        }, {
            "code": "07",
            "name": "Noord-Holland"
        }, {
            "code": "08",
            "name": "Overijssel"
        }, {
            "code": "09",
            "name": "Utrecht"
        }, {
            "code": "10",
            "name": "Zeeland"
        }, {
            "code": "11",
            "name": "Zuid-Holland"
        }, {
            "code": "12",
            "name": "Dronten"
        }, {
            "code": "13",
            "name": "Zuidelijke IJsselmeerpolders"
        }, {
            "code": "14",
            "name": "Lelystad"
        }, {
            "code": "15",
            "name": "Overijssel"
        }, {
            "code": "16",
            "name": "Flevoland"
        }]
    }, {
        "code": "NC",
        "name": "New Caledonia"
    }, {
        "code": "NZ",
        "name": "New Zealand",
        "states": [{
            "code": "01",
            "name": "Akaroa"
        }, {
            "code": "03",
            "name": "Amuri"
        }, {
            "code": "04",
            "name": "Ashburton"
        }, {
            "code": "07",
            "name": "Bay of Islands"
        }, {
            "code": "08",
            "name": "Bruce"
        }, {
            "code": "09",
            "name": "Buller"
        }, {
            "code": "10",
            "name": "Chatham Islands"
        }, {
            "code": "11",
            "name": "Cheviot"
        }, {
            "code": "12",
            "name": "Clifton"
        }, {
            "code": "13",
            "name": "Clutha"
        }, {
            "code": "14",
            "name": "Cook"
        }, {
            "code": "16",
            "name": "Dannevirke"
        }, {
            "code": "17",
            "name": "Egmont"
        }, {
            "code": "18",
            "name": "Eketahuna"
        }, {
            "code": "19",
            "name": "Ellesmere"
        }, {
            "code": "20",
            "name": "Eltham"
        }, {
            "code": "21",
            "name": "Eyre"
        }, {
            "code": "22",
            "name": "Featherston"
        }, {
            "code": "24",
            "name": "Franklin"
        }, {
            "code": "26",
            "name": "Golden Bay"
        }, {
            "code": "27",
            "name": "Great Barrier Island"
        }, {
            "code": "28",
            "name": "Grey"
        }, {
            "code": "29",
            "name": "Hauraki Plains"
        }, {
            "code": "30",
            "name": "Hawera"
        }, {
            "code": "31",
            "name": "Hawke's Bay"
        }, {
            "code": "32",
            "name": "Heathcote"
        }, {
            "code": "33",
            "name": "Hobson"
        }, {
            "code": "34",
            "name": "Hokianga"
        }, {
            "code": "35",
            "name": "Horowhenua"
        }, {
            "code": "36",
            "name": "Hutt"
        }, {
            "code": "37",
            "name": "Inangahua"
        }, {
            "code": "38",
            "name": "Inglewood"
        }, {
            "code": "39",
            "name": "Kaikoura"
        }, {
            "code": "40",
            "name": "Kairanga"
        }, {
            "code": "41",
            "name": "Kiwitea"
        }, {
            "code": "43",
            "name": "Lake"
        }, {
            "code": "45",
            "name": "Mackenzie"
        }, {
            "code": "46",
            "name": "Malvern"
        }, {
            "code": "47",
            "name": "Manawatu"
        }, {
            "code": "48",
            "name": "Mangonui"
        }, {
            "code": "49",
            "name": "Maniototo"
        }, {
            "code": "50",
            "name": "Marlborough"
        }, {
            "code": "51",
            "name": "Masterton"
        }, {
            "code": "52",
            "name": "Matamata"
        }, {
            "code": "53",
            "name": "Mount Herbert"
        }, {
            "code": "54",
            "name": "Ohinemuri"
        }, {
            "code": "55",
            "name": "Opotiki"
        }, {
            "code": "56",
            "name": "Oroua"
        }, {
            "code": "57",
            "name": "Otamatea"
        }, {
            "code": "58",
            "name": "Otorohanga"
        }, {
            "code": "59",
            "name": "Oxford"
        }, {
            "code": "60",
            "name": "Pahiatua"
        }, {
            "code": "61",
            "name": "Paparua"
        }, {
            "code": "63",
            "name": "Patea"
        }, {
            "code": "65",
            "name": "Piako"
        }, {
            "code": "66",
            "name": "Pohangina"
        }, {
            "code": "67",
            "name": "Raglan"
        }, {
            "code": "68",
            "name": "Rangiora"
        }, {
            "code": "69",
            "name": "Rangitikei"
        }, {
            "code": "70",
            "name": "Rodney"
        }, {
            "code": "71",
            "name": "Rotorua"
        }, {
            "code": "72",
            "name": "Southland"
        }, {
            "code": "73",
            "name": "Stewart Island"
        }, {
            "code": "74",
            "name": "Stratford"
        }, {
            "code": "76",
            "name": "Taranaki"
        }, {
            "code": "77",
            "name": "Taumarunui"
        }, {
            "code": "78",
            "name": "Taupo"
        }, {
            "code": "79",
            "name": "Tauranga"
        }, {
            "code": "81",
            "name": "Tuapeka"
        }, {
            "code": "82",
            "name": "Vincent"
        }, {
            "code": "83",
            "name": "Waiapu"
        }, {
            "code": "84",
            "name": "Waihemo"
        }, {
            "code": "85",
            "name": "Waikato"
        }, {
            "code": "86",
            "name": "Waikohu"
        }, {
            "code": "88",
            "name": "Waimairi"
        }, {
            "code": "89",
            "name": "Waimarino"
        }, {
            "code": "90",
            "name": "Waimate"
        }, {
            "code": "91",
            "name": "Waimate West"
        }, {
            "code": "92",
            "name": "Waimea"
        }, {
            "code": "93",
            "name": "Waipa"
        }, {
            "code": "95",
            "name": "Waipawa"
        }, {
            "code": "96",
            "name": "Waipukurau"
        }, {
            "code": "97",
            "name": "Wairarapa South"
        }, {
            "code": "98",
            "name": "Wairewa"
        }, {
            "code": "99",
            "name": "Wairoa"
        }, {
            "code": "A1",
            "name": "Whangarei"
        }, {
            "code": "A2",
            "name": "Whangaroa"
        }, {
            "code": "A3",
            "name": "Woodville"
        }, {
            "code": "A4",
            "name": "Waitaki"
        }, {
            "code": "A6",
            "name": "Waitomo"
        }, {
            "code": "A8",
            "name": "Waitotara"
        }, {
            "code": "B2",
            "name": "Wanganui"
        }, {
            "code": "B3",
            "name": "Westland"
        }, {
            "code": "B4",
            "name": "Whakatane"
        }, {
            "code": "D4",
            "name": "Hurunui"
        }, {
            "code": "D5",
            "name": "Silverpeaks"
        }, {
            "code": "D6",
            "name": "Strathallan"
        }, {
            "code": "D8",
            "name": "Waiheke"
        }, {
            "code": "D9",
            "name": "Hikurangi"
        }, {
            "code": "E1",
            "name": "Manaia"
        }, {
            "code": "E2",
            "name": "Runanga"
        }, {
            "code": "E3",
            "name": "Saint Kilda"
        }, {
            "code": "E4",
            "name": "Thames-Coromandel"
        }, {
            "code": "E5",
            "name": "Waverley"
        }, {
            "code": "E6",
            "name": "Wallace"
        }]
    }, {
        "code": "NI",
        "name": "Nicaragua",
        "states": [{
            "code": "01",
            "name": "Boaco"
        }, {
            "code": "02",
            "name": "Carazo"
        }, {
            "code": "03",
            "name": "Chinandega"
        }, {
            "code": "04",
            "name": "Chontales"
        }, {
            "code": "05",
            "name": "Esteli"
        }, {
            "code": "06",
            "name": "Granada"
        }, {
            "code": "07",
            "name": "Jinotega"
        }, {
            "code": "08",
            "name": "Leon"
        }, {
            "code": "09",
            "name": "Madriz"
        }, {
            "code": "10",
            "name": "Managua"
        }, {
            "code": "11",
            "name": "Masaya"
        }, {
            "code": "12",
            "name": "Matagalpa"
        }, {
            "code": "13",
            "name": "Nueva Segovia"
        }, {
            "code": "14",
            "name": "Rio San Juan"
        }, {
            "code": "15",
            "name": "Rivas"
        }, {
            "code": "16",
            "name": "Zelaya"
        }]
    }, {
        "code": "NE",
        "name": "Niger",
        "states": [{
            "code": "01",
            "name": "Agadez"
        }, {
            "code": "02",
            "name": "Diffa"
        }, {
            "code": "03",
            "name": "Dosso"
        }, {
            "code": "04",
            "name": "Maradi"
        }, {
            "code": "05",
            "name": "Niamey"
        }, {
            "code": "06",
            "name": "Tahoua"
        }, {
            "code": "07",
            "name": "Zinder"
        }]
    }, {
        "code": "NG",
        "name": "Nigeria",
        "states": [{
            "code": "05",
            "name": "Lagos"
        }, {
            "code": "11",
            "name": "Abuja Capital Territory"
        }, {
            "code": "16",
            "name": "Ogun"
        }, {
            "code": "21",
            "name": "Akwa Ibom"
        }, {
            "code": "22",
            "name": "Cross River"
        }, {
            "code": "23",
            "name": "Kaduna"
        }, {
            "code": "24",
            "name": "Katsina"
        }, {
            "code": "25",
            "name": "Anambra"
        }, {
            "code": "26",
            "name": "Benue"
        }, {
            "code": "27",
            "name": "Borno"
        }, {
            "code": "28",
            "name": "Imo"
        }, {
            "code": "29",
            "name": "Kano"
        }, {
            "code": "30",
            "name": "Kwara"
        }, {
            "code": "31",
            "name": "Niger"
        }, {
            "code": "32",
            "name": "Oyo"
        }, {
            "code": "35",
            "name": "Adamawa"
        }, {
            "code": "36",
            "name": "Delta"
        }, {
            "code": "37",
            "name": "Edo"
        }, {
            "code": "39",
            "name": "Jigawa"
        }, {
            "code": "40",
            "name": "Kebbi"
        }, {
            "code": "41",
            "name": "Kogi"
        }, {
            "code": "42",
            "name": "Osun"
        }, {
            "code": "43",
            "name": "Taraba"
        }, {
            "code": "44",
            "name": "Yobe"
        }, {
            "code": "45",
            "name": "Abia"
        }, {
            "code": "46",
            "name": "Bauchi"
        }, {
            "code": "47",
            "name": "Enugu"
        }, {
            "code": "48",
            "name": "Ondo"
        }, {
            "code": "49",
            "name": "Plateau"
        }, {
            "code": "50",
            "name": "Rivers"
        }, {
            "code": "51",
            "name": "Sokoto"
        }, {
            "code": "52",
            "name": "Bayelsa"
        }, {
            "code": "53",
            "name": "Ebonyi"
        }, {
            "code": "54",
            "name": "Ekiti"
        }, {
            "code": "55",
            "name": "Gombe"
        }, {
            "code": "56",
            "name": "Nassarawa"
        }, {
            "code": "57",
            "name": "Zamfara"
        }]
    }, {
        "code": "NU",
        "name": "Niue"
    }, {
        "code": "NF",
        "name": "Norfolk Island"
    }, {
        "code": "MP",
        "name": "Northern Mariana Islands"
    }, {
        "code": "NO",
        "name": "Norway",
        "states": [{
            "code": "01",
            "name": "Akershus"
        }, {
            "code": "02",
            "name": "Aust-Agder"
        }, {
            "code": "04",
            "name": "Buskerud"
        }, {
            "code": "05",
            "name": "Finnmark"
        }, {
            "code": "06",
            "name": "Hedmark"
        }, {
            "code": "07",
            "name": "Hordaland"
        }, {
            "code": "08",
            "name": "More og Romsdal"
        }, {
            "code": "09",
            "name": "Nordland"
        }, {
            "code": "10",
            "name": "Nord-Trondelag"
        }, {
            "code": "11",
            "name": "Oppland"
        }, {
            "code": "12",
            "name": "Oslo"
        }, {
            "code": "13",
            "name": "Ostfold"
        }, {
            "code": "14",
            "name": "Rogaland"
        }, {
            "code": "15",
            "name": "Sogn og Fjordane"
        }, {
            "code": "16",
            "name": "Sor-Trondelag"
        }, {
            "code": "17",
            "name": "Telemark"
        }, {
            "code": "18",
            "name": "Troms"
        }, {
            "code": "19",
            "name": "Vest-Agder"
        }, {
            "code": "20",
            "name": "Vestfold"
        }]
    }, {
        "code": "OM",
        "name": "Oman",
        "states": [{
            "code": "01",
            "name": "Ad Dakhiliyah"
        }, {
            "code": "02",
            "name": "Al Batinah"
        }, {
            "code": "03",
            "name": "Al Wusta"
        }, {
            "code": "04",
            "name": "Ash Sharqiyah"
        }, {
            "code": "05",
            "name": "Az Zahirah"
        }, {
            "code": "06",
            "name": "Masqat"
        }, {
            "code": "07",
            "name": "Musandam"
        }, {
            "code": "08",
            "name": "Zufar"
        }]
    }, {
        "code": "PK",
        "name": "Pakistan",
        "states": [{
            "code": "01",
            "name": "Federally Administered Tribal Ar"
        }, {
            "code": "02",
            "name": "Balochistan"
        }, {
            "code": "03",
            "name": "North-West Frontier"
        }, {
            "code": "04",
            "name": "Punjab"
        }, {
            "code": "05",
            "name": "Sindh"
        }, {
            "code": "06",
            "name": "Azad Kashmir"
        }, {
            "code": "07",
            "name": "Northern Areas"
        }, {
            "code": "08",
            "name": "Islamabad"
        }]
    }, {
        "code": "PW",
        "name": "Palau"
    }, {
        "code": "PS",
        "name": "Palestinian Territory, Occupied"
    }, {
        "code": "PA",
        "name": "Panama",
        "states": [{
            "code": "01",
            "name": "Bocas del Toro"
        }, {
            "code": "02",
            "name": "Chiriqui"
        }, {
            "code": "03",
            "name": "Cocle"
        }, {
            "code": "04",
            "name": "Colon"
        }, {
            "code": "05",
            "name": "Darien"
        }, {
            "code": "06",
            "name": "Herrera"
        }, {
            "code": "07",
            "name": "Los Santos"
        }, {
            "code": "08",
            "name": "Panama"
        }, {
            "code": "09",
            "name": "San Blas"
        }, {
            "code": "10",
            "name": "Veraguas"
        }]
    }, {
        "code": "PG",
        "name": "Papua New Guinea",
        "states": [{
            "code": "01",
            "name": "Central"
        }, {
            "code": "02",
            "name": "Gulf"
        }, {
            "code": "03",
            "name": "Milne Bay"
        }, {
            "code": "04",
            "name": "Northern"
        }, {
            "code": "05",
            "name": "Southern Highlands"
        }, {
            "code": "06",
            "name": "Western"
        }, {
            "code": "07",
            "name": "North Solomons"
        }, {
            "code": "08",
            "name": "Chimbu"
        }, {
            "code": "09",
            "name": "Eastern Highlands"
        }, {
            "code": "10",
            "name": "East New Britain"
        }, {
            "code": "11",
            "name": "East Sepik"
        }, {
            "code": "12",
            "name": "Madang"
        }, {
            "code": "13",
            "name": "Manus"
        }, {
            "code": "14",
            "name": "Morobe"
        }, {
            "code": "15",
            "name": "New Ireland"
        }, {
            "code": "16",
            "name": "Western Highlands"
        }, {
            "code": "17",
            "name": "West New Britain"
        }, {
            "code": "18",
            "name": "Sandaun"
        }, {
            "code": "19",
            "name": "Enga"
        }, {
            "code": "20",
            "name": "National Capital"
        }]
    }, {
        "code": "PY",
        "name": "Paraguay",
        "states": [{
            "code": "01",
            "name": "Alto Parana"
        }, {
            "code": "02",
            "name": "Amambay"
        }, {
            "code": "03",
            "name": "Boqueron"
        }, {
            "code": "04",
            "name": "Caaguazu"
        }, {
            "code": "05",
            "name": "Caazapa"
        }, {
            "code": "06",
            "name": "Central"
        }, {
            "code": "07",
            "name": "Concepcion"
        }, {
            "code": "08",
            "name": "Cordillera"
        }, {
            "code": "10",
            "name": "Guaira"
        }, {
            "code": "11",
            "name": "Itapua"
        }, {
            "code": "12",
            "name": "Misiones"
        }, {
            "code": "13",
            "name": "Neembucu"
        }, {
            "code": "15",
            "name": "Paraguari"
        }, {
            "code": "16",
            "name": "Presidente Hayes"
        }, {
            "code": "17",
            "name": "San Pedro"
        }, {
            "code": "19",
            "name": "Canindeyu"
        }, {
            "code": "20",
            "name": "Chaco"
        }, {
            "code": "21",
            "name": "Nueva Asuncion"
        }, {
            "code": "23",
            "name": "Alto Paraguay"
        }]
    }, {
        "code": "PE",
        "name": "Peru",
        "states": [{
            "code": "01",
            "name": "Amazonas"
        }, {
            "code": "02",
            "name": "Ancash"
        }, {
            "code": "03",
            "name": "Apurimac"
        }, {
            "code": "04",
            "name": "Arequipa"
        }, {
            "code": "05",
            "name": "Ayacucho"
        }, {
            "code": "06",
            "name": "Cajamarca"
        }, {
            "code": "07",
            "name": "Callao"
        }, {
            "code": "08",
            "name": "Cusco"
        }, {
            "code": "09",
            "name": "Huancavelica"
        }, {
            "code": "10",
            "name": "Huanuco"
        }, {
            "code": "11",
            "name": "Ica"
        }, {
            "code": "12",
            "name": "Junin"
        }, {
            "code": "13",
            "name": "La Libertad"
        }, {
            "code": "14",
            "name": "Lambayeque"
        }, {
            "code": "15",
            "name": "Lima"
        }, {
            "code": "16",
            "name": "Loreto"
        }, {
            "code": "17",
            "name": "Madre de Dios"
        }, {
            "code": "18",
            "name": "Moquegua"
        }, {
            "code": "19",
            "name": "Pasco"
        }, {
            "code": "20",
            "name": "Piura"
        }, {
            "code": "21",
            "name": "Puno"
        }, {
            "code": "22",
            "name": "San Martin"
        }, {
            "code": "23",
            "name": "Tacna"
        }, {
            "code": "24",
            "name": "Tumbes"
        }, {
            "code": "25",
            "name": "Ucayali"
        }]
    }, {
        "code": "PH",
        "name": "Philippines",
        "states": [{
            "code": "01",
            "name": "Abra"
        }, {
            "code": "02",
            "name": "Agusan del Norte"
        }, {
            "code": "03",
            "name": "Agusan del Sur"
        }, {
            "code": "04",
            "name": "Aklan"
        }, {
            "code": "05",
            "name": "Albay"
        }, {
            "code": "06",
            "name": "Antique"
        }, {
            "code": "07",
            "name": "Bataan"
        }, {
            "code": "08",
            "name": "Batanes"
        }, {
            "code": "09",
            "name": "Batangas"
        }, {
            "code": "10",
            "name": "Benguet"
        }, {
            "code": "11",
            "name": "Bohol"
        }, {
            "code": "12",
            "name": "Bukidnon"
        }, {
            "code": "13",
            "name": "Bulacan"
        }, {
            "code": "14",
            "name": "Cagayan"
        }, {
            "code": "15",
            "name": "Camarines Norte"
        }, {
            "code": "16",
            "name": "Camarines Sur"
        }, {
            "code": "17",
            "name": "Camiguin"
        }, {
            "code": "18",
            "name": "Capiz"
        }, {
            "code": "19",
            "name": "Catanduanes"
        }, {
            "code": "20",
            "name": "Cavite"
        }, {
            "code": "21",
            "name": "Cebu"
        }, {
            "code": "22",
            "name": "Basilan"
        }, {
            "code": "23",
            "name": "Eastern Samar"
        }, {
            "code": "24",
            "name": "Davao"
        }, {
            "code": "25",
            "name": "Davao del Sur"
        }, {
            "code": "26",
            "name": "Davao Oriental"
        }, {
            "code": "27",
            "name": "Ifugao"
        }, {
            "code": "28",
            "name": "Ilocos Norte"
        }, {
            "code": "29",
            "name": "Ilocos Sur"
        }, {
            "code": "30",
            "name": "Iloilo"
        }, {
            "code": "31",
            "name": "Isabela"
        }, {
            "code": "32",
            "name": "Kalinga-Apayao"
        }, {
            "code": "33",
            "name": "Laguna"
        }, {
            "code": "34",
            "name": "Lanao del Norte"
        }, {
            "code": "35",
            "name": "Lanao del Sur"
        }, {
            "code": "36",
            "name": "La Union"
        }, {
            "code": "37",
            "name": "Leyte"
        }, {
            "code": "38",
            "name": "Marinduque"
        }, {
            "code": "39",
            "name": "Masbate"
        }, {
            "code": "40",
            "name": "Mindoro Occidental"
        }, {
            "code": "41",
            "name": "Mindoro Oriental"
        }, {
            "code": "42",
            "name": "Misamis Occidental"
        }, {
            "code": "43",
            "name": "Misamis Oriental"
        }, {
            "code": "44",
            "name": "Mountain"
        }, {
            "code": "46",
            "name": "Negros Oriental"
        }, {
            "code": "47",
            "name": "Nueva Ecija"
        }, {
            "code": "48",
            "name": "Nueva Vizcaya"
        }, {
            "code": "49",
            "name": "Palawan"
        }, {
            "code": "50",
            "name": "Pampanga"
        }, {
            "code": "51",
            "name": "Pangasinan"
        }, {
            "code": "53",
            "name": "Rizal"
        }, {
            "code": "54",
            "name": "Romblon"
        }, {
            "code": "55",
            "name": "Samar"
        }, {
            "code": "56",
            "name": "Maguindanao"
        }, {
            "code": "57",
            "name": "North Cotabato"
        }, {
            "code": "58",
            "name": "Sorsogon"
        }, {
            "code": "59",
            "name": "Southern Leyte"
        }, {
            "code": "60",
            "name": "Sulu"
        }, {
            "code": "61",
            "name": "Surigao del Norte"
        }, {
            "code": "62",
            "name": "Surigao del Sur"
        }, {
            "code": "63",
            "name": "Tarlac"
        }, {
            "code": "64",
            "name": "Zambales"
        }, {
            "code": "65",
            "name": "Zamboanga del Norte"
        }, {
            "code": "66",
            "name": "Zamboanga del Sur"
        }, {
            "code": "67",
            "name": "Northern Samar"
        }, {
            "code": "68",
            "name": "Quirino"
        }, {
            "code": "69",
            "name": "Siquijor"
        }, {
            "code": "70",
            "name": "South Cotabato"
        }, {
            "code": "71",
            "name": "Sultan Kudarat"
        }, {
            "code": "72",
            "name": "Tawitawi"
        }, {
            "code": "A1",
            "name": "Angeles"
        }, {
            "code": "A2",
            "name": "Bacolod"
        }, {
            "code": "A3",
            "name": "Bago"
        }, {
            "code": "A4",
            "name": "Baguio"
        }, {
            "code": "A5",
            "name": "Bais"
        }, {
            "code": "A6",
            "name": "Basilan City"
        }, {
            "code": "A7",
            "name": "Batangas City"
        }, {
            "code": "A8",
            "name": "Butuan"
        }, {
            "code": "A9",
            "name": "Cabanatuan"
        }, {
            "code": "B1",
            "name": "Cadiz"
        }, {
            "code": "B2",
            "name": "Cagayan de Oro"
        }, {
            "code": "B3",
            "name": "Calbayog"
        }, {
            "code": "B4",
            "name": "Caloocan"
        }, {
            "code": "B5",
            "name": "Canlaon"
        }, {
            "code": "B6",
            "name": "Cavite City"
        }, {
            "code": "B7",
            "name": "Cebu City"
        }, {
            "code": "B8",
            "name": "Cotabato"
        }, {
            "code": "B9",
            "name": "Dagupan"
        }, {
            "code": "C1",
            "name": "Danao"
        }, {
            "code": "C2",
            "name": "Dapitan"
        }, {
            "code": "C3",
            "name": "Davao City"
        }, {
            "code": "C4",
            "name": "Dipolog"
        }, {
            "code": "C5",
            "name": "Dumaguete"
        }, {
            "code": "C6",
            "name": "General Santos"
        }, {
            "code": "C7",
            "name": "Gingoog"
        }, {
            "code": "C8",
            "name": "Iligan"
        }, {
            "code": "C9",
            "name": "Iloilo City"
        }, {
            "code": "D1",
            "name": "Iriga"
        }, {
            "code": "D2",
            "name": "La Carlota"
        }, {
            "code": "D3",
            "name": "Laoag"
        }, {
            "code": "D4",
            "name": "Lapu-Lapu"
        }, {
            "code": "D5",
            "name": "Legaspi"
        }, {
            "code": "D6",
            "name": "Lipa"
        }, {
            "code": "D7",
            "name": "Lucena"
        }, {
            "code": "D8",
            "name": "Mandaue"
        }, {
            "code": "D9",
            "name": "Manila"
        }, {
            "code": "E1",
            "name": "Marawi"
        }, {
            "code": "E2",
            "name": "Naga"
        }, {
            "code": "E3",
            "name": "Olongapo"
        }, {
            "code": "E4",
            "name": "Ormoc"
        }, {
            "code": "E5",
            "name": "Oroquieta"
        }, {
            "code": "E6",
            "name": "Ozamis"
        }, {
            "code": "E7",
            "name": "Pagadian"
        }, {
            "code": "E8",
            "name": "Palayan"
        }, {
            "code": "E9",
            "name": "Pasay"
        }, {
            "code": "F1",
            "name": "Puerto Princesa"
        }, {
            "code": "F2",
            "name": "Quezon City"
        }, {
            "code": "F3",
            "name": "Roxas"
        }, {
            "code": "F4",
            "name": "San Carlos"
        }, {
            "code": "F5",
            "name": "San Carlos"
        }, {
            "code": "F6",
            "name": "San Jose"
        }, {
            "code": "F7",
            "name": "San Pablo"
        }, {
            "code": "F8",
            "name": "Silay"
        }, {
            "code": "F9",
            "name": "Surigao"
        }, {
            "code": "G1",
            "name": "Tacloban"
        }, {
            "code": "G2",
            "name": "Tagaytay"
        }, {
            "code": "G3",
            "name": "Tagbilaran"
        }, {
            "code": "G4",
            "name": "Tangub"
        }, {
            "code": "G5",
            "name": "Toledo"
        }, {
            "code": "G6",
            "name": "Trece Martires"
        }, {
            "code": "G7",
            "name": "Zamboanga"
        }, {
            "code": "G8",
            "name": "Aurora"
        }, {
            "code": "H2",
            "name": "Quezon"
        }, {
            "code": "H3",
            "name": "Negros Occidental"
        }]
    }, {
        "code": "PN",
        "name": "Pitcairn"
    }, {
        "code": "PL",
        "name": "Poland",
        "states": [{
            "code": "23",
            "name": "Biala Podlaska"
        }, {
            "code": "24",
            "name": "Bialystok"
        }, {
            "code": "25",
            "name": "Bielsko"
        }, {
            "code": "26",
            "name": "Bydgoszcz"
        }, {
            "code": "27",
            "name": "Chelm"
        }, {
            "code": "28",
            "name": "Ciechanow"
        }, {
            "code": "29",
            "name": "Czestochowa"
        }, {
            "code": "30",
            "name": "Elblag"
        }, {
            "code": "31",
            "name": "Gdansk"
        }, {
            "code": "32",
            "name": "Gorzow"
        }, {
            "code": "33",
            "name": "Jelenia Gora"
        }, {
            "code": "34",
            "name": "Kalisz"
        }, {
            "code": "35",
            "name": "Katowice"
        }, {
            "code": "36",
            "name": "Kielce"
        }, {
            "code": "37",
            "name": "Konin"
        }, {
            "code": "38",
            "name": "Koszalin"
        }, {
            "code": "39",
            "name": "Krakow"
        }, {
            "code": "40",
            "name": "Krosno"
        }, {
            "code": "41",
            "name": "Legnica"
        }, {
            "code": "42",
            "name": "Leszno"
        }, {
            "code": "43",
            "name": "Lodz"
        }, {
            "code": "44",
            "name": "Lomza"
        }, {
            "code": "45",
            "name": "Lublin"
        }, {
            "code": "46",
            "name": "Nowy Sacz"
        }, {
            "code": "47",
            "name": "Olsztyn"
        }, {
            "code": "48",
            "name": "Opole"
        }, {
            "code": "49",
            "name": "Ostroleka"
        }, {
            "code": "50",
            "name": "Pila"
        }, {
            "code": "51",
            "name": "Piotrkow"
        }, {
            "code": "52",
            "name": "Plock"
        }, {
            "code": "53",
            "name": "Poznan"
        }, {
            "code": "54",
            "name": "Przemysl"
        }, {
            "code": "55",
            "name": "Radom"
        }, {
            "code": "56",
            "name": "Rzeszow"
        }, {
            "code": "57",
            "name": "Siedlce"
        }, {
            "code": "58",
            "name": "Sieradz"
        }, {
            "code": "59",
            "name": "Skierniewice"
        }, {
            "code": "60",
            "name": "Slupsk"
        }, {
            "code": "61",
            "name": "Suwalki"
        }, {
            "code": "62",
            "name": "Szczecin"
        }, {
            "code": "63",
            "name": "Tarnobrzeg"
        }, {
            "code": "64",
            "name": "Tarnow"
        }, {
            "code": "65",
            "name": "Torun"
        }, {
            "code": "66",
            "name": "Walbrzych"
        }, {
            "code": "67",
            "name": "Warszawa"
        }, {
            "code": "68",
            "name": "Wloclawek"
        }, {
            "code": "69",
            "name": "Wroclaw"
        }, {
            "code": "70",
            "name": "Zamosc"
        }, {
            "code": "71",
            "name": "Zielona Gora"
        }, {
            "code": "72",
            "name": "Dolnoslaskie"
        }, {
            "code": "73",
            "name": "Kujawsko-Pomorskie"
        }, {
            "code": "74",
            "name": "Lodzkie"
        }, {
            "code": "75",
            "name": "Lubelskie"
        }, {
            "code": "76",
            "name": "Lubuskie"
        }, {
            "code": "77",
            "name": "Malopolskie"
        }, {
            "code": "78",
            "name": "Mazowieckie"
        }, {
            "code": "79",
            "name": "Opolskie"
        }, {
            "code": "80",
            "name": "Podkarpackie"
        }, {
            "code": "81",
            "name": "Podlaskie"
        }, {
            "code": "82",
            "name": "Pomorskie"
        }, {
            "code": "83",
            "name": "Slaskie"
        }, {
            "code": "84",
            "name": "Swietokrzyskie"
        }, {
            "code": "85",
            "name": "Warminsko-Mazurskie"
        }, {
            "code": "86",
            "name": "Wielkopolskie"
        }, {
            "code": "87",
            "name": "Zachodniopomorskie"
        }]
    }, {
        "code": "PT",
        "name": "Portugal",
        "states": [{
            "code": "02",
            "name": "Aveiro"
        }, {
            "code": "03",
            "name": "Beja"
        }, {
            "code": "04",
            "name": "Braga"
        }, {
            "code": "05",
            "name": "Braganca"
        }, {
            "code": "06",
            "name": "Castelo Branco"
        }, {
            "code": "07",
            "name": "Coimbra"
        }, {
            "code": "08",
            "name": "Evora"
        }, {
            "code": "09",
            "name": "Faro"
        }, {
            "code": "10",
            "name": "Madeira"
        }, {
            "code": "11",
            "name": "Guarda"
        }, {
            "code": "13",
            "name": "Leiria"
        }, {
            "code": "14",
            "name": "Lisboa"
        }, {
            "code": "16",
            "name": "Portalegre"
        }, {
            "code": "17",
            "name": "Porto"
        }, {
            "code": "18",
            "name": "Santarem"
        }, {
            "code": "19",
            "name": "Setubal"
        }, {
            "code": "20",
            "name": "Viana do Castelo"
        }, {
            "code": "21",
            "name": "Vila Real"
        }, {
            "code": "22",
            "name": "Viseu"
        }, {
            "code": "23",
            "name": "Azores"
        }]
    }, {
        "code": "PR",
        "name": "Puerto Rico"
    }, {
        "code": "QA",
        "name": "Qatar",
        "states": [{
            "code": "01",
            "name": "Ad Dawhah"
        }, {
            "code": "02",
            "name": "Al Ghuwariyah"
        }, {
            "code": "03",
            "name": "Al Jumaliyah"
        }, {
            "code": "04",
            "name": "Al Khawr"
        }, {
            "code": "06",
            "name": "Ar Rayyan"
        }, {
            "code": "08",
            "name": "Madinat ach Shamal"
        }, {
            "code": "09",
            "name": "Umm Salal"
        }, {
            "code": "10",
            "name": "Al Wakrah"
        }, {
            "code": "11",
            "name": "Jariyan al Batnah"
        }, {
            "code": "12",
            "name": "Umm Sa'id"
        }]
    }, {
        "code": "RE",
        "name": "Reunion"
    }, {
        "code": "RO",
        "name": "Romania",
        "states": [{
            "code": "01",
            "name": "Alba"
        }, {
            "code": "02",
            "name": "Arad"
        }, {
            "code": "03",
            "name": "Arges"
        }, {
            "code": "04",
            "name": "Bacau"
        }, {
            "code": "05",
            "name": "Bihor"
        }, {
            "code": "06",
            "name": "Bistrita-Nasaud"
        }, {
            "code": "07",
            "name": "Botosani"
        }, {
            "code": "08",
            "name": "Braila"
        }, {
            "code": "09",
            "name": "Brasov"
        }, {
            "code": "10",
            "name": "Bucuresti"
        }, {
            "code": "11",
            "name": "Buzau"
        }, {
            "code": "12",
            "name": "Caras-Severin"
        }, {
            "code": "13",
            "name": "Cluj"
        }, {
            "code": "14",
            "name": "Constanta"
        }, {
            "code": "15",
            "name": "Covasna"
        }, {
            "code": "16",
            "name": "Dambovita"
        }, {
            "code": "17",
            "name": "Dolj"
        }, {
            "code": "18",
            "name": "Galati"
        }, {
            "code": "19",
            "name": "Gorj"
        }, {
            "code": "20",
            "name": "Harghita"
        }, {
            "code": "21",
            "name": "Hunedoara"
        }, {
            "code": "22",
            "name": "Ialomita"
        }, {
            "code": "23",
            "name": "Iasi"
        }, {
            "code": "25",
            "name": "Maramures"
        }, {
            "code": "26",
            "name": "Mehedinti"
        }, {
            "code": "27",
            "name": "Mures"
        }, {
            "code": "28",
            "name": "Neamt"
        }, {
            "code": "29",
            "name": "Olt"
        }, {
            "code": "30",
            "name": "Prahova"
        }, {
            "code": "31",
            "name": "Salaj"
        }, {
            "code": "32",
            "name": "Satu Mare"
        }, {
            "code": "33",
            "name": "Sibiu"
        }, {
            "code": "34",
            "name": "Suceava"
        }, {
            "code": "35",
            "name": "Teleorman"
        }, {
            "code": "36",
            "name": "Timis"
        }, {
            "code": "37",
            "name": "Tulcea"
        }, {
            "code": "38",
            "name": "Vaslui"
        }, {
            "code": "39",
            "name": "Valcea"
        }, {
            "code": "40",
            "name": "Vrancea"
        }, {
            "code": "41",
            "name": "Calarasi"
        }, {
            "code": "42",
            "name": "Giurgiu"
        }, {
            "code": "43",
            "name": "Ilfov"
        }]
    }, {
        "code": "RU",
        "name": "Russian Federation",
        "states": [{
            "code": "01",
            "name": "Adygeya, Republic of"
        }, {
            "code": "02",
            "name": "Aginsky Buryatsky AO"
        }, {
            "code": "03",
            "name": "Gorno-Altay"
        }, {
            "code": "04",
            "name": "Altaisky krai"
        }, {
            "code": "05",
            "name": "Amur"
        }, {
            "code": "06",
            "name": "Arkhangel'sk"
        }, {
            "code": "07",
            "name": "Astrakhan'"
        }, {
            "code": "08",
            "name": "Bashkortostan"
        }, {
            "code": "09",
            "name": "Belgorod"
        }, {
            "code": "10",
            "name": "Bryansk"
        }, {
            "code": "11",
            "name": "Buryat"
        }, {
            "code": "12",
            "name": "Chechnya"
        }, {
            "code": "13",
            "name": "Chelyabinsk"
        }, {
            "code": "14",
            "name": "Chita"
        }, {
            "code": "15",
            "name": "Chukot"
        }, {
            "code": "16",
            "name": "Chuvashia"
        }, {
            "code": "17",
            "name": "Dagestan"
        }, {
            "code": "18",
            "name": "Evenk"
        }, {
            "code": "19",
            "name": "Ingush"
        }, {
            "code": "20",
            "name": "Irkutsk"
        }, {
            "code": "21",
            "name": "Ivanovo"
        }, {
            "code": "22",
            "name": "Kabardin-Balkar"
        }, {
            "code": "23",
            "name": "Kaliningrad"
        }, {
            "code": "24",
            "name": "Kalmyk"
        }, {
            "code": "25",
            "name": "Kaluga"
        }, {
            "code": "26",
            "name": "Kamchatka"
        }, {
            "code": "27",
            "name": "Karachay-Cherkess"
        }, {
            "code": "28",
            "name": "Karelia"
        }, {
            "code": "29",
            "name": "Kemerovo"
        }, {
            "code": "30",
            "name": "Khabarovsk"
        }, {
            "code": "31",
            "name": "Khakass"
        }, {
            "code": "32",
            "name": "Khanty-Mansiy"
        }, {
            "code": "33",
            "name": "Kirov"
        }, {
            "code": "34",
            "name": "Komi"
        }, {
            "code": "35",
            "name": "Komi-Permyak"
        }, {
            "code": "36",
            "name": "Koryak"
        }, {
            "code": "37",
            "name": "Kostroma"
        }, {
            "code": "38",
            "name": "Krasnodar"
        }, {
            "code": "39",
            "name": "Krasnoyarsk"
        }, {
            "code": "40",
            "name": "Kurgan"
        }, {
            "code": "41",
            "name": "Kursk"
        }, {
            "code": "42",
            "name": "Leningrad"
        }, {
            "code": "43",
            "name": "Lipetsk"
        }, {
            "code": "44",
            "name": "Magadan"
        }, {
            "code": "45",
            "name": "Mariy-El"
        }, {
            "code": "46",
            "name": "Mordovia"
        }, {
            "code": "47",
            "name": "Moskva"
        }, {
            "code": "48",
            "name": "Moscow City"
        }, {
            "code": "49",
            "name": "Murmansk"
        }, {
            "code": "50",
            "name": "Nenets"
        }, {
            "code": "51",
            "name": "Nizhegorod"
        }, {
            "code": "52",
            "name": "Novgorod"
        }, {
            "code": "53",
            "name": "Novosibirsk"
        }, {
            "code": "54",
            "name": "Omsk"
        }, {
            "code": "55",
            "name": "Orenburg"
        }, {
            "code": "56",
            "name": "Orel"
        }, {
            "code": "57",
            "name": "Penza"
        }, {
            "code": "58",
            "name": "Perm'"
        }, {
            "code": "59",
            "name": "Primor'ye"
        }, {
            "code": "60",
            "name": "Pskov"
        }, {
            "code": "61",
            "name": "Rostov"
        }, {
            "code": "62",
            "name": "Ryazan'"
        }, {
            "code": "63",
            "name": "Sakha"
        }, {
            "code": "64",
            "name": "Sakhalin"
        }, {
            "code": "65",
            "name": "Samara"
        }, {
            "code": "66",
            "name": "Saint Petersburg City"
        }, {
            "code": "67",
            "name": "Saratov"
        }, {
            "code": "68",
            "name": "North Ossetia"
        }, {
            "code": "69",
            "name": "Smolensk"
        }, {
            "code": "70",
            "name": "Stavropol'"
        }, {
            "code": "71",
            "name": "Sverdlovsk"
        }, {
            "code": "72",
            "name": "Tambovskaya oblast"
        }, {
            "code": "73",
            "name": "Tatarstan"
        }, {
            "code": "74",
            "name": "Taymyr"
        }, {
            "code": "75",
            "name": "Tomsk"
        }, {
            "code": "76",
            "name": "Tula"
        }, {
            "code": "77",
            "name": "Tver'"
        }, {
            "code": "78",
            "name": "Tyumen'"
        }, {
            "code": "79",
            "name": "Tuva"
        }, {
            "code": "80",
            "name": "Udmurt"
        }, {
            "code": "81",
            "name": "Ul'yanovsk"
        }, {
            "code": "82",
            "name": "Ust-Orda Buryat"
        }, {
            "code": "83",
            "name": "Vladimir"
        }, {
            "code": "84",
            "name": "Volgograd"
        }, {
            "code": "85",
            "name": "Vologda"
        }, {
            "code": "86",
            "name": "Voronezh"
        }, {
            "code": "87",
            "name": "Yamal-Nenets"
        }, {
            "code": "88",
            "name": "Yaroslavl'"
        }, {
            "code": "89",
            "name": "Yevrey"
        }]
    }, {
        "code": "RW",
        "name": "Rwanda",
        "states": [{
            "code": "01",
            "name": "Butare"
        }, {
            "code": "02",
            "name": "Byumba"
        }, {
            "code": "03",
            "name": "Cyangugu"
        }, {
            "code": "04",
            "name": "Gikongoro"
        }, {
            "code": "05",
            "name": "Gisenyi"
        }, {
            "code": "06",
            "name": "Gitarama"
        }, {
            "code": "07",
            "name": "Kibungo"
        }, {
            "code": "08",
            "name": "Kibuye"
        }, {
            "code": "09",
            "name": "Kigali"
        }, {
            "code": "10",
            "name": "Ruhengeri"
        }]
    }, {
        "code": "BL",
        "name": "Saint Barthelemy"
    }, {
        "code": "LC",
        "name": "Saint Lucia",
        "states": [{
            "code": "01",
            "name": "Anse-la-Raye"
        }, {
            "code": "02",
            "name": "Dauphin"
        }, {
            "code": "03",
            "name": "Castries"
        }, {
            "code": "04",
            "name": "Choiseul"
        }, {
            "code": "05",
            "name": "Dennery"
        }, {
            "code": "06",
            "name": "Gros-Islet"
        }, {
            "code": "07",
            "name": "Laborie"
        }, {
            "code": "08",
            "name": "Micoud"
        }, {
            "code": "09",
            "name": "Soufriere"
        }, {
            "code": "10",
            "name": "Vieux-Fort"
        }, {
            "code": "11",
            "name": "Praslin"
        }]
    }, {
        "code": "MF",
        "name": "Saint Martin (French part)"
    }, {
        "code": "WS",
        "name": "Samoa",
        "states": [{
            "code": "02",
            "name": "Aiga-i-le-Tai"
        }, {
            "code": "03",
            "name": "Atua"
        }, {
            "code": "04",
            "name": "Fa"
        }, {
            "code": "05",
            "name": "Gaga"
        }, {
            "code": "06",
            "name": "Va"
        }, {
            "code": "07",
            "name": "Gagaifomauga"
        }, {
            "code": "08",
            "name": "Palauli"
        }, {
            "code": "09",
            "name": "Satupa"
        }, {
            "code": "10",
            "name": "Tuamasaga"
        }, {
            "code": "11",
            "name": "Vaisigano"
        }]
    }, {
        "code": "SM",
        "name": "San Marino",
        "states": [{
            "code": "01",
            "name": "Acquaviva"
        }, {
            "code": "02",
            "name": "Chiesanuova"
        }, {
            "code": "03",
            "name": "Domagnano"
        }, {
            "code": "04",
            "name": "Faetano"
        }, {
            "code": "05",
            "name": "Fiorentino"
        }, {
            "code": "06",
            "name": "Borgo Maggiore"
        }, {
            "code": "07",
            "name": "San Marino"
        }, {
            "code": "08",
            "name": "Monte Giardino"
        }, {
            "code": "09",
            "name": "Serravalle"
        }]
    }, {
        "code": "ST",
        "name": "Sao Tome & Principe",
        "states": [{
            "code": "01",
            "name": "Principe"
        }, {
            "code": "02",
            "name": "Sao Tome"
        }]
    }, {
        "code": "SA",
        "name": "Saudi Arabia",
        "states": [{
            "code": "02",
            "name": "Al Bahah"
        }, {
            "code": "03",
            "name": "Al Jawf"
        }, {
            "code": "05",
            "name": "Al Madinah"
        }, {
            "code": "06",
            "name": "Ash Sharqiyah"
        }, {
            "code": "08",
            "name": "Al Qasim"
        }, {
            "code": "09",
            "name": "Al Qurayyat"
        }, {
            "code": "10",
            "name": "Ar Riyad"
        }, {
            "code": "13",
            "name": "Ha'il"
        }, {
            "code": "14",
            "name": "Makkah"
        }, {
            "code": "15",
            "name": "Al Hudud ash Shamaliyah"
        }, {
            "code": "16",
            "name": "Najran"
        }, {
            "code": "17",
            "name": "Jizan"
        }, {
            "code": "19",
            "name": "Tabuk"
        }, {
            "code": "20",
            "name": "Al Jawf"
        }]
    }, {
        "code": "SN",
        "name": "Senegal",
        "states": [{
            "code": "01",
            "name": "Dakar"
        }, {
            "code": "03",
            "name": "Diourbel"
        }, {
            "code": "04",
            "name": "Saint-Louis"
        }, {
            "code": "05",
            "name": "Tambacounda"
        }, {
            "code": "07",
            "name": "Thies"
        }, {
            "code": "08",
            "name": "Louga"
        }, {
            "code": "09",
            "name": "Fatick"
        }, {
            "code": "10",
            "name": "Kaolack"
        }, {
            "code": "11",
            "name": "Kolda"
        }, {
            "code": "12",
            "name": "Ziguinchor"
        }]
    }, {
        "code": "RS",
        "name": "Serbia"
    }, {
        "code": "SC",
        "name": "Seychelles",
        "states": [{
            "code": "01",
            "name": "Anse aux Pins"
        }, {
            "code": "02",
            "name": "Anse Boileau"
        }, {
            "code": "03",
            "name": "Anse Etoile"
        }, {
            "code": "04",
            "name": "Anse Louis"
        }, {
            "code": "05",
            "name": "Anse Royale"
        }, {
            "code": "06",
            "name": "Baie Lazare"
        }, {
            "code": "07",
            "name": "Baie Sainte Anne"
        }, {
            "code": "08",
            "name": "Beau Vallon"
        }, {
            "code": "09",
            "name": "Bel Air"
        }, {
            "code": "10",
            "name": "Bel Ombre"
        }, {
            "code": "11",
            "name": "Cascade"
        }, {
            "code": "12",
            "name": "Glacis"
        }, {
            "code": "13",
            "name": "Grand' Anse"
        }, {
            "code": "14",
            "name": "Grand' Anse"
        }, {
            "code": "15",
            "name": "La Digue"
        }, {
            "code": "16",
            "name": "La Riviere Anglaise"
        }, {
            "code": "17",
            "name": "Mont Buxton"
        }, {
            "code": "18",
            "name": "Mont Fleuri"
        }, {
            "code": "19",
            "name": "Plaisance"
        }, {
            "code": "20",
            "name": "Pointe La Rue"
        }, {
            "code": "21",
            "name": "Port Glaud"
        }, {
            "code": "22",
            "name": "Saint Louis"
        }, {
            "code": "23",
            "name": "Takamaka"
        }]
    }, {
        "code": "SL",
        "name": "Sierra Leone",
        "states": [{
            "code": "01",
            "name": "Eastern"
        }, {
            "code": "02",
            "name": "Northern"
        }, {
            "code": "03",
            "name": "Southern"
        }, {
            "code": "04",
            "name": "Western Area"
        }]
    }, {
        "code": "SG",
        "name": "Singapore"
    }, {
        "code": "SX",
        "name": "Sint Maarten (Dutch part)"
    }, {
        "code": "SK",
        "name": "Slovakia",
        "states": [{
            "code": "01",
            "name": "Banska Bystrica"
        }, {
            "code": "02",
            "name": "Bratislava"
        }, {
            "code": "03",
            "name": "Kosice"
        }, {
            "code": "04",
            "name": "Nitra"
        }, {
            "code": "05",
            "name": "Presov"
        }, {
            "code": "06",
            "name": "Trencin"
        }, {
            "code": "07",
            "name": "Trnava"
        }, {
            "code": "08",
            "name": "Zilina"
        }]
    }, {
        "code": "SI",
        "name": "Slovenia",
        "states": [{
            "code": "01",
            "name": "Ajdovscina"
        }, {
            "code": "02",
            "name": "Beltinci"
        }, {
            "code": "03",
            "name": "Bled"
        }, {
            "code": "04",
            "name": "Bohinj"
        }, {
            "code": "05",
            "name": "Borovnica"
        }, {
            "code": "06",
            "name": "Bovec"
        }, {
            "code": "07",
            "name": "Brda"
        }, {
            "code": "08",
            "name": "Brezice"
        }, {
            "code": "09",
            "name": "Brezovica"
        }, {
            "code": "11",
            "name": "Celje"
        }, {
            "code": "12",
            "name": "Cerklje na Gorenjskem"
        }, {
            "code": "13",
            "name": "Cerknica"
        }, {
            "code": "14",
            "name": "Cerkno"
        }, {
            "code": "15",
            "name": "Crensovci"
        }, {
            "code": "16",
            "name": "Crna na Koroskem"
        }, {
            "code": "17",
            "name": "Crnomelj"
        }, {
            "code": "19",
            "name": "Divaca"
        }, {
            "code": "20",
            "name": "Dobrepolje"
        }, {
            "code": "22",
            "name": "Dol pri Ljubljani"
        }, {
            "code": "24",
            "name": "Dornava"
        }, {
            "code": "25",
            "name": "Dravograd"
        }, {
            "code": "26",
            "name": "Duplek"
        }, {
            "code": "27",
            "name": "Gorenja Vas-Poljane"
        }, {
            "code": "28",
            "name": "Gorisnica"
        }, {
            "code": "29",
            "name": "Gornja Radgona"
        }, {
            "code": "30",
            "name": "Gornji Grad"
        }, {
            "code": "31",
            "name": "Gornji Petrovci"
        }, {
            "code": "32",
            "name": "Grosuplje"
        }, {
            "code": "34",
            "name": "Hrastnik"
        }, {
            "code": "35",
            "name": "Hrpelje-Kozina"
        }, {
            "code": "36",
            "name": "Idrija"
        }, {
            "code": "37",
            "name": "Ig"
        }, {
            "code": "38",
            "name": "Ilirska Bistrica"
        }, {
            "code": "39",
            "name": "Ivancna Gorica"
        }, {
            "code": "40",
            "name": "Izola-Isola"
        }, {
            "code": "42",
            "name": "Jursinci"
        }, {
            "code": "44",
            "name": "Kanal"
        }, {
            "code": "45",
            "name": "Kidricevo"
        }, {
            "code": "46",
            "name": "Kobarid"
        }, {
            "code": "47",
            "name": "Kobilje"
        }, {
            "code": "49",
            "name": "Komen"
        }, {
            "code": "50",
            "name": "Koper-Capodistria"
        }, {
            "code": "51",
            "name": "Kozje"
        }, {
            "code": "52",
            "name": "Kranj"
        }, {
            "code": "53",
            "name": "Kranjska Gora"
        }, {
            "code": "54",
            "name": "Krsko"
        }, {
            "code": "55",
            "name": "Kungota"
        }, {
            "code": "57",
            "name": "Lasko"
        }, {
            "code": "61",
            "name": "Ljubljana"
        }, {
            "code": "62",
            "name": "Ljubno"
        }, {
            "code": "64",
            "name": "Logatec"
        }, {
            "code": "66",
            "name": "Loski Potok"
        }, {
            "code": "68",
            "name": "Lukovica"
        }, {
            "code": "71",
            "name": "Medvode"
        }, {
            "code": "72",
            "name": "Menges"
        }, {
            "code": "73",
            "name": "Metlika"
        }, {
            "code": "74",
            "name": "Mezica"
        }, {
            "code": "76",
            "name": "Mislinja"
        }, {
            "code": "77",
            "name": "Moravce"
        }, {
            "code": "78",
            "name": "Moravske Toplice"
        }, {
            "code": "79",
            "name": "Mozirje"
        }, {
            "code": "80",
            "name": "Murska Sobota"
        }, {
            "code": "81",
            "name": "Muta"
        }, {
            "code": "82",
            "name": "Naklo"
        }, {
            "code": "83",
            "name": "Nazarje"
        }, {
            "code": "84",
            "name": "Nova Gorica"
        }, {
            "code": "86",
            "name": "Odranci"
        }, {
            "code": "87",
            "name": "Ormoz"
        }, {
            "code": "88",
            "name": "Osilnica"
        }, {
            "code": "89",
            "name": "Pesnica"
        }, {
            "code": "91",
            "name": "Pivka"
        }, {
            "code": "92",
            "name": "Podcetrtek"
        }, {
            "code": "94",
            "name": "Postojna"
        }, {
            "code": "97",
            "name": "Puconci"
        }, {
            "code": "98",
            "name": "Racam"
        }, {
            "code": "99",
            "name": "Radece"
        }, {
            "code": "A1",
            "name": "Radenci"
        }, {
            "code": "A2",
            "name": "Radlje ob Dravi"
        }, {
            "code": "A3",
            "name": "Radovljica"
        }, {
            "code": "A6",
            "name": "Rogasovci"
        }, {
            "code": "A7",
            "name": "Rogaska Slatina"
        }, {
            "code": "A8",
            "name": "Rogatec"
        }, {
            "code": "B1",
            "name": "Semic"
        }, {
            "code": "B2",
            "name": "Sencur"
        }, {
            "code": "B3",
            "name": "Sentilj"
        }, {
            "code": "B4",
            "name": "Sentjernej"
        }, {
            "code": "B6",
            "name": "Sevnica"
        }, {
            "code": "B7",
            "name": "Sezana"
        }, {
            "code": "B8",
            "name": "Skocjan"
        }, {
            "code": "B9",
            "name": "Skofja Loka"
        }, {
            "code": "C1",
            "name": "Skofljica"
        }, {
            "code": "C2",
            "name": "Slovenj Gradec"
        }, {
            "code": "C4",
            "name": "Slovenske Konjice"
        }, {
            "code": "C5",
            "name": "Smarje pri Jelsah"
        }, {
            "code": "C6",
            "name": "Smartno ob Paki"
        }, {
            "code": "C7",
            "name": "Sostanj"
        }, {
            "code": "C8",
            "name": "Starse"
        }, {
            "code": "C9",
            "name": "Store"
        }, {
            "code": "D1",
            "name": "Sveti Jurij"
        }, {
            "code": "D2",
            "name": "Tolmin"
        }, {
            "code": "D3",
            "name": "Trbovlje"
        }, {
            "code": "D4",
            "name": "Trebnje"
        }, {
            "code": "D5",
            "name": "Trzic"
        }, {
            "code": "D6",
            "name": "Turnisce"
        }, {
            "code": "D7",
            "name": "Velenje"
        }, {
            "code": "D8",
            "name": "Velike Lasce"
        }, {
            "code": "E1",
            "name": "Vipava"
        }, {
            "code": "E2",
            "name": "Vitanje"
        }, {
            "code": "E3",
            "name": "Vodice"
        }, {
            "code": "E5",
            "name": "Vrhnika"
        }, {
            "code": "E6",
            "name": "Vuzenica"
        }, {
            "code": "E7",
            "name": "Zagorje ob Savi"
        }, {
            "code": "E9",
            "name": "Zavrc"
        }, {
            "code": "F1",
            "name": "Zelezniki"
        }, {
            "code": "F2",
            "name": "Ziri"
        }, {
            "code": "F3",
            "name": "Zrece"
        }, {
            "code": "G4",
            "name": "Dobrova-Horjul-Polhov Gradec"
        }, {
            "code": "G7",
            "name": "Domzale"
        }, {
            "code": "H4",
            "name": "Jesenice"
        }, {
            "code": "H6",
            "name": "Kamnik"
        }, {
            "code": "H7",
            "name": "Kocevje"
        }, {
            "code": "I2",
            "name": "Kuzma"
        }, {
            "code": "I3",
            "name": "Lenart"
        }, {
            "code": "I5",
            "name": "Litija"
        }, {
            "code": "I6",
            "name": "Ljutomer"
        }, {
            "code": "I7",
            "name": "Loska Dolina"
        }, {
            "code": "I9",
            "name": "Luce"
        }, {
            "code": "J1",
            "name": "Majsperk"
        }, {
            "code": "J2",
            "name": "Maribor"
        }, {
            "code": "J5",
            "name": "Miren-Kostanjevica"
        }, {
            "code": "J7",
            "name": "Novo Mesto"
        }, {
            "code": "J9",
            "name": "Piran"
        }, {
            "code": "K5",
            "name": "Preddvor"
        }, {
            "code": "K7",
            "name": "Ptuj"
        }, {
            "code": "L1",
            "name": "Ribnica"
        }, {
            "code": "L3",
            "name": "Ruse"
        }, {
            "code": "L7",
            "name": "Sentjur pri Celju"
        }, {
            "code": "L8",
            "name": "Slovenska Bistrica"
        }, {
            "code": "N2",
            "name": "Videm"
        }, {
            "code": "N3",
            "name": "Vojnik"
        }, {
            "code": "N5",
            "name": "Zalec"
        }]
    }, {
        "code": "SB",
        "name": "Solomon Islands",
        "states": [{
            "code": "03",
            "name": "Malaita"
        }, {
            "code": "04",
            "name": "Western"
        }, {
            "code": "05",
            "name": "Central"
        }, {
            "code": "06",
            "name": "Guadalcanal"
        }, {
            "code": "07",
            "name": "Isabel"
        }, {
            "code": "08",
            "name": "Makira"
        }, {
            "code": "09",
            "name": "Temotu"
        }]
    }, {
        "code": "SO",
        "name": "Somalia",
        "states": [{
            "code": "01",
            "name": "Bakool"
        }, {
            "code": "02",
            "name": "Banaadir"
        }, {
            "code": "03",
            "name": "Bari"
        }, {
            "code": "04",
            "name": "Bay"
        }, {
            "code": "05",
            "name": "Galguduud"
        }, {
            "code": "06",
            "name": "Gedo"
        }, {
            "code": "07",
            "name": "Hiiraan"
        }, {
            "code": "08",
            "name": "Jubbada Dhexe"
        }, {
            "code": "09",
            "name": "Jubbada Hoose"
        }, {
            "code": "10",
            "name": "Mudug"
        }, {
            "code": "11",
            "name": "Nugaal"
        }, {
            "code": "12",
            "name": "Sanaag"
        }, {
            "code": "13",
            "name": "Shabeellaha Dhexe"
        }, {
            "code": "14",
            "name": "Shabeellaha Hoose"
        }, {
            "code": "15",
            "name": "Togdheer"
        }, {
            "code": "16",
            "name": "Woqooyi Galbeed"
        }]
    }, {
        "code": "ZA",
        "name": "South Africa",
        "states": [{
            "code": "02",
            "name": "KwaZulu-Natal"
        }, {
            "code": "03",
            "name": "Free State"
        }, {
            "code": "05",
            "name": "Eastern Cape"
        }, {
            "code": "06",
            "name": "Gauteng"
        }, {
            "code": "07",
            "name": "Mpumalanga"
        }, {
            "code": "08",
            "name": "Northern Cape"
        }, {
            "code": "09",
            "name": "Limpopo"
        }, {
            "code": "10",
            "name": "North-West"
        }, {
            "code": "11",
            "name": "Western Cape"
        }]
    }, {
        "code": "GS",
        "name": "South Georgia and the South Sandwich Islands"
    }, {
        "code": "SS",
        "name": "South Sudan"
    }, {
        "code": "ES",
        "name": "Spain",
        "states": [{
            "code": "07",
            "name": "Islas Baleares"
        }, {
            "code": "27",
            "name": "La Rioja"
        }, {
            "code": "29",
            "name": "Madrid"
        }, {
            "code": "31",
            "name": "Murcia"
        }, {
            "code": "32",
            "name": "Navarra"
        }, {
            "code": "34",
            "name": "Asturias"
        }, {
            "code": "39",
            "name": "Cantabria"
        }, {
            "code": "51",
            "name": "Andalucia"
        }, {
            "code": "52",
            "name": "Aragon"
        }, {
            "code": "53",
            "name": "Canarias"
        }, {
            "code": "54",
            "name": "Castilla-La Mancha"
        }, {
            "code": "55",
            "name": "Castilla y Leon"
        }, {
            "code": "56",
            "name": "Catalonia"
        }, {
            "code": "57",
            "name": "Extremadura"
        }, {
            "code": "58",
            "name": "Galicia"
        }, {
            "code": "59",
            "name": "Pais Vasco"
        }, {
            "code": "60",
            "name": "Comunidad Valenciana"
        }]
    }, {
        "code": "LK",
        "name": "Sri Lanka",
        "states": [{
            "code": "01",
            "name": "Amparai"
        }, {
            "code": "02",
            "name": "Anuradhapura"
        }, {
            "code": "03",
            "name": "Badulla"
        }, {
            "code": "04",
            "name": "Batticaloa"
        }, {
            "code": "06",
            "name": "Galle"
        }, {
            "code": "07",
            "name": "Hambantota"
        }, {
            "code": "09",
            "name": "Kalutara"
        }, {
            "code": "10",
            "name": "Kandy"
        }, {
            "code": "11",
            "name": "Kegalla"
        }, {
            "code": "12",
            "name": "Kurunegala"
        }, {
            "code": "14",
            "name": "Matale"
        }, {
            "code": "15",
            "name": "Matara"
        }, {
            "code": "16",
            "name": "Moneragala"
        }, {
            "code": "17",
            "name": "Nuwara Eliya"
        }, {
            "code": "18",
            "name": "Polonnaruwa"
        }, {
            "code": "19",
            "name": "Puttalam"
        }, {
            "code": "20",
            "name": "Ratnapura"
        }, {
            "code": "21",
            "name": "Trincomalee"
        }, {
            "code": "23",
            "name": "Colombo"
        }, {
            "code": "24",
            "name": "Gampaha"
        }, {
            "code": "25",
            "name": "Jaffna"
        }, {
            "code": "26",
            "name": "Mannar"
        }, {
            "code": "27",
            "name": "Mullaittivu"
        }, {
            "code": "28",
            "name": "Vavuniya"
        }]
    }, {
        "code": "SH",
        "name": "St. Helena",
        "states": [{
            "code": "01",
            "name": "Ascension"
        }, {
            "code": "02",
            "name": "Saint Helena"
        }, {
            "code": "03",
            "name": "Tristan da Cunha"
        }]
    }, {
        "code": "KN",
        "name": "St. Kitts and Nevis",
        "states": [{
            "code": "01",
            "name": "Christ Church Nichola Town"
        }, {
            "code": "02",
            "name": "Saint Anne Sandy Point"
        }, {
            "code": "03",
            "name": "Saint George Basseterre"
        }, {
            "code": "04",
            "name": "Saint George Gingerland"
        }, {
            "code": "05",
            "name": "Saint James Windward"
        }, {
            "code": "06",
            "name": "Saint John Capisterre"
        }, {
            "code": "07",
            "name": "Saint John Figtree"
        }, {
            "code": "08",
            "name": "Saint Mary Cayon"
        }, {
            "code": "09",
            "name": "Saint Paul Capisterre"
        }, {
            "code": "10",
            "name": "Saint Paul Charlestown"
        }, {
            "code": "11",
            "name": "Saint Peter Basseterre"
        }, {
            "code": "12",
            "name": "Saint Thomas Lowland"
        }, {
            "code": "13",
            "name": "Saint Thomas Middle Island"
        }, {
            "code": "15",
            "name": "Trinity Palmetto Point"
        }]
    }, {
        "code": "PM",
        "name": "St. Pierre & Miquelon"
    }, {
        "code": "VC",
        "name": "St. Vincent & the Grenadines",
        "states": [{
            "code": "01",
            "name": "Charlotte"
        }, {
            "code": "02",
            "name": "Saint Andrew"
        }, {
            "code": "03",
            "name": "Saint David"
        }, {
            "code": "04",
            "name": "Saint George"
        }, {
            "code": "05",
            "name": "Saint Patrick"
        }, {
            "code": "06",
            "name": "Grenadines"
        }]
    }, {
        "code": "SD",
        "name": "Sudan",
        "states": [{
            "code": "27",
            "name": "Al Wusta"
        }, {
            "code": "28",
            "name": "Al Istiwa'iyah"
        }, {
            "code": "29",
            "name": "Al Khartum"
        }, {
            "code": "30",
            "name": "Ash Shamaliyah"
        }, {
            "code": "31",
            "name": "Ash Sharqiyah"
        }, {
            "code": "32",
            "name": "Bahr al Ghazal"
        }, {
            "code": "33",
            "name": "Darfur"
        }, {
            "code": "34",
            "name": "Kurdufan"
        }]
    }, {
        "code": "SR",
        "name": "Suriname",
        "states": [{
            "code": "10",
            "name": "Brokopondo"
        }, {
            "code": "11",
            "name": "Commewijne"
        }, {
            "code": "12",
            "name": "Coronie"
        }, {
            "code": "13",
            "name": "Marowijne"
        }, {
            "code": "14",
            "name": "Nickerie"
        }, {
            "code": "15",
            "name": "Para"
        }, {
            "code": "16",
            "name": "Paramaribo"
        }, {
            "code": "17",
            "name": "Saramacca"
        }, {
            "code": "18",
            "name": "Sipaliwini"
        }, {
            "code": "19",
            "name": "Wanica"
        }]
    }, {
        "code": "SJ",
        "name": "Svalbard & Jan Mayen Islands"
    }, {
        "code": "SZ",
        "name": "Swaziland",
        "states": [{
            "code": "01",
            "name": "Hhohho"
        }, {
            "code": "02",
            "name": "Lubombo"
        }, {
            "code": "03",
            "name": "Manzini"
        }, {
            "code": "04",
            "name": "Shiselweni"
        }, {
            "code": "05",
            "name": "Praslin"
        }]
    }, {
        "code": "SE",
        "name": "Sweden",
        "states": [{
            "code": "01",
            "name": "Alvsborgs Lan"
        }, {
            "code": "02",
            "name": "Blekinge Lan"
        }, {
            "code": "03",
            "name": "Gavleborgs Lan"
        }, {
            "code": "04",
            "name": "Goteborgs och Bohus Lan"
        }, {
            "code": "05",
            "name": "Gotlands Lan"
        }, {
            "code": "06",
            "name": "Hallands Lan"
        }, {
            "code": "07",
            "name": "Jamtlands Lan"
        }, {
            "code": "08",
            "name": "Jonkopings Lan"
        }, {
            "code": "09",
            "name": "Kalmar Lan"
        }, {
            "code": "10",
            "name": "Dalarnas Lan"
        }, {
            "code": "11",
            "name": "Kristianstads Lan"
        }, {
            "code": "12",
            "name": "Kronobergs Lan"
        }, {
            "code": "13",
            "name": "Malmohus Lan"
        }, {
            "code": "14",
            "name": "Norrbottens Lan"
        }, {
            "code": "15",
            "name": "Orebro Lan"
        }, {
            "code": "16",
            "name": "Ostergotlands Lan"
        }, {
            "code": "17",
            "name": "Skaraborgs Lan"
        }, {
            "code": "18",
            "name": "Sodermanlands Lan"
        }, {
            "code": "21",
            "name": "Uppsala Lan"
        }, {
            "code": "22",
            "name": "Varmlands Lan"
        }, {
            "code": "23",
            "name": "Vasterbottens Lan"
        }, {
            "code": "24",
            "name": "Vasternorrlands Lan"
        }, {
            "code": "25",
            "name": "Vastmanlands Lan"
        }, {
            "code": "26",
            "name": "Stockholms Lan"
        }, {
            "code": "27",
            "name": "Skane Lan"
        }, {
            "code": "28",
            "name": "Vastra Gotaland"
        }]
    }, {
        "code": "CH",
        "name": "Switzerland",
        "states": [{
            "code": "01",
            "name": "Aargau"
        }, {
            "code": "02",
            "name": "Ausser-Rhoden"
        }, {
            "code": "03",
            "name": "Basel-Landschaft"
        }, {
            "code": "04",
            "name": "Basel-Stadt"
        }, {
            "code": "05",
            "name": "Bern"
        }, {
            "code": "06",
            "name": "Fribourg"
        }, {
            "code": "07",
            "name": "Geneve"
        }, {
            "code": "08",
            "name": "Glarus"
        }, {
            "code": "09",
            "name": "Graubunden"
        }, {
            "code": "10",
            "name": "Inner-Rhoden"
        }, {
            "code": "11",
            "name": "Luzern"
        }, {
            "code": "12",
            "name": "Neuchatel"
        }, {
            "code": "13",
            "name": "Nidwalden"
        }, {
            "code": "14",
            "name": "Obwalden"
        }, {
            "code": "15",
            "name": "Sankt Gallen"
        }, {
            "code": "16",
            "name": "Schaffhausen"
        }, {
            "code": "17",
            "name": "Schwyz"
        }, {
            "code": "18",
            "name": "Solothurn"
        }, {
            "code": "19",
            "name": "Thurgau"
        }, {
            "code": "20",
            "name": "Ticino"
        }, {
            "code": "21",
            "name": "Uri"
        }, {
            "code": "22",
            "name": "Valais"
        }, {
            "code": "23",
            "name": "Vaud"
        }, {
            "code": "24",
            "name": "Zug"
        }, {
            "code": "25",
            "name": "Zurich"
        }, {
            "code": "26",
            "name": "Jura"
        }]
    }, {
        "code": "SY",
        "name": "Syrian Arab Republic",
        "states": [{
            "code": "01",
            "name": "Al Hasakah"
        }, {
            "code": "02",
            "name": "Al Ladhiqiyah"
        }, {
            "code": "03",
            "name": "Al Qunaytirah"
        }, {
            "code": "04",
            "name": "Ar Raqqah"
        }, {
            "code": "05",
            "name": "As Suwayda'"
        }, {
            "code": "06",
            "name": "Dar"
        }, {
            "code": "07",
            "name": "Dayr az Zawr"
        }, {
            "code": "08",
            "name": "Rif Dimashq"
        }, {
            "code": "09",
            "name": "Halab"
        }, {
            "code": "10",
            "name": "Hamah"
        }, {
            "code": "11",
            "name": "Hims"
        }, {
            "code": "12",
            "name": "Idlib"
        }, {
            "code": "13",
            "name": "Dimashq"
        }, {
            "code": "14",
            "name": "Tartus"
        }]
    }, {
        "code": "TW",
        "name": "Taiwan",
        "states": [{
            "code": "01",
            "name": "Fu-chien"
        }, {
            "code": "02",
            "name": "Kao-hsiung"
        }, {
            "code": "03",
            "name": "T'ai-pei"
        }, {
            "code": "04",
            "name": "T'ai-wan"
        }]
    }, {
        "code": "TJ",
        "name": "Tajikistan",
        "states": [{
            "code": "01",
            "name": "Kuhistoni Badakhshon"
        }, {
            "code": "02",
            "name": "Khatlon"
        }, {
            "code": "03",
            "name": "Sughd"
        }]
    }, {
        "code": "TZ",
        "name": "Tanzania, United Republic of",
        "states": [{
            "code": "01",
            "name": "Arusha"
        }, {
            "code": "02",
            "name": "Pwani"
        }, {
            "code": "03",
            "name": "Dodoma"
        }, {
            "code": "04",
            "name": "Iringa"
        }, {
            "code": "05",
            "name": "Kigoma"
        }, {
            "code": "06",
            "name": "Kilimanjaro"
        }, {
            "code": "07",
            "name": "Lindi"
        }, {
            "code": "08",
            "name": "Mara"
        }, {
            "code": "09",
            "name": "Mbeya"
        }, {
            "code": "10",
            "name": "Morogoro"
        }, {
            "code": "11",
            "name": "Mtwara"
        }, {
            "code": "12",
            "name": "Mwanza"
        }, {
            "code": "13",
            "name": "Pemba North"
        }, {
            "code": "14",
            "name": "Ruvuma"
        }, {
            "code": "15",
            "name": "Shinyanga"
        }, {
            "code": "16",
            "name": "Singida"
        }, {
            "code": "17",
            "name": "Tabora"
        }, {
            "code": "18",
            "name": "Tanga"
        }, {
            "code": "19",
            "name": "Kagera"
        }, {
            "code": "20",
            "name": "Pemba South"
        }, {
            "code": "21",
            "name": "Zanzibar Central"
        }, {
            "code": "22",
            "name": "Zanzibar North"
        }, {
            "code": "23",
            "name": "Dar es Salaam"
        }, {
            "code": "24",
            "name": "Rukwa"
        }, {
            "code": "25",
            "name": "Zanzibar Urban"
        }]
    }, {
        "code": "TH",
        "name": "Thailand",
        "states": [{
            "code": "01",
            "name": "Mae Hong Son"
        }, {
            "code": "02",
            "name": "Chiang Mai"
        }, {
            "code": "03",
            "name": "Chiang Rai"
        }, {
            "code": "04",
            "name": "Nan"
        }, {
            "code": "05",
            "name": "Lamphun"
        }, {
            "code": "06",
            "name": "Lampang"
        }, {
            "code": "07",
            "name": "Phrae"
        }, {
            "code": "08",
            "name": "Tak"
        }, {
            "code": "09",
            "name": "Sukhothai"
        }, {
            "code": "10",
            "name": "Uttaradit"
        }, {
            "code": "11",
            "name": "Kamphaeng Phet"
        }, {
            "code": "12",
            "name": "Phitsanulok"
        }, {
            "code": "13",
            "name": "Phichit"
        }, {
            "code": "14",
            "name": "Phetchabun"
        }, {
            "code": "15",
            "name": "Uthai Thani"
        }, {
            "code": "16",
            "name": "Nakhon Sawan"
        }, {
            "code": "17",
            "name": "Nong Khai"
        }, {
            "code": "18",
            "name": "Loei"
        }, {
            "code": "20",
            "name": "Sakon Nakhon"
        }, {
            "code": "21",
            "name": "Nakhon Phanom"
        }, {
            "code": "22",
            "name": "Khon Kaen"
        }, {
            "code": "23",
            "name": "Kalasin"
        }, {
            "code": "24",
            "name": "Maha Sarakham"
        }, {
            "code": "25",
            "name": "Roi Et"
        }, {
            "code": "26",
            "name": "Chaiyaphum"
        }, {
            "code": "27",
            "name": "Nakhon Ratchasima"
        }, {
            "code": "28",
            "name": "Buriram"
        }, {
            "code": "29",
            "name": "Surin"
        }, {
            "code": "30",
            "name": "Sisaket"
        }, {
            "code": "31",
            "name": "Narathiwat"
        }, {
            "code": "32",
            "name": "Chai Nat"
        }, {
            "code": "33",
            "name": "Sing Buri"
        }, {
            "code": "34",
            "name": "Lop Buri"
        }, {
            "code": "35",
            "name": "Ang Thong"
        }, {
            "code": "36",
            "name": "Phra Nakhon Si Ayutthaya"
        }, {
            "code": "37",
            "name": "Saraburi"
        }, {
            "code": "38",
            "name": "Nonthaburi"
        }, {
            "code": "39",
            "name": "Pathum Thani"
        }, {
            "code": "40",
            "name": "Krung Thep"
        }, {
            "code": "41",
            "name": "Phayao"
        }, {
            "code": "42",
            "name": "Samut Prakan"
        }, {
            "code": "43",
            "name": "Nakhon Nayok"
        }, {
            "code": "44",
            "name": "Chachoengsao"
        }, {
            "code": "45",
            "name": "Prachin Buri"
        }, {
            "code": "46",
            "name": "Chon Buri"
        }, {
            "code": "47",
            "name": "Rayong"
        }, {
            "code": "48",
            "name": "Chanthaburi"
        }, {
            "code": "49",
            "name": "Trat"
        }, {
            "code": "50",
            "name": "Kanchanaburi"
        }, {
            "code": "51",
            "name": "Suphan Buri"
        }, {
            "code": "52",
            "name": "Ratchaburi"
        }, {
            "code": "53",
            "name": "Nakhon Pathom"
        }, {
            "code": "54",
            "name": "Samut Songkhram"
        }, {
            "code": "55",
            "name": "Samut Sakhon"
        }, {
            "code": "56",
            "name": "Phetchaburi"
        }, {
            "code": "57",
            "name": "Prachuap Khiri Khan"
        }, {
            "code": "58",
            "name": "Chumphon"
        }, {
            "code": "59",
            "name": "Ranong"
        }, {
            "code": "60",
            "name": "Surat Thani"
        }, {
            "code": "61",
            "name": "Phangnga"
        }, {
            "code": "62",
            "name": "Phuket"
        }, {
            "code": "63",
            "name": "Krabi"
        }, {
            "code": "64",
            "name": "Nakhon Si Thammarat"
        }, {
            "code": "65",
            "name": "Trang"
        }, {
            "code": "66",
            "name": "Phatthalung"
        }, {
            "code": "67",
            "name": "Satun"
        }, {
            "code": "68",
            "name": "Songkhla"
        }, {
            "code": "69",
            "name": "Pattani"
        }, {
            "code": "70",
            "name": "Yala"
        }, {
            "code": "72",
            "name": "Yasothon"
        }, {
            "code": "75",
            "name": "Ubon Ratchathani"
        }, {
            "code": "76",
            "name": "Udon Thani"
        }, {
            "code": "78",
            "name": "Mukdahan"
        }]
    }, {
        "code": "TL",
        "name": "Timor-Leste"
    }, {
        "code": "TG",
        "name": "Togo",
        "states": [{
            "code": "01",
            "name": "Amlame"
        }, {
            "code": "02",
            "name": "Aneho"
        }, {
            "code": "03",
            "name": "Atakpame"
        }, {
            "code": "04",
            "name": "Bafilo"
        }, {
            "code": "05",
            "name": "Bassar"
        }, {
            "code": "06",
            "name": "Dapaong"
        }, {
            "code": "07",
            "name": "Kante"
        }, {
            "code": "08",
            "name": "Klouto"
        }, {
            "code": "09",
            "name": "Lama-Kara"
        }, {
            "code": "10",
            "name": "Lome"
        }, {
            "code": "11",
            "name": "Mango"
        }, {
            "code": "12",
            "name": "Niamtougou"
        }, {
            "code": "13",
            "name": "Notse"
        }, {
            "code": "14",
            "name": "Kpagouda"
        }, {
            "code": "15",
            "name": "Badou"
        }, {
            "code": "16",
            "name": "Sotouboua"
        }, {
            "code": "17",
            "name": "Tabligbo"
        }, {
            "code": "18",
            "name": "Tsevie"
        }, {
            "code": "19",
            "name": "Tchamba"
        }, {
            "code": "20",
            "name": "Tchaoudjo"
        }, {
            "code": "21",
            "name": "Vogan"
        }]
    }, {
        "code": "TK",
        "name": "Tokelau"
    }, {
        "code": "TO",
        "name": "Tonga",
        "states": [{
            "code": "01",
            "name": "Ha"
        }, {
            "code": "02",
            "name": "Tongatapu"
        }, {
            "code": "03",
            "name": "Vava"
        }]
    }, {
        "code": "TT",
        "name": "Trinidad & Tobago",
        "states": [{
            "code": "01",
            "name": "Arima"
        }, {
            "code": "02",
            "name": "Caroni"
        }, {
            "code": "03",
            "name": "Mayaro"
        }, {
            "code": "04",
            "name": "Nariva"
        }, {
            "code": "05",
            "name": "Port-of-Spain"
        }, {
            "code": "06",
            "name": "Saint Andrew"
        }, {
            "code": "07",
            "name": "Saint David"
        }, {
            "code": "08",
            "name": "Saint George"
        }, {
            "code": "09",
            "name": "Saint Patrick"
        }, {
            "code": "10",
            "name": "San Fernando"
        }, {
            "code": "11",
            "name": "Tobago"
        }, {
            "code": "12",
            "name": "Victoria"
        }]
    }, {
        "code": "TN",
        "name": "Tunisia",
        "states": [{
            "code": "02",
            "name": "Al Qasrayn"
        }, {
            "code": "03",
            "name": "Al Qayrawan"
        }, {
            "code": "06",
            "name": "Jundubah"
        }, {
            "code": "10",
            "name": "Qafsah"
        }, {
            "code": "14",
            "name": "Kef"
        }, {
            "code": "15",
            "name": "Al Mahdiyah"
        }, {
            "code": "16",
            "name": "Al Munastir"
        }, {
            "code": "17",
            "name": "Bajah"
        }, {
            "code": "18",
            "name": "Banzart"
        }, {
            "code": "19",
            "name": "Nabul"
        }, {
            "code": "22",
            "name": "Silyanah"
        }, {
            "code": "23",
            "name": "Susah"
        }, {
            "code": "27",
            "name": "Bin"
        }, {
            "code": "28",
            "name": "Madanin"
        }, {
            "code": "29",
            "name": "Qabis"
        }, {
            "code": "31",
            "name": "Qibili"
        }, {
            "code": "32",
            "name": "Safaqis"
        }, {
            "code": "33",
            "name": "Sidi Bu Zayd"
        }, {
            "code": "34",
            "name": "Tatawin"
        }, {
            "code": "35",
            "name": "Tawzar"
        }, {
            "code": "36",
            "name": "Tunis"
        }, {
            "code": "37",
            "name": "Zaghwan"
        }, {
            "code": "38",
            "name": "Ariana"
        }, {
            "code": "39",
            "name": "Manouba"
        }]
    }, {
        "code": "TR",
        "name": "Turkey",
        "states": [{
            "code": "02",
            "name": "Adiyaman"
        }, {
            "code": "03",
            "name": "Afyon"
        }, {
            "code": "04",
            "name": "Agri"
        }, {
            "code": "05",
            "name": "Amasya"
        }, {
            "code": "07",
            "name": "Antalya"
        }, {
            "code": "08",
            "name": "Artvin"
        }, {
            "code": "09",
            "name": "Aydin"
        }, {
            "code": "10",
            "name": "Balikesir"
        }, {
            "code": "11",
            "name": "Bilecik"
        }, {
            "code": "12",
            "name": "Bingol"
        }, {
            "code": "13",
            "name": "Bitlis"
        }, {
            "code": "14",
            "name": "Bolu"
        }, {
            "code": "15",
            "name": "Burdur"
        }, {
            "code": "16",
            "name": "Bursa"
        }, {
            "code": "17",
            "name": "Canakkale"
        }, {
            "code": "19",
            "name": "Corum"
        }, {
            "code": "20",
            "name": "Denizli"
        }, {
            "code": "21",
            "name": "Diyarbakir"
        }, {
            "code": "22",
            "name": "Edirne"
        }, {
            "code": "23",
            "name": "Elazig"
        }, {
            "code": "24",
            "name": "Erzincan"
        }, {
            "code": "25",
            "name": "Erzurum"
        }, {
            "code": "26",
            "name": "Eskisehir"
        }, {
            "code": "28",
            "name": "Giresun"
        }, {
            "code": "31",
            "name": "Hatay"
        }, {
            "code": "32",
            "name": "Icel"
        }, {
            "code": "33",
            "name": "Isparta"
        }, {
            "code": "34",
            "name": "Istanbul"
        }, {
            "code": "35",
            "name": "Izmir"
        }, {
            "code": "37",
            "name": "Kastamonu"
        }, {
            "code": "38",
            "name": "Kayseri"
        }, {
            "code": "39",
            "name": "Kirklareli"
        }, {
            "code": "40",
            "name": "Kirsehir"
        }, {
            "code": "41",
            "name": "Kocaeli"
        }, {
            "code": "43",
            "name": "Kutahya"
        }, {
            "code": "44",
            "name": "Malatya"
        }, {
            "code": "45",
            "name": "Manisa"
        }, {
            "code": "46",
            "name": "Kahramanmaras"
        }, {
            "code": "48",
            "name": "Mugla"
        }, {
            "code": "49",
            "name": "Mus"
        }, {
            "code": "50",
            "name": "Nevsehir"
        }, {
            "code": "52",
            "name": "Ordu"
        }, {
            "code": "53",
            "name": "Rize"
        }, {
            "code": "54",
            "name": "Sakarya"
        }, {
            "code": "55",
            "name": "Samsun"
        }, {
            "code": "57",
            "name": "Sinop"
        }, {
            "code": "58",
            "name": "Sivas"
        }, {
            "code": "59",
            "name": "Tekirdag"
        }, {
            "code": "60",
            "name": "Tokat"
        }, {
            "code": "61",
            "name": "Trabzon"
        }, {
            "code": "62",
            "name": "Tunceli"
        }, {
            "code": "63",
            "name": "Sanliurfa"
        }, {
            "code": "64",
            "name": "Usak"
        }, {
            "code": "65",
            "name": "Van"
        }, {
            "code": "66",
            "name": "Yozgat"
        }, {
            "code": "68",
            "name": "Ankara"
        }, {
            "code": "69",
            "name": "Gumushane"
        }, {
            "code": "70",
            "name": "Hakkari"
        }, {
            "code": "71",
            "name": "Konya"
        }, {
            "code": "72",
            "name": "Mardin"
        }, {
            "code": "73",
            "name": "Nigde"
        }, {
            "code": "74",
            "name": "Siirt"
        }, {
            "code": "75",
            "name": "Aksaray"
        }, {
            "code": "76",
            "name": "Batman"
        }, {
            "code": "77",
            "name": "Bayburt"
        }, {
            "code": "78",
            "name": "Karaman"
        }, {
            "code": "79",
            "name": "Kirikkale"
        }, {
            "code": "80",
            "name": "Sirnak"
        }, {
            "code": "81",
            "name": "Adana"
        }, {
            "code": "82",
            "name": "Cankiri"
        }, {
            "code": "83",
            "name": "Gaziantep"
        }, {
            "code": "84",
            "name": "Kars"
        }, {
            "code": "85",
            "name": "Zonguldak"
        }, {
            "code": "86",
            "name": "Ardahan"
        }, {
            "code": "87",
            "name": "Bartin"
        }, {
            "code": "88",
            "name": "Igdir"
        }, {
            "code": "89",
            "name": "Karabuk"
        }, {
            "code": "90",
            "name": "Kilis"
        }, {
            "code": "91",
            "name": "Osmaniye"
        }, {
            "code": "92",
            "name": "Yalova"
        }, {
            "code": "93",
            "name": "Duzce"
        }]
    }, {
        "code": "TM",
        "name": "Turkmenistan",
        "states": [{
            "code": "01",
            "name": "Ahal"
        }, {
            "code": "02",
            "name": "Balkan"
        }, {
            "code": "03",
            "name": "Dashoguz"
        }, {
            "code": "04",
            "name": "Lebap"
        }, {
            "code": "05",
            "name": "Mary"
        }]
    }, {
        "code": "TC",
        "name": "Turks & Caicos Islands"
    }, {
        "code": "TV",
        "name": "Tuvalu"
    }, {
        "code": "UG",
        "name": "Uganda",
        "states": [{
            "code": "05",
            "name": "Busoga"
        }, {
            "code": "08",
            "name": "Karamoja"
        }, {
            "code": "12",
            "name": "South Buganda"
        }, {
            "code": "18",
            "name": "Central"
        }, {
            "code": "20",
            "name": "Eastern"
        }, {
            "code": "21",
            "name": "Nile"
        }, {
            "code": "22",
            "name": "North Buganda"
        }, {
            "code": "23",
            "name": "Northern"
        }, {
            "code": "24",
            "name": "Southern"
        }, {
            "code": "25",
            "name": "Western"
        }, {
            "code": "37",
            "name": "Kampala"
        }, {
            "code": "65",
            "name": "Adjumani"
        }, {
            "code": "66",
            "name": "Bugiri"
        }, {
            "code": "67",
            "name": "Busia"
        }, {
            "code": "69",
            "name": "Katakwi"
        }, {
            "code": "73",
            "name": "Nakasongola"
        }, {
            "code": "74",
            "name": "Sembabule"
        }, {
            "code": "77",
            "name": "Arua"
        }, {
            "code": "78",
            "name": "Iganga"
        }, {
            "code": "79",
            "name": "Kabarole"
        }, {
            "code": "80",
            "name": "Kaberamaido"
        }, {
            "code": "81",
            "name": "Kamwenge"
        }, {
            "code": "82",
            "name": "Kanungu"
        }, {
            "code": "83",
            "name": "Kayunga"
        }, {
            "code": "84",
            "name": "Kitgum"
        }, {
            "code": "85",
            "name": "Kyenjojo"
        }, {
            "code": "86",
            "name": "Mayuge"
        }, {
            "code": "87",
            "name": "Mbale"
        }, {
            "code": "88",
            "name": "Moroto"
        }, {
            "code": "89",
            "name": "Mpigi"
        }, {
            "code": "90",
            "name": "Mukono"
        }, {
            "code": "91",
            "name": "Nakapiripirit"
        }, {
            "code": "92",
            "name": "Pader"
        }, {
            "code": "93",
            "name": "Rukungiri"
        }, {
            "code": "94",
            "name": "Sironko"
        }, {
            "code": "95",
            "name": "Soroti"
        }, {
            "code": "96",
            "name": "Wakiso"
        }, {
            "code": "97",
            "name": "Yumbe"
        }]
    }, {
        "code": "UA",
        "name": "Ukraine",
        "states": [{
            "code": "01",
            "name": "Cherkas'ka Oblast'"
        }, {
            "code": "02",
            "name": "Chernihivs'ka Oblast'"
        }, {
            "code": "03",
            "name": "Chernivets'ka Oblast'"
        }, {
            "code": "04",
            "name": "Dnipropetrovs'ka Oblast'"
        }, {
            "code": "05",
            "name": "Donets'ka Oblast'"
        }, {
            "code": "06",
            "name": "Ivano-Frankivs'ka Oblast'"
        }, {
            "code": "07",
            "name": "Kharkivs'ka Oblast'"
        }, {
            "code": "08",
            "name": "Khersons'ka Oblast'"
        }, {
            "code": "09",
            "name": "Khmel'nyts'ka Oblast'"
        }, {
            "code": "10",
            "name": "Kirovohrads'ka Oblast'"
        }, {
            "code": "11",
            "name": "Krym"
        }, {
            "code": "12",
            "name": "Kyyiv"
        }, {
            "code": "13",
            "name": "Kyyivs'ka Oblast'"
        }, {
            "code": "14",
            "name": "Luhans'ka Oblast'"
        }, {
            "code": "15",
            "name": "L'vivs'ka Oblast'"
        }, {
            "code": "16",
            "name": "Mykolayivs'ka Oblast'"
        }, {
            "code": "17",
            "name": "Odes'ka Oblast'"
        }, {
            "code": "18",
            "name": "Poltavs'ka Oblast'"
        }, {
            "code": "19",
            "name": "Rivnens'ka Oblast'"
        }, {
            "code": "20",
            "name": "Sevastopol'"
        }, {
            "code": "21",
            "name": "Sums'ka Oblast'"
        }, {
            "code": "22",
            "name": "Ternopil's'ka Oblast'"
        }, {
            "code": "23",
            "name": "Vinnyts'ka Oblast'"
        }, {
            "code": "24",
            "name": "Volyns'ka Oblast'"
        }, {
            "code": "25",
            "name": "Zakarpats'ka Oblast'"
        }, {
            "code": "26",
            "name": "Zaporiz'ka Oblast'"
        }, {
            "code": "27",
            "name": "Zhytomyrs'ka Oblast'"
        }]
    }, {
        "code": "AE",
        "name": "United Arab Emirates",
        "states": [{
            "code": "01",
            "name": "Abu Dhabi"
        }, {
            "code": "02",
            "name": "Ajman"
        }, {
            "code": "03",
            "name": "Dubai"
        }, {
            "code": "04",
            "name": "Fujairah"
        }, {
            "code": "05",
            "name": "Ras Al Khaimah"
        }, {
            "code": "06",
            "name": "Sharjah"
        }, {
            "code": "07",
            "name": "Umm Al Quwain"
        }]
    }, {
        "code": "GB",
        "name": "United Kingdom (Great Britain)",
        "states": [{
            "code": "01",
            "name": "Avon"
        }, {
            "code": "03",
            "name": "Berkshire"
        }, {
            "code": "07",
            "name": "Cleveland"
        }, {
            "code": "17",
            "name": "Greater London"
        }, {
            "code": "18",
            "name": "Greater Manchester"
        }, {
            "code": "20",
            "name": "Hereford and Worcester"
        }, {
            "code": "22",
            "name": "Humberside"
        }, {
            "code": "28",
            "name": "Merseyside"
        }, {
            "code": "37",
            "name": "South Yorkshire"
        }, {
            "code": "41",
            "name": "Tyne and Wear"
        }, {
            "code": "43",
            "name": "West Midlands"
        }, {
            "code": "45",
            "name": "West Yorkshire"
        }, {
            "code": "79",
            "name": "Central"
        }, {
            "code": "82",
            "name": "Grampian"
        }, {
            "code": "84",
            "name": "Lothian"
        }, {
            "code": "87",
            "name": "Strathclyde"
        }, {
            "code": "88",
            "name": "Tayside"
        }, {
            "code": "90",
            "name": "Clwyd"
        }, {
            "code": "91",
            "name": "Dyfed"
        }, {
            "code": "92",
            "name": "Gwent"
        }, {
            "code": "94",
            "name": "Mid Glamorgan"
        }, {
            "code": "96",
            "name": "South Glamorgan"
        }, {
            "code": "97",
            "name": "West Glamorgan"
        }, {
            "code": "A1",
            "name": "Barking and Dagenham"
        }, {
            "code": "A2",
            "name": "Barnet"
        }, {
            "code": "A3",
            "name": "Barnsley"
        }, {
            "code": "A4",
            "name": "Bath and North East Somerset"
        }, {
            "code": "A5",
            "name": "Bedfordshire"
        }, {
            "code": "A6",
            "name": "Bexley"
        }, {
            "code": "A7",
            "name": "Birmingham"
        }, {
            "code": "A8",
            "name": "Blackburn with Darwen"
        }, {
            "code": "A9",
            "name": "Blackpool"
        }, {
            "code": "B1",
            "name": "Bolton"
        }, {
            "code": "B2",
            "name": "Bournemouth"
        }, {
            "code": "B3",
            "name": "Bracknell Forest"
        }, {
            "code": "B4",
            "name": "Bradford"
        }, {
            "code": "B5",
            "name": "Brent"
        }, {
            "code": "B6",
            "name": "Brighton and Hove"
        }, {
            "code": "B7",
            "name": "Bristol, City of"
        }, {
            "code": "B8",
            "name": "Bromley"
        }, {
            "code": "B9",
            "name": "Buckinghamshire"
        }, {
            "code": "C1",
            "name": "Bury"
        }, {
            "code": "C2",
            "name": "Calderdale"
        }, {
            "code": "C3",
            "name": "Cambridgeshire"
        }, {
            "code": "C4",
            "name": "Camden"
        }, {
            "code": "C5",
            "name": "Cheshire"
        }, {
            "code": "C6",
            "name": "Cornwall"
        }, {
            "code": "C7",
            "name": "Coventry"
        }, {
            "code": "C8",
            "name": "Croydon"
        }, {
            "code": "C9",
            "name": "Cumbria"
        }, {
            "code": "D1",
            "name": "Darlington"
        }, {
            "code": "D2",
            "name": "Derby"
        }, {
            "code": "D3",
            "name": "Derbyshire"
        }, {
            "code": "D4",
            "name": "Devon"
        }, {
            "code": "D5",
            "name": "Doncaster"
        }, {
            "code": "D6",
            "name": "Dorset"
        }, {
            "code": "D7",
            "name": "Dudley"
        }, {
            "code": "D8",
            "name": "Durham"
        }, {
            "code": "D9",
            "name": "Ealing"
        }, {
            "code": "E1",
            "name": "East Riding of Yorkshire"
        }, {
            "code": "E2",
            "name": "East Sussex"
        }, {
            "code": "E3",
            "name": "Enfield"
        }, {
            "code": "E4",
            "name": "Essex"
        }, {
            "code": "E5",
            "name": "Gateshead"
        }, {
            "code": "E6",
            "name": "Gloucestershire"
        }, {
            "code": "E7",
            "name": "Greenwich"
        }, {
            "code": "E8",
            "name": "Hackney"
        }, {
            "code": "E9",
            "name": "Halton"
        }, {
            "code": "F1",
            "name": "Hammersmith and Fulham"
        }, {
            "code": "F2",
            "name": "Hampshire"
        }, {
            "code": "F3",
            "name": "Haringey"
        }, {
            "code": "F4",
            "name": "Harrow"
        }, {
            "code": "F5",
            "name": "Hartlepool"
        }, {
            "code": "F6",
            "name": "Havering"
        }, {
            "code": "F7",
            "name": "Herefordshire"
        }, {
            "code": "F8",
            "name": "Hertford"
        }, {
            "code": "F9",
            "name": "Hillingdon"
        }, {
            "code": "G1",
            "name": "Hounslow"
        }, {
            "code": "G2",
            "name": "Isle of Wight"
        }, {
            "code": "G3",
            "name": "Islington"
        }, {
            "code": "G4",
            "name": "Kensington and Chelsea"
        }, {
            "code": "G5",
            "name": "Kent"
        }, {
            "code": "G6",
            "name": "Kingston upon Hull, City of"
        }, {
            "code": "G7",
            "name": "Kingston upon Thames"
        }, {
            "code": "G8",
            "name": "Kirklees"
        }, {
            "code": "G9",
            "name": "Knowsley"
        }, {
            "code": "H1",
            "name": "Lambeth"
        }, {
            "code": "H2",
            "name": "Lancashire"
        }, {
            "code": "H3",
            "name": "Leeds"
        }, {
            "code": "H4",
            "name": "Leicester"
        }, {
            "code": "H5",
            "name": "Leicestershire"
        }, {
            "code": "H6",
            "name": "Lewisham"
        }, {
            "code": "H7",
            "name": "Lincolnshire"
        }, {
            "code": "H8",
            "name": "Liverpool"
        }, {
            "code": "H9",
            "name": "London, City of"
        }, {
            "code": "I1",
            "name": "Luton"
        }, {
            "code": "I2",
            "name": "Manchester"
        }, {
            "code": "I3",
            "name": "Medway"
        }, {
            "code": "I4",
            "name": "Merton"
        }, {
            "code": "I5",
            "name": "Middlesbrough"
        }, {
            "code": "I6",
            "name": "Milton Keynes"
        }, {
            "code": "I7",
            "name": "Newcastle upon Tyne"
        }, {
            "code": "I8",
            "name": "Newham"
        }, {
            "code": "I9",
            "name": "Norfolk"
        }, {
            "code": "J1",
            "name": "Northamptonshire"
        }, {
            "code": "J2",
            "name": "North East Lincolnshire"
        }, {
            "code": "J3",
            "name": "North Lincolnshire"
        }, {
            "code": "J4",
            "name": "North Somerset"
        }, {
            "code": "J5",
            "name": "North Tyneside"
        }, {
            "code": "J6",
            "name": "Northumberland"
        }, {
            "code": "J7",
            "name": "North Yorkshire"
        }, {
            "code": "J8",
            "name": "Nottingham"
        }, {
            "code": "J9",
            "name": "Nottinghamshire"
        }, {
            "code": "K1",
            "name": "Oldham"
        }, {
            "code": "K2",
            "name": "Oxfordshire"
        }, {
            "code": "K3",
            "name": "Peterborough"
        }, {
            "code": "K4",
            "name": "Plymouth"
        }, {
            "code": "K5",
            "name": "Poole"
        }, {
            "code": "K6",
            "name": "Portsmouth"
        }, {
            "code": "K7",
            "name": "Reading"
        }, {
            "code": "K8",
            "name": "Redbridge"
        }, {
            "code": "K9",
            "name": "Redcar and Cleveland"
        }, {
            "code": "L1",
            "name": "Richmond upon Thames"
        }, {
            "code": "L2",
            "name": "Rochdale"
        }, {
            "code": "L3",
            "name": "Rotherham"
        }, {
            "code": "L4",
            "name": "Rutland"
        }, {
            "code": "L5",
            "name": "Salford"
        }, {
            "code": "L6",
            "name": "Shropshire"
        }, {
            "code": "L7",
            "name": "Sandwell"
        }, {
            "code": "L8",
            "name": "Sefton"
        }, {
            "code": "L9",
            "name": "Sheffield"
        }, {
            "code": "M1",
            "name": "Slough"
        }, {
            "code": "M2",
            "name": "Solihull"
        }, {
            "code": "M3",
            "name": "Somerset"
        }, {
            "code": "M4",
            "name": "Southampton"
        }, {
            "code": "M5",
            "name": "Southend-on-Sea"
        }, {
            "code": "M6",
            "name": "South Gloucestershire"
        }, {
            "code": "M7",
            "name": "South Tyneside"
        }, {
            "code": "M8",
            "name": "Southwark"
        }, {
            "code": "M9",
            "name": "Staffordshire"
        }, {
            "code": "N1",
            "name": "St. Helens"
        }, {
            "code": "N2",
            "name": "Stockport"
        }, {
            "code": "N3",
            "name": "Stockton-on-Tees"
        }, {
            "code": "N4",
            "name": "Stoke-on-Trent"
        }, {
            "code": "N5",
            "name": "Suffolk"
        }, {
            "code": "N6",
            "name": "Sunderland"
        }, {
            "code": "N7",
            "name": "Surrey"
        }, {
            "code": "N8",
            "name": "Sutton"
        }, {
            "code": "N9",
            "name": "Swindon"
        }, {
            "code": "O1",
            "name": "Tameside"
        }, {
            "code": "O2",
            "name": "Telford and Wrekin"
        }, {
            "code": "O3",
            "name": "Thurrock"
        }, {
            "code": "O4",
            "name": "Torbay"
        }, {
            "code": "O5",
            "name": "Tower Hamlets"
        }, {
            "code": "O6",
            "name": "Trafford"
        }, {
            "code": "O7",
            "name": "Wakefield"
        }, {
            "code": "O8",
            "name": "Walsall"
        }, {
            "code": "O9",
            "name": "Waltham Forest"
        }, {
            "code": "P1",
            "name": "Wandsworth"
        }, {
            "code": "P2",
            "name": "Warrington"
        }, {
            "code": "P3",
            "name": "Warwickshire"
        }, {
            "code": "P4",
            "name": "West Berkshire"
        }, {
            "code": "P5",
            "name": "Westminster"
        }, {
            "code": "P6",
            "name": "West Sussex"
        }, {
            "code": "P7",
            "name": "Wigan"
        }, {
            "code": "P8",
            "name": "Wiltshire"
        }, {
            "code": "P9",
            "name": "Windsor and Maidenhead"
        }, {
            "code": "Q1",
            "name": "Wirral"
        }, {
            "code": "Q2",
            "name": "Wokingham"
        }, {
            "code": "Q3",
            "name": "Wolverhampton"
        }, {
            "code": "Q4",
            "name": "Worcestershire"
        }, {
            "code": "Q5",
            "name": "York"
        }, {
            "code": "Q6",
            "name": "Antrim"
        }, {
            "code": "Q7",
            "name": "Ards"
        }, {
            "code": "Q8",
            "name": "Armagh"
        }, {
            "code": "Q9",
            "name": "Ballymena"
        }, {
            "code": "R1",
            "name": "Ballymoney"
        }, {
            "code": "R2",
            "name": "Banbridge"
        }, {
            "code": "R3",
            "name": "Belfast"
        }, {
            "code": "R4",
            "name": "Carrickfergus"
        }, {
            "code": "R5",
            "name": "Castlereagh"
        }, {
            "code": "R6",
            "name": "Coleraine"
        }, {
            "code": "R7",
            "name": "Cookstown"
        }, {
            "code": "R8",
            "name": "Craigavon"
        }, {
            "code": "R9",
            "name": "Down"
        }, {
            "code": "S1",
            "name": "Dungannon"
        }, {
            "code": "S2",
            "name": "Fermanagh"
        }, {
            "code": "S3",
            "name": "Larne"
        }, {
            "code": "S4",
            "name": "Limavady"
        }, {
            "code": "S5",
            "name": "Lisburn"
        }, {
            "code": "S6",
            "name": "Derry"
        }, {
            "code": "S7",
            "name": "Magherafelt"
        }, {
            "code": "S8",
            "name": "Moyle"
        }, {
            "code": "S9",
            "name": "Newry and Mourne"
        }, {
            "code": "T1",
            "name": "Newtownabbey"
        }, {
            "code": "T2",
            "name": "North Down"
        }, {
            "code": "T3",
            "name": "Omagh"
        }, {
            "code": "T4",
            "name": "Strabane"
        }, {
            "code": "T5",
            "name": "Aberdeen City"
        }, {
            "code": "T6",
            "name": "Aberdeenshire"
        }, {
            "code": "T7",
            "name": "Angus"
        }, {
            "code": "T8",
            "name": "Argyll and Bute"
        }, {
            "code": "T9",
            "name": "Scottish Borders, The"
        }, {
            "code": "U1",
            "name": "Clackmannanshire"
        }, {
            "code": "U2",
            "name": "Dumfries and Galloway"
        }, {
            "code": "U3",
            "name": "Dundee City"
        }, {
            "code": "U4",
            "name": "East Ayrshire"
        }, {
            "code": "U5",
            "name": "East Dunbartonshire"
        }, {
            "code": "U6",
            "name": "East Lothian"
        }, {
            "code": "U7",
            "name": "East Renfrewshire"
        }, {
            "code": "U8",
            "name": "Edinburgh, City of"
        }, {
            "code": "U9",
            "name": "Falkirk"
        }, {
            "code": "V1",
            "name": "Fife"
        }, {
            "code": "V2",
            "name": "Glasgow City"
        }, {
            "code": "V3",
            "name": "Highland"
        }, {
            "code": "V4",
            "name": "Inverclyde"
        }, {
            "code": "V5",
            "name": "Midlothian"
        }, {
            "code": "V6",
            "name": "Moray"
        }, {
            "code": "V7",
            "name": "North Ayrshire"
        }, {
            "code": "V8",
            "name": "North Lanarkshire"
        }, {
            "code": "V9",
            "name": "Orkney"
        }, {
            "code": "W1",
            "name": "Perth and Kinross"
        }, {
            "code": "W2",
            "name": "Renfrewshire"
        }, {
            "code": "W3",
            "name": "Shetland Islands"
        }, {
            "code": "W4",
            "name": "South Ayrshire"
        }, {
            "code": "W5",
            "name": "South Lanarkshire"
        }, {
            "code": "W6",
            "name": "Stirling"
        }, {
            "code": "W7",
            "name": "West Dunbartonshire"
        }, {
            "code": "W8",
            "name": "Eilean Siar"
        }, {
            "code": "W9",
            "name": "West Lothian"
        }, {
            "code": "X1",
            "name": "Isle of Anglesey"
        }, {
            "code": "X2",
            "name": "Blaenau Gwent"
        }, {
            "code": "X3",
            "name": "Bridgend"
        }, {
            "code": "X4",
            "name": "Caerphilly"
        }, {
            "code": "X5",
            "name": "Cardiff"
        }, {
            "code": "X6",
            "name": "Ceredigion"
        }, {
            "code": "X7",
            "name": "Carmarthenshire"
        }, {
            "code": "X8",
            "name": "Conwy"
        }, {
            "code": "X9",
            "name": "Denbighshire"
        }, {
            "code": "Y1",
            "name": "Flintshire"
        }, {
            "code": "Y2",
            "name": "Gwynedd"
        }, {
            "code": "Y3",
            "name": "Merthyr Tydfil"
        }, {
            "code": "Y4",
            "name": "Monmouthshire"
        }, {
            "code": "Y5",
            "name": "Neath Port Talbot"
        }, {
            "code": "Y6",
            "name": "Newport"
        }, {
            "code": "Y7",
            "name": "Pembrokeshire"
        }, {
            "code": "Y8",
            "name": "Powys"
        }, {
            "code": "Y9",
            "name": "Rhondda Cynon Taff"
        }, {
            "code": "Z1",
            "name": "Swansea"
        }, {
            "code": "Z2",
            "name": "Torfaen"
        }, {
            "code": "Z3",
            "name": "Vale of Glamorgan, The"
        }, {
            "code": "Z4",
            "name": "Wrexham"
        }]
    }, {
        "code": "UM",
        "name": "United States Minor Outlying"
    }, {
        "code": "VI",
        "name": "United States Virgin Islands"
    }, {
        "code": "UY",
        "name": "Uruguay",
        "states": [{
            "code": "01",
            "name": "Artigas"
        }, {
            "code": "02",
            "name": "Canelones"
        }, {
            "code": "03",
            "name": "Cerro Largo"
        }, {
            "code": "04",
            "name": "Colonia"
        }, {
            "code": "05",
            "name": "Durazno"
        }, {
            "code": "06",
            "name": "Flores"
        }, {
            "code": "07",
            "name": "Florida"
        }, {
            "code": "08",
            "name": "Lavalleja"
        }, {
            "code": "09",
            "name": "Maldonado"
        }, {
            "code": "10",
            "name": "Montevideo"
        }, {
            "code": "11",
            "name": "Paysandu"
        }, {
            "code": "12",
            "name": "Rio Negro"
        }, {
            "code": "13",
            "name": "Rivera"
        }, {
            "code": "14",
            "name": "Rocha"
        }, {
            "code": "15",
            "name": "Salto"
        }, {
            "code": "16",
            "name": "San Jose"
        }, {
            "code": "17",
            "name": "Soriano"
        }, {
            "code": "18",
            "name": "Tacuarembo"
        }, {
            "code": "19",
            "name": "Treinta y Tres"
        }]
    }, {
        "code": "UZ",
        "name": "Uzbekistan",
        "states": [{
            "code": "01",
            "name": "Andijon"
        }, {
            "code": "02",
            "name": "Bukhoro"
        }, {
            "code": "03",
            "name": "Farghona"
        }, {
            "code": "04",
            "name": "Jizzakh"
        }, {
            "code": "05",
            "name": "Khorazm"
        }, {
            "code": "06",
            "name": "Namangan"
        }, {
            "code": "07",
            "name": "Nawoiy"
        }, {
            "code": "08",
            "name": "Qashqadaryo"
        }, {
            "code": "09",
            "name": "Qoraqalpoghiston"
        }, {
            "code": "10",
            "name": "Samarqand"
        }, {
            "code": "11",
            "name": "Sirdaryo"
        }, {
            "code": "12",
            "name": "Surkhondaryo"
        }, {
            "code": "13",
            "name": "Toshkent"
        }, {
            "code": "14",
            "name": "Toshkent"
        }]
    }, {
        "code": "VU",
        "name": "Vanuatu",
        "states": [{
            "code": "05",
            "name": "Ambrym"
        }, {
            "code": "06",
            "name": "Aoba"
        }, {
            "code": "07",
            "name": "Torba"
        }, {
            "code": "08",
            "name": "Efate"
        }, {
            "code": "09",
            "name": "Epi"
        }, {
            "code": "10",
            "name": "Malakula"
        }, {
            "code": "11",
            "name": "Paama"
        }, {
            "code": "12",
            "name": "Pentecote"
        }, {
            "code": "13",
            "name": "Sanma"
        }, {
            "code": "14",
            "name": "Shepherd"
        }, {
            "code": "15",
            "name": "Tafea"
        }, {
            "code": "16",
            "name": "Malampa"
        }, {
            "code": "17",
            "name": "Penama"
        }, {
            "code": "18",
            "name": "Shefa"
        }]
    }, {
        "code": "VA",
        "name": "Vatican City State (Holy See)"
    }, {
        "code": "VE",
        "name": "Venezuela",
        "states": [{
            "code": "01",
            "name": "Amazonas"
        }, {
            "code": "02",
            "name": "Anzoategui"
        }, {
            "code": "03",
            "name": "Apure"
        }, {
            "code": "04",
            "name": "Aragua"
        }, {
            "code": "05",
            "name": "Barinas"
        }, {
            "code": "06",
            "name": "Bolivar"
        }, {
            "code": "07",
            "name": "Carabobo"
        }, {
            "code": "08",
            "name": "Cojedes"
        }, {
            "code": "09",
            "name": "Delta Amacuro"
        }, {
            "code": "11",
            "name": "Falcon"
        }, {
            "code": "12",
            "name": "Guarico"
        }, {
            "code": "13",
            "name": "Lara"
        }, {
            "code": "14",
            "name": "Merida"
        }, {
            "code": "15",
            "name": "Miranda"
        }, {
            "code": "16",
            "name": "Monagas"
        }, {
            "code": "17",
            "name": "Nueva Esparta"
        }, {
            "code": "18",
            "name": "Portuguesa"
        }, {
            "code": "19",
            "name": "Sucre"
        }, {
            "code": "20",
            "name": "Tachira"
        }, {
            "code": "21",
            "name": "Trujillo"
        }, {
            "code": "22",
            "name": "Yaracuy"
        }, {
            "code": "23",
            "name": "Zulia"
        }, {
            "code": "24",
            "name": "Dependencias Federales"
        }, {
            "code": "25",
            "name": "Distrito Federal"
        }, {
            "code": "26",
            "name": "Vargas"
        }]
    }, {
        "code": "VN",
        "name": "Viet Nam",
        "states": [{
            "code": "02",
            "name": "Bac Thai"
        }, {
            "code": "03",
            "name": "Ben Tre"
        }, {
            "code": "05",
            "name": "Cao Bang"
        }, {
            "code": "11",
            "name": "Ha Bac"
        }, {
            "code": "12",
            "name": "Hai Hung"
        }, {
            "code": "13",
            "name": "Hai Phong"
        }, {
            "code": "22",
            "name": "Lai Chau"
        }, {
            "code": "23",
            "name": "Lam Dong"
        }, {
            "code": "24",
            "name": "Long An"
        }, {
            "code": "29",
            "name": "Quang Nam-Da Nang"
        }, {
            "code": "30",
            "name": "Quang Ninh"
        }, {
            "code": "32",
            "name": "Son La"
        }, {
            "code": "33",
            "name": "Tay Ninh"
        }, {
            "code": "34",
            "name": "Thanh Hoa"
        }, {
            "code": "35",
            "name": "Thai Binh"
        }, {
            "code": "37",
            "name": "Tien Giang"
        }, {
            "code": "39",
            "name": "Lang Son"
        }, {
            "code": "43",
            "name": "An Giang"
        }, {
            "code": "44",
            "name": "Dac Lac"
        }, {
            "code": "45",
            "name": "Dong Nai"
        }, {
            "code": "46",
            "name": "Dong Thap"
        }, {
            "code": "47",
            "name": "Kien Giang"
        }, {
            "code": "48",
            "name": "Minh Hai"
        }, {
            "code": "49",
            "name": "Song Be"
        }, {
            "code": "50",
            "name": "Vinh Phu"
        }, {
            "code": "51",
            "name": "Ha Noi"
        }, {
            "code": "52",
            "name": "Ho Chi Minh"
        }, {
            "code": "53",
            "name": "Ba Ria-Vung Tau"
        }, {
            "code": "54",
            "name": "Binh Dinh"
        }, {
            "code": "55",
            "name": "Binh Thuan"
        }, {
            "code": "56",
            "name": "Can Tho"
        }, {
            "code": "57",
            "name": "Gia Lai"
        }, {
            "code": "58",
            "name": "Ha Giang"
        }, {
            "code": "59",
            "name": "Ha Tay"
        }, {
            "code": "60",
            "name": "Ha Tinh"
        }, {
            "code": "61",
            "name": "Hoa Binh"
        }, {
            "code": "62",
            "name": "Khanh Hoa"
        }, {
            "code": "63",
            "name": "Kon Tum"
        }, {
            "code": "64",
            "name": "Lao Cai"
        }, {
            "code": "65",
            "name": "Nam Ha"
        }, {
            "code": "66",
            "name": "Nghe An"
        }, {
            "code": "67",
            "name": "Ninh Binh"
        }, {
            "code": "68",
            "name": "Ninh Thuan"
        }, {
            "code": "69",
            "name": "Phu Yen"
        }, {
            "code": "70",
            "name": "Quang Binh"
        }, {
            "code": "71",
            "name": "Quang Ngai"
        }, {
            "code": "72",
            "name": "Quang Tri"
        }, {
            "code": "73",
            "name": "Soc Trang"
        }, {
            "code": "74",
            "name": "Thua Thien"
        }, {
            "code": "75",
            "name": "Tra Vinh"
        }, {
            "code": "76",
            "name": "Tuyen Quang"
        }, {
            "code": "77",
            "name": "Vinh Long"
        }, {
            "code": "78",
            "name": "Yen Bai"
        }]
    }, {
        "code": "WF",
        "name": "Wallis & Futuna Islands"
    }, {
        "code": "EH",
        "name": "Western Sahara"
    }, {
        "code": "YE",
        "name": "Yemen",
        "states": [{
            "code": "01",
            "name": "Abyan"
        }, {
            "code": "03",
            "name": "Al Mahrah"
        }, {
            "code": "04",
            "name": "Hadramawt"
        }, {
            "code": "05",
            "name": "Shabwah"
        }, {
            "code": "08",
            "name": "Al Hudaydah"
        }, {
            "code": "10",
            "name": "Al Mahwit"
        }, {
            "code": "11",
            "name": "Dhamar"
        }, {
            "code": "14",
            "name": "Ma'rib"
        }, {
            "code": "15",
            "name": "Sa"
        }, {
            "code": "16",
            "name": "San"
        }, {
            "code": "20",
            "name": "Al Bayda'"
        }, {
            "code": "21",
            "name": "Al Jawf"
        }, {
            "code": "22",
            "name": "Hajjah"
        }, {
            "code": "23",
            "name": "Ibb"
        }, {
            "code": "24",
            "name": "Lahij"
        }, {
            "code": "25",
            "name": "Ta"
        }]
    }, {
        "code": "ZM",
        "name": "Zambia",
        "states": [{
            "code": "01",
            "name": "Western"
        }, {
            "code": "02",
            "name": "Central"
        }, {
            "code": "03",
            "name": "Eastern"
        }, {
            "code": "04",
            "name": "Luapula"
        }, {
            "code": "05",
            "name": "Northern"
        }, {
            "code": "06",
            "name": "North-Western"
        }, {
            "code": "07",
            "name": "Southern"
        }, {
            "code": "08",
            "name": "Copperbelt"
        }, {
            "code": "09",
            "name": "Lusaka"
        }]
    }, {
        "code": "ZW",
        "name": "Zimbabwe",
        "states": [{
            "code": "01",
            "name": "Manicaland"
        }, {
            "code": "02",
            "name": "Midlands"
        }, {
            "code": "03",
            "name": "Mashonaland Central"
        }, {
            "code": "04",
            "name": "Mashonaland East"
        }, {
            "code": "05",
            "name": "Mashonaland West"
        }, {
            "code": "06",
            "name": "Matabeleland North"
        }, {
            "code": "07",
            "name": "Matabeleland South"
        }, {
            "code": "08",
            "name": "Masvingo"
        }, {
            "code": "09",
            "name": "Bulawayo"
        }, {
            "code": "10",
            "name": "Harare"
        }]
    }]
}

export const currencies = {
    countries:[
    {
        "country": "Afghanistan",
        "currency_code": "AFN"
    },
    {
        "country": "Albania",
        "currency_code": "ALL"
    },
    {
        "country": "Algeria",
        "currency_code": "DZD"
    },
    {
        "country": "American Samoa",
        "currency_code": "USD"
    },
    {
        "country": "Andorra",
        "currency_code": "EUR"
    },
    {
        "country": "Angola",
        "currency_code": "AOA"
    },
    {
        "country": "Anguilla",
        "currency_code": "XCD"
    },
    {
        "country": "Antarctica",
        "currency_code": "XCD"
    },
    {
        "country": "Antigua and Barbuda",
        "currency_code": "XCD"
    },
    {
        "country": "Argentina",
        "currency_code": "ARS"
    },
    {
        "country": "Armenia",
        "currency_code": "AMD"
    },
    {
        "country": "Aruba",
        "currency_code": "AWG"
    },
    {
        "country": "Australia",
        "currency_code": "AUD"
    },
    {
        "country": "Austria",
        "currency_code": "EUR"
    },
    {
        "country": "Azerbaijan",
        "currency_code": "AZN"
    },
    {
        "country": "Bahamas",
        "currency_code": "BSD"
    },
    {
        "country": "Bahrain",
        "currency_code": "BHD"
    },
    {
        "country": "Bangladesh",
        "currency_code": "BDT"
    },
    {
        "country": "Barbados",
        "currency_code": "BBD"
    },
    {
        "country": "Belarus",
        "currency_code": "BYR"
    },
    {
        "country": "Belgium",
        "currency_code": "EUR"
    },
    {
        "country": "Belize",
        "currency_code": "BZD"
    },
    {
        "country": "Benin",
        "currency_code": "XOF"
    },
    {
        "country": "Bermuda",
        "currency_code": "BMD"
    },
    {
        "country": "Bhutan",
        "currency_code": "BTN"
    },
    {
        "country": "Bolivia",
        "currency_code": "BOB"
    },
    {
        "country": "Bosnia and Herzegovina",
        "currency_code": "BAM"
    },
    {
        "country": "Botswana",
        "currency_code": "BWP"
    },
    {
        "country": "Bouvet Island",
        "currency_code": "NOK"
    },
    {
        "country": "Brazil",
        "currency_code": "BRL"
    },
    {
        "country": "British Indian Ocean Territory",
        "currency_code": "USD"
    },
    {
        "country": "Brunei",
        "currency_code": "BND"
    },
    {
        "country": "Bulgaria",
        "currency_code": "BGN"
    },
    {
        "country": "Burkina Faso",
        "currency_code": "XOF"
    },
    {
        "country": "Burundi",
        "currency_code": "BIF"
    },
    {
        "country": "Cambodia",
        "currency_code": "KHR"
    },
    {
        "country": "Cameroon",
        "currency_code": "XAF"
    },
    {
        "country": "Canada",
        "currency_code": "CAD"
    },
    {
        "country": "Cape Verde",
        "currency_code": "CVE"
    },
    {
        "country": "Cayman Islands",
        "currency_code": "KYD"
    },
    {
        "country": "Central African Republic",
        "currency_code": "XAF"
    },
    {
        "country": "Chad",
        "currency_code": "XAF"
    },
    {
        "country": "Chile",
        "currency_code": "CLP"
    },
    {
        "country": "China",
        "currency_code": "CNY"
    },
    {
        "country": "Christmas Island",
        "currency_code": "AUD"
    },
    {
        "country": "Cocos (Keeling) Islands",
        "currency_code": "AUD"
    },
    {
        "country": "Colombia",
        "currency_code": "COP"
    },
    {
        "country": "Comoros",
        "currency_code": "KMF"
    },
    {
        "country": "Congo",
        "currency_code": "XAF"
    },
    {
        "country": "Cook Islands",
        "currency_code": "NZD"
    },
    {
        "country": "Costa Rica",
        "currency_code": "CRC"
    },
    {
        "country": "Croatia",
        "currency_code": "HRK"
    },
    {
        "country": "Cuba",
        "currency_code": "CUP"
    },
    {
        "country": "Cyprus",
        "currency_code": "EUR"
    },
    {
        "country": "Czech Republic",
        "currency_code": "CZK"
    },
    {
        "country": "Denmark",
        "currency_code": "DKK"
    },
    {
        "country": "Djibouti",
        "currency_code": "DJF"
    },
    {
        "country": "Dominica",
        "currency_code": "XCD"
    },
    {
        "country": "Dominican Republic",
        "currency_code": "DOP"
    },
    {
        "country": "East Timor",
        "currency_code": "USD"
    },
    {
        "country": "Ecuador",
        "currency_code": "ECS"
    },
    {
        "country": "Egypt",
        "currency_code": "EGP"
    },
    {
        "country": "El Salvador",
        "currency_code": "SVC"
    },
    {
        "country": "England",
        "currency_code": "GBP"
    },
    {
        "country": "Equatorial Guinea",
        "currency_code": "XAF"
    },
    {
        "country": "Eritrea",
        "currency_code": "ERN"
    },
    {
        "country": "Estonia",
        "currency_code": "EUR"
    },
    {
        "country": "Ethiopia",
        "currency_code": "ETB"
    },
    {
        "country": "Falkland Islands",
        "currency_code": "FKP"
    },
    {
        "country": "Faroe Islands",
        "currency_code": "DKK"
    },
    {
        "country": "Fiji Islands",
        "currency_code": "FJD"
    },
    {
        "country": "Finland",
        "currency_code": "EUR"
    },
    {
        "country": "France",
        "currency_code": "EUR"
    },
    {
        "country": "French Guiana",
        "currency_code": "EUR"
    },
    {
        "country": "French Polynesia",
        "currency_code": "XPF"
    },
    {
        "country": "French Southern territories",
        "currency_code": "EUR"
    },
    {
        "country": "Gabon",
        "currency_code": "XAF"
    },
    {
        "country": "Gambia",
        "currency_code": "GMD"
    },
    {
        "country": "Georgia",
        "currency_code": "GEL"
    },
    {
        "country": "Germany",
        "currency_code": "EUR"
    },
    {
        "country": "Ghana",
        "currency_code": "GHS"
    },
    {
        "country": "Gibraltar",
        "currency_code": "GIP"
    },
    {
        "country": "Greece",
        "currency_code": "EUR"
    },
    {
        "country": "Greenland",
        "currency_code": "DKK"
    },
    {
        "country": "Grenada",
        "currency_code": "XCD"
    },
    {
        "country": "Guadeloupe",
        "currency_code": "EUR"
    },
    {
        "country": "Guam",
        "currency_code": "USD"
    },
    {
        "country": "Guatemala",
        "currency_code": "QTQ"
    },
    {
        "country": "Guinea",
        "currency_code": "GNF"
    },
    {
        "country": "Guinea-Bissau",
        "currency_code": "CFA"
    },
    {
        "country": "Guyana",
        "currency_code": "GYD"
    },
    {
        "country": "Haiti",
        "currency_code": "HTG"
    },
    {
        "country": "Heard Island and McDonald Islands",
        "currency_code": "AUD"
    },
    {
        "country": "Holy See (Vatican City State)",
        "currency_code": "EUR"
    },
    {
        "country": "Honduras",
        "currency_code": "HNL"
    },
    {
        "country": "Hong Kong",
        "currency_code": "HKD"
    },
    {
        "country": "Hungary",
        "currency_code": "HUF"
    },
    {
        "country": "Iceland",
        "currency_code": "ISK"
    },
    {
        "country": "India",
        "currency_code": "INR"
    },
    {
        "country": "Indonesia",
        "currency_code": "IDR"
    },
    {
        "country": "Iran",
        "currency_code": "IRR"
    },
    {
        "country": "Iraq",
        "currency_code": "IQD"
    },
    {
        "country": "Ireland",
        "currency_code": "EUR"
    },
    {
        "country": "Israel",
        "currency_code": "ILS"
    },
    {
        "country": "Italy",
        "currency_code": "EUR"
    },
    {
        "country": "Ivory Coast",
        "currency_code": "XOF"
    },
    {
        "country": "Jamaica",
        "currency_code": "JMD"
    },
    {
        "country": "Japan",
        "currency_code": "JPY"
    },
    {
        "country": "Jordan",
        "currency_code": "JOD"
    },
    {
        "country": "Kazakhstan",
        "currency_code": "KZT"
    },
    {
        "country": "Kenya",
        "currency_code": "KES"
    },
    {
        "country": "Kiribati",
        "currency_code": "AUD"
    },
    {
        "country": "Kuwait",
        "currency_code": "KWD"
    },
    {
        "country": "Kyrgyzstan",
        "currency_code": "KGS"
    },
    {
        "country": "Laos",
        "currency_code": "LAK"
    },
    {
        "country": "Latvia",
        "currency_code": "LVL"
    },
    {
        "country": "Lebanon",
        "currency_code": "LBP"
    },
    {
        "country": "Lesotho",
        "currency_code": "LSL"
    },
    {
        "country": "Liberia",
        "currency_code": "LRD"
    },
    {
        "country": "Libyan Arab Jamahiriya",
        "currency_code": "LYD"
    },
    {
        "country": "Liechtenstein",
        "currency_code": "CHF"
    },
    {
        "country": "Lithuania",
        "currency_code": "LTL"
    },
    {
        "country": "Luxembourg",
        "currency_code": "EUR"
    },
    {
        "country": "Macao",
        "currency_code": "MOP"
    },
    {
        "country": "North Macedonia",
        "currency_code": "MKD"
    },
    {
        "country": "Madagascar",
        "currency_code": "MGF"
    },
    {
        "country": "Malawi",
        "currency_code": "MWK"
    },
    {
        "country": "Malaysia",
        "currency_code": "MYR"
    },
    {
        "country": "Maldives",
        "currency_code": "MVR"
    },
    {
        "country": "Mali",
        "currency_code": "XOF"
    },
    {
        "country": "Malta",
        "currency_code": "EUR"
    },
    {
        "country": "Marshall Islands",
        "currency_code": "USD"
    },
    {
        "country": "Martinique",
        "currency_code": "EUR"
    },
    {
        "country": "Mauritania",
        "currency_code": "MRO"
    },
    {
        "country": "Mauritius",
        "currency_code": "MUR"
    },
    {
        "country": "Mayotte",
        "currency_code": "EUR"
    },
    {
        "country": "Mexico",
        "currency_code": "MXN"
    },
    {
        "country": "Micronesia, Federated States of",
        "currency_code": "USD"
    },
    {
        "country": "Moldova",
        "currency_code": "MDL"
    },
    {
        "country": "Monaco",
        "currency_code": "EUR"
    },
    {
        "country": "Mongolia",
        "currency_code": "MNT"
    },
    {
        "country": "Montserrat",
        "currency_code": "XCD"
    },
    {
        "country": "Morocco",
        "currency_code": "MAD"
    },
    {
        "country": "Mozambique",
        "currency_code": "MZN"
    },
    {
        "country": "Myanmar",
        "currency_code": "MMR"
    },
    {
        "country": "Namibia",
        "currency_code": "NAD"
    },
    {
        "country": "Nauru",
        "currency_code": "AUD"
    },
    {
        "country": "Nepal",
        "currency_code": "NPR"
    },
    {
        "country": "Netherlands",
        "currency_code": "EUR"
    },
    {
        "country": "Netherlands Antilles",
        "currency_code": "ANG"
    },
    {
        "country": "New Caledonia",
        "currency_code": "XPF"
    },
    {
        "country": "New Zealand",
        "currency_code": "NZD"
    },
    {
        "country": "Nicaragua",
        "currency_code": "NIO"
    },
    {
        "country": "Niger",
        "currency_code": "XOF"
    },
    {
        "country": "Nigeria",
        "currency_code": "NGN"
    },
    {
        "country": "Niue",
        "currency_code": "NZD"
    },
    {
        "country": "Norfolk Island",
        "currency_code": "AUD"
    },
    {
        "country": "North Korea",
        "currency_code": "KPW"
    },
    {
        "country": "Northern Ireland",
        "currency_code": "GBP"
    },
    {
        "country": "Northern Mariana Islands",
        "currency_code": "USD"
    },
    {
        "country": "Norway",
        "currency_code": "NOK"
    },
    {
        "country": "Oman",
        "currency_code": "OMR"
    },
    {
        "country": "Pakistan",
        "currency_code": "PKR"
    },
    {
        "country": "Palau",
        "currency_code": "USD"
    },
    {
        "country": "Palestine",
        "currency_code": null
    },
    {
        "country": "Panama",
        "currency_code": "PAB"
    },
    {
        "country": "Papua New Guinea",
        "currency_code": "PGK"
    },
    {
        "country": "Paraguay",
        "currency_code": "PYG"
    },
    {
        "country": "Peru",
        "currency_code": "PEN"
    },
    {
        "country": "Philippines",
        "currency_code": "PHP"
    },
    {
        "country": "Pitcairn",
        "currency_code": "NZD"
    },
    {
        "country": "Poland",
        "currency_code": "PLN"
    },
    {
        "country": "Portugal",
        "currency_code": "EUR"
    },
    {
        "country": "Puerto Rico",
        "currency_code": "USD"
    },
    {
        "country": "Qatar",
        "currency_code": "QAR"
    },
    {
        "country": "Reunion",
        "currency_code": "EUR"
    },
    {
        "country": "Romania",
        "currency_code": "RON"
    },
    {
        "country": "Russian Federation",
        "currency_code": "RUB"
    },
    {
        "country": "Rwanda",
        "currency_code": "RWF"
    },
    {
        "country": "Saint Helena",
        "currency_code": "SHP"
    },
    {
        "country": "Saint Kitts and Nevis",
        "currency_code": "XCD"
    },
    {
        "country": "Saint Lucia",
        "currency_code": "XCD"
    },
    {
        "country": "Saint Pierre and Miquelon",
        "currency_code": "EUR"
    },
    {
        "country": "Saint Vincent and the Grenadines",
        "currency_code": "XCD"
    },
    {
        "country": "Samoa",
        "currency_code": "WST"
    },
    {
        "country": "San Marino",
        "currency_code": "EUR"
    },
    {
        "country": "Sao Tome and Principe",
        "currency_code": "STD"
    },
    {
        "country": "Saudi Arabia",
        "currency_code": "SAR"
    },
    {
        "country": "Scotland",
        "currency_code": "GBP"
    },
    {
        "country": "Senegal",
        "currency_code": "XOF"
    },
    {
        "country": "Seychelles",
        "currency_code": "SCR"
    },
    {
        "country": "Sierra Leone",
        "currency_code": "SLL"
    },
    {
        "country": "Singapore",
        "currency_code": "SGD"
    },
    {
        "country": "Slovakia",
        "currency_code": "EUR"
    },
    {
        "country": "Slovenia",
        "currency_code": "EUR"
    },
    {
        "country": "Solomon Islands",
        "currency_code": "SBD"
    },
    {
        "country": "Somalia",
        "currency_code": "SOS"
    },
    {
        "country": "South Africa",
        "currency_code": "ZAR"
    },
    {
        "country": "South Georgia and the South Sandwich Islands",
        "currency_code": "GBP"
    },
    {
        "country": "South Korea",
        "currency_code": "KRW"
    },
    {
        "country": "South Sudan",
        "currency_code": "SSP"
    },
    {
        "country": "Spain",
        "currency_code": "EUR"
    },
    {
        "country": "SriLanka",
        "currency_code": "LKR"
    },
    {
        "country": "Sudan",
        "currency_code": "SDG"
    },
    {
        "country": "Suriname",
        "currency_code": "SRD"
    },
    {
        "country": "Svalbard and Jan Mayen",
        "currency_code": "NOK"
    },
    {
        "country": "Swaziland",
        "currency_code": "SZL"
    },
    {
        "country": "Sweden",
        "currency_code": "SEK"
    },
    {
        "country": "Switzerland",
        "currency_code": "CHF"
    },
    {
        "country": "Syria",
        "currency_code": "SYP"
    },
    {
        "country": "Tajikistan",
        "currency_code": "TJS"
    },
    {
        "country": "Tanzania",
        "currency_code": "TZS"
    },
    {
        "country": "Thailand",
        "currency_code": "THB"
    },
    {
        "country": "The Democratic Republic of Congo",
        "currency_code": "CDF"
    },
    {
        "country": "Togo",
        "currency_code": "XOF"
    },
    {
        "country": "Tokelau",
        "currency_code": "NZD"
    },
    {
        "country": "Tonga",
        "currency_code": "TOP"
    },
    {
        "country": "Trinidad and Tobago",
        "currency_code": "TTD"
    },
    {
        "country": "Tunisia",
        "currency_code": "TND"
    },
    {
        "country": "Turkey",
        "currency_code": "TRY"
    },
    {
        "country": "Turkmenistan",
        "currency_code": "TMT"
    },
    {
        "country": "Turks and Caicos Islands",
        "currency_code": "USD"
    },
    {
        "country": "Tuvalu",
        "currency_code": "AUD"
    },
    {
        "country": "Uganda",
        "currency_code": "UGX"
    },
    {
        "country": "Ukraine",
        "currency_code": "UAH"
    },
    {
        "country": "United Arab Emirates",
        "currency_code": "AED"
    },
    {
        "country": "United Kingdom",
        "currency_code": "GBP"
    },
    {
        "country": "United States",
        "currency_code": "USD"
    },
    {
        "country": "United States Minor Outlying Islands",
        "currency_code": "USD"
    },
    {
        "country": "Uruguay",
        "currency_code": "UYU"
    },
    {
        "country": "Uzbekistan",
        "currency_code": "UZS"
    },
    {
        "country": "Vanuatu",
        "currency_code": "VUV"
    },
    {
        "country": "Venezuela",
        "currency_code": "VEF"
    },
    {
        "country": "Vietnam",
        "currency_code": "VND"
    },
    {
        "country": "Virgin Islands, British",
        "currency_code": "USD"
    },
    {
        "country": "Virgin Islands, U.S.",
        "currency_code": "USD"
    },
    {
        "country": "Wales",
        "currency_code": "GBP"
    },
    {
        "country": "Wallis and Futuna",
        "currency_code": "XPF"
    },
    {
        "country": "Western Sahara",
        "currency_code": "MAD"
    },
    {
        "country": "Yemen",
        "currency_code": "YER"
    },
    {
        "country": "Yugoslavia",
        "currency_code": null
    },
    {
        "country": "Zambia",
        "currency_code": "ZMW"
    },
    {
        "country": "Zimbabwe",
        "currency_code": "ZWD"
    }
]}

export const currency_symbols = {
    countries: {
    "USD": {
        "symbol": "$",
        "name": "US Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "USD",
        "name_plural": "US dollars"
    },
    "CAD": {
        "symbol": "CA$",
        "name": "Canadian Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "CAD",
        "name_plural": "Canadian dollars"
    },
    "EUR": {
        "symbol": "€",
        "name": "Euro",
        "symbol_native": "€",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "EUR",
        "name_plural": "euros"
    },
    "AED": {
        "symbol": "AED",
        "name": "United Arab Emirates Dirham",
        "symbol_native": "د.إ.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "AED",
        "name_plural": "UAE dirhams"
    },
    "AFN": {
        "symbol": "Af",
        "name": "Afghan Afghani",
        "symbol_native": "؋",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "AFN",
        "name_plural": "Afghan Afghanis"
    },
    "ALL": {
        "symbol": "ALL",
        "name": "Albanian Lek",
        "symbol_native": "Lek",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "ALL",
        "name_plural": "Albanian lekë"
    },
    "AMD": {
        "symbol": "AMD",
        "name": "Armenian Dram",
        "symbol_native": "դր.",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "AMD",
        "name_plural": "Armenian drams"
    },
    "ARS": {
        "symbol": "AR$",
        "name": "Argentine Peso",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "ARS",
        "name_plural": "Argentine pesos"
    },
    "AUD": {
        "symbol": "AU$",
        "name": "Australian Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "AUD",
        "name_plural": "Australian dollars"
    },
    "AZN": {
        "symbol": "man.",
        "name": "Azerbaijani Manat",
        "symbol_native": "ман.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "AZN",
        "name_plural": "Azerbaijani manats"
    },
    "BAM": {
        "symbol": "KM",
        "name": "Bosnia-Herzegovina Convertible Mark",
        "symbol_native": "KM",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BAM",
        "name_plural": "Bosnia-Herzegovina convertible marks"
    },
    "BDT": {
        "symbol": "Tk",
        "name": "Bangladeshi Taka",
        "symbol_native": "৳",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BDT",
        "name_plural": "Bangladeshi takas"
    },
    "BGN": {
        "symbol": "BGN",
        "name": "Bulgarian Lev",
        "symbol_native": "лв.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BGN",
        "name_plural": "Bulgarian leva"
    },
    "BHD": {
        "symbol": "BD",
        "name": "Bahraini Dinar",
        "symbol_native": "د.ب.‏",
        "decimal_digits": 3,
        "rounding": 0,
        "code": "BHD",
        "name_plural": "Bahraini dinars"
    },
    "BIF": {
        "symbol": "FBu",
        "name": "Burundian Franc",
        "symbol_native": "FBu",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "BIF",
        "name_plural": "Burundian francs"
    },
    "BND": {
        "symbol": "BN$",
        "name": "Brunei Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BND",
        "name_plural": "Brunei dollars"
    },
    "BOB": {
        "symbol": "Bs",
        "name": "Bolivian Boliviano",
        "symbol_native": "Bs",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BOB",
        "name_plural": "Bolivian bolivianos"
    },
    "BRL": {
        "symbol": "R$",
        "name": "Brazilian Real",
        "symbol_native": "R$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BRL",
        "name_plural": "Brazilian reals"
    },
    "BWP": {
        "symbol": "BWP",
        "name": "Botswanan Pula",
        "symbol_native": "P",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BWP",
        "name_plural": "Botswanan pulas"
    },
    "BYR": {
        "symbol": "BYR",
        "name": "Belarusian Ruble",
        "symbol_native": "BYR",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "BYR",
        "name_plural": "Belarusian rubles"
    },
    "BZD": {
        "symbol": "BZ$",
        "name": "Belize Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BZD",
        "name_plural": "Belize dollars"
    },
    "CDF": {
        "symbol": "CDF",
        "name": "Congolese Franc",
        "symbol_native": "FrCD",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "CDF",
        "name_plural": "Congolese francs"
    },
    "CHF": {
        "symbol": "CHF",
        "name": "Swiss Franc",
        "symbol_native": "CHF",
        "decimal_digits": 2,
        "rounding": 0.05,
        "code": "CHF",
        "name_plural": "Swiss francs"
    },
    "CLP": {
        "symbol": "CL$",
        "name": "Chilean Peso",
        "symbol_native": "$",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "CLP",
        "name_plural": "Chilean pesos"
    },
    "CNY": {
        "symbol": "CN¥",
        "name": "Chinese Yuan",
        "symbol_native": "CN¥",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "CNY",
        "name_plural": "Chinese yuan"
    },
    "COP": {
        "symbol": "CO$",
        "name": "Colombian Peso",
        "symbol_native": "$",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "COP",
        "name_plural": "Colombian pesos"
    },
    "CRC": {
        "symbol": "₡",
        "name": "Costa Rican Colón",
        "symbol_native": "₡",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "CRC",
        "name_plural": "Costa Rican colóns"
    },
    "CVE": {
        "symbol": "CV$",
        "name": "Cape Verdean Escudo",
        "symbol_native": "CV$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "CVE",
        "name_plural": "Cape Verdean escudos"
    },
    "CZK": {
        "symbol": "Kč",
        "name": "Czech Republic Koruna",
        "symbol_native": "Kč",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "CZK",
        "name_plural": "Czech Republic korunas"
    },
    "DJF": {
        "symbol": "Fdj",
        "name": "Djiboutian Franc",
        "symbol_native": "Fdj",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "DJF",
        "name_plural": "Djiboutian francs"
    },
    "DKK": {
        "symbol": "Dkr",
        "name": "Danish Krone",
        "symbol_native": "kr",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "DKK",
        "name_plural": "Danish kroner"
    },
    "DOP": {
        "symbol": "RD$",
        "name": "Dominican Peso",
        "symbol_native": "RD$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "DOP",
        "name_plural": "Dominican pesos"
    },
    "DZD": {
        "symbol": "DA",
        "name": "Algerian Dinar",
        "symbol_native": "د.ج.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "DZD",
        "name_plural": "Algerian dinars"
    },
    "EEK": {
        "symbol": "Ekr",
        "name": "Estonian Kroon",
        "symbol_native": "kr",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "EEK",
        "name_plural": "Estonian kroons"
    },
    "EGP": {
        "symbol": "EGP",
        "name": "Egyptian Pound",
        "symbol_native": "ج.م.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "EGP",
        "name_plural": "Egyptian pounds"
    },
    "ERN": {
        "symbol": "Nfk",
        "name": "Eritrean Nakfa",
        "symbol_native": "Nfk",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "ERN",
        "name_plural": "Eritrean nakfas"
    },
    "ETB": {
        "symbol": "Br",
        "name": "Ethiopian Birr",
        "symbol_native": "Br",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "ETB",
        "name_plural": "Ethiopian birrs"
    },
    "GBP": {
        "symbol": "£",
        "name": "British Pound Sterling",
        "symbol_native": "£",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "GBP",
        "name_plural": "British pounds sterling"
    },
    "GEL": {
        "symbol": "GEL",
        "name": "Georgian Lari",
        "symbol_native": "GEL",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "GEL",
        "name_plural": "Georgian laris"
    },
    "GHS": {
        "symbol": "GH₵",
        "name": "Ghanaian Cedi",
        "symbol_native": "GH₵",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "GHS",
        "name_plural": "Ghanaian cedis"
    },
    "GNF": {
        "symbol": "FG",
        "name": "Guinean Franc",
        "symbol_native": "FG",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "GNF",
        "name_plural": "Guinean francs"
    },
    "GTQ": {
        "symbol": "GTQ",
        "name": "Guatemalan Quetzal",
        "symbol_native": "Q",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "GTQ",
        "name_plural": "Guatemalan quetzals"
    },
    "HKD": {
        "symbol": "HK$",
        "name": "Hong Kong Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "HKD",
        "name_plural": "Hong Kong dollars"
    },
    "HNL": {
        "symbol": "HNL",
        "name": "Honduran Lempira",
        "symbol_native": "L",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "HNL",
        "name_plural": "Honduran lempiras"
    },
    "HRK": {
        "symbol": "kn",
        "name": "Croatian Kuna",
        "symbol_native": "kn",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "HRK",
        "name_plural": "Croatian kunas"
    },
    "HUF": {
        "symbol": "Ft",
        "name": "Hungarian Forint",
        "symbol_native": "Ft",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "HUF",
        "name_plural": "Hungarian forints"
    },
    "IDR": {
        "symbol": "Rp",
        "name": "Indonesian Rupiah",
        "symbol_native": "Rp",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "IDR",
        "name_plural": "Indonesian rupiahs"
    },
    "ILS": {
        "symbol": "₪",
        "name": "Israeli New Sheqel",
        "symbol_native": "₪",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "ILS",
        "name_plural": "Israeli new sheqels"
    },
    "INR": {
        "symbol": "Rs",
        "name": "Indian Rupee",
        "symbol_native": "টকা",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "INR",
        "name_plural": "Indian rupees"
    },
    "IQD": {
        "symbol": "IQD",
        "name": "Iraqi Dinar",
        "symbol_native": "د.ع.‏",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "IQD",
        "name_plural": "Iraqi dinars"
    },
    "IRR": {
        "symbol": "IRR",
        "name": "Iranian Rial",
        "symbol_native": "﷼",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "IRR",
        "name_plural": "Iranian rials"
    },
    "ISK": {
        "symbol": "Ikr",
        "name": "Icelandic Króna",
        "symbol_native": "kr",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "ISK",
        "name_plural": "Icelandic krónur"
    },
    "JMD": {
        "symbol": "J$",
        "name": "Jamaican Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "JMD",
        "name_plural": "Jamaican dollars"
    },
    "JOD": {
        "symbol": "JD",
        "name": "Jordanian Dinar",
        "symbol_native": "د.أ.‏",
        "decimal_digits": 3,
        "rounding": 0,
        "code": "JOD",
        "name_plural": "Jordanian dinars"
    },
    "JPY": {
        "symbol": "¥",
        "name": "Japanese Yen",
        "symbol_native": "￥",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "JPY",
        "name_plural": "Japanese yen"
    },
    "KES": {
        "symbol": "Ksh",
        "name": "Kenyan Shilling",
        "symbol_native": "Ksh",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "KES",
        "name_plural": "Kenyan shillings"
    },
    "KHR": {
        "symbol": "KHR",
        "name": "Cambodian Riel",
        "symbol_native": "៛",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "KHR",
        "name_plural": "Cambodian riels"
    },
    "KMF": {
        "symbol": "CF",
        "name": "Comorian Franc",
        "symbol_native": "FC",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "KMF",
        "name_plural": "Comorian francs"
    },
    "KRW": {
        "symbol": "₩",
        "name": "South Korean Won",
        "symbol_native": "₩",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "KRW",
        "name_plural": "South Korean won"
    },
    "KWD": {
        "symbol": "KD",
        "name": "Kuwaiti Dinar",
        "symbol_native": "د.ك.‏",
        "decimal_digits": 3,
        "rounding": 0,
        "code": "KWD",
        "name_plural": "Kuwaiti dinars"
    },
    "KZT": {
        "symbol": "KZT",
        "name": "Kazakhstani Tenge",
        "symbol_native": "тңг.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "KZT",
        "name_plural": "Kazakhstani tenges"
    },
    "LBP": {
        "symbol": "LB£",
        "name": "Lebanese Pound",
        "symbol_native": "ل.ل.‏",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "LBP",
        "name_plural": "Lebanese pounds"
    },
    "LKR": {
        "symbol": "SLRs",
        "name": "Sri Lankan Rupee",
        "symbol_native": "SL Re",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "LKR",
        "name_plural": "Sri Lankan rupees"
    },
    "LTL": {
        "symbol": "Lt",
        "name": "Lithuanian Litas",
        "symbol_native": "Lt",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "LTL",
        "name_plural": "Lithuanian litai"
    },
    "LVL": {
        "symbol": "Ls",
        "name": "Latvian Lats",
        "symbol_native": "Ls",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "LVL",
        "name_plural": "Latvian lati"
    },
    "LYD": {
        "symbol": "LD",
        "name": "Libyan Dinar",
        "symbol_native": "د.ل.‏",
        "decimal_digits": 3,
        "rounding": 0,
        "code": "LYD",
        "name_plural": "Libyan dinars"
    },
    "MAD": {
        "symbol": "MAD",
        "name": "Moroccan Dirham",
        "symbol_native": "د.م.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MAD",
        "name_plural": "Moroccan dirhams"
    },
    "MDL": {
        "symbol": "MDL",
        "name": "Moldovan Leu",
        "symbol_native": "MDL",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MDL",
        "name_plural": "Moldovan lei"
    },
    "MGA": {
        "symbol": "MGA",
        "name": "Malagasy Ariary",
        "symbol_native": "MGA",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "MGA",
        "name_plural": "Malagasy Ariaries"
    },
    "MKD": {
        "symbol": "MKD",
        "name": "Macedonian Denar",
        "symbol_native": "MKD",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MKD",
        "name_plural": "Macedonian denari"
    },
    "MMK": {
        "symbol": "MMK",
        "name": "Myanma Kyat",
        "symbol_native": "K",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "MMK",
        "name_plural": "Myanma kyats"
    },
    "MOP": {
        "symbol": "MOP$",
        "name": "Macanese Pataca",
        "symbol_native": "MOP$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MOP",
        "name_plural": "Macanese patacas"
    },
    "MUR": {
        "symbol": "MURs",
        "name": "Mauritian Rupee",
        "symbol_native": "MURs",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "MUR",
        "name_plural": "Mauritian rupees"
    },
    "MXN": {
        "symbol": "MX$",
        "name": "Mexican Peso",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MXN",
        "name_plural": "Mexican pesos"
    },
    "MYR": {
        "symbol": "RM",
        "name": "Malaysian Ringgit",
        "symbol_native": "RM",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MYR",
        "name_plural": "Malaysian ringgits"
    },
    "MZN": {
        "symbol": "MTn",
        "name": "Mozambican Metical",
        "symbol_native": "MTn",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MZN",
        "name_plural": "Mozambican meticals"
    },
    "NAD": {
        "symbol": "N$",
        "name": "Namibian Dollar",
        "symbol_native": "N$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "NAD",
        "name_plural": "Namibian dollars"
    },
    "NGN": {
        "symbol": "₦",
        "name": "Nigerian Naira",
        "symbol_native": "₦",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "NGN",
        "name_plural": "Nigerian nairas"
    },
    "NIO": {
        "symbol": "C$",
        "name": "Nicaraguan Córdoba",
        "symbol_native": "C$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "NIO",
        "name_plural": "Nicaraguan córdobas"
    },
    "NOK": {
        "symbol": "Nkr",
        "name": "Norwegian Krone",
        "symbol_native": "kr",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "NOK",
        "name_plural": "Norwegian kroner"
    },
    "NPR": {
        "symbol": "NPRs",
        "name": "Nepalese Rupee",
        "symbol_native": "नेरू",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "NPR",
        "name_plural": "Nepalese rupees"
    },
    "NZD": {
        "symbol": "NZ$",
        "name": "New Zealand Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "NZD",
        "name_plural": "New Zealand dollars"
    },
    "OMR": {
        "symbol": "OMR",
        "name": "Omani Rial",
        "symbol_native": "ر.ع.‏",
        "decimal_digits": 3,
        "rounding": 0,
        "code": "OMR",
        "name_plural": "Omani rials"
    },
    "PAB": {
        "symbol": "B/.",
        "name": "Panamanian Balboa",
        "symbol_native": "B/.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "PAB",
        "name_plural": "Panamanian balboas"
    },
    "PEN": {
        "symbol": "S/.",
        "name": "Peruvian Nuevo Sol",
        "symbol_native": "S/.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "PEN",
        "name_plural": "Peruvian nuevos soles"
    },
    "PHP": {
        "symbol": "₱",
        "name": "Philippine Peso",
        "symbol_native": "₱",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "PHP",
        "name_plural": "Philippine pesos"
    },
    "PKR": {
        "symbol": "PKRs",
        "name": "Pakistani Rupee",
        "symbol_native": "₨",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "PKR",
        "name_plural": "Pakistani rupees"
    },
    "PLN": {
        "symbol": "zł",
        "name": "Polish Zloty",
        "symbol_native": "zł",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "PLN",
        "name_plural": "Polish zlotys"
    },
    "PYG": {
        "symbol": "₲",
        "name": "Paraguayan Guarani",
        "symbol_native": "₲",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "PYG",
        "name_plural": "Paraguayan guaranis"
    },
    "QAR": {
        "symbol": "QR",
        "name": "Qatari Rial",
        "symbol_native": "ر.ق.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "QAR",
        "name_plural": "Qatari rials"
    },
    "RON": {
        "symbol": "RON",
        "name": "Romanian Leu",
        "symbol_native": "RON",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "RON",
        "name_plural": "Romanian lei"
    },
    "RSD": {
        "symbol": "din.",
        "name": "Serbian Dinar",
        "symbol_native": "дин.",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "RSD",
        "name_plural": "Serbian dinars"
    },
    "RUB": {
        "symbol": "RUB",
        "name": "Russian Ruble",
        "symbol_native": "руб.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "RUB",
        "name_plural": "Russian rubles"
    },
    "RWF": {
        "symbol": "RWF",
        "name": "Rwandan Franc",
        "symbol_native": "FR",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "RWF",
        "name_plural": "Rwandan francs"
    },
    "SAR": {
        "symbol": "SR",
        "name": "Saudi Riyal",
        "symbol_native": "ر.س.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "SAR",
        "name_plural": "Saudi riyals"
    },
    "SDG": {
        "symbol": "SDG",
        "name": "Sudanese Pound",
        "symbol_native": "SDG",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "SDG",
        "name_plural": "Sudanese pounds"
    },
    "SEK": {
        "symbol": "Skr",
        "name": "Swedish Krona",
        "symbol_native": "kr",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "SEK",
        "name_plural": "Swedish kronor"
    },
    "SGD": {
        "symbol": "S$",
        "name": "Singapore Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "SGD",
        "name_plural": "Singapore dollars"
    },
    "SOS": {
        "symbol": "Ssh",
        "name": "Somali Shilling",
        "symbol_native": "Ssh",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "SOS",
        "name_plural": "Somali shillings"
    },
    "SYP": {
        "symbol": "SY£",
        "name": "Syrian Pound",
        "symbol_native": "ل.س.‏",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "SYP",
        "name_plural": "Syrian pounds"
    },
    "THB": {
        "symbol": "฿",
        "name": "Thai Baht",
        "symbol_native": "฿",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "THB",
        "name_plural": "Thai baht"
    },
    "TND": {
        "symbol": "DT",
        "name": "Tunisian Dinar",
        "symbol_native": "د.ت.‏",
        "decimal_digits": 3,
        "rounding": 0,
        "code": "TND",
        "name_plural": "Tunisian dinars"
    },
    "TOP": {
        "symbol": "T$",
        "name": "Tongan Paʻanga",
        "symbol_native": "T$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "TOP",
        "name_plural": "Tongan paʻanga"
    },
    "TRY": {
        "symbol": "TL",
        "name": "Turkish Lira",
        "symbol_native": "TL",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "TRY",
        "name_plural": "Turkish Lira"
    },
    "TTD": {
        "symbol": "TT$",
        "name": "Trinidad and Tobago Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "TTD",
        "name_plural": "Trinidad and Tobago dollars"
    },
    "TWD": {
        "symbol": "NT$",
        "name": "New Taiwan Dollar",
        "symbol_native": "NT$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "TWD",
        "name_plural": "New Taiwan dollars"
    },
    "TZS": {
        "symbol": "TSh",
        "name": "Tanzanian Shilling",
        "symbol_native": "TSh",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "TZS",
        "name_plural": "Tanzanian shillings"
    },
    "UAH": {
        "symbol": "₴",
        "name": "Ukrainian Hryvnia",
        "symbol_native": "₴",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "UAH",
        "name_plural": "Ukrainian hryvnias"
    },
    "UGX": {
        "symbol": "USh",
        "name": "Ugandan Shilling",
        "symbol_native": "USh",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "UGX",
        "name_plural": "Ugandan shillings"
    },
    "UYU": {
        "symbol": "$U",
        "name": "Uruguayan Peso",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "UYU",
        "name_plural": "Uruguayan pesos"
    },
    "UZS": {
        "symbol": "UZS",
        "name": "Uzbekistan Som",
        "symbol_native": "UZS",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "UZS",
        "name_plural": "Uzbekistan som"
    },
    "VEF": {
        "symbol": "Bs.F.",
        "name": "Venezuelan Bolívar",
        "symbol_native": "Bs.F.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "VEF",
        "name_plural": "Venezuelan bolívars"
    },
    "VND": {
        "symbol": "₫",
        "name": "Vietnamese Dong",
        "symbol_native": "₫",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "VND",
        "name_plural": "Vietnamese dong"
    },
    "XAF": {
        "symbol": "FCFA",
        "name": "CFA Franc BEAC",
        "symbol_native": "FCFA",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "XAF",
        "name_plural": "CFA francs BEAC"
    },
    "XOF": {
        "symbol": "CFA",
        "name": "CFA Franc BCEAO",
        "symbol_native": "CFA",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "XOF",
        "name_plural": "CFA francs BCEAO"
    },
    "YER": {
        "symbol": "YR",
        "name": "Yemeni Rial",
        "symbol_native": "ر.ي.‏",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "YER",
        "name_plural": "Yemeni rials"
    },
    "ZAR": {
        "symbol": "R",
        "name": "South African Rand",
        "symbol_native": "R",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "ZAR",
        "name_plural": "South African rand"
    },
    "ZMK": {
        "symbol": "ZK",
        "name": "Zambian Kwacha",
        "symbol_native": "ZK",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "ZMK",
        "name_plural": "Zambian kwachas"
    }
}
}
