import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import RightArrow from "@material-ui/icons/KeyboardArrowRight";
import DeleteIcon from "@material-ui/icons/Delete";
import LockIcon from "@material-ui/icons/Lock";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import { Elements, StripeProvider } from "react-stripe-elements";
import { CheckoutForm } from "./index";
import { db, cf } from "../firebase";
import PaymentIcon from "@material-ui/icons/Payment";
import CouponIcon from "@material-ui/icons/Style";
import BackArrow from "@material-ui/icons/KeyboardBackspace";
import _ from "lodash";
import withSizes from "react-sizes";
import LinearProgress from "@material-ui/core/LinearProgress";
import jwt from "jsonwebtoken";
import Fade from "react-reveal/Fade";
import QueryString from "query-string";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		maxWidth: 500
	},
	formControl: {
		margin: theme.spacing(1)
		// width: '21em',
	},
	formControl2: {
		display: "inline",
		margin: theme.spacing(1),
		position: "inherit"
	},
	group: {
		margin: theme.spacing(1, 0),
		display: "inline-block"
	},
	button: {
		display: "inline-block"
	},
	stripeNewEntry: {
		margin: "1em 0em"
	},
	infoDiv: {
		// backgroundColor: '#f6f6f8',
		// padding: theme.spacing(1,2),
	},
	contentDiv: {
		padding: theme.spacing(0, 2)
	}
}));

function PaymentSelector(props) {
	const { alldata } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [data, setData] = useState(alldata.data);
	const [couponAdded, setCouponAdded] = useState(false);
	const [openCard, setOpenCard] = useState(false);
	const [openCode, setOpenCode] = useState(false);
	const [show, setShow] = useState(
		props.anonymous ? false : _.isEmpty(data.payment) ? false : true
	);

	let p = QueryString.parse(window.location.search);
	let storeName = p.s;
	var couponNotReady = true;
	if (storeName === "zanerobe") {
		couponNotReady = false;
	}
	// couponNotReady = false

	var cardString;
	if (_.isEmpty(data.payment)) {
		cardString = "Enter your payment details...";
	} else {
		cardString = `${data.payment.type}\u00A0\u00A0\u00A0\u00A0 •••• ${data.payment.last4}`;
		let month = data.payment.month;
		if (month < 10) {
			cardString = cardString + `\u00A0\u00A0\u00A0\u00A0 0${month}/${data.payment.year}`;
		} else {
			cardString = cardString + `\u00A0\u00A0\u00A0\u00A0 ${month}/${data.payment.year}`;
		}
	}

	const [selectedCard, setSelectedCard] = useState(cardString);
	const [selectedCode, setSelectedCode] = useState("Enter Code");

	function handleClickOpenCard() {
		setShow(false);
		props.parentCallbackChangeCard(true);
	}

	function handleClickOpenCode() {
		setOpenCode(true);
	}

	const handleCloseCard = value => {
		setOpenCard(false);
		setShow(true);
		setSelectedCard(value);
		// add call back to offer.js to handle change of payment
		if (value !== "Enter your payment details...") {
			props.parentCallbackCard(true);
		} else {
			props.parentCallbackCard(false);
		}
	};

	const handleCloseCode = (
		success,
		couponToken,
		couponValue,
		couponString,
		shippingUpdateRequired
	) => {
		if (success) {
			setCouponAdded(true);
		}
		setOpenCode(false);
		setSelectedCode(couponString);
		props.parentCallbackCoupon(
			success,
			couponToken,
			couponValue,
			couponString,
			shippingUpdateRequired
		);
		// add call back to offer.js to handle change of payment
	};

	const handleRemoveCoupon = () => {
		setSelectedCode("Enter Code");
		setCouponAdded(false);
		props.handleRemoveCoupon();
	};

	function errorMessage(message) {
		props.errorMessage(message);
		props.parentCallbackCard(true);
	}

	function popError(type) {
		props.popError(type);
	}

	const [add, setAdd] = useState(false);
	const [done, setDone] = useState(false);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (alldata.addCard) {
			setAdd(true);
		}
	}, [alldata.addCard]);

	function handleClose() {
		handleCloseCard(selectedCard);
	}

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleCloseDialog = () => {
		setOpen(false);
	};

	function errorMessage(message) {
		props.errorMessage(message);
		setDone(false);
		setAdd(false);
	}

	const returnStatus = message => {
		var selectedCard = `${message.type}\u00A0\u00A0\u00A0\u00A0 •••• ${message.last4}`;
		let month = message.month;
		if (month < 10) {
			selectedCard = selectedCard + `\u00A0\u00A0\u00A0\u00A0 0${month}/${message.year}`;
		} else {
			selectedCard = selectedCard + `\u00A0\u00A0\u00A0\u00A0 ${month}/${message.year}`;
		}
		setDone(true);
		handleCloseCard(selectedCard);
	};

	return (
		<div className={classes.root}>
			<CodeDialog
				isMobile={props.isMobile}
				data={data}
				selectedCode={selectedCode}
				open={openCode}
				onClose={handleCloseCode}
				variants={alldata.variants}
				prices={alldata.prices}
				quantities={alldata.quantities}
				token={alldata.token}
				shipping={alldata.selectedShipping}
				storeName={alldata.storeName}
				cartTotal={alldata.cartTotal}
				cartAbandoned={alldata.cartAbandoned}
				popError={popError}
			/>
			<Paper elevation={0} className={classes.infoDiv}>
				{couponNotReady ? null : couponAdded ? (
					<div
						onClick={handleClickOpenCode}
						style={{ cursor: "pointer", padding: "1em" }}
					>
						<Typography
							variant="body1"
							color="textSecondary"
							style={{ fontWeight: "700" }}
						>
							COUPON
						</Typography>
						<Typography variant="body1" style={{ fontWeight: "700" }}>
							{selectedCode}
						</Typography>
						<IconButton
							className={classes.button}
							style={{ float: "right", margin: "-2em 0em" }}
							aria-label="Edit"
							onClick={handleRemoveCoupon}
						>
							<DeleteIcon />
						</IconButton>
						<br />
						<Divider />
					</div>
				) : (
					<div
						onClick={handleClickOpenCode}
						style={{ cursor: "pointer", padding: "1em" }}
					>
						<Typography
							variant="body1"
							color="textSecondary"
							style={{ fontWeight: "700" }}
						>
							COUPON
						</Typography>
						<Typography variant="body1" style={{ fontWeight: "700" }}>
							<CouponIcon style={{ verticalAlign: "middle" }} /> {selectedCode}
						</Typography>
						<IconButton
							className={classes.button}
							style={{ float: "right", margin: "-2em 0em" }}
							aria-label="Edit"
							onClick={handleClickOpenCode}
						>
							<RightArrow />
						</IconButton>
						<br />
						<Divider />
					</div>
				)}

				<div
					style={{
						display: !show ? "none" : "block"
						// marginTop: "-1em"
					}}
				>
					<Fade>
						<div
							onClick={handleClickOpenCard}
							style={{ cursor: "pointer", padding: "1em" }}
						>
							<Typography
								variant="body1"
								color="textSecondary"
								style={{ fontWeight: "700" }}
							>
								PAYMENT
							</Typography>
							<Typography
								variant="body1"
								style={{
									fontWeight: "700"
								}}
							>
								<PaymentIcon style={{ verticalAlign: "middle" }} /> {selectedCard}
							</Typography>
							<IconButton
								className={classes.button}
								style={{ float: "right", margin: "-2em 0em" }}
								aria-label="Edit"
								onClick={handleClickOpenCard}
							>
								<RightArrow />
							</IconButton>
						</div>
					</Fade>
				</div>
				<div style={{ display: show ? "none" : "block" }}>
					<Fade>
						<div>
							<div
								style={{
									padding: "0.5em 1em",
									fontWeight: 700,
									color: "#929292",
									textAlign: "center",
									fontSize: props.isMobile ? "0.9em" : "1em"
								}}
							>
								<LockIcon
									style={{
										verticalAlign: "text-top",
										fontSize: props.isMobile ? "1.4em" : "1.2em",
										color: "#22c78e"
									}}
								/>
								<span style={{ color: "#22c78e" }}>Payment secured</span> using
								AES-256 encryption
							</div>
							<Fade>
								<div style={{ textAlign: "center" }}>
									<div style={{ padding: "0em 1em" }}>
										<StripeProvider apiKey="pk_live_maA6v1kcLz9op0OzwcwpCtCQ">
											<div className="example">
												<Elements>
													<CheckoutForm
														isMobile={props.isMobile}
														darkMode={theme.palette.type}
														add={add}
														errorMessage={errorMessage}
														returnStatus={returnStatus}
														resetSave={() => setAdd(false)}
													/>
												</Elements>
											</div>
										</StripeProvider>
									</div>
									{props.mpOffer ? null : (
										<p
											style={{
												backgroundColor: theme.palette.secondary.main,
												fontSize: props.isMobile ? "0.95em" : "1em",
												fontWeight: "700",
												textAlign: "center"
											}}
										>
											You will ONLY be charged if your offer is accepted.
										</p>
									)}
									{props.mpOffer ? null : <Divider />}
									{props.mpOffer ? null : (
										<p
											style={{
												color: "#929292",
												textAlign: "center",
												fontWeight: 700,
												display: "inline-block"
											}}
										>
											<u
												style={{
													cursor: "pointer"
												}}
												onClick={handleClickOpen}
											>
												Why do I need to enter card details?
											</u>
										</p>
									)}
									<Dialog
										open={open}
										onClose={handleCloseDialog}
										fullScreen={props.isMobile ? true : false}
										aria-labelledby="alert-dialog-title"
										aria-describedby="alert-dialog-description"
									>
										<div
											style={{
												padding: props.isMobile ? "3em 2em" : "1em 2em",
												maxWidth: 500
											}}
										>
											<Fade>
												<p
													style={{
														margin: "0em",
														color: theme.palette.primary.main
													}}
												>
													<BackArrow
														onClick={handleCloseDialog}
														style={{
															cursor: "pointer"
														}}
													/>
												</p>
												<p
													style={{
														fontWeight: 700,
														fontSize: props.isMobile
															? "1.3em"
															: "1.2em",
														marginTop: "0em"
													}}
												>
													WHY DO I NEED TO ENTER CARD DETAILS?
												</p>
												<p>
													Entering your card details ensures an authentic
													offer for the retailer.
												</p>
												<p>
													You are only charged the value of your offer
													order when the retailer accepts it.
												</p>
												<p>
													You are not charged if your offer is rejected.
												</p>
											</Fade>
										</div>
									</Dialog>

									{add && !done ? <LinearProgress /> : null}
								</div>
							</Fade>
						</div>
					</Fade>
				</div>
			</Paper>
		</div>
	);
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600
});
export default withSizes(mapSizesToProps)(PaymentSelector);

function CodeDialog(props) {
	const { onClose, selectedCode, ...other } = props;
	const [values, setValues] = useState({
		code: "",
		add: false,
		couponLoading: false
	});
	const theme = useTheme();

	function popError(type) {
		props.popError(type);
	}

	function noError() {
		var flag = true;
		var emptyFlag = true;
		if (!props.cartAbandoned) {
			for (let i in props.prices) {
				emptyFlag = false;
				if (
					props.prices[i] === "" ||
					props.prices[i] === undefined ||
					props.prices[i] === null
				) {
					popError(1);
					return false;
				} else if (Number(props.prices[i]) < 1) {
					popError(2);
					return false;
				}

				for (let j in props.data.products) {
					if (props.data.products[j].offerable === true) {
						let temp = props.data.products[j];
						for (let k in temp.variants) {
							for (let n in props.variants) {
								if (temp.variants[k].id === props.variants[n]) {
									if (
										Number(i) === temp.id &&
										Number(props.prices[i]) >= Number(temp.variants[k].price)
									) {
										popError(3);
										return false;
									}
								}
							}
						}
					}
				}
			}

			if (emptyFlag) {
				popError(1);
				console.log("error at 534");
				return false;
			}
		}
		if (flag) {
			db.getAddress("shippingAddress").then(snapshot => {
				if (_.isEmpty(snapshot)) {
					popError(4);
					return false;
				}
			});

			db.getAddress("billingAddress").then(snapshot => {
				if (_.isEmpty(snapshot) && flag) {
					popError(5);
					return false;
				}
			});

			if (_.isEmpty(props.shipping) && flag) {
				popError(6);
				return false;
			}
		}
		return true;
	}

	function handleClose() {
		onClose(false, "", 0, "Enter Code", false);
	}

	async function handleAdd() {
		setValues({ ...values, couponLoading: true });

		if (noError()) {
			var products = [];
			for (var i in props.data.products) {
				var prod = {
					offerable: props.data.products[i].offerable,
					variantID: Number(props.variants[props.data.products[i].id]),
					bidPrice: Number(props.prices[props.data.products[i].id]),
					productID: props.data.products[i].id,
					quantity:
						props.quantities[i] === undefined || props.quantities[i] === null
							? 1
							: props.quantities[i]
				};
				products.push(prod);
			}
			var offer = {
				token: props.token,
				shipping: props.shipping,
				storeName: props.storeName,
				offer: products,
				code: values.code,
				currency: props.data.currency,
				cartTotal: props.cartTotal,
				cartAbandoned: props.cartAbandoned
			};
			let res;
			let coupon;
			try {
				res = await cf.applyCoupon(offer);
				coupon = jwt.decode(res.data);
			} catch (e) {
				popError(9);
				setValues({ ...values, couponLoading: false });
				onClose(false, "", 0, "Enter Code", false);
				return false;
			}
			let coup;
			if (coupon.data.couponValue + props.cartTotal < 0) {
				coup = -props.cartTotal;
			} else {
				coup = coupon.data.couponValue;
			}
			if (
				coupon.data.shippingAction !== undefined &&
				coupon.data.shippingAction === "updateRequired"
			) {
				popError(10);
				onClose(true, res.data, coup, values.code, true);
			} else {
				onClose(true, res.data, coup, values.code, false);
			}
			setValues({ ...values, couponLoading: false, add: true });
			return true;
		} else {
			setValues({ ...values, couponLoading: false });
			onClose(false, "", 0, "Enter Code", false);
			return false;
		}
	}

	const handleChange = name => event => {
		setValues({ ...values, [name]: event.target.value });
	};

	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby="simple-dialog-title"
			{...other}
			justify="center"
			alignItems="center"
		>
			{values.couponLoading ? (
				<DialogContent>
					<Lottie
						height={100}
						width={100}
						options={{
							loop: true,
							autoplay: true,
							animationData: loader.default,
							rendererSettings: {
								preserveAspectRatio: "xMidYMid slice"
							}
						}}
					/>
					<h3>Checking coupon</h3>
				</DialogContent>
			) : (
				<div>
					<DialogTitle id="simple-dialog-title">
						<p
							style={{
								margin: "0em",
								color: theme.palette.primary.main
							}}
						>
							<BackArrow onClick={handleClose} style={{ cursor: "pointer" }} />
						</p>
						<span
							style={{
								color: theme.palette.primary.main,
								fontWeight: 700
							}}
						>
							ENTER
						</span>
						CODE
					</DialogTitle>
					<DialogContent>
						<TextField
							autoFocus
							margin="dense"
							id="code"
							label="Code"
							fullWidth
							value={values.code}
							onChange={handleChange("code")}
						/>
					</DialogContent>
					<DialogActions style={{ padding: "8px 24px" }}>
						<Button
							onClick={handleAdd}
							style={{
								color: " #35B2FF",
								backgroundColor: "#35B2FF30",
								fontWeight: "700"
							}}
						>
							Add
						</Button>
					</DialogActions>
				</div>
			)}
		</Dialog>
	);
}

CodeDialog.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool,
	selectedCode: PropTypes.string
};
