import React, { useState } from "react";
import { withStyles, useTheme } from "@material-ui/core/styles";
import {
	Drawer,
	List,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
	IconButton,
	Switch
} from "@material-ui/core";
import Person from "@material-ui/icons/Person";
import Help from "@material-ui/icons/HelpOutline";
import Explore from "@material-ui/icons/Explore";
import MenuIcon from "@material-ui/icons/Menu";
import ShoppingIcon from "@material-ui/icons/ShoppingCart";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import StoreIcon from "@material-ui/icons/Store";
import Brightness3Icon from "@material-ui/icons/Brightness3";
import { Link, withRouter } from "react-router-dom";

const styles = theme => ({
	list: {
		width: 250
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},

	text: {
		color: theme.palette.secondary.dark,
		fontWeight: "600",
		lineHeight: "1.5em"
	},
	nested: {
		paddingLeft: theme.spacing(2)
	}
});

function TemporaryDrawer(props) {
	const { classes } = props;
	const theme = useTheme();
	const [state, setState] = useState({
		left: false,
		nightToggle:
			localStorage.getItem("darkMode") === "true"
				? Boolean(localStorage.getItem("darkMode"))
				: false
	});

	const handleChange = name => event => {
		setState({ ...state, [name]: event.target.checked });
		if (name === "nightToggle") {
			localStorage.setItem("darkMode", event.target.checked);
			props.darkModeCallback(event.target.checked);
			// window.location.reload();
		}
	};

	const toggleDrawer = (side, open) => event => {
		if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}

		setState({ ...state, [side]: open });
	};

	const sideList = side => (
		<div
			className={classes.list}
			role="presentation"
			onClick={toggleDrawer(side, false)}
			onKeyDown={toggleDrawer(side, false)}
		>
			<a href="https://enteroffer.com" target="_blank" rel="noopener noreferrer">
				<img
					src={
						theme.palette.type === "dark"
							? "https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlueAlt.png"
							: "https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png"
					}
					alt="Logo"
					style={{ maxWidth: "12em", padding: "1em" }}
				/>
			</a>
			<List>
				<Link
					to={{ pathname: "/", search: props.location.search }}
					style={{
						textDecoration: "none",
						color: theme.palette.secondary.main
					}}
				>
					<ListItem button key="Offer" className={classes.nested}>
						<ListItemIcon>
							{props.location.pathname === "/" ? (
								<ShoppingIcon color="primary" />
							) : (
								<ShoppingIcon />
							)}
						</ListItemIcon>
						<ListItemText className={classes.text} primary="Offer" />
					</ListItem>
				</Link>
				{props.anonymous ? null : (
					<Link
						to={{
							pathname: "/settings",
							search: props.location.search
						}}
						style={{
							textDecoration: "none",
							color: theme.palette.primary.main
						}}
					>
						<ListItem button key="Account" className={classes.nested}>
							<ListItemIcon>
								{props.location.pathname === "/settings" ? (
									<Person color="primary" />
								) : (
									<Person />
								)}
							</ListItemIcon>
							<ListItemText className={classes.text} primary="Account Settings" />
						</ListItem>
					</Link>
				)}
				<Link
					to={{ pathname: "/help", search: props.location.search }}
					style={{
						textDecoration: "none",
						color: theme.palette.primary.main
					}}
				>
					<ListItem button key="Help" className={classes.nested}>
						<ListItemIcon>
							{props.location.pathname === "/help" ? (
								<Help color="primary" />
							) : (
								<Help />
							)}
						</ListItemIcon>
						<ListItemText className={classes.text} primary="Help" />
					</ListItem>
				</Link>
				<Divider style={{ margin: "0.5em 0em" }} />
				{props.mp ? (
					<a
						href="https://market.enteroffer.com"
						style={{
							textDecoration: "none",
							color: theme.palette.primary.main
						}}
					>
						<ListItem button key="Marketplace" className={classes.nested}>
							<ListItemIcon>
								<StoreIcon />
							</ListItemIcon>
							<ListItemText className={classes.text} primary="Marketplace" />
						</ListItem>
					</a>
				) : null}
				<a
					href="https://enteroffer.com"
					target="_blank"
					rel="noopener noreferrer"
					style={{
						textDecoration: "none",
						color: theme.palette.primary.main
					}}
				>
					<ListItem button key="Website" className={classes.nested}>
						<ListItemIcon>
							<Explore />
						</ListItemIcon>
						<ListItemText className={classes.text} primary="Website" />
					</ListItem>
				</a>
			</List>
		</div>
	);

	return (
		<div style={{ width: "3em", zoom: "1.2", display: "inline-block" }}>
			<IconButton
				edge="start"
				onClick={toggleDrawer("left", true)}
				className={classes.menuButton}
				color="inherit"
				aria-label="menu"
			>
				<MenuIcon style={{ color: theme.palette.secondary.dark }} />
			</IconButton>
			<Drawer open={state.left} onClose={toggleDrawer("left", false)}>
				{sideList("left")}
				<div
					style={{
						position: "absolute",
						bottom: "50px",
						textAlign: "center",
						width: "100%"
					}}
				>
					<WbSunnyIcon style={{ verticalAlign: "middle" }} />
					<Switch
						checked={Boolean(state.nightToggle)}
						onChange={handleChange("nightToggle")}
						value="nightToggle"
						color="primary"
					/>
					<Brightness3Icon style={{ verticalAlign: "middle" }} />
				</div>
			</Drawer>
		</div>
	);
}

export default withRouter(withStyles(styles)(TemporaryDrawer));
